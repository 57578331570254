import { overlayStore } from "./OverlayStore";

export default function Overlays() {

    // An intermeidary for our overlays, 
    // easier way of rendering our overlays in react components
 
    const { overlays, sidePopouts } = overlayStore((state: any) => state);
    
    return (
        <>
            {overlays.map((overlay: any) => {
                return overlay.overlay
            })}


            {sidePopouts.map((popout: any) => {
                return popout.overlay;
            })}

            {/*
                Future version should look like this

                {cards.map((card: any) => {
                    return card.overlay;
                })}

                {popups.map((popup: any) => {
                    return popup.overlay;
                })}

                We could even go a step furhter and iterate through these as mapped keys

            */}
        </>
    );
}