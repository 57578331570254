
import type { section } from './types';


export const adminSections: section[] = [
    {
      title: "Location",
      views: [
        "sites", 
        "infoscreens"
      ],
    },
  
    // {
    //   title: "Email",
    //   views: [
    //     "subscribers",
    //     "forms",
    //     "e-mail flows",
    //     "newsletter",
    //   ],
    // },
  
    // {
    //   title: "Admin & Logs",
    //   views: [
    //     "webflow content",
    //     "message overview",
    //   ],
    // },
  
    {
      title: "What's on",
      views: [
        // "smart phrases",
        "amenities (Place)",
      ],
    },
  
  ];

 