import { faCamera } from "@fortawesome/pro-light-svg-icons";
import { faPlayCircle } from "@fortawesome/pro-duotone-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import "./OneMedia.scss";
import env from "../../../environment.json";
import VerifyMethods from "../../VerifyMethods";
import ReactPlayer from "react-player";
import VideoThumbnail from "react-video-thumbnail";
import noImage from "../../images/empty.jpg";
import axios from "axios";
import languages from "../language.json";

export default function OneMedia({
  style,
  media,
  media: { extension: ext, id, src },
  disableChange,
  size,
  className,
  setSiteRef,
  siteRef,
  openOverlay,
  whatMedia,
  onClick,
  draggable,
  onDragStart,
  onDragOver,
  onDragEnd,
  onDrop,
  imgFormat,
  videoThumbnail,
}) {
  // const {media,media:{extension:ext,id},disableChange,size,className,setSiteRef,siteRef,openOverlay,whatMedia,onClick,draggable,onDragStart,onDragOver,onDragEnd,onDrop,imgFormat,videoThumbnail} = props;
  const language = localStorage.getItem("language")
    ? localStorage.getItem("language")
    : "english";
  const lang = languages[language].one_media;
  const [foundExtension, setFoundExtension] = useState(undefined);

  useEffect(() => {
    if (media)
      if (!Boolean(ext) && !src) {
        axios
          .get(env.protocol + env.env + "/api/public/GetMedia.php?id=" + id)
          .then((response) => {
            if (response.data)
              if (response.data.file_extension)
                setFoundExtension(response.data.file_extension);
          })
          .catch((error) => console.error(error));
      }
  }, [media, id, ext, src]);

  const srcExtension = src
    ? src.substring(src.lastIndexOf(".") + 1)
    : undefined;
  const extension = media
    ? srcExtension
      ? srcExtension
      : ext !== undefined && ext !== ""
      ? ext
      : foundExtension !== undefined
      ? foundExtension
      : undefined
    : undefined;

  return (
    <div
      style={style}
      className={
        "one-media " +
        (disableChange ? "" : "clickable ") +
        (size ? size : "") +
        (className ? className : "")
      }
    >
      <div className={"upload-box"}>
        <div
          className="image-container"
          draggable={draggable}
          onDragStart={(e) => {
            if (onDragStart) onDragStart(e);
          }}
          onDragOver={(e) => {
            if (onDragOver) onDragOver(e);
          }}
          onDragEnd={(e) => {
            if (onDragEnd) onDragEnd(e);
          }}
          onDrop={(e) => {
            if (onDrop) onDrop(e);
          }}
        >
          {disableChange === true ? (
            ""
          ) : (
            <div
              className="add-media"
              title="Choose medium"
              onClick={() => {
                if (!disableChange) {
                  if (setSiteRef && siteRef) {
                    setSiteRef(siteRef);
                  }
                  if (openOverlay) {
                    openOverlay("mediaChoose", {
                      filter: { mediaType: whatMedia },
                      singleMedia: true,
                    });
                  }
                  if (onClick) onClick();
                }
              }}
            >
              <FontAwesomeIcon icon={faCamera} />
              <div className="text">{lang.upload_image}</div>
            </div>
          )}

          {media && (extension || src) ? (
            !VerifyMethods.extensionIsVideo(extension) ? (
              extension !== undefined && extension !== "" ? (
                <img
                  src={
                    media.medium
                      ? media.medium.large
                      : src ??
                        env.protocol +
                          env.env +
                          "/uploads/" +
                          media.file_name +
                          (imgFormat
                            ? "_" + imgFormat + "."
                            : ["gif", "svg"].includes(media.extension)
                            ? "."
                            : "_large.") +
                          extension
                  }
                  alt="media"
                  draggable="false"
                />
              ) : (
                <img
                  className="no-image"
                  src={noImage}
                  alt={"none"}
                  draggable="false"
                />
              )
            ) : !videoThumbnail ? (
              <ReactPlayer
                url={
                  src ??
                  env.protocol +
                    env.env +
                    "/uploads/" +
                    media.file_name +
                    "." +
                    extension
                }
                controls={true}
                height="100%"
                width="100%"
                style={{ pointerEvents: "auto" }}
              />
            ) : (
              <span className="video-thumbnail">
                <VideoThumbnail
                  videoUrl={
                    env.protocol +
                    env.env +
                    "/uploads/" +
                    media.file_name +
                    "." +
                    extension
                  }
                  snapshotAtTime={10}
                />
                <FontAwesomeIcon icon={faPlayCircle} />
              </span>
            )
          ) : (
            // <FontAwesomeIcon
            //   className="before-image"
            //   icon={props.faIcon ? props.faIcon : faPhotoVideo}
            // />
            <div className="missing-image">
              <img src={noImage} alt="missing" />
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
