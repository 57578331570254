import Select from "react-select";
import { useState, useEffect } from "react";
import { VfiCheckbox } from "../../../../../assets/VfiCheckbox";

/**
 * Extract the selected property
 *
 * Will take the selected property with all its data from the system properties based on the
 * currently processed pricing element. Returns object formatted for the properties select
 *
 * @param 		{array} 	properties 											Properties on the system
 * @param 		{object} 	element 												Currently processed pricing element
 *
 * @returns 	{object} 																	Object, formatted for the properties select
 *
 * @author 					Pætur Mortensen
 */
function extract_selected_property(properties, element) {
  // Get the selected property
  const prop = properties.filter(
    (item) => item.slug === element.property.property
  )[0];
  // ...and return it formatted
  return { label: prop.name, value: prop.value, type: prop.type, prop };
}

/**
 * Extract the possible values from a property
 *
 * @param 		{array} 		properties 					All properties used in the system
 * @param 		{object} 		element 						Currently processed pricing element
 *
 * @returns 	{mixed} 												Possible values, limits, enum etc, depending on the
 * 																						property
 *
 * @author 					Pætur Mortensen
 */
function extract_property_values(properties, element) {
  // Get the selected property
  const prop = properties.filter(
    (item) => item.slug === element.property.property
  )[0];
  // Depending on type of property...
  switch (prop.type) {
    case "numeric":
      // Numeric property, get min- and max values for the number input
      return { maxVal: prop.maxVal, minVal: prop.minVal };
    case "enum":
      // Enum property (fixed set of options)
      return prop.enum;
    case "string":
      // String property (fixed set of options)
      return prop.stringOptions;
    default:
      return "";
  }
}

/**
 * Get operator value
 *
 * Gets an  operator value object for insertion into the operator select, based on the operator
 * value string e.g. "eg", or "lt"
 *
 * @param 		{object} 	element
 * @returns
 */
function get_operator_value(element) {
  // If the element has a property, and this property has an operator...
  if (element.property && element.property.operator) {
    // Get all operators
    const operators = get_operators();
    // Filter out the value object from the operators (for use with the select)
    return operators.filter(
      (operator) => operator.value === element.property.operator
    )[0];
  } else {
    // If the element does not have an operator, just return empty selection object
    return { value: "", label: "" };
  }
}

/**
 * Get operators for operator select
 *
 * @returns 		{array} 									Array of operator values and labels
 *
 * @author 					Pætur Mortensen
 */
function get_operators() {
  return [
    { value: "eq", label: "=" },
    { value: "lt", label: "<" },
    { value: "gt", label: ">" },
    { value: "lte", label: "<=" },
    { value: "gte", label: ">=" },
  ];
}

/**
 * Select properties for price filtering
 *
 * Gives the user the option to select which properties and which values are to apply to the price
 * rule they are setting.
 *
 * @param 		{array}  		properties 											List of properties from the system
 * @param 		{object}  	element 												Currently processed pricing rule
 * @param 		{object} 		updateConf 											Object to hold update values
 *
 * @returns 	{jsx} 																			Form fields for property selection
 *
 * @author 					Pætur Mortensen
 */
function PropertiesSelect({ properties, element, updateConf }) {
  // Type of options (enum, string, boolean...)
  const [optionType, setOptionType] = useState(element.property?.type || null);
  // What option is selected (determines what type of values we can have)
  const [selectedProperty, setSelectedProperty] = useState(() => {
    // If the element has a property set...
    if (element.property && element.property.property) {
      // Extract the selected property
      return extract_selected_property(properties, element);
    } else return "";
  });
  // What numeric operator is selected
  const [selectedOperator, setSelectedOperator] = useState(() =>
    get_operator_value(element)
  );
  // Property value options (mixed data types)
  const [propertyValues, setPropertyValues] = useState(() => {
    if (element.property && element.property.property) {
      return extract_property_values(properties, element);
    }
  });
  // Property value. The set value for this property
  const [propertyValue, setPropertyValue] = useState(() => {
    if (element.property) {
      return element.property.value || "";
    }
  });

  // Call each time any property value changes
  useEffect(() => {
    let addProp = false;

    // Validate property selection
    if (selectedProperty) {
      element.update = true;
      // If this is a numeric property (operator must be selected)
      if (selectedProperty.type === "numeric") {
        // If operator has been selected and property value has been selected
        if (selectedOperator && propertyValue) {
          addProp = true;
        }
      } else if (selectedProperty.type === "boolean") {
        // Boolean property has been set
        addProp = true;
      } else if (propertyValue) {
        // Property is not numeric or boolean, just ensure that a value has been set
        addProp = true;
      }
    }

    // If we are to add the property....
    if (addProp) {
      element.property = {
        property: selectedProperty.prop.slug,
        operator: selectedOperator.value,
        value: propertyValue,
        type: selectedProperty.type,
      };
    }

    // Find the element by uuid
    let findex = updateConf.prices.findIndex((x) => x.uuid === element.uuid);
    if (findex === -1) {
      // If element did not exist in updateConf, push it
      updateConf.prices.push(element);
    } else {
      // Element exists, replace it
      updateConf.prices[findex] = element;
    }
  }, [selectedProperty, selectedOperator, propertyValue]);

  /**
   * Get options for properties select
   *
   * @param 		{array} 	props 				Properties from server
   *
   * @returns 	{array} 								Array of options for select
   *
   * @author 					Pætur Mortensen
   */
  function get_options(props) {
    return props.map((prop) => {
      return {
        value: prop.slug,
        label: prop.name,
        type: prop.type,
        prop: prop,
      };
    });
  }

  /**
   * Handle change of the main property select
   *
   * @param 	{object} 	e  								React Select synthetic event object
   *
   * @author 					Pætur Mortensen
   */
  function handlePropertyChange(e) {
    const type = !!e ? e.type : null;

    // Depending on type of property...
    switch (type) {
      case "numeric":
        // Numeric property
        setPropertyValues({ maxVal: e.prop.maxVal, minVal: e.prop.minVal });
        break;
      case "enum":
        // Enum property (fixed set of options)
        setPropertyValues(e.prop.enum);
        break;
      case "string":
        // String property (fixed set of options)
        setPropertyValues(e.prop.stringOptions);
        break;
      default:
        setPropertyValues("");
    }

    setOptionType(type);

    setSelectedProperty(e);
    // Reset operator and value when changing property
    setSelectedOperator({ value: "", label: "" });
    setPropertyValue("");
  }

  /**
   * Some option types need operators
   *
   * @returns 	jsx 									operator element (select or other)
   *
   * @author 					Pætur Mortensen
   */
  function render_operator_input() {
    // Render operator based on option type
    switch (optionType) {
      case "numeric":
        return get_numeric_operator_select();
      default:
        return null;
    }

    /**
     * Get a select with numeric operators (=, <, <= etc.) for numeric filtering
     *
     * @returns jsx 										Select with operators
     *
     * @author 					Pætur Mortensen
     */
    function get_numeric_operator_select() {
      // List the available operators for the select
      const operators = get_operators();

      return (
        <Select
          className="select"
          value={selectedOperator}
          options={operators}
          onChange={(e) => {
            setSelectedOperator(
              operators.filter((item) => item.value === e.value)[0]
            );
          }}
        />
      );
    }
  }

  /**
   * Render property values depending on property type
   *
   * @returns 	jsx 													Field element for values
   *
   * @author 					Pætur Mortensen
   */
  function render_value_input() {
    // Depending on option type
    switch (optionType) {
      case "numeric":
        // Numeric properties
        return get_numeric_input();
      case "enum":
        // Enum properties
        return get_enum_input();
      case "boolean":
        // Boolean properties
        return get_boolean_checkbox();
      case "string":
        // String properties
        return get_string_input();
      default:
        return null;
    }

    /**
     * Get number input with min and max values for property
     *
     * @returns 	jsx 											Number input field
     *
     * @author 					Pætur Mortensen
     */
    function get_numeric_input() {
      return (
        <input
          type="number"
          key={selectedProperty.value}
          min={propertyValues.minVal}
          value={propertyValue}
          max={propertyValues.maxVal}
          onChange={(e) => {
            setPropertyValue(e.target.value);
          }}
          onBlur={(e) => {
            const inputVal = e.target.value;
            const max = e.target.max;
            const min = e.target.min;
            if (inputVal > max) {
              setPropertyValue(max);
            }
            if (inputVal < min) {
              setPropertyValue(min);
            }
          }}
        />
      );
    }

    /**
     * Get select with enum property values
     *
     * @returns 	jsx 										Select with enum values as options
     *
     * @author 					Pætur Mortensen
     */
    function get_enum_input() {
      // Build the options for the select
      const options = propertyValues.map((item) => {
        return { value: item.value, label: item.value };
      });

      return (
        <Select
          key={selectedProperty.value}
          className="select"
          value={{ label: propertyValue, value: propertyValue }}
          options={options}
          onChange={(e) => {
            setPropertyValue(e.value);
          }}
        />
      );
    }

    /**
     * Get checkbox for boolean property values
     *
     * @returns 	jsx 										Checkbox
     *
     * @author 					Pætur Mortensen
     */
    function get_boolean_checkbox() {
      return (
        <div className="property-bool-check">
          <VfiCheckbox
            key={selectedProperty.value}
            checked={propertyValue}
            onChange={(e) => {
              setPropertyValue(e.target.checked);
            }}
          >
            {
              <span style={{ marginLeft: "10px" }}>
                {selectedProperty.label}
              </span>
            }
          </VfiCheckbox>
        </div>
      );
    }

    /**
     * Get a select with searchable string values
     *
     * @returns 	jsx 							 					Select with property string options
     *
     * @author 					Pætur Mortensen
     */
    function get_string_input() {
      // Build the options for the select
      const options = propertyValues.map((item) => {
        return { value: item.value, label: item.value };
      });

      return (
        <Select
          key={selectedProperty.value}
          className="select"
          value={{ value: propertyValue, label: propertyValue }}
          options={options}
          onChange={(e) => {
            setPropertyValue(e.value);
          }}
        />
      );
    }
  }

  return (
    <div className="w100">
      <label>
        Properties <i>Optional</i>
      </label>
      <div className="properties-cont">
        <Select
          className="select"
          defaultValue={() => {
            if (element.property) {
              return {
                value: element.property.property,
                label: element.property.property,
              };
            }
          }}
          isClearable={true}
          options={get_options(properties)}
          onChange={(e) => {
            handlePropertyChange(e);
          }}
        />
        {render_operator_input()}
        {render_value_input()}
      </div>
    </div>
  );
}

export default PropertiesSelect;
