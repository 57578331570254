import axios from "axios";
import { create } from "zustand";
import env from "../../environment.json";
import React from "react";

export const whatsonStore = (set) => ({
  places: [],
  events: [],
  tours: [],
  hiking: [],
  tourOperators: [],
  getAllWhatson: (e) => {
    axios
      .get(
        `${env.protocol}${env.env}/api/whatson/GetAllWhatsonList?all&ignore_today_and_later`
      )
      .then((response) => {
        console.log(response);
        const data = response.data;
        const places = data.filter((f) => f.type === "place");
        const events = data.filter((f) => f.type === "event");
        const tours = data.filter((f) => f.type === "tour");
        const hiking = data.filter((f) => f.type === "hike");

        set({ places, events, tours, hiking });
      })
      .catch((error) => console.error(error));
  },
  getTourOperators: () => {
    axios
      .get(
        `${env.protocol}${env.env}/api/whatson/touroperators/GetTourOperatorsList`
      )
      .then((response) => {
        console.log(response);
        set({ tourOperators: response.data });
      })
      .catch((error) => console.error(error));
  },
});

export const useWhatsonStore = create(whatsonStore);

export const withWhatsonStore = (BaseComponent) => (props) => {
  const store = useWhatsonStore();
  return <BaseComponent {...props} whatsonStore={store} />;
};
