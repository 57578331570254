import { useEffect, useRef, useState } from 'react';
import styles from './SidePopout.module.scss';
import CloseBtn from '../../buttons/closeBtn';
import { overlayStore } from '../../OverlayStore';

interface Props {
    children: any;
    close: () => void;
}

export default function SidePopout({ children, close }: Props) {

    const [loaded, setLoaded] = useState<boolean>(false);

    const ref = useRef<any>(null);
    const closeBtnRef = useRef<any>(null);

    useEffect(() => {
        closeBtnRef.current?.focus();

        setLoaded(true);
    }, []);

    return (
        <div 
            className={`${styles.container} ${loaded? styles.loaded : ''}`}>

            {/* Close button */}

            <button
            className={styles.closeBtn}
            ref={closeBtnRef}
            onClick={() => {
                close();
            }}
            >
            <CloseBtn width={20} height={20} />
            </button>

            {/* Content */}

            <div className={styles.content}>{children}</div>
        </div>
    );
}