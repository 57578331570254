import React from "react";

/**
 * The theme components only imports it's theme CSS-file. These components are lazy
 * loaded, to enable "code splitting" (in order to avoid the themes being bundled together)
 */
const Theme1 = React.lazy(() => import("./DSTheme"));
// const Theme2 = React.lazy(() => import('./Theme2'));

export const themesToRender = {
  DS: false,
};

const ThemeSelector = ({ children, themes = themesToRender }) => (
  <>
    {/* Conditionally render theme, based on the current client context */}
    <React.Suspense fallback={() => null}>
      {themes.DS && <Theme1 />}
      {/* {shouldRenderTheme2 && <Theme2 />} */}
    </React.Suspense>
    {/* Render children immediately! */}
    {children}
  </>
);

export default ThemeSelector;
