import { useEffect, useState } from "react";
import OneMedia from "../../../assets/PageModules/OneMedia/OneMedia";
import VfiCKeditor from "../../../assets/VfiCKeditor/VfiCKeditor";
import VfiInputText from "../../../assets/VfiInputText/VfiInputText";
import VfiTextarea from "../../../assets/VfiTextarea/VfiTextarea";
import { WhatsonApprovalStatusButtons } from "../WhatsonDisplay/WhatsonContent";
import noImage from "../../../assets/images/empty.jpg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronLeft,
  faChevronRight,
} from "@fortawesome/pro-light-svg-icons";
import { capitalize, showDate } from "../../../assets/helperFunctions";

export default function GeneralOptions({
  data: d,
  whatWhatson,
  changeValue = (key, value, inWhatTable) => void 0,
  onChange = (data) => void 0,
  approvalStatusChange = (id, value) => void 0,
  className,
  selectedLanguage,
}) {
  const [data, setData] = useState(d);
  const [subOption, setSubOption] = useState("");
  const featureImage = data?.images.main ? data?.images.main[0] : undefined;
  const language = selectedLanguage === "en" ? "english" : "faroese";

  useEffect(() => {
    setData(d);
  }, [d]);

  if (data === undefined) return "";
  let content;

  const blurFunc = (key, value) => {
    if (value !== d.text[language][key]) {
      changeValue(key, value, "text");
      const changeVal = {
        ...data,
        text: {
          ...data.text,
          [language]: {
            ...data.text[language],
            [key]: value,
          },
        },
      };
      onChange(changeVal);
      setData(changeVal);
    }
  };

  switch (subOption) {
    case "current_status":
      const statuses = ["draft", "published"];
      content = (
        <div>
          <div className="small-label top-gap">CURRENT STATUS</div>
          {statuses.map((e) => (
            <label
              key={e}
              className="encapsulate status top-gap"
              onClick={() => {
                console.log("Click!!");
                console.log(new Date().getTime());
                changeValue("current_status", e, "");
              }}
            >
              <div className="text">{capitalize(e)}</div>
              <input
                type={"radio"}
                className="right-point"
                checked={data.data.current_status === e}
              />
            </label>
          ))}
        </div>
      );
      break;
    default:
      content = (
        <div className="wrap-general-options">
          <div>
            <div className="small-label top-gap">
              {whatWhatson.toUpperCase()} HEADLINE
            </div>
            <VfiInputText
              value={data.text[language].headline}
              maxLength={50}
              onChange={(e) => {
                setData({
                  ...data,
                  text: {
                    ...data.text,
                    [language]: {
                      ...data.text[language],
                      headline: e.target.value,
                    },
                  },
                });
              }}
              onBlur={(e) => {
                const value = e.target.value;
                blurFunc("headline", value);
              }}
            />
          </div>
          <div>
            <div className="small-label top-gap">EXCERPT</div>
            <VfiTextarea
              className="excerpt"
              defaultValue={data.text[language].excerpt}
              maxLength={100}
              onBlur={(e) => {
                // if (e !== d.text.excerpt) {
                //   changeValue("excerpt", e, "text");
                //   onChange({
                //     ...data,
                //     text: { ...data.text, excerpt: e },
                //   });
                //   setData({
                //     ...data,
                //     text: { ...data.text, excerpt: e },
                //   });
                // }
                blurFunc("excerpt", e);
              }}
            />
          </div>
          {/* <div className="small-label top-gap">DESCRIPTION</div>
                <VfiTextarea className="description" defaultValue={data.text.description} maxLength={1500} /> */}
          <div>
            <div className="small-label top-gap">FEATURE IMAGE</div>
            <OneMedia
              className={"feature-image"}
              media={{
                src: featureImage ? featureImage.medium : noImage,
              }}
              alt={featureImage ? featureImage.alt : "Missing media"}
              disableChange={true}
            />
          </div>
          {/* <div className="small-label top-gap">CURRENT STATUS</div> */}
          {whatWhatson !== "hiking" && (
            <div>
              <div className="small-label top-gap">APPROVAL STATUS</div>
              {data && data.data && (
                <WhatsonApprovalStatusButtons
                  element={{
                    id: data.data.id,
                    approvalStatus: data.data.approval_status,
                  }}
                  whatWhatson={whatWhatson}
                  onChange={(e) => {
                    approvalStatusChange(e.id, e.approval_status);
                  }}
                />
              )}
            </div>
          )}
          {data && data.data && (
            <div
              className="encapsulate status top-gap"
              onClick={() => {
                setSubOption("current_status");
              }}
            >
              <div className="small-label title">CURRENT STATUS</div>
              <div className="text">{capitalize(data.data.current_status)}</div>
              <div className="small-label date">
                {showDate(data.data.reg_date)}
              </div>
              <FontAwesomeIcon className="right-point" icon={faChevronRight} />
            </div>
          )}
        </div>
      );
  }

  return (
    <div className={"general-options " + (className ?? "")}>
      <div
        className={"option-title" + (subOption ? " clickable" : "")}
        onClick={() => setSubOption("")}
      >
        {subOption && <FontAwesomeIcon icon={faChevronLeft} />}General
      </div>
      {content}
    </div>
  );
}
