import React, { Component } from "react";
import env from "../../environment.json";
import Editor from "ckeditor5-custom-build/build/ckeditor";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import { removeTags } from "../helperFunctions";
import { faFontCase } from "@fortawesome/pro-duotone-svg-icons";
import "./CustomHeadings.scss";
import { faItalic } from "@fortawesome/pro-regular-svg-icons";
import { cloneDeep } from "lodash";

// function MyOwn(editor){
//   // The model-to-view converter for the <div> element (attributes are converted separately).
//   editor.conversion.for( 'downcast' ).elementToElement( {
//     model: (viewElement)=>{},
//     view: 'p'
//   } );

//   editor.conversion.for('downcast').add(
//     dispatcher => {
//       dispatcher.on('attribute',(evt,data,conversionApi)=>{
//       })
//     }
//   );
// }

// function ConvertPAttributes( editor ) {
//   // Allow <div> elements in the model.
//   editor.model.schema.register( 'p', {
//     allowWhere: '$block',
//     allowContentOf: '$root'
//   } );

//   // Allow <div> elements in the model to have all attributes.
//   editor.model.schema.addAttributeCheck( context => {
//       if ( context.endsWith( 'p' ) ) {
//           return true;
//       }
//   } );

//   // The view-to-model converter converting a view <div> with all its attributes to the model.
//   editor.conversion.for( 'upcast' ).elementToElement( {
//       view: 'p',
//       model: ( viewElement, { writer: modelWriter } ) => {

//           return modelWriter.createElement( 'p', viewElement.getAttributes() );
//       }
//   } );
//   //   // The model-to-view converter for the <div> element (attributes are converted separately).
//   editor.conversion.for( 'downcast' ).elementToElement( {
//     model: 'p',
//     view: 'p'
//   } );

//   // The model-to-view converter for <div> attributes.
//   // Note that a lower-level, event-based API is used here.
//   editor.conversion.for( 'downcast' ).add( dispatcher => {
//       dispatcher.on( 'attribute', ( evt, data, conversionApi ) => {
//           // Convert <div> attributes only.
//           if ( data.item.name != 'p' ) {
//               return;
//           }

//           const viewWriter = conversionApi.writer;
//           const viewDiv = conversionApi.mapper.toViewElement( data.item );

//           // In the model-to-view conversion we convert changes.
//           // An attribute can be added or removed or changed.
//           // The below code handles all 3 cases.
//           if ( data.attributeNewValue ) {
//               viewWriter.setAttribute( data.attributeKey, data.attributeNewValue, viewDiv );
//           } else {
//               viewWriter.removeAttribute( data.attributeKey, viewDiv );
//           }
//       } );
//   } );
// }

// function AddClassToPTag(editor) {
//   // // Both the data and the editing pipelines are affected by this conversion.
//   // editor.model.schema.addAttributeCheck((context) => {
//   //   if (context.endsWith("p")) {
//   //     return true;
//   //   }
//   // });

//   // editor.conversion.for("downcast").add((dispatcher) => {
//   //   dispatcher.on("attribute", (evt, data, conversionApi) => {
//   //     // Convert <div> attributes only.
//   //     if (data.item.name != "p") {
//   //       return;
//   //     }

//   //     const viewWriter = conversionApi.writer;
//   //     const viewDiv = conversionApi.mapper.toViewElement(data.item);

//   //     // In the model-to-view conversion we convert changes.
//   //     // An attribute can be added or removed or changed.
//   //     // The below code handles all 3 cases.
//   //     if (data.attributeNewValue) {
//   //       viewWriter.setAttribute(
//   //         data.attributeKey,
//   //         data.attributeNewValue,
//   //         viewDiv
//   //       );
//   //     } else {
//   //       viewWriter.removeAttribute(data.attributeKey, viewDiv);
//   //     }
//   //   });
//   // });

//   editor.conversion.for("downcast").add((dispatcher) => {
//     // Links are represented in the model as a "linkHref" attribute.
//     // Use the "low" listener priority to apply the changes after the link feature.
//     dispatcher.on(
//       "insert:paragraph",
//       (evt, data, conversionApi) => {
//         const viewWriter = conversionApi.writer;
//         const viewFigure = editor.editing.mapper.toViewElement( data.item );
//         // viewWriter.addClass('excerpt', viewFigure);
//       },
//       { priority: "low" }
//     );
//   });
// }

// function myTest( editor )
// {
//   editor.conversion.attributeToAttribute({
//     model:{
//       name: 'paragraph',
//       key: 'class',
//       value: 'excerpt'
//     },
//     view:{
//       name: 'p',
//       key: 'class',
//       value: 'excerpt'
//     }
//   });
// }

export const defaultCkToolbar = [
  "heading",
  "bold",
  "italic",
  // "underline",
  "|",
  "link",
  "fontcolor",
  "fontsize",
  // "fontfamily",
  "|",
  "alignment",
  "|",
  "numberedList",
  "bulletedList",
  "|",
  "blockQuote",
  "inserttable",
  "|",
  // "mediaEmbed",
  "|",
  "undo",
  "redo",
  // "sourceediting",
  // "indent",
  // "outdent",
  // "|",
  // "horizontalline",
];
// const defaultToolbar = {
//   toolbar: [ 'bold', 'italic' ]
// };
export function moduleOptions(index, contentData, setContentData) {
  return [
    // {
    //   is: "choice",
    //   icon: faFontCase,
    //   text: "Make first letter big",
    //   onClick: () => {
    //     let newContentData = cloneDeep(contentData);
    //     // newContentData.splice(i, 1);
    //     newContentData[index].type = "first_letter_big";
    //     setContentData(newContentData);
    //   },
    //   // style: { color: "red" },
    // },
    // {
    //   is: "choice",
    //   icon: faItalic,
    //   text: "Make into excerpt text",
    //   onClick: () => {
    //     let newContentData = cloneDeep(contentData);
    //     // newContentData.splice(i, 1);
    //     newContentData[index].type = "ckeditor_5_excerpt";
    //     setContentData(newContentData);
    //   },
    //   // style: { color: "red" },
    // },
  ];
}

class VfiCKeditor extends Component {
  constructor(props) {
    super(props);
    this.myRef = React.createRef();
    this.maxLengthReach = false;
    this.state = {
      focus: false,
      errorMessages: [],
    };
  }

  componentDidMount() {
    this.errorCheck(this.props.value || "");
  }

  thisChange(e) {
    this.errorCheck(removeTags(e));
  }

  errorCheck(value) {
    let errors = [];
    if (this.props.errorCheckSequence) {
      this.props.errorCheckSequence.forEach((element) => {
        const error = element(value);

        if (error !== "" && error !== undefined) {
          errors.push(error);
        }
      });
    }
    this.setState({ errorMessages: errors });
  }

  render() {
    const inputError = this.state.errorMessages.length > 0 ? true : false;

    let extraClassName = "";
    if (typeof this.props.className === "string") {
      extraClassName += this.props.className;
    }
    if (this.state.focus) {
      extraClassName += " focus";
    }
    if (inputError && !this.props.hideError) {
      extraClassName += " error";
    }
    if (this.props.label) {
      extraClassName += " " + this.props.label;
    }

    return (
      <div
        style={this.props.style}
        ref={this.myRef}
        className={"vfi-text-area " + extraClassName}
      >
        <div style={{ marginLeft: this.props.marginLeft + "px" }}>
          <CKEditor
            editor={Editor}
            // config={ defaultToolbar }
            // editor={TestEditor}
            data={this.props.value}
            // data={"<p>Hello from <i>CKEditor</i> 5!</p>"}
            defaultValue={this.props.value}
            onReady={(editor) => {
              // You can store the "editor" and use when it is needed.
              if (editor) {
                // // editor.conversion.for('downcast').attributeToElement( config2 );

                // // editor.config.allowedContent = true;
                const data = editor.getData();
                this.thisChange(data);
              }
            }}
            onChange={(event, editor) => {
              const data = editor.getData();
              var temp = document.createElement("div");
              temp.innerHTML = data;
              this.thisChange(data);
              if (this.props.onChange) this.props.onChange(data);
            }}
            onBlur={(event, editor) => {
              const data = editor.getData();
              var temp = document.createElement("div");
              temp.innerHTML = data;
              if (this.props.onBlur) {
                this.props.onBlur(data);
              }
              this.setState({ focus: false });
            }}
            onFocus={(event, editor) => {
              if (this.props.onFocus) {
                this.props.onFocus(editor);
              }
              this.setState({ focus: true });
            }}
            dataFilter={{
              name: "section",
              attributes: {
                "data-foo": true,
              },
            }}
            config={{
              fontFamily: {
                options: ["default", "Libre Baskerville"],
              },
              fontSize: {
                options: [16, 18, 36, 66],
              },
              heading: {
                options: [
                  {
                    model: "paragraph",
                    title: "Paragraph",
                    class: "ck-heading_paragraph",
                  },
                  {
                    model: "paragraphLibre",
                    view: { name: "h4", classes: "paragraph_libre" },
                    title: "Paragraph Libre",
                    class: "ck-heading_paragraph_libre",
                  },
                  {
                    model: "excerpt",
                    view: { name: "h4", classes: "excerpt" },
                    title: "Excerpt",
                    class: "ck-heading_excerpt",
                  },
                  {
                    model: "firstLetterBig",
                    view: { name: "h4", classes: "first_letter_big" },
                    title: "First letter big",
                    class: "ck-heading_first_letter_big",
                  },
                  {
                    model: "heading1",
                    view: "h1",
                    title: "Heading 1",
                    class: "ck-heading_heading1",
                  },
                  {
                    model: "heading2Lib",
                    view: { name: "h2", classes: "h2_libre" },
                    title: "Heading 2 Libre",
                    class: "ck-heading_heading2_libre",
                  },
                  {
                    model: "heading2",
                    view: "h2",
                    title: "Heading 2",
                    class: "ck-heading_heading2",
                  },
                  {
                    model: "heading3",
                    view: "h3",
                    title: "Heading 3",
                    class: "ck-heading_heading3",
                  },
                  {
                    model: "heading01",
                    view: { name: "h4", classes: "large_headline" },
                    title: "Góðan Dag",
                    class: "ck-heading_god_day",
                  },

                  // { model: 'dropcap', view: {name:'p',classes:'drop-cap'}, title: 'Drop cap', class: 'ck-heading_dropcap' }
                ],
              },
              toolbar:
                this.props.toolbar !== undefined
                  ? this.props.toolbar
                  : defaultCkToolbar,
              fontColor: {
                colors: [
                  {
                    color: "#000000",
                    label: "Black",
                  },
                  {
                    color: "#373737",
                    label: "Dark",
                  },
                  {
                    color: "#A2A2A2",
                    label: "Gray",
                  },
                  {
                    color: "#009FFF",
                    label: "Blue",
                  },
                  {
                    color: "#f18f01",
                    label: "Orange",
                  },
                  {
                    color: "#ffffff",
                    label: "White",
                  },
                  // {
                  //     color: 'hsl(0, 0%, 100%)',
                  //     label: 'White',
                  //     hasBorder: true
                  // },
                ],
              },
              blockToolbar: this.props.blockToolbar
                ? this.props.blockToolbar
                : [
                    "heading",
                    "|",
                    "alignment",
                    "|",
                    "bulletedList",
                    "numberedList",
                    "|",
                    "blockQuote",
                    "inserttable",
                    "|",
                    "undo",
                    "redo",
                  ],
              ckfinder: {
                uploadUrl: env.protocol + env.env + "/api/UploadMediaFile",
                //uploadUrl: env.protocol+env.env+"/ckfinder/connector/?command=QuickUpload&type=Images"
              },
              content: {
                height: 300,
                filebrowserUploadUrl:
                  env.protocol + env.env + "/api/UploadMediaFile",
              },
              placeholder: this.props.placeholder ? this.props.placeholder : "",
              extraPlugins: [
                // AddClassToPTag,
                // ConvertPAttributes,
                // MyOwn
              ],
            }}
            disabled={this.props.disabled ? true : false}
            placeholder={this.props.placeholder}
          />
        </div>
        <div className="errors">
          {this.state.errorMessages.map((element, i) => {
            return !this.props.hideError ? (
              <div className="error" key={i}>
                {element}
              </div>
            ) : (
              ""
            );
          })}
        </div>
      </div>
    );
  }
}

export default VfiCKeditor;
