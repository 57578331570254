//All sliders, including grids are in this EditModule

import {
  faDiagramLeanCanvas,
  faList,
  faPlus,
  faTrashAlt,
} from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";
import "../VfiCarousel/EditModule.scss";
import cloneDeep from "lodash.clonedeep";
import { VfiCheckbox2 } from "../../VfiCheckbox";
import GridTemplateChoose from "../../../Components/Content/Overlay/GridTemplateChoose/GridTemplateChoose";
import noImage from "../../images/empty.jpg";
import { WhatsonDesignTypeChoose } from "../../../Components/Content/Overlay/WhatsonDesignTypeChoose/WhatsonDesignTypeChoose";

export default function EditModule(props) {
  const { values, change } = props;
  const [innerValues, setInnerValues] = useState(values);
  const [screen, setScreen] = useState("media");
  const [draggingIndex, setDraggingIndex] = useState(undefined);

  const setValues = (v) => {
    change(v);
    setInnerValues(v);
  };

  useEffect(() => {
    setInnerValues(values);
  }, [values]);

  // console.log(props);

  // const setDesignType = (key, value) => {
  //   onChange({ ...data, [key]: value });
  // };

  let content = <p>Shouldn't be shown..</p>;
  switch (screen) {
    case "media":
      content = (
        <div className="media">
          {innerValues.whatson_list.map((element, i) => {
            const hasImage = !!element.image && element.image.info;
            return (
              <div
                className="medium"
                key={i + "|" + element.id}
                draggable={true}
                onDragStart={(e) => {
                  setDraggingIndex(i);
                }}
                onDragEnd={(e) => {
                  setDraggingIndex(undefined);
                }}
                onDrop={(e) => {
                  if (draggingIndex !== undefined) {
                    let copyWhatsonList = cloneDeep(innerValues.whatson_list);
                    const draggedMedium =
                      innerValues.whatson_list[draggingIndex];
                    copyWhatsonList.splice(draggingIndex, 1);
                    copyWhatsonList.splice(i, 0, draggedMedium);
                    setValues({
                      ...innerValues,
                      whatson_list: copyWhatsonList,
                    });
                    setDraggingIndex(undefined);
                  }
                }}
                onDragOver={(e) => {
                  e.preventDefault();
                }}
              >
                <div
                  className="remove"
                  onClick={() => {
                    let copyMedia = cloneDeep(innerValues.whatson_list);
                    copyMedia.splice(i, 1);
                    setValues({
                      ...innerValues,
                      whatson_list: copyMedia,
                      preset_number: 1,
                    });
                  }}
                >
                  <FontAwesomeIcon icon={faTrashAlt} />
                </div>
                <img
                  src={hasImage ? element.image.large : noImage}
                  alt="Small display"
                />
                <p className="title">{element.headline}</p>
              </div>
            );
          })}

          <div className="add-wrap">
            <div
              className="add"
              onClick={() => {
                props.setModuleKeyInEdit("whatson_add");
                let designType = "";
                switch (values.type) {
                  case "whatson_grid":
                    designType = "grid";
                    break;
                  case "whatson_list":
                    designType = "list";
                    break;
                  case "whatson_card":
                    designType = "card";
                    break;
                  default:
                    designType = "Should not get here... :(";
                    break;
                }

                props.openOverlay("whatsonPagesChoose", {
                  whatson_chosen_ids: [],
                  category_filters: [],
                  amount_per_grid: 99999,
                  whatson_design_type: designType,
                });
                props.close();
              }}
            >
              <FontAwesomeIcon icon={faPlus} />
            </div>
          </div>
        </div>
      );
      break;
    case "design":
      content = (
        <div className="design">
          {values.type === "whatson_grid" && (
            <label className="option whole-click">
              <div className="label">Mirror</div>
              <div className="value">
                <VfiCheckbox2
                  style={{ pointerEvents: "none" }}
                  checked={innerValues.swapped === true}
                  isRadio={true}
                  onChange={(e) => {
                    setValues({ ...innerValues, swapped: e.target.checked });
                  }}
                />
              </div>
            </label>
          )}
          {innerValues.type === "whatson_grid" && (
            <div className="grid-types">
              <GridTemplateChoose
                onlyTemplates={true}
                returnV={(index) => {
                  setValues({ ...innerValues, preset_number: index });
                }}
                values={{
                  ...innerValues,
                  grid_type: "gallery",
                  media: innerValues.whatson_list,
                }}
                setValues={setValues}
                hideGridTypes={true}
              />
            </div>
          )}
          {(values.type === "whatson_card" ||
            values.type === "whatson_list") && (
            <WhatsonDesignTypeChoose
              values={{
                ...innerValues,
              }}
              setValues={setValues}
            />
          )}
        </div>
      );
      break;
    default:
      break;
  }

  return (
    <div className="vfi-carousel whatson-grid edit-module">
      <div className="top">
        <div
          className={"top-choice " + (screen === "media" ? "active " : "")}
          onClick={() => setScreen("media")}
        >
          WHATSON
        </div>
        <div
          className={"top-choice " + (screen === "design" ? "active " : "")}
          onClick={() => setScreen("design")}
        >
          DESIGN
        </div>
      </div>
      <div className="content">{content}</div>
    </div>
  );
}
