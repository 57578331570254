
import type { section } from './types';


export const articlesSections: section[] = [
    // {
    //   title: "Inspiration",
    //   views: [
    //     "Articles", 
    //     "Podcasts"
    //   ],
    // },
  
    {
        title: "Infoscreens",
        views: [
          "See & Do", 
          "Eat & Drink",
          "Transport",
        ],
      },
  ];

 