import { useState } from "react";
import PropTypes from "prop-types";
import ReactMapGl, { Marker } from "react-map-gl";
import mapMarkers from "../images/map-markers/map-markers";

export const defaultViewportValue = {
  latitude: 61.8926,
  longitude: -6.9118,
  zoom: 8,
  transitionDuration: 500,
};

function ReactMapGlOpen({
  defaultViewport = defaultViewportValue,
  width,
  height,
  onClick = () => void 0,
  children,
  className,
}) {
  const [viewport, setViewport] = useState(defaultViewport);
  console.log(children);

  return (
    <ReactMapGl
      {...viewport}
      className={className}
      mapStyle={"mapbox://styles/mapbox/light-v10"}
      width={isNaN(width) ? width : width + "px"}
      height={isNaN(height) ? height : height + "px"}
      mapboxApiAccessToken="pk.eyJ1IjoiYXNrb2RhbiIsImEiOiJja2drcWR4eW4xYWtuMnN0ZXJpNnJlemk3In0.Dy7UfnJQiXaBYYkN-okLpw"
      onViewStateChange={setViewport}
      onClick={onClick}
    >
      {children}
    </ReactMapGl>
  );
}

ReactMapGlOpen.propTypes = {
  defaultViewport: PropTypes.object,
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  onClick: PropTypes.func,
};

export default ReactMapGlOpen;
