import Verify from "../../assets/VerifyMethods";
import VfiInputText from "../../assets/VfiInputText/VfiInputText";
import env from "../../environment.json";
import vfiLogo from "../../assets/images/vfi-logo.png";
import vitaLogo from "../../assets/images/vita-logo.png";
import FacebookLogin from "react-facebook-login";
import { useEffect, useRef, useState } from "react";
import Select from "react-select";
import VfiCustomSelect from "../../assets/VfiCustomSelect";
import countryCodes from "../../assets/countryCodes.json";
import { allObjTrue } from "../../assets/helperFunctions";
import languages from "./language.json";
import VfiCheckbox from "../../assets/VfiCheckbox";
import axios from "axios";

const language = localStorage.getItem("language") ?? "english";

export default function LoginTable({
  loginInfo,
  editloginInfo = (key, value) => void 0,
  backward = () => void 0,
  forward = () => void 0,
  redirect,
  setNav,
  setForward,
  setId,
}) {
  const [a, setA] = useState(0);
  const [changedValues, setChangedValues] = useState({
    firstname: false,
    lastname: false,
    password: false,
    phone: false,
    email: false,
    confirmEmail: false,
  });

  const [rememberMe, setRememberMe] = useState(false);

  const langText = languages[language].personal_info;

  const [userData, setUserData] = useState({
    email: null,
    psw: null,
  });

  const formRef = useRef(null);

  const responseFacebook = (res) => {
    var e = res;

    axios
      .post(env.protocol + env.env + "/api/public/facebookAuth.php", {
        access_token: e.accessToken,
      }) //userData)
      .then((response) => {
        var data = response.data;

        if (
          data.msg === "Login successful" || 
          data.msg === "Registration successful"
        ) {
          editloginInfo("firstname", e.first_name);
          editloginInfo("lastname", e.last_name);
          editloginInfo("teldupostur", e.email);
          // editloginInfo("imgurl", e.picture.data.url);
          editloginInfo("accessToken", e.accessToken);
          editloginInfo("id", data.id);
          // setA(new Date().getTime());
          editloginInfo("auth", true);

          // sessionStorage.setItem("vfiUser", data.id);
          setId(data.id);

          if (redirect === "Event") {
            forward();
          } else if (redirect === "change") {
            forward();
          } else {
            setNav("");
            window.parent.postMessage(
              JSON.stringify({
                redirect: env.protocol + env.env,
              }),
              "*"
            );
          }
        }
      });

    // Verify via access_token in backend, and login/forward if login success (valid facebook user)
    // Create new enpoint for facebook login/register
  };

  const registerWithoutFacebook = () => {
    editloginInfo("auth", true);
    setA(new Date().getTime());
  };

  const mapCountryCodes = countryCodes.map((e) => {
    return { label: `+${e.dial_code} ${e.name}`, value: e.dial_code };
  });

  const mapCountryCodesAdd = countryCodes.map((e) => {
    return { label: `+${e.dial_code}`, value: e.dial_code };
  });

  const login = () => {
    axios
      .post(env.protocol + env.env + "/api/public/login.php", {
        email: loginInfo.teldupostur,
        psw: loginInfo.password,
      }) //userData)
      .then((response) => {
        var data = JSON.parse(response.data);

        console.log(data);

        if (data.msg === "Login successful") {
          if (rememberMe) {
						sessionStorage.setItem("vfiUser", data.id);
          }

          setId(data.id);
          forward();
        }

        // setId(data.id);

        if (redirect === "Event") {
          // forward();
          // setForward();
        }
      });
  };

  useEffect(() => {
    // console.log('init');
    // handleResize(null);

    // window.addEventListener("message", handleMessage);
    // window.addEventListener("resize", handleResize);

    if (formRef.current) {
      let height = formRef.current.clientHeight + 300;

      window.parent.postMessage(
        {
          resize: {
            height: height,
          },
        },
        "*"
      );
    }

    return () => {
      // window.removeEventListener("message", handleMessage);
      // window.removeEventListener("resize", handleResize);
    };
  }, [formRef]);

  return (
    <div ref={formRef} id="LogoContainer">
      <div className="imgcontainer">
        <img
          src={env.env === "cms.vita.fo" ? vitaLogo : vfiLogo}
          alt="vfi-logo"
        />
        <div className="text">
          <p className="whatson-text">What's on</p>
          {/* <p className="vfi-text">The Faroe Islands</p> */}
        </div>
      </div>
      <div className="container">
        <div className="loginTable">
          <div className="form">
            <div className="username">
              <p>Username</p>

              <VfiInputText
                name="username"
                defaultValue={loginInfo.teldupostur}
                placeholder="Username"
                onFocus={(e) => {
                  e.target.placeholder = "";
                }}
                onBlur={(e) => {
                  e.target.placeholder = "Username";
                }}
                onChange={(e) => {
                  editloginInfo("teldupostur", e.target.value);
                  setChangedValues({ ...changedValues, email: true });
                  // setUserData({
                  //   ...userData,
                  //   email: e.target.value,
                  // });

                  // console.log(userData);
                }}
              />
            </div>

            <div className="password">
              <p>Password</p>

              <VfiInputText
                name="password"
                defaultValue={loginInfo.password}
                placeholder="Password"
                type="password"
                onFocus={(e) => {
                  e.target.placeholder = "";
                }}
                onBlur={(e) => {
                  e.target.placeholder = "Password";
                }}
                onChange={(e) => {
                  editloginInfo("password", e.target.value);
                  setChangedValues({ ...changedValues, password: true });

                  setUserData({
                    ...userData,
                    psw: e.target.value,
                  });
                }}
              />
            </div>

            <div className="remember-me">
              <VfiCheckbox
                // checked={false}
                onChange={() => {
                  setRememberMe(!rememberMe);
                }}
              />
              <p>Remember me</p>
            </div>

            <div className="buttons">
              <button
                className="login"
                onClick={() => {
                  login();

                  // forward();
                  // setChangedValues({ ...allObjTrue(changedValues) });
                  // setA(new Date().getTime());

                  // editloginInfo("auth", true);
                }}
              >
                LOGIN
              </button>
            </div>
          </div>
        </div>

        <div className="cli">
          <a>Can't log in?</a>
        </div>

        <div className="seperator">or</div>

        <FacebookLogin
          appId="520595711890674"
          fields="first_name, last_name,email,picture"
          callback={responseFacebook}
          // onClick={this.facebookClick}
          version={"v3.2"}
          xfbml={true}
          cssClass="my-facebook-button-class"
          textButton={
            <div>
              <img
                src="https://upload.wikimedia.org/wikipedia/commons/5/51/Facebook_f_logo_%282019%29.svg"
                alt="fb-logo"
              />
              <span className="fb-text">Continue with Facebook</span>
            </div>
          }
        />
      </div>
    </div>
  );
}
