import React from "react";
import VfiInputText from "./VfiInputText/VfiInputText";

const { compose, withProps, lifecycle } = require("recompose");
const { withScriptjs } = require("react-google-maps");
const {
  StandaloneSearchBox,
} = require("react-google-maps/lib/components/places/StandaloneSearchBox");

const GoogleMapSearchBox = compose(
  withProps({
    googleMapURL:
      "https://maps.googleapis.com/maps/api/js?key=AIzaSyA9xP3HbkU6iQiud39nHIrSRVKlvemgsXY&v=3.exp&libraries=geometry,drawing,places",
    loadingElement: <div style={{ height: `100%` }} />,
    containerElement: <div style={{ height: `400px` }} />,
  }),
  lifecycle({
    componentWillMount() {
      const refs = {};

      this.setState({
        places: [],
        onSearchBoxMounted: (ref) => {
          refs.searchBox = ref;
        },
        onPlacesChanged: () => {
          const places = refs.searchBox.getPlaces();
          this.setState({
            places,
          });
          this.props.change(places[0]);
        },
      });
    },
  }),
  withScriptjs
)((props) => (
  <div
    data-standalone-searchbox=""
    name="practicalInfo"
    className={props.className}
  >
    <StandaloneSearchBox
      ref={props.onSearchBoxMounted}
      bounds={props.bounds}
      onPlacesChanged={props.onPlacesChanged}
    >
      <VfiInputText
        placeholder={props.placeholder}
        // style={{
        //   boxSizing: `border-box`,
        //   // border: `1px solid transparent`,
        //   // width: `240px`,
        //   width: '100%'
        //   // height: `32px`,
        //   // padding: `0 12px`,
        //   // borderRadius: `3px`,
        //   // boxShadow: `0 2px 6px rgba(0, 0, 0, 0.3)`,
        //   // fontSize: `14px`,
        //   // outline: `none`,
        //   // textOverflow: `ellipses`
        // }}
        value={props.value}
        defaultValue={props.defaultValue}
        name={props.name}
        onChange={props.onChange}
        onBlur={props.onBlur}
        disabled={props.disabled}
      />
    </StandaloneSearchBox>
    {props.listResult === true ? (
      <ol>
        {props.places.map(
          ({ place_id, formatted_address, geometry: { location } }) => (
            <li key={place_id}>
              {formatted_address}
              {" at "}({location.lat()}, {location.lng()})
            </li>
          )
        )}
      </ol>
    ) : (
      ""
    )}
  </div>
));

export default GoogleMapSearchBox;
