import React, { Component } from "react";
import {
  withScriptjs,
  withGoogleMap,
  GoogleMap,
  Marker,
  StreetViewPanorama,
} from "react-google-maps";
import Geocode from "react-geocode";

class SimpleMap extends Component {
  constructor(props) {
    super(props);

    this.state = {
      zoom: 8,
      marker: {
        lat: 62.01091098697576,
        lng: -6.773021411790148,
      },
    };

    Geocode.setApiKey("AIzaSyA9xP3HbkU6iQiud39nHIrSRVKlvemgsXY");

    this.editMark = this.editMark.bind(this);
  }

  static defaultProps = {
    center: {
      lat: 62.01091098697576,
      lng: -6.773021411790148,
    },
    zoom: 8,
  };

  editMark(e) {
    this.setState({ marker: { lat: e.latLng.lat(), lng: e.latLng.lng() } });

    Geocode.fromLatLng(e.latLng.lat(), e.latLng.lng()).then(
      (response) => {
        e.geocode = response;
        this.props.onMarkerChange(e);
      },
      (error) => {
        console.error(error);
      }
    );
  }

  render() {
    return (
      // Important! Always set the container height explicitly
      // <div className={this.props.className ? "simple-map " + this.props.className : "simple-map"}>
      <div>
        <GoogleMap
          googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyA9xP3HbkU6iQiud39nHIrSRVKlvemgsXY&v=3.exp&libraries=geometry,drawing,places"
          defaultCenter={this.props.center}
          defaultZoom={this.props.zoom}
          center={this.props.center}
          zoom={this.props.zoom}
          // onClick={this.editMark.bind(this)}
          onClick={!this.props.disabled ? this.editMark : ""}
          disableDefaultUI={true}
          options={{ streetViewControl: false, mapTypeControl: false }}
        >
          {this.props.isMarkerShown ? (
            <Marker
              position={{
                lat: this.props.marker.lat,
                lng: this.props.marker.lng,
              }}
            />
          ) : (
            ""
          )}
          <StreetViewPanorama
            //defaultPosition={{lat:62.2360392,lng:-6.5892436}}
            //defaultPosition={{lat:this.props.marker.lat,lng:this.props.marker.lng}}
            position={{
              lat: this.props.marker.lat,
              lng: this.props.marker.lng,
            }}
            visible={this.props.streetViewVisible}
            options={{
              streetViewControl: false,
              disableDefaultUI: true,
            }}
            onCloseClick={() => {
              this.props.streetViewUIClose();
            }}
          ></StreetViewPanorama>
        </GoogleMap>
      </div>
    );
  }
}

export default withScriptjs(withGoogleMap(SimpleMap));
