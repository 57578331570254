function FormGroup({ children, header }) {
  return (
    <div className="single-form-group-container">
      <hr />
      <h3>{header}</h3>
      {children}
    </div>
  );
}

export default FormGroup;
