import React, { Component, useEffect, useState } from "react";
import env from "../../../environment.json";
import { faSlidersH } from "@fortawesome/pro-regular-svg-icons";
import { faSortAlt } from "@fortawesome/pro-solid-svg-icons";
import {
  faCalendarPlus,
  faChevronUp,
  faChevronDown,
  faUserPlus,
  faSpinner,
} from "@fortawesome/pro-light-svg-icons";
import { faSortAlt as faSortAltSorted } from "@fortawesome/pro-duotone-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import helperfunctions, {
  capitalize,
  showDate,
  pushMedia,
} from "../../../assets/helperFunctions";
import VfiInputText from "../../../assets/VfiInputText/VfiInputText";
import empty from "../../../assets/images/empty.jpg";
import "./ActivityDisplay.scss";
import {
  approvalStatusChange,
  ChangeApprovalScreen,
  WhatsonApprovalStatusButtons,
} from "../WhatsonDisplay/WhatsonContent";
import VfiCheckbox from "../../../assets/VfiCheckbox";
import PreviewWhatson from "../PreviewWhatson/PreviewWhatson";
import OptionsList from "../../../assets/OptionsList/OptionsList";
import { clone, cloneDeep } from "lodash";
import RightSideInfo from "../../../assets/RightSideInfo";
import ReactDatePicker from "react-datepicker";
import { GlobalToolTipController } from "../../ToolTips/GlobalToolTip";
import ToolTip from "../../ToolTips/ToolTip";
import EditPage from "../Pages/EditPage/EditPage";
import MediaSettings from "../MediaDisplay/MediaSettings";

const tdObj = {
  key: 0,
  className: "",
  content: (data) => <div></div>,
  onClick: () => void 0,
};
const thObj = {
  key: 0,
  className: "",
  content: <div></div>,
  onClick: () => void 0,
};

class Activity extends Component {
  constructor(props) {
    super(props);
    const preDate = new Date();
    preDate.setMonth(preDate.getMonth() - 1);
    this.state = {
      show: "activities",
      sortedBy: "date",
      reverse: true,
      searchValue: "",
      showItems: 15,
      activity: [],
      awaiting_approval: [],
      awaiting_approval_refresh: 0,
      allActivityHidden: false,
      awaitingApprovalHidden: false,
      listIsOpen: false,
      optionsListOptions: [],
      optionsListPos: { x: 0, y: 0 },

      whatWhatson: "",
      whatsonId: 0,
      showTable: "awaiting",
      filterOption: "all",
      filters: {
        searchValue: "",
        fromDate: preDate,
        toDate: new Date(),
        sites: [],
        regions: [],
        activity_types: [],
        awaiting_status: [],
      },
      sites: [],
      regions: [],
      filterShown: false,
      expandedActivities: [],
      expandedShownAmount: {},
      changeDecisionInfo: { type: "", id: 0, value: "", state: "index" },
      loading: false,
    };

    this.axios = require("axios");
    this.activity = [];
    // this.sites = this.props.listOptions.filter((element) => element.index !== "0")
    this.sitesChecked = [];

    this.filterContent = [
      {
        headline: "Date",
        value: [{ startDate: preDate, endDate: new Date() }],
        style: "date",
      },
      {
        headline: "Sites",
        value: [],
        style: "checkbox",
      },
    ];
    // this.search = { bool: true, searchValue: "" };
  }

  getActivities = () => {
    this.setState({ loading: true });
    const protocolAndHost = env.protocol + env.env;
    this.axios
      .post(protocolAndHost + "/api/activity/GetActivityList", {
        vfiUser: sessionStorage.getItem("vfiUser"),
        fromDate: this.state.filters.fromDate,
        toDate: this.state.filters.toDate,
      })
      .then((response) => {
        // console.log(response);
        const length = response.data.length;
        if (length > 0) {
          this.setState({
            activity: response.data,
            random: new Date().getTime(),
          });
        }
        this.setState({ loading: false });
      })
      .catch((error) => {
        console.error(error);
      });
  };

  componentDidMount() {
    const protocolAndHost = env.protocol + env.env;

    this.getActivities();

    if (parseInt(this.props.activityUpdate) > 0) {
      this.axios
        .post(protocolAndHost + "/api/updateActivity", {
          userId: sessionStorage.getItem("vfiUser"),
        })
        .then((response) => {
          this.props.updateActUpt(response.data.Counting);
        })
        .catch((error) => {
          console.error(error);
        });
    }

    this.axios
      .post(protocolAndHost + "/api/public/GetSites.php", {
        userId: sessionStorage.getItem("vfiUser"),
        ignoreRootPages: true,
      })
      .then((response) => {
        this.setState({ sites: response.data });
      })
      .catch((error) => console.error(error));

    this.axios
      .get(protocolAndHost + "/api/public/GetRegions.php")
      .then((response) => {
        this.setState({ regions: response.data });
      })
      .catch((error) => console.error(error));
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      this.state.filters.fromDate !== prevState.filters.fromDate ||
      this.state.filters.toDate !== prevState.filters.toDate ||
      (this.state.show !== prevState.show && this.state.show === "activities")
    ) {
      this.getActivities();
    }
  }

  sitesToMap() {
    const sites = [];
    this.props.listOptions.forEach((element) => {
      if (element.index !== "0") {
        sites.push({
          text: element.name,
          selected: this.sitesChecked.includes(element.name),
        });
      }
    });
    return sites;
  }

  handleShowMore() {
    var increment = 30;
    const activityLength = this.state.activity.length;
    this.setState({
      showItems:
        this.state.showItems + increment >= activityLength
          ? activityLength
          : this.state.showItems + increment,
    });
  }

  onCheckboxChange = (e) => {
    // When checking a checkbox we document it and update the array
    const index = this.sitesChecked.indexOf(e.target.name); // Document if object is selected or not
    if (index > -1) {
      this.sitesChecked.splice(index, 1);
    } else {
      this.sitesChecked.push(e.target.name);
    }

    // Update selected
    this.filterContent[1].value = this.filterContent[1].value.map(
      (element) => ({
        text: element.text,
        selected: this.sitesChecked.includes(element.text),
      })
    );
    this.props.SetState("filterdata.content", this.filterContent); //update the array in main
  };

  onDateChange = (whatdate, date) => {
    this.filterContent[0].value[0][whatdate] = date;

    this.props.SetState("filterdata.content", this.filterContent); //update the array in main
  };

  checkboxFiltering(list, locateAttr) {
    //Filter the list when checkbox in filter changes
    const filter = this.sitesChecked;
    // console.log(filter)
    let tempList = [];
    if (filter.includes("all") || filter.length === 0) {
      //If show all or if we are filtering wrong data return all
      tempList = list;
    } else {
      filter.forEach((element) => {
        const filteredList = list.filter((q) =>
          q[locateAttr] !== undefined
            ? q.type === "User"
              ? q[locateAttr].includes(element)
              : q[locateAttr] === element
            : ""
        );
        tempList = tempList.concat(filteredList);
      });
      //   }
    }
    return tempList;
  }

  // onSearchChange = (e) => {
  //   //Change search value
  //   this.search.searchValue = e.target.value;
  //   this.props.SetState("filterdata.search", this.search);

  //   // Change list depending on search
  //   this.filterContent[1].value = helperfunctions.searchFilter(
  //     this.sitesToMap(),
  //     this.search.searchValue
  //   );
  //   this.props.SetState("filterdata.content", this.filterContent);
  // };

  ActivityTable = ({
    content,
    listName = "",
    startShowingLength = 15,
    hidden = false,
    mainClick = () => void 0,
    dropdownButtonClick = () => void 0,
  }) => {
    const [showAmount, setShowAmount] = useState(startShowingLength);
    useEffect(() => {
      if (hidden) {
        setShowAmount(startShowingLength);
      }
    }, [hidden, startShowingLength]);
    if (hidden) return "";
    return (
      <>
        <table className="activity-table">
          <thead>
            <tr>
              <th></th>
              <th
                className={
                  this.state.sortedBy === "date" ? "date sorted-by" : "date"
                }
                onClick={() => {
                  let reverse = false;
                  if (
                    this.state.sortedBy === "date" &&
                    this.state.reverse === false
                  )
                    reverse = true;
                  this.setState({
                    sortedBy: "date",
                    reverse: reverse,
                    activity: helperfunctions.sortList(
                      "activities.0.create_date",
                      this.state.activity,
                      this.state.sortedBy === "date"
                    ),
                  });
                }}
              >
                <GlobalToolTipController
                  offset={{ x: 30, y: 30 }}
                  priority={"right,bottom"}
                  title=""
                  delay={600}
                  toolTipElements={<div>Sort by Date</div>}
                >
                  Date
                  <FontAwesomeIcon
                    icon={
                      this.state.sortedBy === "date"
                        ? faSortAltSorted
                        : faSortAlt
                    }
                    flip={"vertical"}
                    transform={this.state.reverse ? {} : { rotate: 180 }}
                  />
                </GlobalToolTipController>
              </th>
              <th
                className={
                  this.state.sortedBy === "region"
                    ? "region sorted-by"
                    : "region"
                }
                onClick={() => {
                  let reverse = false;
                  if (
                    this.state.sortedBy === "region" &&
                    this.state.reverse === false
                  )
                    reverse = true;
                  this.setState({
                    sortedBy: "region",
                    reverse: reverse,
                    activity: helperfunctions.sortList(
                      "regionName",
                      this.state.activity,
                      this.state.sortedBy === "region"
                    ),
                  });
                }}
              >
                <GlobalToolTipController
                  offset={{ x: 30, y: 30 }}
                  priority={"right,bottom"}
                  title=""
                  delay={600}
                  toolTipElements={<div>Sort by Region</div>}
                >
                  Region
                  <FontAwesomeIcon
                    icon={
                      this.state.sortedBy === "region"
                        ? faSortAltSorted
                        : faSortAlt
                    }
                    flip={"vertical"}
                    transform={this.state.reverse ? {} : { rotate: 180 }}
                  />
                </GlobalToolTipController>
              </th>
              <th
                className={
                  this.state.sortedBy === "activity"
                    ? "activity sorted-by"
                    : "activity"
                }
                onClick={() => {
                  let reverse = false;
                  if (
                    this.state.sortedBy === "activity" &&
                    this.state.reverse === false
                  )
                    reverse = true;
                  this.setState({
                    sortedBy: "activity",
                    reverse: reverse,
                    activity: helperfunctions.sortList(
                      "activities.0.activity",
                      this.state.activity,
                      this.state.sortedBy === "activity"
                    ),
                  });
                }}
              >
                <GlobalToolTipController
                  offset={{ x: 30, y: 30 }}
                  priority={"right,bottom"}
                  title=""
                  delay={600}
                  toolTipElements={<div>Sort by Activity</div>}
                >
                  Activity
                  <FontAwesomeIcon
                    icon={
                      this.state.sortedBy === "activity"
                        ? faSortAltSorted
                        : faSortAlt
                    }
                    flip={"vertical"}
                    transform={this.state.reverse ? {} : { rotate: 180 }}
                  />
                </GlobalToolTipController>
              </th>
              <th
                className={
                  this.state.sortedBy === "approval"
                    ? "approval sorted-by"
                    : "approval"
                }
                onClick={() => {
                  let reverse = false;
                  if (
                    this.state.sortedBy === "approval" &&
                    this.state.reverse === false
                  )
                    reverse = true;
                  this.setState({
                    sortedBy: "approval",
                    reverse: reverse,
                    activity: helperfunctions.sortList(
                      "approval_status",
                      this.state.activity,
                      this.state.sortedBy === "approval"
                    ),
                  });
                }}
              >
                <GlobalToolTipController
                  offset={{ x: 30, y: 30 }}
                  priority={"right,bottom"}
                  title=""
                  delay={600}
                  toolTipElements={<div>Sort by Approval status</div>}
                >
                  Approval
                  <FontAwesomeIcon
                    icon={
                      this.state.sortedBy === "approval"
                        ? faSortAltSorted
                        : faSortAlt
                    }
                    flip={"vertical"}
                    transform={this.state.reverse ? {} : { rotate: 180 }}
                  />
                </GlobalToolTipController>
              </th>

              <th className="status">
                <GlobalToolTipController
                  offset={{ x: 10, y: 30 }}
                  priority={"right,bottom"}
                  title=""
                  delay={600}
                  toolTipElements={<div>Sort by Status</div>}
                >
                  Status
                  <FontAwesomeIcon
                    icon={
                      this.state.sortedBy === "status"
                        ? faSortAltSorted
                        : faSortAlt
                    }
                    flip={"vertical"}
                    transform={this.state.reverse ? {} : { rotate: 180 }}
                  />
                </GlobalToolTipController>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr
              className={"loading-data" + (this.state.loading ? "" : " hidden")}
            >
              <td colSpan={6}>
                <FontAwesomeIcon icon={faSpinner} spin />
              </td>
            </tr>
            {this.mapActivity(content, showAmount, listName, mainClick)}
          </tbody>
        </table>
        <p
          className={
            this.state.showItems >= content.length ? "SeeMore hide" : "SeeMore"
          }
          // onClick={() => this.handleShowMore()}
          onClick={() => setShowAmount(showAmount + 30)}
        >
          See more
          <FontAwesomeIcon icon={faChevronDown} />
        </p>
      </>
    );
  };

  mapActivity(searchContent, showAmount, listName, mainClick = () => void 0) {
    // console.log(searchContent)
    const protocolAndHost = env.protocol + env.env;
    var activityList = searchContent.slice(0, showAmount).map((data, i) => {
      var title;
      var icon;
      var location;
      location = <td className="Location">{data.siteName}</td>;

      //Broyta title og location
      if (data.type === "User") {
        title = (
          <td className="page">
            <p>{data.postName} </p>
            <p className="gray_small">
              {"Applied " +
                data.time_since_reg +
                " \u00b7 " +
                data.userName +
                " \u00b7 " +
                data.userCompany}
            </p>
            {/* <p className="gray_small">{"Submitted "+data.time_since_reg +' \u00b7 ' + data.userName + ' \u00b7 ' + data.userCompany}</p> */}
          </td>
        );
        icon = <FontAwesomeIcon icon={faUserPlus} />;
        location = (
          <td className="Location">
            {data.siteName !== undefined
              ? data.siteName.map((elm, i) =>
                  i === data.siteName.length - 1 ? elm : elm + ", "
                )
              : ""}
          </td>
        );
      } else {
        title = (
          <td className="page" onClick={() => mainClick(data)}>
            {data.thumbnail ? (
              <img src={data.thumbnail} alt="user img" />
            ) : (
              <img src={empty} alt="user img" />
            )}
            <p>{data.title}</p>
            <p className="gray_small">
              {"Updated " +
                showDate(data.activities[0].create_date) +
                " \u00b7 " +
                (data.activities[0].userFullname ?? "No name") +
                (data.placeName ? " \u00b7 " + data.placeName : "")}
            </p>
          </td>
        );
        icon = <FontAwesomeIcon icon={faCalendarPlus} />;
      }

      const thisActivity = data.type + data.id;
      const activityExpanded =
        this.state.expandedActivities.find((e) => e === thisActivity) ===
        undefined;

      const theReturn = [
        <tr key={thisActivity}>
          <td
            className="expand"
            onClick={() => {
              let expandedActivities = clone(this.state.expandedActivities);
              if (activityExpanded) {
                expandedActivities.push(thisActivity);
                this.setState({ expandedActivities });
              } else {
                expandedActivities = expandedActivities.filter(
                  (e) => e !== thisActivity
                );
                this.setState({ expandedActivities });
              }
            }}
          >
            {activityExpanded ? (
              <FontAwesomeIcon icon={faChevronDown} />
            ) : (
              <FontAwesomeIcon icon={faChevronUp} />
            )}
          </td>
          {title}
          {/* {location} */}
          <td className="site">{data.regionName ?? "All regions"}</td>

          <td className="Activity">
            {icon}
            {data.activities[0].activity}
          </td>
          <td className="Approval">
            {data.status === "pending" ? (
              <WhatsonApprovalStatusButtons
                element={{ ...data, approvalStatus: data.approval_status }}
                whatWhatson={data.type}
                onChange={(e) => {
                  /* remove from awaiting list if we are not in 'awaiting approval' screen */
                  /* change approval_status param to the change value. */
                  const activity = cloneDeep(this.state.activity);
                  const index = activity.findIndex(
                    (a) => a.id === e.id && a.type === e.type
                  );
                  activity[index] = { ...e, status: e.approval_status };
                  this.setState({ activity });
                }}
                clickFunc={(s) => {
                  if (["changes required", "deny"].includes(s)) {
                    this.setState({
                      changeDecisionInfo: {
                        type: data.type,
                        id: data.id,
                        value: s,
                        state: "mail",
                      },
                    });
                  } else {
                    approvalStatusChange(data.type, data.id, s, (response) => {
                      if (response.data.success) {
                        const activity = cloneDeep(this.state.activity);
                        const index = activity.findIndex(
                          (a) => a.id === data.id && a.type === data.type
                        );
                        activity[index] = {
                          ...activity[index],
                          status: s,
                          approval_status: s,
                        };
                        this.setState({ activity });
                      }
                    });
                  }
                }}
              />
            ) : (
              ""
            )}
            {["approve", "changes required", "deny"].includes(
              data.approval_status
            ) ? (
              <div>
                <div>
                  {data.approval_status === "approve" ? "Approved" : ""}
                  {data.approval_status === "changes required"
                    ? "Changes required"
                    : ""}
                  {data.approval_status === "deny" ? "Denied" : ""}
                </div>
                <div
                  className="change-decision-button"
                  onClick={() => {
                    this.setState({
                      changeDecisionInfo: {
                        type: data.type,
                        id: data.id,
                        value: data.approval_status,
                        state: "index",
                      },
                    });
                  }}
                >
                  Change decision
                </div>
              </div>
            ) : (
              ""
            )}
          </td>

          <td className="status">
            {data.status ? capitalize(data.status) : "No status"}
          </td>
        </tr>,
      ];
      if (this.state.expandedActivities.find((e) => e === thisActivity)) {
        theReturn.push(
          <tr key={thisActivity + "expanded"} className={"expanded head"}>
            <td></td>
            <td>Version by</td>
            <td></td>
            <td>Activity</td>
            <td>Date</td>
            <td></td>
          </tr>
        );
        const amountShown = this.state.expandedShownAmount[thisActivity] ?? 5;
        data.activities.forEach((e, i) => {
          if (i < amountShown)
            theReturn.push(
              <tr key={e.id + "expanded"} className={"expanded"}>
                <td></td>
                <td>
                  {e.userFullname ?? "No name"}
                  {" \u00b7 "}
                  {e.posts}
                </td>
                <td></td>
                <td className="Activity">
                  <FontAwesomeIcon icon={faCalendarPlus} />
                  {e.activity}
                </td>
                <td>{showDate(e.create_date)}</td>
                <td></td>
              </tr>
            );
        });
        if (amountShown < data.activities.length)
          theReturn.push(
            <tr
              key={thisActivity + "show-more"}
              className="expanded show-more"
              onClick={() => {
                this.setState({
                  expandedShownAmount: {
                    ...this.state.expandedShownAmount,
                    [thisActivity]:
                      amountShown !== undefined ? amountShown + 5 : 5,
                  },
                });
              }}
            >
              <td></td>
              <td>Show more</td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
            </tr>
          );
      }

      return theReturn;
    });

    return activityList;
  }

  showFilters = () => {
    const filterData = this.state.filters;

    // const activityTypes = [
    //   "Event",
    //   "Place",
    //   "Tour operator",
    //   "Person",
    //   "Theme",
    // ];
    const activityTypes = [...new Set(this.state.activity.map((a) => a.type))];
    activityTypes.sort((a, b) => a > b);
    const awaitingStatus = [
      "Pending",
      "Approved",
      "Changes required",
      "Denied",
    ];

    return [
      <VfiInputText
        key="search"
        className="search-box"
        value={this.state.filters.searchValue}
        placeholder="Search..."
        onChange={(e) => {
          this.setState({
            filters: { ...this.state.filters, searchValue: e.target.value },
          });
        }}
        isSearch={true}
      />,
      {
        type: "dropdown",
        title: "DATE",
        content: (
          <div key={"date-from-to"} className="date-from-to">
            <label>
              <ReactDatePicker
                maxDate={this.state.filters.toDate}
                dateFormat="dd/MM/yy"
                selected={this.state.filters.fromDate}
                onChange={(date) => {
                  const filterData = this.state.filters;
                  this.setState({
                    filters: { ...filterData, fromDate: date },
                  });
                }}
                // popperModifiers={[]}
              />
              {/* <FontAwesomeIcon icon={faCalendarDays} /> */}
            </label>
            <div className="to-text">to</div>
            <label>
              <ReactDatePicker
                minDate={this.state.filters.fromDate}
                maxDate={new Date()}
                dateFormat="dd/MM/yy"
                selected={this.state.filters.toDate}
                onChange={(date) => {
                  const filterData = this.state.filters;
                  this.setState({
                    filters: { ...filterData, toDate: date },
                  });
                }}
                popperModifiers={[]}
              />
              {/* <FontAwesomeIcon icon={faCalendarDays} /> */}
            </label>
          </div>
        ),
        defaultCollapsed: false,
      },
      {
        type: "dropdown",
        title: "SITE",
        content: (
          <div key={"filter-sites"} className="filters">
            <div className="filter">
              <label>
                <VfiCheckbox
                  checked={filterData.sites.length === 0}
                  onChange={() => {
                    this.setState({ filters: { ...filterData, sites: [] } });
                  }}
                />
                <span
                  className={
                    "text" + (filterData.sites.length === 0 ? " active" : "")
                  }
                >
                  All
                </span>
              </label>
            </div>
            {this.state.sites.map((site) => {
              const checked =
                this.state.filters.sites.find((name) => name === site.name) !==
                undefined;
              return (
                <div className="filter" key={site.siteId}>
                  <label>
                    <VfiCheckbox
                      onChange={(e) => {
                        let sites = clone(filterData.sites);
                        if (e.target.checked) {
                          sites.push(site.name);
                        } else {
                          sites = sites.filter((f) => f !== site.name);
                        }
                        this.setState({
                          filters: { ...filterData, sites },
                        });
                      }}
                      checked={checked}
                    />
                    <span className={"text" + (checked ? " active" : "")}>
                      {site.name}
                    </span>
                  </label>
                </div>
              );
            })}
          </div>
        ),
        defaultCollapsed: true,
      },
      {
        type: "dropdown",
        title: "REGION",
        content: (
          <div key={"filter-regions"} className="filters">
            <div className="filter">
              <label>
                <VfiCheckbox
                  checked={filterData.regions.length === 0}
                  onChange={() => {
                    this.setState({ filters: { ...filterData, regions: [] } });
                  }}
                />
                <span
                  className={
                    "text" + (filterData.regions.length === 0 ? " active" : "")
                  }
                >
                  All
                </span>
              </label>
            </div>
            {this.state.regions.map((region) => {
              const checked =
                this.state.filters.regions.find(
                  (name) => name === region.region_name
                ) !== undefined;
              return (
                <div className="filter" key={region.region_id}>
                  <label>
                    <VfiCheckbox
                      onChange={(e) => {
                        let regions = clone(filterData.regions);
                        if (e.target.checked) {
                          regions.push(region.region_name);
                        } else {
                          regions = regions.filter(
                            (f) => f !== region.region_name
                          );
                        }
                        this.setState({
                          filters: { ...filterData, regions: regions },
                        });
                      }}
                      checked={checked}
                    />
                    <span className={"text" + (checked ? " active" : "")}>
                      {region.region_name}
                    </span>
                  </label>
                </div>
              );
            })}
          </div>
        ),
        defaultCollapsed: true,
      },
      {
        type: "dropdown",
        title: "ACTIVITY TYPE",
        content: (
          <div key={"filter-activity-types"} className="filters">
            <div className="filter">
              <label>
                <VfiCheckbox
                  checked={filterData.activity_types.length === 0}
                  onChange={() => {
                    this.setState({
                      filters: { ...filterData, activity_types: [] },
                    });
                  }}
                />
                <span
                  className={
                    "text" +
                    (filterData.activity_types.length === 0 ? " active" : "")
                  }
                >
                  All
                </span>
              </label>
            </div>
            {activityTypes.map((aType) => {
              const checked =
                this.state.filters.activity_types.find(
                  (name) => name === aType
                ) !== undefined;
              return (
                <div className="filter" key={aType}>
                  <label>
                    <VfiCheckbox
                      onChange={(e) => {
                        let aTypes = clone(filterData.activity_types);
                        if (e.target.checked) {
                          aTypes.push(aType);
                        } else {
                          aTypes = aTypes.filter((f) => f !== aType);
                        }
                        this.setState({
                          filters: { ...filterData, activity_types: aTypes },
                        });
                      }}
                      checked={checked}
                    />
                    <span className={"text" + (checked ? " active" : "")}>
                      {capitalize(aType)}
                    </span>
                  </label>
                </div>
              );
            })}
          </div>
        ),
        defaultCollapsed: true,
      },
      {
        type: "dropdown",
        title: "AWAITING STATUS",
        content: (
          <div key={"filter-awaiting-status"} className="filters">
            <div className="filter">
              <label>
                <VfiCheckbox
                  checked={filterData.awaiting_status.length === 0}
                  onChange={() => {
                    this.setState({
                      filters: { ...filterData, awaiting_status: [] },
                    });
                  }}
                />
                <span
                  className={
                    "text" +
                    (filterData.awaiting_status.length === 0 ? " active" : "")
                  }
                >
                  All
                </span>
              </label>
            </div>
            {awaitingStatus.map((aStauses) => {
              const checked =
                this.state.filters.awaiting_status.find(
                  (name) => name === aStauses
                ) !== undefined;
              return (
                <div className="filter" key={aStauses}>
                  <label>
                    <VfiCheckbox
                      onChange={(e) => {
                        let aStatus = clone(filterData.awaiting_status);
                        if (e.target.checked) {
                          aStatus.push(aStauses);
                        } else {
                          aStatus = aStatus.filter((f) => f !== aStauses);
                        }
                        this.setState({
                          filters: { ...filterData, awaiting_status: aStatus },
                        });
                      }}
                      checked={checked}
                    />
                    <span className={"text" + (checked ? " active" : "")}>
                      {aStauses}
                    </span>
                  </label>
                </div>
              );
            })}
          </div>
        ),
        defaultCollapsed: true,
      },
    ];
    // awaiting_status;
  };

  mainClickFunction = (data) => {
    switch (data.type) {
      case "page":
      case "article":
        this.axios(
          env.protocol + env.env + "/api/public/pages/GetPage.php?id=" + data.id
        )
          .then((response) => {
            this.setState({ pageData: response.data, show: "page" });
          })
          .catch((error) => console.error(error));
        break;
      case "event":
      case "place":
      case "hiking":
      case "hike":
      case "tour":
        this.setState({
          show: "preview_whatson",
          whatWhatson: data.type,
          whatsonId: data.id,
        });
        break;
      case "user":
        // this.setState({
        //   show: "user",
        // });
        this.axios
          .post(env.protocol + env.env + "/api/GetUser", {
            vfiUser: data.userId,
          })
          .then((response) => {
            this.props.openOverlay("userEdit", {
              ...response.data,
              id: response.data.userId.toString(),
            });
          })
          .catch((error) => console.error(error));
        break;
      case "media":
        this.setState({
          show: "media",
          media_opened: pushMedia(data),
          media_opened_edit: pushMedia(data),
        });
        break;
      default:
        console.error(`The type '${data.type}' isn't a correct type.`);
        break;
    }
  };

  // Filter
  filterContentAction = (activities) => {
    const filters = this.state.filters;

    // Search input
    activities = helperfunctions.searchFilter(activities, filters.searchValue);

    if (this.state.showTable === "awaiting") {
      activities = activities.filter((f) => {
        return (
          f.approval_status === "awaiting approval" || f.submits?.length > 0
        );
      });
    }

    // Date
    // activities = activities.filter((f) => {
    //   const date = new Date(f.activities[0].create_date);
    //   const fromDate = new Date(filters.fromDate);
    //   fromDate.setDate(fromDate.getDate() - 1);
    //   return date >= fromDate && date < filters.toDate;
    // });

    // Sites
    if (filters.sites.length !== 0) {
      activities = activities.filter((e) => {
        return (
          filters.sites.find((siteName) => e.siteInfo?.name === siteName) !==
          undefined
        );
      });
    }

    // Regions
    if (filters.regions.length !== 0) {
      activities = activities.filter((e) => {
        return (
          filters.regions.find((regionName) => e.regionName === regionName) !==
          undefined
        );
      });
    }

    // Regions
    if (filters.activity_types.length !== 0) {
      activities = activities.filter((e) => {
        return (
          filters.activity_types.find((type) => e.type === type) !== undefined
        );
      });
    }

    // Awaiting status
    if (filters.awaiting_status.length !== 0) {
      activities = activities.filter((e) => {
        return (
          filters.awaiting_status.find((status) => {
            switch (status) {
              case "Pending":
                return e.approval_status === "awaiting approval";
              case "Approved":
                return e.approval_status === "approve";
              case "Changes required":
                return e.approval_status === "changes required";
              case "Denied":
                return e.approval_status === "deny";
              default:
                return false;
            }
          }) !== undefined
        );
      });
    }

    return activities;
  };

  render() {
    // console.log(this.props.listOptions)
    if (this.state.show === "page") {
      return (
        <EditPage
          data={this.state.pageData}
          close={() => this.setState({ show: "activities" })}
          goBackText={"Activity"}
        />
      );
    }

    if (this.state.show === "preview_whatson") {
      return (
        <PreviewWhatson
          whatWhatson={this.state.whatWhatson}
          whatsonId={this.state.whatsonId}
          close={() => {
            this.setState({ show: "activities" });
            // this.getAllWhatsonList();
          }}
          openOverlay={this.props.openEdit}
          setSiteRef={this.props.setSiteRef}
          addOne={this.props.addOne}
        />
      );
    }

    if (this.state.show === "media") {
      return (
        <div className="media-content">
          <div className="media-display-parent">
            <div className="media-display">
              <MediaSettings
                media={this.state.media_opened}
                editMedia={this.state.media_opened_edit}
                closeSettings={() => this.setState({ show: "activities" })}
                editSettings={(key, value) => {
                  key = key.split(".");
                  const media = cloneDeep(this.state.media_opened_edit);
                  let mediaDive = media;
                  for (let i = 0; i < key.length; i++) {
                    if (i + 1 !== key.length) {
                      mediaDive = mediaDive[key[i]];
                    } else {
                      mediaDive[key[i]] = value;
                    }
                  }
                  this.setState({ media_opened_edit: media });
                }}
                updateClick={(m) => {
                  console.log(m);
                }}
                deleteMedia={(id) => {
                  if (
                    window.confirm(
                      "Are you sure you want to delete this media?\nThis action cannot be undone."
                    )
                  ) {
                    this.axios
                      .post(env.protocol + env.env + "/api/deleteMedia", {
                        id,
                      })
                      .then((response) => {
                        this.setState({
                          activity: this.state.activity.filter(
                            (f) =>
                              f.type !== "media" ||
                              f.id !== response.data.deletedIds[0]
                          ),
                          show: "activities",
                        });
                      })
                      .catch((error) => console.error(error));
                  }
                }}
              />
            </div>
          </div>
        </div>
      );
    }

    // if (this.state.show === "user") {
    //   return "";
    // } // User opens as overlay

    const filterHidden =
      this.props.addFilterDroppedDown[0] &&
      this.props.addFilterDroppedDown[1] === "activityDisplay"
        ? ""
        : "hidden";
    let filteredContent = this.filterContentAction(this.state.activity);
		// searchContent = this.checkboxFiltering(searchContent, "siteName");
    // var date = this.filterContent[0].value[0];
    // searchContent = helperfunctions.filterDateInterval(
    //   searchContent,
    //   date.startDate,
    //   date.endDate
    // );

    filteredContent = filteredContent.filter((e) => {
      if (this.state.filterOption === "all") return true;
      return e.type === this.state.filterOption;
    });

    let awaitingSearchContent = helperfunctions.searchFilter(
      this.state.awaiting_approval,
      this.state.filters.searchValue
    );

    const newEventsAmount = this.state.activity.filter(
      (e) => e.type === "event" && e.status === "pending"
    ).length;
    const updateEventsAmount = this.state.activity.filter(
      (e) => e.type === "event" && e.submits.length !== 0
    ).length;
    const newPlacesAmount = this.state.activity.filter(
      (e) => e.type === "place" && e.status === "pending"
    ).length;
    const updatePlacesAmount = this.state.activity.filter(
      (e) => e.type === "place" && e.submits.length !== 0
    ).length;
    const newToursAmount = this.state.activity.filter(
      (e) => e.type === "tour" && e.status === "pending"
    ).length;
    const updateToursAmount = this.state.activity.filter(
      (e) => e.type === "tour" && e.submits.length !== 0
    ).length;

    return (
      <div className="activity-display">
        <OptionsList
          isOpen={this.state.listIsOpen}
          close={() => {
            this.setState({ listIsOpen: false });
          }}
          options={this.state.optionsListOptions}
          position={this.state.optionsListPos}
        />
        <div className="header-panel">
          <div className="header-title">
            <h1>Activity</h1>
          </div>
          <div className="all-sort-and-filter">
            <ToolTip
              offset={{ x: 30, y: 30 }}
              title=""
              delay={600}
              toolTipElements={<div>Toggle filter panel</div>}
              className={"filter-button " + filterHidden}
              onClick={() => {
                // this.filterContent[1].value = this.sitesToMap();
                // this.props.openCloseFilter(
                //   [!this.props.addFilterDroppedDown[0], "activityDisplay"],
                //   {
                //     type: "activity",
                //     search: this.search,
                //     style: {},
                //     content: this.filterContent,
                //     executeCheckbox: (e) => {
                //       // When elements are clicked
                //       this.onCheckboxChange(e);
                //     },
                //     executeDate: (whatdate, date) => {
                //       // When date changes
                //       this.onDateChange(whatdate, date);
                //     },
                //     executeSearch: (e) => {
                //       // When search value changes
                //       this.onSearchChange(e);
                //     },
                //   }
                // );
                this.setState({ filterShown: !this.state.filterShown });
              }}
            >
              <span className="text">Filter</span>
              <FontAwesomeIcon
                className="slide-icon"
                icon={faSlidersH}
                flip={"vertical"}
              />
            </ToolTip>
          </div>
          <div className="search-parent">
            <div className="search">
              <VfiInputText
                value={this.state.filters.searchValue}
                placeholder="Search for activity..."
                onChange={(e) => {
                  this.setState({
                    filters: {
                      ...this.state.filters,
                      searchValue: e.target.value,
                    },
                  });
                }}
                isSearch={true}
              />
            </div>
          </div>
        </div>
        <div className="table-select">
          <div
            className={`t-option ${
              this.state.showTable === "awaiting" ? "active" : ""
            }`}
            onClick={() => {
              this.setState({ showTable: "awaiting" });
            }}
          >
            Awaiting
          </div>
          <div
            className={`t-option ${
              this.state.showTable === "all" ? "active" : ""
            }`}
            onClick={() => {
              this.setState({ showTable: "all" });
            }}
          >
            All activity
          </div>
          <div className="t-option rest"></div>
        </div>
        <div className="filter-what">
          <div
            className={`f-option ${
              this.state.filterOption === "all" ? "active" : ""
            }`}
            onClick={() => this.setState({ filterOption: "all" })}
          >
            <div className="f-text">All</div>
            {/* <div className="notify new-notify">3</div>
            <div className="notify update-notify">1</div> */}
          </div>
          |
          {this.state.showTable === "all" && (
            <>
              <div
                className={`f-option ${
                  this.state.filterOption === "page" ? "active" : ""
                }`}
                onClick={() => this.setState({ filterOption: "page" })}
              >
                <div className="f-text">Pages</div>
                {/* <div className="notify new-notify">3</div>
                <div className="notify update-notify">1</div> */}
              </div>
              |
            </>
          )}
          {this.state.showTable === "all" && (
            <>
              <div
                className={`f-option ${
                  this.state.filterOption === "article" ? "active" : ""
                }`}
                onClick={() => this.setState({ filterOption: "article" })}
              >
                <div className="f-text">Articles</div>
              </div>
              |
            </>
          )}
          {(this.state.showTable === "all" ||
            newEventsAmount > 0 ||
            updateEventsAmount > 0) && (
            <>
              <div
                className={`f-option ${
                  this.state.filterOption === "event" ? "active" : ""
                }`}
                onClick={() => this.setState({ filterOption: "event" })}
              >
                <div className="f-text">Events</div>
                {newEventsAmount > 0 && (
                  <div className="notify new-notify">{newEventsAmount}</div>
                )}
                {updateEventsAmount > 0 && (
                  <div className="notify update-notify">
                    {updateEventsAmount}
                  </div>
                )}
              </div>
              |
            </>
          )}
          {(this.state.showTable === "all" ||
            newPlacesAmount > 0 ||
            updatePlacesAmount > 0) && (
            <>
              <div
                className={`f-option ${
                  this.state.filterOption === "place" ? "active" : ""
                }`}
                onClick={() => this.setState({ filterOption: "place" })}
              >
                <div className="f-text">Places</div>
                {newPlacesAmount > 0 && (
                  <div className="notify new-notify">{newPlacesAmount}</div>
                )}
                {updatePlacesAmount > 0 && (
                  <div className="notify update-notify">
                    {updatePlacesAmount}
                  </div>
                )}
              </div>
              |
            </>
          )}
          {(this.state.showTable === "all" ||
            newToursAmount > 0 ||
            updateToursAmount > 0) && (
            <>
              <div
                className={`f-option ${
                  this.state.filterOption === "tour" ? "active" : ""
                }`}
                onClick={() => this.setState({ filterOption: "tour" })}
              >
                <div className="f-text">Tours</div>
                {newToursAmount > 0 && (
                  <div className="notify new-notify">{newToursAmount}</div>
                )}
                {updateToursAmount > 0 && (
                  <div className="notify update-notify">
                    {updateToursAmount}
                  </div>
                )}
              </div>
              |
            </>
          )}
          {this.state.showTable === "all" && (
            <>
              <div
                className={`f-option ${
                  this.state.filterOption === "tour operator" ? "active" : ""
                }`}
                onClick={() => this.setState({ filterOption: "tour operator" })}
              >
                <div className="f-text">Tour operators</div>
                {/* <div className="notify new-notify">3</div>
            <div className="notify update-notify">1</div> */}
              </div>
              |
            </>
          )}
          {this.state.showTable === "all" && (
            <>
              <div
                className={`f-option ${
                  this.state.filterOption === "hike" ? "active" : ""
                }`}
                onClick={() => this.setState({ filterOption: "hike" })}
              >
                <div className="f-text">Hiking</div>
                {/* <div className="notify new-notify">3</div>
            <div className="notify update-notify">1</div> */}
              </div>
              |
            </>
          )}
          {this.state.showTable === "all" && (
            <>
              <div
                className={`f-option ${
                  this.state.filterOption === "media" ? "active" : ""
                }`}
                onClick={() => this.setState({ filterOption: "media" })}
              >
                <div className="f-text">Media</div>
                {/* <div className="notify new-notify">3</div>
            <div className="notify update-notify">1</div> */}
              </div>
              |
            </>
          )}
          {this.state.showTable === "all" && (
            <>
              <div
                className={`f-option ${
                  this.state.filterOption === "user" ? "active" : ""
                }`}
                onClick={() => this.setState({ filterOption: "user" })}
              >
                <div className="f-text">Users</div>
                {/* <div className="notify new-notify">3</div>
            <div className="notify update-notify">1</div> */}
              </div>
            </>
          )}
        </div>
        <this.ActivityTable
          content={filteredContent}
          extraThs={[
            {
              key: 0,
              className:
                this.state.sortedBy === "date" ? "date sorted-by" : "date",
              content: (
                <div>
                  Date{" "}
                  <FontAwesomeIcon
                    icon={
                      this.state.sortedBy === "date"
                        ? faSortAltSorted
                        : faSortAlt
                    }
                    flip={"vertical"}
                    transform={this.state.reverse ? {} : { rotate: 180 }}
                  />
                </div>
              ),
              onClick: () => {
                helperfunctions.sortList(
                  "regdate",
                  filteredContent,
                  this.state.sortedBy === "date",
                  true
                );
                let reverse = false;
                if (
                  this.state.sortedBy === "date" &&
                  this.state.reverse === false
                )
                  reverse = true;
                this.setState({ sortedBy: "date", reverse: reverse });
              },
            },
          ]}
          extraTds={[
            {
              key: 0,
              className: "Date",
              content: (data) => <div>{data.time_since_reg}</div>,
              onClick: () => {
                console.log("aaa");
              },
            },
          ]}
          hidden={this.state.allActivityHidden}
          listName="activity"
          mainClick={this.mainClickFunction}
        />
        <RightSideInfo
          info={this.showFilters()}
          title="Filter"
          isShowing={this.state.filterShown}
          close={() => this.setState({ filterShown: false })}
          // defaultAllCollapsed={true}
        />
        {Boolean(this.state.changeDecisionInfo?.id) && (
          <ChangeApprovalScreen
            defaultInfo={this.state.changeDecisionInfo}
            close={() => {
              this.setState({ changeDecisionInfo: undefined });
            }}
            updateApprovalStatus={(type, id, value) => {
              const activities = cloneDeep(this.state.activity);
              const activity = activities.find(
                (a) => a.type === type && a.id === id
              );
              if (activity !== undefined) {
                activity.approval_status = value;
                activity.status = value;
                this.setState({ activity: activities });
              }
            }}
          />
        )}
      </div>
    );
  }
}
export default Activity;
