import React, { useState, useEffect } from "react";
import WhatsonCreateNew from "../Content/WhatsonDisplay/WhatsonCreateNew";
import axios from "axios";
import env from "../../environment.json";
import LoginTable from "./LoginTable";
import { forward, defaultLoginInfo } from "./CreateWhatson";
import "./Login.scss";
import RegisterTable from "./RegisterTable";
import { faUser, faUserPlus } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { update } from 'lodash';

export default function SubmitChange(props) {
  // Declare a new state variable, which we'll call "count"

  // const [id, setId] = useState(props.id);
  // const [language, setLanguage] = useState("english");
  // const [nav, setNav] = useState(props.navTo);
  const { id, navTo: nav, urlToGoBack } = props;
  const language = "english";
  // const nav = props.navTo;
  const [domainBack, setDomainBack] = useState(undefined);
  const [editContent, setEditContent] = useState(undefined);
  const [loginInfo, setLoginInfo] = useState({ ...defaultLoginInfo });
  const [loggedIn, setLoggedIn] = useState(false);
  const [userId, setUserId] = useState(undefined);

  const [register, setRegister] = useState(true);

  useEffect(() => {
    // Update the document title using the browser API
    // get(nav);

    if (nav.toLowerCase() === "event") {
      axios
        .post(env.protocol + env.env + "/api/whatson/events/GetEvent", {
          id: id,
        })
        .then((response) => {

          console.log(response);

          if (response.data.data !== null) {
            setEditContent(response.data);
          }
        })
        .catch((error) => console.error(error));
    }

    if (nav.toLowerCase() === "place") {
      axios
        .post(env.protocol + env.env + "/api/whatson/places/GetPlace", {
          id: id,
        })
        .then((response) => {
          if (response.data.data !== null) {
            setEditContent(response.data);
          }
        })
        .catch((error) => console.error(error));
    }

    if (nav.toLowerCase() === "tour") {
      axios
        .post(env.protocol + env.env + "/api/whatson/tours/GetTour", {
          id: id,
        })
        .then((response) => {
          if (response.data.data !== null) {
            setEditContent(response.data);
          }
        })
        .catch((error) => console.error(error));
    }

    axios
      .post(env.protocol + env.env + "/api/whatson/GetWhatsonSite", {})
      .then((response) => {
        console.log(response);
        const { protocol, domain_name } = response.data;
        setDomainBack(protocol + domain_name);
      })
      .catch((error) => console.error(error));
  }, [nav, id]);

  useEffect(() => {
    console.log(editContent);
  }, [editContent]);

  // const get = (nav) => {
  //     if (nav.toLowerCase() === "event") {
  //         axios.post(
  //                 env.protocol + env.env + "/api/whatson/events/GetEvent",
  //                 {id: id }
  //             )
  //             .then((response) => {
  //                 if(response.data.data !== null){
  //                     setEditContent(response.data);
  //                 }
  //             })
  //             .catch((error) => console.error(error));
  //     }

  //     if (nav.toLowerCase() === "place") {
  //         axios.post(
  //                 env.protocol + env.env + "/api/whatson/places/GetPlace",
  //                 { id: id }
  //             )
  //             .then((response) => {
  //                 if(response.data.data !== null){
  //                     setEditContent(response.data);
  //                 }
  //             })
  //             .catch((error) => console.error(error));
  //     }

  //     if (nav.toLowerCase() === "tour") {
  //         axios.post(
  //             env.protocol + env.env + "/api/whatson/tours/GetTour",
  //             { id: id }
  //         )
  //             .then((response) => {
  //                 if(response.data.data !== null){
  //                     setEditContent(response.data);
  //                 }
  //             })
  //             .catch((error) => console.error(error));
  //     }
  // }

  if (!loggedIn) {
    return (
      <div className="whatson-contents">
        <div className="content">
          {register? (
            <RegisterTable
            loginInfo={loginInfo}
            redirect={'change'}
            editloginInfo={(key, value) => {
              loginInfo[key] = value;
              setLoginInfo(loginInfo);
            }}
            backward={() => {
              setLoginInfo({ ...defaultLoginInfo });
            }}
            forward={() =>
              forward({
                setForward: (enabled) => {
                  setLoggedIn(enabled);
                },
                loginInfo,
                afterFunc: (id) => {
                  setUserId(id);
                },
              })
            }
            setId={() => {}}
          />
          ) : (
            <LoginTable
            loginInfo={loginInfo}
            redirect={'change'}
            editloginInfo={(key, value) => {
              loginInfo[key] = value;
              setLoginInfo(loginInfo);
            }}
            backward={() => {
              setLoginInfo({ ...defaultLoginInfo });
            }}
            forward={() =>
              forward({
                setForward: (enabled) => {
                  setLoggedIn(enabled);
                },
                loginInfo,
                afterFunc: (id) => {
                  setUserId(id);
                },
              })
            }
            setId={() => {}}
          />
          )}
        </div>

        <a
          className="register-login"
          onClick={() => {

            setRegister(!register);

            }}>
          <p>
            {register? 'ALREADY HAVE A USER? LOGIN IN HERE' : "DON'T HAVE A USER? REGISTER HERE" }
          </p>
          <FontAwesomeIcon icon={register? faUser : faUserPlus} />
        </a>
      </div>
    );
  }

  if (editContent !== undefined) {
    return (
      <div className="content-forWhatson">
        <div className="whatson-content">
          <WhatsonCreateNew
            language={language}
            newWhat={nav}
            updateData={editContent}
            close={() =>
              window.open(
                // `${domainBack}/${nav.toLowerCase()}s/${urlToGoBack ? (nav.toLowerCase()+"/"+urlToGoBack) : ""}`,
                decodeURIComponent(urlToGoBack),
                "_self"
              )
            }
            isFront={true}
            userFront={userId}
            submitChange={"true"}
            setSiteRef={() => console.log("Set site ref")}
          />
        </div>
      </div>
    );
  } else {
    return <div>loading</div>;
  }
}
