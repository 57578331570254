import React, { Component } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import NewEvent from "./Event/NewEvent";
import NewPlace from "./Place/NewPlace";
import NewHiking from "./Hiking/NewHiking";
import NewTour from "./Tour/NewTour";
import NewTourOperator from "./TourOperator/NewTourOperator";

import "./GlobalWhatsonCreateNew.scss";

class WhatsonCreateNew extends Component {
  constructor(props) {
    super(props);

    this.state = {
      index: 0,
      blurScreen: false,
      changes: [],
      progressbar: false,
    };

    this.stepBubble = this.stepBubble.bind(this);
  }

  stepBubble(logo, text, index, passed, edit = false, disabled = false) {
    let extraClassname = "";
    if (this.state.index === index) extraClassname += "selected ";

    if (!passed) extraClassname += "bubble-error ";

    return (
      <div
        className={
          "whatson-step " + (disabled ? "disabled " : "") + extraClassname
        }
        onClick={() => {
          if (!disabled) this.setState({ index });
        }}
      >
        <div className="w-bubble">
          {typeof logo === "string" || logo instanceof String ? (
            <div className="b-text">{logo}</div>
          ) : (
            <FontAwesomeIcon icon={logo} />
          )}
          {edit ? <p className="edit_true">1</p> : ""}
        </div>
        <p>{text}</p>
        {!passed && <div className="error">!</div>}
      </div>
    );
  }

  indexChange = (i, after = () => void 0) => {
    this.setState({ index: this.state.index + i }, after);
    return this.state.index + i;
  };

  blurScreen = (bool, progress = false) =>
    this.setState({ blurScreen: bool, progressbar: progress });

  render() {
    let createWhat = "";

    switch (this.props.newWhat.toLowerCase()) {
      case "event":
        createWhat = (
          <NewEvent
            index={this.state.index}
            indexChange={this.indexChange}
            stepBubble={this.stepBubble}
            updateData={this.props.updateData}
            autoSave={this.props.autoSave}
            isFront={this.props.isFront}
            saveDraftDisabled={this.props.saveDraftDisabled}
            pushOneAndGoToList={this.props.pushOneAndGoToList} //brúka til backend
            updateOneAndGoToList={this.props.updateOneAndGoToList} //brúka til backend
            goToList={this.props.close}
            blurScreen={this.blurScreen}
            openOverlay={this.props.openOverlay}
            addOne={this.props.addOne}
            setSiteRef={this.props.setSiteRef}
            language={this.props.language}
            closeEvent={this.props.close}
            submitChange={this.props.submitChange}
            userFront={this.props.userFront}
            register={this.props.register}
          />
        );
        break;
      case "place":
        createWhat = (
          <NewPlace
            index={this.state.index}
            indexChange={this.indexChange}
            stepBubble={this.stepBubble}
            updateData={this.props.updateData}
            autoSave={this.props.autoSave}
            pushOneAndGoToList={this.props.pushOneAndGoToList}
            updateOneAndGoToList={this.props.updateOneAndGoToList}
            goToList={this.props.close}
            blurScreen={this.blurScreen}
            isFront={this.props.isFront}
            openOverlay={this.props.openOverlay}
            addOne={this.props.addOne}
            setSiteRef={this.props.setSiteRef}
            language={this.props.language}
            closePlace={this.props.close}
            submitChange={this.props.submitChange}
            userFront={this.props.userFront}
          />
        );
        break;
      case "hiking":
      case "hike":
        createWhat = (
          <NewHiking
            index={this.state.index}
            indexChange={this.indexChange}
            stepBubble={this.stepBubble}
            updateData={this.props.updateData}
            autoSave={this.props.autoSave}
            pushOneAndGoToList={this.props.pushOneAndGoToList}
            updateOneAndGoToList={this.props.updateOneAndGoToList}
            goToList={this.props.close}
            blurScreen={this.blurScreen}
            openOverlay={this.props.openOverlay}
            setSiteRef={this.props.setSiteRef}
            addOne={this.props.addOne}
            addContent={this.props.addContent}
            language={this.props.language}
            closeHiking={this.props.close}
            // userFront={this.props.userFront}
          />
        );
        break;
      case "tour":
        createWhat = (
          <NewTour
            index={this.state.index}
            indexChange={this.indexChange}
            stepBubble={this.stepBubble}
            updateData={this.props.updateData}
            autoSave={this.props.autoSave}
            pushOneAndGoToList={this.props.pushOneAndGoToList}
            updateOneAndGoToList={this.props.updateOneAndGoToList}
            blurScreen={this.blurScreen}
            language={this.props.language}
            isFront={this.props.isFront}
            closeTour={this.props.close}
            openOverlay={this.props.openOverlay}
            setSiteRef={this.props.setSiteRef}
            addOne={this.props.addOne}
            submitChange={this.props.submitChange}
            userFront={this.props.userFront}
          />
        );
        break;
      case "tour operator":
        createWhat = (
          <NewTourOperator
            index={this.state.index}
            indexChange={this.indexChange}
            setState={(state) => this.setState(state)}
            stepBubble={this.stepBubble}
            updateData={this.props.updateData}
            autoSave={this.props.autoSave}
            blurScreen={this.blurScreen}
            language={this.props.language}
            closeTourOperator={this.props.close}
            openOverlay={this.props.openOverlay}
            setSiteRef={this.props.setSiteRef}
          />
        );
        break;
      default:
        createWhat = "Should not get here...";
    }

    return (
      <div className="whatson-create-new">
        {createWhat}
        <div
          className={
            this.state.blurScreen ? "blur-screen" : "blur-screen hidden"
          }
        >
          {this.state.progressbar !== false && (
            <progress value={this.state.progressbar} max="100" />
          )}
          {/* {<progress value={50} max="100" />} */}
        </div>
        {/* <div className={"blur-screen"} /> */}
      </div>
    );
  }
}

export default WhatsonCreateNew;
