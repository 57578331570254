import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown, faChevronUp } from "@fortawesome/pro-light-svg-icons";
import "./Filter.scss";
import { useState } from "react";

export default function Filter(props) {
  const { findex, filterOptions, disabledFilters, setForceUpdate } = props;
  const [openFilter, setOpenFilter] = useState(false);

  return (
    <div className="filters">
      <div key={"filter" + findex} className={"box"}>
        <div className="wrap-top">
          <div>
            <h3>Filters</h3>
            <p>Disable the filter that the user can filter on</p>
          </div>
          <div
            className="expandBrand"
            onClick={() => {
              setOpenFilter((f) => !f);
            }}
          >
            <FontAwesomeIcon
              icon={openFilter ? faChevronUp : faChevronDown}
            ></FontAwesomeIcon>
          </div>
        </div>
        {openFilter && (
          <div className="wrap-filter-options">
            {filterOptions.map((element) => {
              return (
                <div
                  className={
                    disabledFilters.includes(element.title) ? "selected" : ""
                  }
                  onClick={() => {
                    const ffindex = disabledFilters.findIndex(
                      (x) => x === element.title
                    );
                    if (ffindex === -1) {
                      disabledFilters.push(element.title);
                    } else {
                      disabledFilters.splice(ffindex, 1);
                    }
                    setForceUpdate((f) => !f);
                  }}
                >
                  <input
                    checked={disabledFilters.includes(element.title)}
                    type={"checkbox"}
                  ></input>
                  {element.title}
                </div>
              );
            })}
          </div>
        )}
      </div>
    </div>
  );
}
