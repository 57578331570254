import { useEffect, useRef, useState } from "react";
import styles from "./AmenetiesDisplay.module.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { sort } from "./sort-functions";
import {
  faArrowDownArrowUp,
  faSortAlt,
  faMagnifyingGlass
} from "@fortawesome/pro-light-svg-icons";
import { faSortAlt as faSortAltSorted } from "@fortawesome/pro-duotone-svg-icons";
import { overlayStore } from "src/Components/OverlayElements/OverlayStore";
import Card from "src/Components/OverlayElements/Elements/Card/Card";
import AddAmenity from "./AmenityModules/AddAmenity/AddAmenity";
import axios from "axios";

import env from "../../../../../environment.json";
import { faLoader } from "@fortawesome/pro-solid-svg-icons";
import { IconProp } from "@fortawesome/fontawesome-svg-core";

// Create amenity type
type amenity = {};

// Array of keys(columns) we use in our table
const amenityKeys = [
  {
    key: "amNameEn",
    label: "English title",
  },
  {
    key: "amNameFo",
    label: "Faroese title",
  },
  {
    key: "Date",
    label: "Date",
  },
];

export default function AmenetiesDisplay() {
  const { addOverlay } = overlayStore((state: any) => state);

  const [loaded, setLoaded] = useState<boolean>(false);
  const [amenities, setAmenities] = useState<any[]>([]);
  const [amenitiesLoaded, setAmenitiesLoaded] = useState<boolean>(false);
  const [search, setSearch] = useState<string>("");
  const [sortBy, setSortBy] = useState<any>({
    key: "Date",
    reverse: false,
  });

  const fetchAmenities = () => {
    axios
      .post(
        env.protocol + env.env + "/api/public/whatson/places/GetPlaceAmenities"
      )
      .then((response: any) => {
        setAmenities(
          response.data.sort((a: any, b: any) =>
            sort(a, b, "Date", !sortBy.reverse)
          )
        );
      })
      .catch((error: any) => {})
      .finally(() => {
        setAmenitiesLoaded(true);
      });
  };

  useEffect(() => {
    fetchAmenities();

    setLoaded(true);
  }, []);
  
  return (
    <div className={`${styles.container} ${loaded ? styles.loaded : ""}`}>
      {/* Content */}

      <div className={styles.content}>
        {/* Header */}

        <div className={styles.header}>
          <h1>Amenities (Place)</h1>
          <button
            className={styles.addAmenity}
            onClick={() => {
              addOverlay("card", (close: any) => {
                return <AddAmenity width={860} height={680} close={close} success={() => {fetchAmenities()}} />;
              });
            }}
          >
            Add new amenity
          </button>
        </div>

        {/* Search */}

        <div className={styles.searchSection}>
          <div className={styles.searchInputContainer}>
            <input
              className={styles.searchInput}
              placeholder="Search amenities..."
              type="text"
              onFocus={(e) => {
                e.target.placeholder = "";
              }}
              onBlur={(e) => {
                e.target.placeholder = "Search amenities...";
              }}
              onChange={(e: any) => {
                setSearch(e.target.value);
              }}
            />
            <FontAwesomeIcon
              className={styles.searchIcon}
              icon={faMagnifyingGlass as IconProp}
            />
          </div>
        </div>

        {/* Table */}

        <div
          className={styles.tableContainer}
          onScroll={(e: any) => {
            console.log(e.currentTarget.scrollTop);
            console.log(e.currentTarget.clientHeight);
          }}
        >
          <table>
            {/* Table header */}

            <thead>
              <tr>
                {amenityKeys.map((amenity: any, index: number) => {

                  const handleClick: any = () => {
                    setAmenities([
                      ...amenities.sort((a: any, b: any) =>
                        sort(a, b, amenity.key, sortBy.reverse)
                      ),
                    ]);
                    setSortBy({
                      key: amenity.key,
                      reverse: !sortBy.reverse,
                    });
                  }

                  return (
                    <th
                    tabIndex={0}
                      className={
                        `
                        ${index + 1 === amenityKeys.length ? styles.last : ""}
                        `
                      }
                      onKeyUp={(e: any) => {
                        if (e.keyCode === 13) {
                          handleClick();
                        }
                      }}
                      onClick={() => {  
                        handleClick();
                      }}
                    >
                      <div className={styles.tableHeaderContent}>
                        <p>{amenity.label}</p>
                        <FontAwesomeIcon
                          icon={
                            sortBy.key === amenity.key
                              ? faSortAltSorted as IconProp
                              : faSortAlt as IconProp
                          }
                          flip={"vertical"}
                          transform={!sortBy.reverse ? {} : { rotate: 180 }}
                        />
                      </div>
                    </th>
                  );
                })}
              </tr>
            </thead>

            {/* Table data */}

            <tbody>
            {amenitiesLoaded ? (
              // FIX DUPLICATE FILTERING PLZZZ!!!!!!!!!
              amenities
                .filter((amenity: any) =>
                  (amenity.amNameFo + amenity.amNameEn) /*  + amenity.Date */
                    .toLowerCase()
                    .includes(search.toLowerCase())
                )
                .map((amenity: any, index: number) => {
                  const handleClick = () => {
                    addOverlay("card", (close: any) => {
                      return (
                        <AddAmenity
                          width={860}
                          height={680}
                          close={close}
                          success={() => {fetchAmenities()}}
                          forms={amenities
                            .filter((amenity: any) =>
                              (
                                amenity.amNameFo + amenity.amNameEn
                              ) /*  + amenity.Date */
                                .toLowerCase()
                                .includes(search.toLowerCase())
                            )
                            .map((a: any) => {

                              return {
                                amenityID: a.amenityID,
                                title: {
                                  en: a.amNameEn,
                                  fo: a.amNameFo,
                                },
                                backend: {
                                  en: a.tooltip,
                                  fo: a.tooltipFo,
                                },
                                frontend: {
                                  en: a.tooltipFrontendEn,
                                  fo: a.tooltipFrontendFo,
                                },
                              };
                            })}
                          idx={index}
                          edit={true}
                        />
                      );
                    });
                  };

                  return (
                    <tr
                      className={`${styles.amenity} ${true && styles.active}`}
                      tabIndex={0}
                      onKeyUp={(e: any) => {
                        if (e.keyCode === 13) {
                          handleClick();
                        }
                      }}
                      onClick={handleClick}
                    >
                      <td>{amenity.amNameEn}</td>
                      <td>{amenity.amNameFo}</td>
                      <td className={styles.last}>{amenity.Date}</td>
                    </tr>
                  );
                })
            ) : (
              <>
                {/* In case amenities are loading */}

                <tr>
                  <td>
                    <div className={styles.amenitiesLoading}>
                        <div className={styles.loader}>
                            <FontAwesomeIcon icon={faLoader as IconProp} />
                        </div>
                    </div>
                  </td>
                </tr>
              </>
            )}
            </tbody>
            
          </table>
        </div>
      </div>
    </div>
  );
}
