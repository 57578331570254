import { useEffect, useState } from "react";
import InfoscreenView from "../InfoscreenView/InfoscreenView";
import styles from "./AdminDisplay.module.scss";
import { adminSections } from "./sections";
import SitesDisplay from "./AdminSections/SitesDisplay/SitesDisplay";
import Leaflet from "../Leaflet/Leaflet";
import Appearances from "./AdminSections/Sites/appearances";
import AmenetiesDisplay from "./AdminSections/Ameneties/AmenetiesDisplay";

interface Props {
  language: string;
  adminMenu: string;
  setSiteRef: any;
  openOverlay: any;
  infoscreenMenu: any;
  setConfirmBeforeNavigate: any;
  addOne: any;
}

export default function AdminDisplay(props: any) {
  const [selectedView, setSelectedView] = useState<string>(props.adminMenu);

  return (
    <div className={styles.container}>
      <Leaflet
        title={"Admin"}
        sections={adminSections}
        selectedView={selectedView}
        setSelectedView={setSelectedView}
      />

      <div className={styles.content}>

        {selectedView === "sites" && (
          <>
            {/* <SitesDisplay /> */}
            <Appearances
              setSiteRef={props.setSiteRef}
              openOverlay={props.openOverlay}
              setConfirmBeforeNavigate={props.setConfirmBeforeNavigate}
              listOptions={[]}
            />
          </>
        )}

        {selectedView === "infoscreens" && (
          <div className="infoscreen-content">
            <span className="shadow-maker" />
            <InfoscreenView
              infoscreenMenu={"screenManagement"}
              openOverlay={props.openOverlay}
              setConfirmBeforeNavigate={props.setConfirmBeforeNavigate}
              setSiteRef={props.setSiteRef}
              newData={props.addOne}
            />
          </div>
        )}

        {selectedView === "amenities (Place)" && (
          <AmenetiesDisplay />
        )}
        
      </div>
    </div>
  );
}
