import { faSearch } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";
import noImage from "../../images/empty.jpg";
import languages from "../../PageModules/language.json";
import "./MenuTemplates.scss";

import cloneDeep from "lodash.clonedeep";
import { searchFilter, showDate } from "../../helperFunctions";
import {
  faEllipsisV,
  faEyeSlash,
  faTimes,
} from "@fortawesome/pro-light-svg-icons";
const language = localStorage.getItem("language")
  ? localStorage.getItem("language")
  : "english";
var draggingPage = [];
export default function MenuTemplates(props) {
  const [screen, setScreen] = useState("add");
  const [subScreen, setSubScreen] = useState("pages");
  const [insideTemplate, setInsideTemplate] = useState(props.selectedTemplate);
  const [search, setSearch] = useState("");
  const [pages, setPages] = useState([]);

  const lang = languages[language].menuTemplates;

  useEffect(() => {
    if (!props.showHiddenPages) {
      props.pages.filter((x) => x.current_state === "published");
    }

    setPages(props.pages);
  }, [props.showHiddenPages, props.pages]);

  useEffect(() => {
    let copyPages = cloneDeep(props.pages);
    copyPages = searchFilter(copyPages, search);
    setPages(copyPages);
  }, [search, props.pages]);

  let content = <p>Shouldn't be shown..</p>;
  switch (screen) {
    case "add":
      switch (subScreen) {
        case "pages":
          content = (
            <div className="pages">
              <div className="search">
                <FontAwesomeIcon icon={faSearch} />
                {/* <input placeholder={languages[language].search_placeholder} onChange={(e)=>props.setSearch(e.target.value)} /> */}
                <input
                  placeholder={lang.search_placeholder}
                  onChange={(e) => setSearch(e.target.value)}
                />
              </div>
              <div className="list">
                {pages.map((page, i) => {
                  return (
                    <div
                      key={i}
                      draggable="true"
                      onDragStart={(e) => {
                        draggingPage.push(page);
                        // setPages(pages.filter((x) => x.id !== page.id));

                        props.onDragStart(e, page.id);
                      }}
                      onDragEnd={(e) => {
                        var index = draggingPage.findIndex(
                          (x) => x.id === page.id
                        );
                        draggingPage.splice(index, 1);
                        setPages(props.pages);
                        props.setDragOver(-1);
                      }}
                      className="each-list-page"
                    >
                      <div className="image">
                        <img
                          alt="page thumpnail"
                          src={
                            page.feature_media !== null
                              ? page.feature_image_dir.thumbnail
                              : noImage
                          }
                        />
                      </div>
                      <div className="page-info">
                        <div className="page-title">
                          {page.pl_title}
                          {page.current_status === "hidden" ? (
                            <div className="menu-hidden">
                              <FontAwesomeIcon icon={faEyeSlash} />
                            </div>
                          ) : (
                            ""
                          )}
                        </div>

                        <div className="last-edit">
                          {showDate(page.last_update_date) +
                            " ∙ " +
                            (page.last_update_user
                              ? page.last_update_user.firstname +
                                " " +
                                page.last_update_user.lastname +
                                " ∙ " +
                                page.last_update_user.company
                              : "")}
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          );
          break;
        case "modules":
          content = (
            <div className="modules">
              <div className="wrap-modules">
                <div
                  draggable="true"
                  onDragStart={(e) => {
                    props.onDragStartModule(e, "blank");
                  }}
                >
                  Blank
                </div>
                <div
                  draggable="true"
                  onDragStart={(e) => {
                    props.onDragStartModule(e, "weather");
                  }}
                >
                  Weather
                </div>
                <div
                  draggable="true"
                  onDragStart={(e) => {
                    props.onDragStartModule(e, "number_and_services");
                  }}
                >
                  Number and Services
                </div>
                <div
                  draggable="true"
                  onDragStart={(e) => {
                    props.onDragStartModule(e, "currency");
                  }}
                >
                  Currency
                </div>
                <div
                  draggable="true"
                  onDragStart={(e) => {
                    props.onDragStartModule(e, "good_to_know");
                  }}
                >
                  Good To Know
                </div>
                <div
                  draggable="true"
                  onDragStart={(e) => {
                    props.onDragStartModule(e, "static_palette");
                  }}
                >
                  Static Image Link
                </div>
              </div>
            </div>
          );
          break;
        default:
          break;
      }

      break;
    case "templates":
      content = (
        <div className="templates">
          <div
            onClick={() => {
              const value = { type: 1, reverse: false };
              setInsideTemplate(value);
              props.setSelectedTemplate(value);
            }}
            className={
              insideTemplate.type === 1 && !insideTemplate.reverse
                ? "selected wrap-templates"
                : "wrap-templates"
            }
          >
            <div className="template">
              <div className="td">
                <div></div>
              </div>
              <div className="td">
                <div></div>
                <div></div>
              </div>
              <div className="td">
                <div></div>
                <div></div>
              </div>
              <div className="td">
                <div></div>
                <div></div>
              </div>
              <div className="td">
                <div></div>
              </div>
            </div>
          </div>
          <div
            onClick={() => {
              const value = { type: 2, reverse: false };
              setInsideTemplate(value);
              props.setSelectedTemplate(value);
            }}
            className={
              insideTemplate.type === 2 && !insideTemplate.reverse
                ? "selected wrap-templates"
                : "wrap-templates"
            }
          >
            <div className="template">
              <div className="td">
                <div></div>
                <div></div>
              </div>
              <div className="td">
                <div></div>
              </div>
              <div className="td">
                <div></div>
                <div></div>
              </div>
              <div className="td">
                <div></div>
              </div>
            </div>
          </div>
          <div
            onClick={() => {
              const value = { type: 2, reverse: true };
              setInsideTemplate(value);
              props.setSelectedTemplate(value);
            }}
            className={
              insideTemplate.type === 2 && insideTemplate.reverse
                ? "selected wrap-templates"
                : "wrap-templates"
            }
          >
            <div className="template reverse">
              <div className="td">
                <div></div>
                <div></div>
              </div>
              <div className="td">
                <div></div>
              </div>
              <div className="td">
                <div></div>
                <div></div>
              </div>
              <div className="td">
                <div></div>
              </div>
            </div>
          </div>
          <div
            onClick={() => {
              const value = { type: 3, reverse: false };
              setInsideTemplate(value);
              props.setSelectedTemplate(value);
            }}
            className={
              insideTemplate.type === 3 && !insideTemplate.reverse
                ? "selected wrap-templates"
                : "wrap-templates"
            }
          >
            <div className="template">
              <div className="td">
                <div></div>
                <div></div>
              </div>
              <div className="td">
                <div></div>
                <div></div>
              </div>
              <div className="td">
                <div></div>
              </div>
              <div className="td">
                <div></div>
              </div>
            </div>
          </div>
          <div
            onClick={() => {
              const value = { type: 3, reverse: true };
              setInsideTemplate(value);
              props.setSelectedTemplate(value);
            }}
            className={
              insideTemplate.type === 3 && insideTemplate.reverse
                ? "selected wrap-templates"
                : "wrap-templates"
            }
          >
            <div className="template reverse">
              <div className="td">
                <div></div>
                <div></div>
              </div>
              <div className="td">
                <div></div>
                <div></div>
              </div>
              <div className="td">
                <div></div>
              </div>
              <div className="td">
                <div></div>
              </div>
            </div>
          </div>
          <div
            onClick={() => {
              const value = { type: 0, reverse: false };
              setInsideTemplate(value);
              props.setSelectedTemplate(value);
            }}
            className={
              insideTemplate.type === 0
                ? "selected wrap-templates"
                : "wrap-templates"
            }
          >
            <div className="template">
              <div className="td">
                <div>
                  <span>CUSTOM</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
      break;
    default:
      break;
  }

  return (
    <div className="vfi-carousel edit-module">
      <div>
        <div id="handle">
          <FontAwesomeIcon icon={faEllipsisV} />
          <FontAwesomeIcon icon={faEllipsisV} />
        </div>
        <div onClick={() => props.close()} className="close">
          <FontAwesomeIcon icon={faTimes} />
        </div>
      </div>
      <div className="top">
        <div
          className={"top-choice " + (screen === "add" ? "active " : "")}
          onClick={() => setScreen("add")}
        >
          Add
        </div>
        <div
          className={"top-choice " + (screen === "templates" ? "active " : "")}
          onClick={() => {
            setScreen("templates");
          }}
        >
          TEMPLATES
        </div>
      </div>
      <div
        style={{ display: screen === "add" ? "flex" : "none" }}
        className="sub-screens"
      >
        <div
          className={subScreen === "pages" ? "active" : ""}
          onClick={() => {
            setSubScreen("pages");
          }}
        >
          Pages
        </div>
        <div
          className={subScreen === "modules" ? "active" : ""}
          onClick={() => {
            setSubScreen("modules");
          }}
        >
          Modules
        </div>
      </div>
      <div className="content">{content}</div>
    </div>
  );
}
