import "./WhatsonDesignTypeChoose.scss";

import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCardClub, faList } from "@fortawesome/pro-thin-svg-icons";

export const WhatsonDesignTypeChoose = (props) => {
  const { values, setValues } = props;

  const setDesignType = (key, value) => {
    values[key] = value;
    setValues(values);
  };

  return (
    <div id="WhatsonDesignTypeChoose" className="choose-view">
      <div
        className={`choice ${values.type === "whatson_card" && "selected"}`}
        onClick={() => {
          setDesignType("type", "whatson_card");
        }}
      >
        <div className="image">
          <FontAwesomeIcon icon={faCardClub} />
        </div>
        <div className="text">Card</div>
      </div>

      <div
        className={`choice ${values.type === "whatson_list" && "selected"}`}
        onClick={() => {
          setDesignType("type", "whatson_list");
        }}
      >
        <div className="image">
          <FontAwesomeIcon icon={faList} />
        </div>
        <div className="text">List</div>
      </div>
    </div>
  );
};
