import { create } from "zustand";
import React from "react";
import { sitesStore } from "./sitesStore";
import { userStore } from "./userStore";
import { whatsonStore } from "./whatsonStore";
import { articlesStore } from "./articlesStore";
import PropTypes from "prop-types";

const useAllOfStore = create((...a) => ({
  ...sitesStore(...a),
  ...userStore(...a),
  ...whatsonStore(...a),
  ...articlesStore(...a),
}));

useAllOfStore.propTypes = {
  ...sitesStore.propTypes,
  ...userStore.propTypes,
};

export { useAllOfStore };

export const withAllOfStore = (BaseComponent) => (props) => {
  const store = useAllOfStore();
  return <BaseComponent {...props} store={store} />;
};

// export const withSitesStore = (WrappedComponent) => {
//   return class extends React.Component {
//     static displayName = `WithStore(${
//       WrappedComponent.displayName || WrappedComponent.name || "Component"
//     })`;
//     state = { storeState: useSitesStore.getState() };
//     componentDidMount() {
//       this.unsubscribe = useSitesStore.subscribe((storeState) => {
//         this.setState({ storeState });
//       });
//     }
//     componentWillUnmount() {
//       this.unsubscribe();
//     }
//     render() {
//       const { storeState } = this.state;
//       return (
//         <WrappedComponent
//           {...storeState}
//           {...useSitesStore.get().actions}
//           {...this.props}
//         />
//       );
//     }
//   };
// };

// import { useSitesStore } from "../../../../ZustandStores/siteAccesses";
// useSitesStore((state) => state.getAllSites());
