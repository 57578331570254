import { faChevronDown, faTimes } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";
import { VfiCheckbox, VfiCheckbox2 } from "../../../assets/VfiCheckbox";
import VfiInputText from "../../../assets/VfiInputText/VfiInputText";
import "./FilterMedia.scss";
import env from "../../../environment.json";
import doodles from "../../../assets/images/profile doodles";
import { Media } from "react-bootstrap";
import { cloneDeep } from "lodash";
import { capitalize, searchJson } from "../../../assets/helperFunctions";
import ReactDatePicker from "react-datepicker";

export const defaultFilterMedia = {
  search: "",
  type: { types: { all: true } },
  uploader: { sites: { all: true }, users: { all: true } },
  date: { from: undefined, to: undefined },
  display: { file_name: false, file_size: false },
  sortBy: {
    value: "date",
    reverse: false,
    options: [
      { label: "Date", value: "date" },
      { label: "File name", value: "fileName" },
      { label: "File size", value: "fileSize" },
      { label: "Uploader person", value: "author.fullName" },
      { label: "Uploader region", value: "locations.0.name" },
    ],
  },
};

export function filterMedia(
  media,
  filterValues = defaultFilterMedia,
  sites = [],
  users = []
) {
  const { search, type, uploader, date } = filterValues;
  let copyMedia = cloneDeep(media);

  if (search !== "") {
    copyMedia = copyMedia.filter((e) => {
      return searchJson(e, search);
    });
  }

  if (type.types.all !== true) {
    // if(type.photo)

    let idsIncluded = [];

    if (type.types.photo === true) {
      const extensions = ["jpg", "jpeg", "png", "svg"];
      idsIncluded.push(
        ...copyMedia
          .filter((m) => extensions.includes(m.fileExtension))
          .map((m) => m.id)
      );
    }
    if (type.types.video === true) {
      const extensions = ["mp4", "avi", "wmv", "webm"];
      idsIncluded.push(
        ...copyMedia
          .filter((m) => extensions.includes(m.fileExtension))
          .map((m) => m.id)
      );
    }
    if (type.types.gif === true) {
      const extensions = ["gif"];
      idsIncluded.push(
        ...copyMedia
          .filter((m) => extensions.includes(m.fileExtension))
          .map((m) => m.id)
      );
    }
    if (type.types.audio === true) {
      const extensions = [];
      idsIncluded.push(
        ...copyMedia
          .filter((m) => extensions.includes(m.fileExtension))
          .map((m) => m.id)
      );
    }
    if (type.types.document === true) {
      const extensions = ["pdf"];
      idsIncluded.push(
        ...copyMedia
          .filter((m) => extensions.includes(m.fileExtension))
          .map((m) => m.id)
      );
    }
    if (type.types.gpx === true) {
      const extensions = ["gpx"];
      idsIncluded.push(
        ...copyMedia
          .filter((m) => extensions.includes(m.fileExtension))
          .map((m) => m.id)
      );
    }
    if (type.types.icon === true) {
      const extensions = [];
      idsIncluded.push(
        ...copyMedia
          .filter((m) => extensions.includes(m.fileExtension))
          .map((m) => m.id)
      );
    }

    copyMedia = copyMedia.filter((e) => idsIncluded.includes(e.id));
  }

  if (uploader.sites.all !== true) {
  }

  if (uploader.users.all !== true) {
    let idsIncluded = [];
    for (const [key, value] of Object.entries(uploader.users)) {
      if (key === "all") continue;

      if (value === true) {
        idsIncluded.push(key);
      }
    }
    copyMedia = copyMedia.filter((m) => idsIncluded.includes(m.author.userId));
  }

  if (date.from !== undefined) {
    copyMedia = copyMedia.filter((m) => {
      return new Date(m.date).getTime() >= date.from.getTime();
    });
  }
  if (date.to !== undefined) {
    copyMedia = copyMedia.filter((m) => {
      const tomorrow = new Date(date.to);
      tomorrow.setDate(tomorrow.getDate() + 1);
      return new Date(m.date).getTime() <= tomorrow.getTime();
    });
  }

  return copyMedia;
}

export default function FilterMedia({
  data: propData = defaultFilterMedia,
  onChange = (value = defaultFilterMedia) => void 0,
  sites = [],
  users = [],
  media,
  forceUpdate,
}) {
  const [data, sData] = useState(propData);

  const setData = (newData) => {
    sData({ ...newData, time: new Date().getTime() });
  };

  const [typeOpened, setTypeOpened] = useState(false);
  const [uploaderOpened, setUploaderOpened] = useState(false);
  const [dateOpened, setDateOpened] = useState(false);
  const [displayOpened, setDisplayOpened] = useState(false);
  const [sortByOpened, setSortByOpened] = useState(false);

  useEffect(() => {
    onChange(data);
  }, [data]);

  useEffect(() => {
    sData(propData);
  }, [forceUpdate, propData]);

  const { search } = data;

  const userImage = (id, imgId, ext) => {
    if (imgId !== null)
      return env.protocol + env.env + "/uploads/" + imgId + "_thumbnail." + ext;
    else {
      return doodles[id % 12];
    }
  };

  const handleAll = (obj, keyChange, newValue) => {
    const copyObj = cloneDeep(obj);
    if (keyChange === "all") {
      for (const [key, value] of Object.entries(copyObj)) {
        if (key === "all") copyObj[key] = true;
        else copyObj[key] = false;
      }
      return copyObj;
    }

    copyObj[keyChange] = newValue;

    if (copyObj.all !== undefined) {
      copyObj.all = true;
      for (const [key, value] of Object.entries(copyObj)) {
        if (value === true && key !== "all") {
          copyObj.all = false;
        }
      }
    }
    return copyObj;
  };

  return (
    <div className="filter-media">
      Filter
      <VfiInputText
        className="search-box"
        value={search}
        placeholder="Search media..."
        onChange={(e) => {
          setData({ ...data, search: e.target.value });
        }}
        isSearch={true}
      />
      <div
        className="top-dropdown"
        onClick={() => {
          setTypeOpened(!typeOpened);
        }}
      >
        <div className="text">Type</div>
        <FontAwesomeIcon icon={faChevronDown} rotation={typeOpened ? 180 : 0} />
      </div>
      {typeOpened && (
        <div className="filter-content">
          <div>
            <label>
              <VfiCheckbox
                checked={data.type ? data.type.types.all : false}
                onChange={(e) => {
                  setData({
                    ...data,
                    type: {
                      ...data.type,
                      types: {
                        ...handleAll(data.type.types, "all", e.target.checked),
                      },
                    },
                  });
                }}
              />{" "}
              All
            </label>
          </div>
          <div>
            <label>
              <VfiCheckbox
                checked={data.type ? data.type.types.photo : false}
                onChange={(e) => {
                  setData({
                    ...data,
                    type: {
                      ...data.type,
                      types: {
                        ...handleAll(
                          data.type.types,
                          "photo",
                          e.target.checked
                        ),
                      },
                    },
                  });
                }}
              />{" "}
              Photo
            </label>
          </div>
          <div>
            <label>
              <VfiCheckbox
                checked={data.type ? data.type.types.video : false}
                onChange={(e) => {
                  setData({
                    ...data,
                    type: {
                      ...data.type,
                      types: {
                        ...handleAll(
                          data.type.types,
                          "video",
                          e.target.checked
                        ),
                      },
                    },
                  });
                }}
              />{" "}
              Video
            </label>
          </div>
          <div>
            <label>
              <VfiCheckbox
                checked={data.type ? data.type.types.gif : false}
                onChange={(e) => {
                  setData({
                    ...data,
                    type: {
                      ...data.type,
                      types: {
                        ...handleAll(data.type.types, "gif", e.target.checked),
                      },
                    },
                  });
                }}
              />{" "}
              Gif
            </label>
          </div>
          <div>
            <label>
              <VfiCheckbox
                checked={data.type ? data.type.types.audio : false}
                onChange={(e) => {
                  setData({
                    ...data,
                    type: {
                      ...data.type,
                      types: {
                        ...handleAll(
                          data.type.types,
                          "audio",
                          e.target.checked
                        ),
                      },
                    },
                  });
                }}
              />{" "}
              Audio
            </label>
          </div>
          <div>
            <label>
              <VfiCheckbox
                checked={data.type ? data.type.types.document : false}
                onChange={(e) => {
                  setData({
                    ...data,
                    type: {
                      ...data.type,
                      types: {
                        ...handleAll(
                          data.type.types,
                          "document",
                          e.target.checked
                        ),
                      },
                    },
                  });
                }}
              />{" "}
              Document
            </label>
          </div>
          <div>
            <label>
              <VfiCheckbox
                checked={data.type ? data.type.types.gpx : false}
                onChange={(e) => {
                  setData({
                    ...data,
                    type: {
                      ...data.type,
                      types: {
                        ...handleAll(data.type.types, "gpx", e.target.checked),
                      },
                    },
                  });
                }}
              />{" "}
              Gpx
            </label>
          </div>
          <div>
            <label>
              <VfiCheckbox
                checked={data.type ? data.type.types.icon : false}
                onChange={(e) => {
                  setData({
                    ...data,
                    type: {
                      ...data.type,
                      types: {
                        ...handleAll(data.type.types, "icon", e.target.checked),
                      },
                    },
                  });
                }}
              />{" "}
              Icon
            </label>
          </div>
        </div>
      )}
      <div
        className="top-dropdown"
        onClick={() => {
          setUploaderOpened(!uploaderOpened);
        }}
      >
        <div className="text">Uploader</div>
        <FontAwesomeIcon
          icon={faChevronDown}
          rotation={uploaderOpened ? 180 : 0}
        />
      </div>
      {uploaderOpened && (
        <div className="filter-content">
          {/* <div className="headline">SITES</div>
        <div>
            <label>
            <VfiCheckbox
                checked={data.uploader && data.uploader.sites ? data.uploader.sites.all : false}
                onChange={(e) => {
                setData({
                    ...data,
                    uploader: { ...data.uploader,
                    sites:{...handleAll(data.uploader.sites, "all", e.target.checked)}
                    },
                });
                }}
            />{" "}
            All
            </label>
        </div>
          {sites.map((site) => {
            if(site.index === "0")
              return "";
            return (
              <div>
                <label>
                    <VfiCheckbox
                      checked={data.uploader.sites[site.index]}
                      onChange={(e) => {
                        setData({
                          ...data,
                          uploader: {
                            ...data.uploader,
                            sites:{...handleAll(data.uploader.sites, site.index, e.target.checked)}
                          },
                        });
                      }}
                    />{" "}
                    {site.name}
                </label>
              </div>
            );
          })} */}
          <div className="headline">USERS</div>
          <div>
            <label className="user-wrap">
              <VfiCheckbox
                checked={
                  data.uploader && data.uploader.users
                    ? data.uploader.users.all
                    : false
                }
                onChange={(e) => {
                  setData({
                    ...data,
                    uploader: {
                      ...data.uploader,
                      users: {
                        ...handleAll(
                          data.uploader.users,
                          "all",
                          e.target.checked
                        ),
                      },
                    },
                  });
                }}
              />
              <div className="user-info">All</div>
            </label>
          </div>
          {users.map((user) => {
            return (
              <div>
                <label className="user-wrap">
                  <VfiCheckbox
                    checked={
                      data.uploader && data.uploader.users
                        ? data.uploader.users[user.id]
                        : false
                    }
                    onChange={(e) => {
                      setData({
                        ...data,
                        uploader: {
                          ...data.uploader,
                          users: {
                            ...handleAll(
                              data.uploader.users,
                              user.id,
                              e.target.checked
                            ),
                          },
                        },
                      });
                    }}
                  />
                  <img
                    className="user-image"
                    src={userImage(
                      user.id,
                      user.localImg_file_name,
                      user.localImg_extension
                    )}
                    alt="the user"
                  />
                  <div className="user-info">
                    {user.firstname} {user.lastname}{" "}
                    <span className="darker">
                      ({media.filter((m) => m.author.userId === user.id).length}
                      )
                    </span>
                  </div>
                </label>
              </div>
            );
          })}
        </div>
      )}
      <div
        className="top-dropdown"
        onClick={() => {
          setDateOpened(!dateOpened);
        }}
      >
        <div className="text">Date</div>
        <FontAwesomeIcon icon={faChevronDown} rotation={dateOpened ? 180 : 0} />
      </div>
      {dateOpened && (
        <div className="filter-content">
          <div className="dates-wrap">
            <div className="date">
              <div className="headline">FROM</div>
              <ReactDatePicker
                maxDate={data.date.to}
                dateFormat="dd MMMM yyyy"
                selected={data.date.from}
                onChange={(date) => {
                  setData({ ...data, date: { ...data.date, from: date } });
                }}
              />
              {data.date.from !== undefined && (
                <div
                  className="clear-date"
                  onClick={() => {
                    setData({
                      ...data,
                      date: { ...data.date, from: undefined },
                    });
                  }}
                >
                  <FontAwesomeIcon icon={faTimes} />
                </div>
              )}
            </div>
            <div className="date">
              <div className="headline">TO</div>
              <ReactDatePicker
                minDate={data.date.from}
                dateFormat="dd MMMM yyyy"
                selected={data.date.to}
                onChange={(date) => {
                  setData({ ...data, date: { ...data.date, to: date } });
                }}
              />
              {data.date.to !== undefined && (
                <div
                  className="clear-date"
                  onClick={() => {
                    setData({ ...data, date: { ...data.date, to: undefined } });
                  }}
                >
                  <FontAwesomeIcon icon={faTimes} />
                </div>
              )}
            </div>
          </div>
        </div>
      )}
      <div
        className="top-dropdown"
        onClick={() => {
          setDisplayOpened(!displayOpened);
        }}
      >
        <div className="text">Display</div>
        <FontAwesomeIcon
          icon={faChevronDown}
          rotation={displayOpened ? 180 : 0}
        />
      </div>
      {displayOpened && (
        <div className="filter-content">
          {Object.entries(data.display).map((e) => {
            const [key] = e;
            return (
              <div>
                <label className="user-wrap">
                  <VfiCheckbox
                    checked={data.display[key]}
                    onChange={(e) => {
                      setData({
                        ...data,
                        display: {
                          ...handleAll(data.display, key, e.target.checked),
                        },
                      });
                    }}
                  />
                  <div className="user-info">
                    {capitalize(key.split("_").join(" "))}
                  </div>
                </label>
              </div>
            );
          })}
        </div>
      )}
      <div
        className="top-dropdown"
        onClick={() => {
          setSortByOpened(!sortByOpened);
        }}
      >
        <div className="text">Sort by</div>
        <FontAwesomeIcon
          icon={faChevronDown}
          rotation={sortByOpened ? 180 : 0}
        />
      </div>
      {sortByOpened && (
        <div className="filter-content">
          <div className="headline">Sort by</div>
          {data.sortBy.options.map((e) => {
            return (
              <label className="user-wrap sort-by" key={e.value}>
                <VfiCheckbox2
                  className={"radio-check"}
                  checked={data.sortBy.value === e.value}
                  onChange={() => {
                    setData({
                      ...data,
                      sortBy: {
                        ...data.sortBy,
                        value: e.value,
                      },
                    });
                  }}
                  isRadio={true}
                />
                <div>{e.label}</div>
              </label>
            );
          })}
          <div className="headline">Rank by</div>
          <label className="user-wrap sort-by">
            <VfiCheckbox2
              className={"radio-check"}
              checked={data.sortBy.reverse === false}
              onChange={() => {
                setData({
                  ...data,
                  sortBy: {
                    ...data.sortBy,
                    reverse: false,
                  },
                });
              }}
              isRadio={true}
            />
            <div>A-Z / High-Low</div>
          </label>
          <label className="user-wrap sort-by">
            <VfiCheckbox2
              className={"radio-check"}
              checked={data.sortBy.reverse === true}
              onChange={() => {
                setData({
                  ...data,
                  sortBy: {
                    ...data.sortBy,
                    reverse: true,
                  },
                });
              }}
              isRadio={true}
            />
            <div>Z-A / Low-High</div>
          </label>
        </div>
      )}
    </div>
  );
}
