import React, { useEffect, useState } from "react";
import "./ReadAlso.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/pro-light-svg-icons";
import Axios from "axios";
import env from "../../../environment.json";
import languages from "../language.json";

export function moduleOptions(index, contentData, setContentData) {
  return [
    // {
    //   is: "choice",
    //   icon: faFrown,
    //   text: "None implemented yet...",
    //   onClick: () => {
    //     let newContentData = Object.assign([], contentData);
    //     // newContentData.splice(i, 1);
    //     // newContentData[index].type = "first_letter_big";
    //     setContentData(newContentData);
    //   },
    //   style: { color: "red" },
    // },
  ];
}

export default function ReadAlso(props) {
  const { inEdit } = props;
  const language = localStorage.getItem("language")
    ? localStorage.getItem("language")
    : "english";
  const lang = languages[language].read_also;
  const [pageTitle, setPageTitle] = useState("No page implemented");

  useEffect(() => {
    console.log(props);
    if (props.data.pageId)
      Axios.get(
        env.protocol +
          env.env +
          "/api/public/pages/GetPage.php?id=" +
          props.data.pageId +
          "&shared=" +
          (props.data.shared ? "1" : "0")
      )
        .then((response) => {
          console.log(response);
          if (response.data.languages[props.selectedLanguage])
            setPageTitle(response.data.languages[props.selectedLanguage].title);
        })
        .catch((error) => console.error(error));
  }, [props.data.pageId, props.selectedLanguage]);

  return (
    <div
      style={props.style}
      className={"read-also " + (props.className ? props.className : "")}
    >
      <div className="read-also-wrap" onClick={props.onClick}>
        <FontAwesomeIcon icon={faArrowRight} />{" "}
        <span className="read-also-sign">{lang.read_also}:</span>{" "}
        <span className="text">{pageTitle}</span>
      </div>
    </div>
  );
}
