import React, { useEffect, useState } from "react";
import axios from "axios";
import env from "../../../environment.json";
import "./Shop.scss";
import DoubleTree from "../../Sidebar/doubleTree/doubleTree";
import Products from "./Products";
import Payments from "./Payments";
import Product from "./Product";
import Units from "./units.json";
import Configuration from "./Configuration";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/pro-solid-svg-icons";
import { isArray } from "lodash";
import { faSpinner } from "@fortawesome/pro-light-svg-icons";

export default function Shop(props) {
  const [sites, setSites] = useState([]);
  const [currentLocation, setCurrentLocation] = useState("main");
  const [selectedProduct, setSelectedProduct] = useState(false);
  const [product, setProduct] = useState([]);
  const [loading, setLoading] = useState(false);
  const [siteSelected, setSiteSelected] = useState({});
  const [info, setInfo] = useState(false);

  const [allProducts, setAllProducts] = useState([]);
  const [allPayments, setAllPayments] = useState([]);
  const [filterOptions, setFilterOptions] = useState([]);
  const [disabledFilters, setDisabledFilters] = useState([]);
  const [updateConf, setUpdateConf] = useState({
    brands: [],
    products: [],
    varients: [],
    properties: [],
    prices: [],
    productTypes: [],
  });
  const [configuration, setConfiguration] = useState({
    productTypes: [],
    physical: [],
  });
  const availability = [
    { name: "Published", available: true },
    { name: "Draft", available: false },
  ];
  const warehouse = [
    { name: "In stock", amount: 0, available: true },
    { name: "Remote storage", amount: 0, available: false },
  ];
  const mypages = [
    { show: "Products", label: "Products" },
    { show: "Payments", label: "Payments" },
    { show: "Configuration", label: "Configuration", setting: "true" },
  ];

  useEffect(() => {
    if (currentLocation === "Configuration") {
      if (!configuration.fetchedAll) {
        setLoading(true);
        const async = async () => {
          getShopConfig(siteSelected.siteId).then((data) => {
            data[0].fetchedAll = true;
            setConfiguration(data[0]);
            setFilterOptions(data[1].filterOptions);
            setDisabledFilters(data[1].disabledFilters);

            setLoading(false);
          });
        };
        async();
      }
    }
    if (["Products", "Payments"].includes(currentLocation)) {
      if (allProducts.length === 0) {
        getShopInfo(siteSelected?.siteId);
      }
    }
  }, [currentLocation]);

  useEffect(() => {
    axios
      .post(env.protocol + env.env + "/api/public/GetSites.php", {
        userId: sessionStorage.getItem("vfiUser"),
      })
      .then((response) => {
        setSites(response.data.filter((e) => e.type_of === "site"));
      })
      .catch((error) => console.error(error));
  }, []);

  useEffect(() => {
    if (selectedProduct) {
      setLoading(true);
      const async = async () => {
        let getConfig = true;
        const findProductType = configuration?.physical?.find(
          (x) => x.productTypeuuid === product.productTypeId
        );
        if (findProductType !== undefined) {
          const brandId = findProductType?.brands?.find(
            (x) => x.id === JSON.parse(product.brand.id)
          );
          if (brandId !== undefined) {
            getConfig = false;
          }
        }
        if (getConfig) {
          const promise1 = axios.post(
            env.protocol + env.env + "/api/shop/getConfig",
            {
              siteId: siteSelected.siteId,
            }
          );

          Promise.all([promise1]).then(function (values) {
            values[0].data.fetchedAll = false;
            setConfiguration(values[0].data);
            setProduct(selectedProduct);
            setCurrentLocation("Product");
            setLoading(false);
          });
        } else {
          setProduct(selectedProduct);
          setCurrentLocation("Product");
          setLoading(false);
        }
      };
      async();
    }
  }, [selectedProduct]);

  const saveConfiguration = (
    siteId = siteSelected,
    configData = false,
    vendor = false,
    SearchProducts = [],
    products = []
  ) => {
    setLoading(true);
    axios
      .post(env.protocol + env.env + "/api/shop/saveConfig", {
        config: configData ? configData : updateConf,
        siteId,
        vendor,
        disabledFilters,
      })
      .then((res) => {
        getShopConfig(siteId).then((data) => {
          setUpdateConf({
            brands: [],
            products: [],
            varients: [],
            properties: [],
            prices: [],
            productTypes: [],
          });

          if (data.length > 0) {
            setConfiguration(data[0]);
          }
          setCurrentLocation("Products");
          setLoading(false);
        });
      })
      .catch(function (err) {
        console.error(err);
        setLoading(false);
      });
  };

  const getShopConfig = async (siteId) => {
    const config = await axios
      .post(env.protocol + env.env + "/api/shop/getConfig", {
        siteId,
      })
      .then(async (response) => {
        return response.data ? response.data : {};
      })
      .catch((error) => console.error(error));
    const filterOptions = await axios
      .post(env.protocol + env.env + "/api/public/shop/getFilterOptions.php", {
        siteId,
      })
      .then(async (response) => {
        return response.data ? response.data : {};
      })
      .catch((error) => console.error(error));

    return [config, filterOptions];
  };

  const getShopInfo = (siteId = siteSelected) => {
    setLoading(true);
    const promise1 = axios.get(
      env.protocol + env.env + "/api/public/shop/getProducts.php?available=all"
    );
    const promise2 = axios.get(
      env.protocol + env.env + "/api/shop/getShopOrders"
    );
    Promise.all([promise1, promise2]).then(function (values) {
      setAllProducts(values[0].data);

      // If we have any shop orders...
      if (values[1].data) {
        values[1].data = values[1].data.map((element) => {
          const finalCost = element.binds
            .reduce((acc, cur) => acc + parseInt(cur.price_pr) * cur.amount, 0)
            .toString();
          return { ...element, finalCost };
        });
        setAllPayments(values[1].data);
      }
      setLoading(false);
    });
  };

  const setEditPage = (element) => {
    setCurrentLocation(element.show);
  };

  const saveProduct = (product) => {
    axios
      .post(
        env.protocol + env.env + "/api/shop/saveProduct",
        isArray(product) ? product : [product]
      )
      .then((res) => {
        if (res.data.id) {
          product.id = res.data.id;
        }
        if (res.data === 1) {
          // setCurrentLocation("Products");
        }
        setSelectedProduct(false);
        getShopInfo();
      })
      .catch(function (err) {
        console.error(err);
      });
  };
  const deleteProduct = (product) => {
    axios
      .post(env.protocol + env.env + "/api/shop/deleteProduct", product)
      .then((res) => {
        if (res.data === 1) {
          let findex = allProducts.findIndex((e) => e.id === product.id);
          allProducts.splice(findex, 1);
          setSelectedProduct(false);
          setCurrentLocation("Products");
          // setSaved((f) => !f);
        }
      })
      .catch(function (err) {
        console.error(err);
      });
  };

  const compare = (a, b) => {
    if (a.order_value < b.order_value) {
      return -1;
    }
    if (a.order_value > b.order_value) {
      return 1;
    }
    return 0;
  };

  let content = "";
  let infoText = "";

  switch (info) {
    case "priceCalc":
      infoText = (
        <div className="wrap-info">
          <h1>Selling price</h1>
          <div className="description">
            <div className="excerpt">
              <p>
                <strong>Selling price</strong> is calculated with{" "}
                <strong>Add Price</strong> and <strong>Global Rate</strong>. The
                calculation order is based <strong>Global Rate Order</strong>
              </p>
              <p>
                Global rates and order can be found and managed under{" "}
                <span
                  className="clickable"
                  onClick={() => {
                    setCurrentLocation("Configuration");
                  }}
                >
                  configurations
                </span>
                .
              </p>
            </div>
            <div className="howto">
              <strong>Fixed Price</strong>
              <p>Global Fixed Rate + Cost Price + Add Price</p>
              <strong>Percentage</strong>
              <p>
                (Global Rate + Cost Price + Add Price) * Global Percentage Rate
                / 100{" "}
              </p>
            </div>
          </div>
        </div>
      );
      break;
    default:
      break;
  }
  switch (currentLocation) {
    case "Products":
      content = (
        <Products
          compare={compare}
          configuration={configuration}
          close={() => {
            setCurrentLocation("main");
          }}
          setSelectedProduct={setSelectedProduct}
          allProducts={allProducts}
          setConfirmBeforeNavigate={props.setConfirmBeforeNavigate} // Not used
          siteSelected={siteSelected}
        />
      );
      break;
    case "Configuration":
      content = (
        <Configuration
          units={Units}
          saveConfiguration={saveConfiguration}
          close={() => {
            setCurrentLocation("main");
          }}
          filterOptions={filterOptions}
          compare={compare}
          updateConf={updateConf}
          configuration={configuration}
          newData={props.newData}
          product={selectedProduct}
          openOverlay={props.openOverlay}
          siteId={siteSelected.siteId}
          disabledFilters={disabledFilters}
        />
      );
      break;
    case "Product":
      content = (
        <Product
          units={Units}
          configuration={configuration}
          close={() => {
            setCurrentLocation("Products");
            setSelectedProduct(false);
          }}
          setInfo={setInfo}
          saveProduct={saveProduct}
          deleteProduct={deleteProduct}
          warehouse={warehouse}
          availability={availability}
          compare={compare}
          newData={props.newData}
          product={product}
          openOverlay={props.openOverlay}
          siteId={siteSelected.siteId}
        />
      );
      break;
    case "Payments":
      content = (
        <Payments
          close={() => {
            setCurrentLocation("main");
          }}
          data={allPayments}
          setData={setAllPayments}
          // mocDataEachOrderProducts={mocDataEachOrderProducts}
          setConfirmBeforeNavigate={props.setConfirmBeforeNavigate} // Not used
          siteSelected={siteSelected}
          allProducts={allProducts}
          setLoading={setLoading}
          refresh={getShopInfo}
        />
      );
      break;
    default:
      break;
  }
  const main = (
    <div className={"main-pages"}>
      <div className={"header absolute row"}>
        <div className="left col-lg-10">
          <span className="title">{"Shop"}</span>
        </div>
      </div>
      <div className="wrap-tree-edit">
        <DoubleTree
          mypages={mypages}
          setSiteSelected={(data) => {
            setSiteSelected(data);

            props.setSiteRef(data.siteId);
          }}
          otherSites={[]}
          setEditPage={setEditPage}
          listOptions={sites}
        />
        {content}
      </div>
    </div>
  );
  return (
    <div className="shop">
      {loading && (
        <div className="loading">
          <FontAwesomeIcon spin={true} icon={faSpinner} />
        </div>
      )}
      {main}
      {info && (
        <div className="info">
          <div
            onClick={() => {
              setInfo(false);
            }}
            className="close"
          >
            <FontAwesomeIcon icon={faTimes} />
          </div>
          {infoText}
        </div>
      )}
    </div>
  );
}
