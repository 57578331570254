import WhatsonNavigation from "../WhatsonNavigation";
import { VfiCheckbox2 as VfiCheckbox } from "../../../../assets/VfiCheckbox";
import style from "./Amenities.module.scss";
import { GlobalToolTipController } from "../../../ToolTips/GlobalToolTip";
import { faCircleQuestion } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

function Amenities({ parent }) {
  /**
   * Render the "enable editing checkbox"
   *
   * @returns   jsx
   *
   * @author          Pætur Mortensen
   */
  function render_enable_editing_checkbox() {
    return (
      <div
        className={
          "enable_editing_checkbox " +
          (parent.submit.includes("amenities") && "enabled")
        }
      >
        <VfiCheckbox
          className="text-include"
          checked={parent.submit.includes("amenities")}
          onChange={(e) => {
            if (!parent.submit.includes("amenities")) {
              parent.submit.push("amenities");
            }
            parent.setState({});
          }}
        />
        <p>{parent.language.amenities.enableEditing}</p>
      </div>
    );
  }

  /**
   * Render the "suggested changes" buttons
   *
   * @returns   jsx
   *
   * @author          Pætur Mortensen
   */
  function render_suggested_changes() {
    return (
      <div className="btn_current_submitted">
        <span
          className={
            parent.changes.currentOrSuggested[
              parent.changes.title.indexOf("amenities")
            ] === "current"
              ? "choosen btn"
              : "btn"
          }
          onClick={() => {
            parent.changes.currentOrSuggested[
              parent.changes.title.indexOf("amenities")
            ] = "current";
            parent.updateEditedFields("amenities");
            parent.setState({});
          }}
        >
          {parent.language.amenities.current}
        </span>
        <span
          className={
            parent.changes.currentOrSuggested[
              parent.changes.title.indexOf("amenities")
            ] === "suggested"
              ? "choosen btn"
              : "btn"
          }
          onClick={() => {
            parent.changes.currentOrSuggested[
              parent.changes.title.indexOf("amenities")
            ] = "suggested";
            parent.updateEditedFields("amenities");
            parent.setState({});
          }}
        >
          {parent.language.amenities.suggestedChanges}
        </span>
      </div>
    );
  }

  /**
   * Handle change of single amenities checkbox
   *
   * @param   {event}   e           Checkbox change event
   * @param   {object}  option      Amenity option (data on amenity)
   *
   * @author          Pætur Mortensen
   */
  function handle_single_check_change(e, option) {
    // If checkbox is checked...
    if (e.target.checked) {
      // Add the checked amenity ID to newPlace
      parent.newPlace.amenities.push({
        id: option.amenityID,
      });
    } else {
      // Checkbox is not checked, filter this amenityID out of selected amenities
      parent.newPlace.amenities = parent.newPlace.amenities.filter(
        (el) => el.id !== option.amenityID
      );
    }

    // Change state to show that amenities have been edited
    parent.setState({
      beenEdit: { ...parent.state.beenEdit, amenities: true },
    });
  }

  /**
   * Render the amenities checkboxes
   *
   * @returns   jsx
   *
   * @author          Pætur Mortensen
   */
  function render_checkboxes() {
    /**
     * Render a single amenities checkbox
     *
     * @param 		{object} 	amenity 						Amenity information
     *
     * @returns 	jsx 													List item with checkbox
     *
     * @author 					Pætur Mortensen
     */
    function render_single_checkbox(amenity) {
      /**
       * Render tooltip contents for a tooltip
       *
       * @param 		{string} 	content 						Content to place inside tooltip
       *
       * @returns 	jsx 													Tooltip content element
       *
       * @author 					Pætur Mortensen
       */
      function render_tooltip_element(content) {
        return (
          <div className={style.tooltip}>
            <h3>{parent.language.amenities.info}</h3>
            <p>{content}</p>
          </div>
        );
      }
      return (
        <li className={style.option} key={amenity.amenityID}>
          <label>
            <VfiCheckbox
              checked={
                parent.changes.currentOrSuggested[
                  parent.changes.title.indexOf("amenities")
                ] === "suggested"
                  ? //Suggested change
                    parent.changes.newValues[
                      parent.changes.title.indexOf("amenities")
                    ].find((el) => el.id === amenity.amenityID)
                    ? true
                    : false
                  : //Current change
                  parent.newPlace.amenities.find(
                      (el) => el.id === amenity.amenityID
                    )
                  ? true
                  : false
              }
              disabled={
                parent.props.submitChange
                  ? !parent.submit.includes("amenities")
                  : false
              }
              onChange={(e) => {
                handle_single_check_change(e, amenity);
              }}
            />
            <span className={style.text}>{amenity.amNameEn}</span>

            <span className={style.smallText}>{amenity.amNameFo}</span>
            {amenity.tooltip && (
              <GlobalToolTipController
                toolTipElements={render_tooltip_element(amenity.tooltip)}
                solid
                title=""
                priority="top"
              >
                <FontAwesomeIcon
                  icon={faCircleQuestion}
                  className={style.tooltipIcon}
                />
              </GlobalToolTipController>
            )}
          </label>
        </li>
      );
    }

    // Return the jsx
    return (
      <ul className={style.placeAmenities}>
        {parent.amenities
          .sort((a, b) => {
            if (a.amNameEn.toLowerCase() > b.amNameEn.toLowerCase()) return 1;
            if (a.amNameEn.toLowerCase() < b.amNameEn.toLowerCase()) return -1;
            return 0;
          })
          .map((amenity) => {
            return render_single_checkbox(amenity);
          })}
      </ul>
    );
  }

  return (
    <div className={style.amenities + " new-content"}>
      {/* Control panel/places navigation */}
      <WhatsonNavigation
        indexChange={parent.props.indexChange}
        undoAndRedoDisabled={true}
        isFront={parent.props.isFront}
        forwardDisabled={false}
        backwardDisabled={false}
        saveDraft={parent.addOrUpdatePlace}
        language={parent.props.language}
      />

      {/* Headers */}
      <h1>{parent.language.amenities.header}</h1>
      <p>{parent.language.amenities.sub_text}</p>

      {/* If submit change is true, render the "enable editing" checkbox */}
      {parent.props.submitChange && render_enable_editing_checkbox()}

      {/* If there have been changes, show current/suggested changes */}
      {parent.changes.title.includes("amenities") && render_suggested_changes()}

      {/* Amenities checkboxes */}
      {render_checkboxes()}
    </div>
  );
}

export default Amenities;
