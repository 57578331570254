import { useEffect, useState } from "react";
import styles from "./Leaflet.module.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/pro-light-svg-icons";
import { IconProp } from "@fortawesome/fontawesome-svg-core";

interface Props {
  title: string;
  sections: any[];
  selectedView: string;
  setSelectedView: any;
  add?: {
    call: () => any,
    active: boolean,
  };
  customCall?: (view: any) => void,
}

export default function Leaflet({
  title,
  sections,
  selectedView,
  setSelectedView,
  add = {
    call: () => {},
    active: false,
  },
  customCall = () => {},
}: Props) {

  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    
    // For initializing our laod animation
    setLoading(false);

  }, []);

  return (
    <div className={`${styles.navigation} ${loading ? "" : styles.loaded}`}>

      {/* Leaflet title */}

      <div className={styles.nnw}>
        <h1>{title}</h1>
      </div>

      {/* Map our sections */}

      {sections.map((section: any, index: number) => {
        return (
          <>
            <div className={styles.nnw} key={index}>
              <p>{section.title}</p>
            </div>

            {/* For each section map its views */}

            {section.views.map((view: string, index: number) => {
              return (
                  <button
                    className={`${styles.view} ${
                      selectedView === view ? styles.selected : ""
                    }`}
                    onClick={() => {
                      setSelectedView(view);
                      customCall(view);
                    }}
                  >
                    {view}
                  </button>
              );
            })}

            <br />
          </>
        );
      })}

      {/* Add button that takes a custom add function */}

      {add.active && (
        <div className={styles.add}>
          <FontAwesomeIcon icon={faPlus as IconProp} />
        </div>
      )}
    </div>
  );
}
