import { faSearch, faTimes } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import "./SvgChoose.scss";

import axios from "axios";

import env from "../../../../environment.json";

export function SvgIcons() {
  return new Promise((resolve, reject) => {
    axios
      .get(env.protocol + env.env + "/api/public/svg_icons/GetSvgIconList.php")
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => reject(error));
  });
}

export default function SvgChoose(props) {
  const [iconSelected, setIconSelected] = useState(props.selected);
  const [allIcons, setAllIcons] = useState([]);

  useEffect(() => {
    (async () => {
      setAllIcons(await SvgIcons());
    })();
  }, []);

  useEffect(() => {
    setIconSelected(props.selected);
  }, [props.selected]);

  const iconsDisplay = (
    <div className="icons">
      {allIcons.map((element, i) => {
        return (
          <div
            key={element.id}
            className={
              "icon " + (iconSelected === element.id ? "selected " : "")
            }
            onClick={() => {
              setIconSelected(element.id);
              if (props.onChange) props.onChange(element.id);
            }}
          >
            <img
              src={env.protocol + env.env + "/svg_icons/" + element.id + ".svg"}
              draggable={false}
              alt="icon"
            />
            <div className="name">{element.name}</div>
          </div>
        );
      })}
    </div>
  );

  if (props.data.onlyIcons) return iconsDisplay;

  return (
    <div className="svg-choose">
      <div className="close" onClick={() => props.close()}>
        <FontAwesomeIcon icon={faTimes} />
      </div>
      <div className="content">
        <h2>Select the desired icon</h2>
        {iconsDisplay}
        <div className="final-options">
          <div
            className="option select"
            onClick={() => {
              props.returnValue(iconSelected);
              props.close();
            }}
          >
            Select
          </div>
          <div className="option cancel" onClick={() => props.close()}>
            Cancel
          </div>
        </div>
      </div>
    </div>
  );
}

export function OnlySvgChoose(props) {
  const [iconSelected, setIconSelected] = useState(props.selected);
  const [allIcons, setAllIcons] = useState([]);
  const [search, setSearch] = useState("");

  useEffect(() => {
    (async () => {
      setAllIcons(await SvgIcons());
    })();
  }, []);

  useEffect(() => {
    setIconSelected(props.selected);
  }, [props.selected]);

  return (
    <div className="edit-module only-svg-choose icons">
      <div className="page-modules-list search">
        <FontAwesomeIcon icon={faSearch} />
        <input
          placeholder={"Search icons"}
          onChange={(e) => setSearch(e.target.value)}
        />
      </div>
      <div className="top">
        <div className="top-choice active">ICONS</div>
      </div>
      <div className="content">
        {allIcons.map((element, i) => {
          if (
            search === "" ||
            element.name.toLowerCase().search(search.toLowerCase()) !== -1
          )
            return (
              <div
                key={element.id}
                className={
                  "icon " + (iconSelected === element.id ? "selected " : "")
                }
                onClick={() => {
                  setIconSelected(element.id);
                  if (props.onChange) props.onChange(element.id);
                }}
              >
                <img
                  src={
                    env.protocol + env.env + "/svg_icons/" + element.id + ".svg"
                  }
                  draggable={false}
                  alt="icon"
                />
                <div className="name">{element.name}</div>
              </div>
            );
          else return "";
        })}
      </div>
    </div>
  );
}
