import { faAngleDown, faTimes } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { cloneDeep } from "lodash";
import React, { useEffect, useState } from "react";
import "./RightSideInfo.scss";

export default function RightSideInfo({
  info,
  isShowing,
  close = () => void 0,
  absolute = false,
  type = "info",
  title = "",
  // defaultAllCollapsed = false,
}) {
  const [collapsedDropdowns, setCollapsedDropdowns] = useState([]);

  useEffect(() => {
    if (info !== undefined && Array.isArray(info)) {
      setCollapsedDropdowns(
        info
          .map((m) => (m.defaultCollapsed ? m.title : undefined))
          .filter((f) => f !== undefined)
      );
    }
  }, []);

  const handleInfo = (info) => {
    if (React.isValidElement(info)) {
      return info;
    }
    if (Array.isArray(info)) {
      return info.map((e) => {
        if (React.isValidElement(e)) {
          return e;
        }

        if (e.type === "dropdown") {
          return (
            <div
              key={e.title}
              className={`dropdown-option ${
                collapsedDropdowns.find((f) => f === e.title) !== undefined
                  ? "collapsed"
                  : ""
              }`}
            >
              <div
                className="drop-title"
                onClick={() => {
                  let copyCollapsed = cloneDeep(collapsedDropdowns);
                  if (copyCollapsed.find((f) => f === e.title) === undefined) {
                    copyCollapsed.push(e.title);
                  } else {
                    copyCollapsed = copyCollapsed.filter((f) => f !== e.title);
                  }
                  setCollapsedDropdowns(copyCollapsed);
                }}
              >
                {e.title}
                <FontAwesomeIcon icon={faAngleDown} />
              </div>
              <div className={`filter-option-content`}>{e.content}</div>
            </div>
          );
        }

        return null;
      });
    }
  };

  return (
    <div
      className={
        "right-side-info" +
        (absolute ? " absolute" : "") +
        (isShowing ? " shown" : "") +
        (type === "info" || type === "filter" ? ` ${type}` : "")
      }
    >
      {isShowing && (
        <div className="close" onClick={() => close()}>
          <FontAwesomeIcon icon={faTimes} />
        </div>
      )}
      {title !== "" && <div className="title">{title}</div>}
      <div className="rsi-content">{handleInfo(info)}</div>
    </div>
  );
}
