import "./StaticPalette.scss";
import noImage from "../../../../assets/images/empty.jpg";
import VfiInputText from "../../../../assets/VfiInputText/VfiInputText";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEllipsisV, faLink, faTimes } from "@fortawesome/pro-light-svg-icons";

export const staticPaletteObj = {
  image: "",
  title: "",
  href: "",
  type: "static_palette",
};

export default function StaticPalette({
  object = staticPaletteObj,
  change = () => void 0,
  openOverlay = () => void 0,
  setOptionsList = () => void 0,
  close = () => void 0,
}) {
  const optionsMenu = (
    <div key={object.href} className="edit-module static-palette-options">
      <div>
        <div id="handle">
          <FontAwesomeIcon icon={faEllipsisV} />
          <FontAwesomeIcon icon={faEllipsisV} />
        </div>
        <div
          onClick={() => {
            close();
          }}
          className="close"
        >
          <FontAwesomeIcon icon={faTimes} />
        </div>
      </div>
      <div className="top">
        <div className="top-choice active">Options</div>
      </div>
      <div className="content">
        <div className="option">
          <div className="label">Link</div>
          <div className="value">
            <VfiInputText
              defaultValue={object.href}
              onChange={(e) => {
                change("href", e.target.value);
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );

  return (
    <div className="static-palette image-palette palette">
      <div
        className="image"
        onClick={() => {
          openOverlay("mediachoose", {
            filter: { mediaType: ["images", "videos"] },
            singleMedia: true,
            afterFunc: (e) => {
              if (e) {
                change("image", e.medium.large);
              }
              // object.image = e.medium.large;
            },
          });
        }}
      >
        <img src={object.image ? object.image : noImage} alt="palette" />
      </div>
      <VfiInputText
        className="title"
        defaultValue={object.title}
        onChange={(e) => {
          change("title", e.target.value);
        }}
      />
      <div
        className="edit-module"
        onClick={(e) => {
          setOptionsList(optionsMenu, { x: e.pageX, y: e.pageY }, true);
        }}
      >
        <FontAwesomeIcon icon={faLink} />
      </div>
    </div>
  );
}
