import {
  faArrowLeftLong,
  faBagShopping,
  faFile,
  faList,
  faMagnifyingGlass,
  faReceipt,
  faTimes,
} from "@fortawesome/pro-light-svg-icons";
import { faReceipt as receipt } from "@fortawesome/pro-thin-svg-icons";
import { faSliders } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import env from "../../../environment.json";
import "./Shop.scss";
import {
  capitalize,
  showDate,
  twoDigits,
} from "../../../assets/helperFunctions";
import styles from "./Payment.module.scss";
import VfiInputText from "../../../assets/VfiInputText/VfiInputText";
import { cloneDeep } from "lodash";

export default function Payment({
  close,
  data: d,
  editable,
  giveChangedData = () => void 0,
}) {
  const [filterState, setFilterState] = useState(false);
  // const [editable, setEditable] = useState(false);
  const [data, setData] = useState(cloneDeep(d));

  useEffect(() => {
    console.log("DATA:", d);
  }, []);

  const filter = () => {
    setFilterState(filterState ? false : true);
  };

  if (!data) {
    return "";
  }

  return (
    <div className={`payment ${styles.payment}`}>
      <div className={styles["payment-wrap"]}>
        <div className={styles.content}>
          <div className={`${styles["user-info-row"]}`}>
            <div className={`${styles["user-info-wrap"]} ${styles.box}`}>
              <div className={`${styles["user-info"]}`}>
                <div className={styles["split-evenly"]}>
                  <div className={`${styles["text-input"]}`}>
                    <label>First name</label>
                    <input
                      defaultValue={data.firstName}
                      disabled={!editable}
                      onChange={(e) =>
                        giveChangedData("firstName", e.target.value)
                      }
                    />
                  </div>
                  <div className={`${styles["text-input"]}`}>
                    <label>Last name</label>
                    <input
                      defaultValue={data.lastName}
                      disabled={!editable}
                      onChange={(e) =>
                        giveChangedData("lastName", e.target.value)
                      }
                    />
                  </div>
                </div>
                <div
                  className={`${styles["text-input"]} ${styles["margin-top"]}`}
                >
                  <label>Email</label>
                  <input
                    defaultValue={data.email}
                    disabled={!editable}
                    onChange={(e) => giveChangedData("email", e.target.value)}
                  />
                </div>
                <div
                  className={`${styles["text-input"]} ${styles["margin-top"]}`}
                >
                  <label>Phone</label>
                  <input
                    defaultValue={data.phone}
                    disabled={!editable}
                    onChange={(e) => giveChangedData("phone", e.target.value)}
                  />
                </div>
                <div
                  className={`${styles["text-input"]} ${styles["margin-top"]} ${styles["margin-bottom"]}`}
                >
                  <label>License plate</label>
                  <input
                    defaultValue={data.licensplate}
                    disabled={!editable}
                    onChange={(e) =>
                      giveChangedData("licensplate", e.target.value)
                    }
                  />
                </div>
                <span className={styles["small-label"]}>Address info</span>
                <div className={`${styles["text-input"]}`}>
                  <label>Address</label>
                  <input
                    defaultValue={data.address}
                    disabled={!editable}
                    onChange={(e) => giveChangedData("address", e.target.value)}
                  />
                </div>
                <div className={styles["split-evenly"]}>
                  <div
                    className={`${styles["text-input"]} ${styles["margin-top"]}`}
                  >
                    <label>City</label>
                    <input
                      defaultValue={data.city}
                      disabled={!editable}
                      onChange={(e) => giveChangedData("city", e.target.value)}
                    />
                  </div>
                  <div
                    className={`${styles["text-input"]} ${styles["margin-top"]} ${styles["margin-bottom"]}`}
                  >
                    <label>Zip</label>
                    <input
                      defaultValue={data.zip}
                      disabled={!editable}
                      onChange={(e) => giveChangedData("zip", e.target.value)}
                    />
                  </div>
                </div>
                <span className={styles["small-label"]}>
                  Delivery Address info
                </span>
                <div className={`${styles["text-input"]}`}>
                  <label>Address</label>
                  <input
                    defaultValue={data.deliveryAddress}
                    disabled={!editable}
                    onChange={(e) =>
                      giveChangedData("deliveryAddress", e.target.value)
                    }
                  />
                </div>
                <div className={styles["split-evenly"]}>
                  <div
                    className={`${styles["text-input"]} ${styles["margin-top"]}`}
                  >
                    <label>City</label>
                    <input
                      defaultValue={data.deliveryCity}
                      disabled={!editable}
                      onChange={(e) =>
                        giveChangedData("deliveryCity", e.target.value)
                      }
                    />
                  </div>
                  <div
                    className={`${styles["text-input"]} ${styles["margin-top"]} ${styles["margin-bottom"]}`}
                  >
                    <label>Zip</label>
                    <input
                      defaultValue={data.deliveryZip}
                      disabled={!editable}
                      onChange={(e) =>
                        giveChangedData("deliveryZip", e.target.value)
                      }
                    />
                  </div>
                </div>
                <div
                  className={`${styles["text-input"]} ${styles["margin-top"]}`}
                >
                  <label>Comments</label>
                  <textarea
                    defaultValue={data.comments}
                    disabled={!editable}
                    onChange={(e) => {
                      giveChangedData("deliveryAddress", e.target.value);
                    }}
                  />
                </div>
              </div>
            </div>
            <div className={styles["right-side-of-user-info"]}>
              {/* <button className={styles["pdf-button"]}>Open PDF</button> */}
              <div className={`${styles.orders} ${styles.box}`}>
                <div
                  className={`${styles["orders-head"]} ${styles["order-id"]}`}
                >
                  ID
                </div>
                <div
                  className={`${styles["orders-head"]} ${styles["order-amount"]}`}
                >
                  Amount
                </div>
                <div
                  className={`${styles["orders-head"]} ${styles["order-name"]}`}
                >
                  Name
                </div>
                <div
                  className={`${styles["orders-head"]} ${styles["order-price-per"]}`}
                >
                  Price per
                </div>
                <div
                  className={`${styles["orders-head"]} ${styles["order-price"]}`}
                >
                  Price excl MVG
                </div>
                {data.binds.map((orderBind, i) => {
                  const {
                    product_id,
                    service_id,
                    price_pr,
                    amount,
                    product_info,
                    service_info,
                  } = orderBind;

                  const orderType = product_id
                    ? "product"
                    : service_id
                    ? "service"
                    : undefined;

                  const name =
                    orderType === "product"
                      ? `${product_info.spP_label} - ${product_info.spV_label}`
                      : orderType === "service"
                      ? `${service_info.Title} - ${service_info.Description}`
                      : undefined;

                  return [
                    <div
                      key={`${orderBind.id}id`}
                      className={`${styles[orderType]} ${styles["order-id"]}`}
                    >
                      {orderBind.id}
                    </div>,
                    <div
                      key={`${orderBind.id}amount`}
                      className={`${styles[orderType]} ${styles["order-name"]}`}
                    >
                      <div className={styles["text-input"]}>
                        <input
                          className={"no-label"}
                          defaultValue={orderBind.amount}
                          type="number"
                          disabled={!editable}
                          onChange={(e) => {
                            data.binds[i].amount = e.target.value;
                            setData({ ...data });
                            giveChangedData(
                              `binds.${i}.amount`,
                              e.target.value
                            );
                          }}
                        />
                      </div>
                    </div>,
                    <div
                      key={`${orderBind.id}name`}
                      className={`${styles[orderType]} ${styles["order-name"]}`}
                    >
                      {name}
                    </div>,
                    <div
                      key={`${orderBind.id}price-pr`}
                      className={`${styles[orderType]} ${styles["order-price-pr"]}`}
                    >
                      <div
                        className={`${styles["text-input"]} ${styles["margin-right"]}`}
                      >
                        <input
                          className={`no-label`}
                          defaultValue={parseFloat(price_pr)}
                          disabled={!editable}
                          onChange={(e) => {
                            e.target.value = twoDigits(e);
                            data.binds[i].price_pr = e.target.value;
                            setData({ ...data });
                            giveChangedData(
                              `binds.${i}.price_pr`,
                              e.target.value
                            );
                          }}
                        />
                      </div>
                      <div className={styles.valuta}>kr</div>
                    </div>,
                    <div
                      key={`${orderBind.id}price`}
                      className={`${styles[orderType]} ${styles["order-price"]}`}
                    >
                      <div className={styles.amount}>
                        {(parseFloat(price_pr) * amount * 0.8).toFixed(2)}
                      </div>
                      <div className={styles.valuta}>kr</div>
                    </div>,
                  ];
                })}
                <div className={`${styles["orders-foot"]}`} />
                <div className={`${styles["orders-foot"]}`} />
                <div className={`${styles["orders-foot"]}`} />
                <div
                  className={`${styles["orders-foot"]} ${styles["order-total"]}`}
                >
                  Total excl MVG
                </div>
                <div
                  className={`${styles["orders-foot"]} ${styles["order-total"]}`}
                >
                  {data.binds
                    .reduce((acc, cur) => {
                      return acc + cur.amount * parseFloat(cur.price_pr) * 0.8;
                    }, 0)
                    .toFixed(2)}{" "}
                  kr
                </div>
                <div className={`${styles["orders-foot"]}`} />
                <div className={`${styles["orders-foot"]}`} />
                <div className={`${styles["orders-foot"]}`} />
                <div
                  className={`${styles["orders-foot"]} ${styles["order-total"]}`}
                >
                  MVG 20%
                </div>
                <div
                  className={`${styles["orders-foot"]} ${styles["order-total"]}`}
                >
                  {data.binds
                    .reduce((acc, cur) => {
                      return acc + cur.amount * parseFloat(cur.price_pr) * 0.2;
                    }, 0)
                    .toFixed(2)}{" "}
                  kr
                </div>
                <div className={`${styles["orders-foot"]}`} />
                <div className={`${styles["orders-foot"]}`} />
                <div className={`${styles["orders-foot"]}`} />
                <div
                  className={`${styles["orders-foot"]} ${styles["order-total"]}`}
                >
                  Total
                </div>
                <div
                  className={`${styles["orders-foot"]} ${styles["order-total-result"]}`}
                >
                  <div className={styles.amount}>
                    {data.binds
                      .reduce((acc, cur) => {
                        return acc + cur.amount * parseFloat(cur.price_pr);
                      }, 0)
                      .toFixed(2)}
                  </div>
                  <div className={styles.valuta}>kr</div>
                </div>
              </div>
            </div>
          </div>
          <div className={styles["orders-row"]}>
            <div className={`${styles.box} ${styles["status-list-wrap"]}`}>
              <h2>Status List</h2>
              <div className={`${styles["status-list"]}`}>
                <div className={`${styles.status} ${styles["margin-top"]}`}>
                  Order status:{" "}
                  <span
                    className={`status ${d.orderStatus} ${styles["margin-left"]}`}
                  >
                    {capitalize(d.orderStatus)}
                  </span>
                </div>
                <div className={`${styles.status} ${styles["margin-top"]}`}>
                  Payment status:{" "}
                  <span
                    className={`status ${d.paymentStatus} ${styles["margin-left"]}`}
                  >
                    {capitalize(d.paymentStatus)}
                  </span>
                </div>
                <div className={`${styles.status} ${styles["margin-top"]}`}>
                  Shipping status:{" "}
                  <span
                    className={`status ${d.shippingStatus} ${styles["margin-left"]}`}
                  >
                    {capitalize(d.shippingStatus)}
                  </span>
                </div>
              </div>
            </div>
            <div className={`${styles.box} ${styles["order-history-wrap"]}`}>
              <h3>Order history</h3>
              <div className={`${styles["order-history"]}`}>
                {/* <div className={`${styles.header}`}>ID</div> */}
                <div className={`${styles.header}`}>Name</div>
                <div className={`${styles.header}`}>Date</div>
                {d.payments
                  .map((payment) => {
                    const { id, pdf_name, date } = payment;
                    return [
                      // <div key={id + "id"} className={`${styles.info}`}>
                      //   {id}
                      // </div>,
                      <div key={id + "name"} className={`${styles.info}`}>
                        <a
                          href={`${env.protocol}${env.env}/pdf/${pdf_name}`}
                          target="_blank"
                        >
                          <span className={styles.icon}>
                            <FontAwesomeIcon icon={faFile} />
                          </span>
                          {pdf_name}
                        </a>
                      </div>,
                      <div key={id + "date"} className={`${styles.info}`}>
                        {showDate(date)}
                      </div>,
                    ];
                  })
                  .reverse()}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
