import { create } from "zustand";
import Card from "./Elements/Card/Card";
import SidePopout from "./Elements/SidePopout/SidePopout";
import SidePopoutSelfClose from "./Elements/SidePopout/SidePopoutSelfClose";
import { v4 as uuidv4 } from 'uuid';

// Initial states
const initialState: any = {
  overlays: [], // An array of jsx elements representing our overlays
  sidePopouts: [],
  /*
    For future updates: overlays should be split up, and instead we should have an array for each type of overlay
    This way we can set priority, and also custom specifications for each different kind of overlay
  */
};

// overlay type array
export const overlayElements: any[] = [
  "card", 
  "popup", 
  "side-popout",
  "side-popout-self-close",
];

export const overlayStore = create((set) => ({
  // Initialize states
  ...initialState,

  // Set overlay
  // - setOverlay allows us to set states within the overlayStore
  setOverlay: (states: any) => set((state: any) => ({ ...states })),

  // Add overlay
  // - addOverlay allows us to add an overlay
  addOverlay: (type: string, callback: any) => {
    // (type)     - type specifies the overlay type we're using
    // (callback) - callback returns a close function, when calling
    //              callback we want to return the jsx we want in our overlay
    set((state: any) => {
      //let overlay: any = null; // We're initializing overlay here to get its reference

      const id = uuidv4();

      const close: any = () => {

        set((state: any) => {

          const retVal: any = (): any => {
            if (type === 'card') return { overlays: state.overlays.filter((e: any) => e.id !== id) };
            if (type === 'side-popout' || type === 'side-popout-self-close') return { sidePopouts: state.sidePopouts.filter((e: any) => e.id !== id) };
          }

          return {
            ...state,
            //overlays: state.overlays.filter((e: any) => e !== overlay, 1),
            ...retVal(),
          };
        });
      };

      let overlay = {
        id: id,
        type: type,
        overlay: (
          <>
            {type === "card" && (
              <Card close={close}>{callback(close)}</Card>
            )}
            {type === "side-popout" && (
              <SidePopout close={close}>{callback(close)}</SidePopout>
            )}
            {type === "side-popout-self-close" && (
              <SidePopoutSelfClose close={close}>{callback(close)}</SidePopoutSelfClose>
            )}
          </>
        ),
        //        (Implemented :D)!!!
        // UUID - possible implementation fix for proper array manipulation
        //        currently deletes overlay from overlay array by comparing its reference.
        //        Current solution doesn't seem completely safe.
      };

      const retVal: any = (): any => {
        if (type === 'card') return { overlays: [...state.overlays, overlay] };
        if (type === 'side-popout' || type === 'side-popout-self-close') return { sidePopouts: [...state.sidePopouts, overlay] };
      }

      return {
        ...state,
        ...retVal()
      };
    });
  },

  // Reset overlay
  // - resetOverlay resets the store to its initial state
  resetOverlay: () => {
    set((state: any) => ({ ...initialState }));
  },
}));
