class VerifyMethods {
  static notEmpty(e) {
    if (e === "") {
      return "Text is missing.";
    } else {
      return "";
    }
  }

  static validEmail(e) {
    const re =
      /^(([^<>()\]\\.,;:\s@"]+(\.[^<>()\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (re.test(String(e).toLowerCase())) {
      return "";
    } else {
      return "Not valid email.";
    }
  }

  static confirmEmail(email1, email2) {
    if (email1 === email2) {
      return "";
    } else {
      return "Emails are not the same.";
    }
  }

  static validPassword(e) {
    const re = /^(?=.*[A-Z])(?=.*\d)(?=.*[\W_])[A-Za-z\d\W_]{8,}$/;
    if (re.test(e)) {
      return "";
    } else {
      return "Not valid password.";
    }
  }

  static noSpace(e) {
    if (e.search(" ") !== -1) {
      return "No space allowed.";
    } else {
      return "";
    }
  }

  static least3Char(e) {
    if (e.length < 3) {
      return "Enter at least 3 characters.";
    } else {
      return "";
    }
  }

  static least10Char(e) {
    if (e.length < 10) {
      return "Enter at least 10 characters.";
    } else {
      return "";
    }
  }

  static least100Char(e) {
    if (e.length < 100) {
      return "Enter at least 100 characters.";
    } else {
      return "";
    }
  }

  /**
   * Validate minimum characters
   *
   * @param 		{string} 	checkStr 							String to validate
   * @param 		{int} 		min 									Minimum number of characters
   *
   * @returns 	{bool}													Whether validation passed
   *
   * @author 					Pætur Mortensen
   */
  static minChars(checkStr, min) {
    return checkStr.length < min ? "Enter at least " + min + " characters" : "";
  }

  /**
   * Validate maximum number of characters
   *
   * @param 		{string} 	checkStr 								String to check
   * @param 		{int} 		max 										Maximum number of characters
   *
   * @returns 	{bool} 														Whether validation passed
   *
   * @author 					Pætur Mortensen
   */
  static maxChars(checkStr, max) {
    return checkStr.length < max
      ? "Enter no more than " + max + " characters"
      : "";
  }

  static extensionIsVideo(extension) {
    if (!Boolean(extension)) return false;

    const value = extension.toLowerCase();

    if (
      value === "mp4" ||
      value === "avi" ||
      value === "wmv" ||
      value === "webm"
    ) {
      return true;
    } else {
      return false;
    }
  }

  static extensionIsImage(extension) {
    if (!Boolean(extension)) return false;

    const value = extension.toLowerCase();

    if (value === "jpg" || value === "jpeg" || value === "png") {
      return true;
    } else {
      return false;
    }
  }
}
export default VerifyMethods;
