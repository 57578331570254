import { cloneDeep } from "lodash";
import React, { useState } from "react";
import "./doubleTree.scss";
import Pages from "./pages";

export default function DoubleTree(props) {
  // const [sitesChecked, setSitesChecked] = useState(false);
  // useEffect(() => {
  //     // Update the document title using the browser API
  //     // get(nav);

  // }, []);
  const [siteSelected, setSiteSelected] = useState({});
  const [rootPageSelected, setRootPageSelected] = useState(false);
  const [pageSelected, setPageSelected] = useState("");

  const editPage = (element) => {
    // props.setIsLoading(true);
    // axios
    //   .post(env.protocol + env.env + "/api/public/pages/GetPage.php", {
    //     user: sessionStorage.getItem("vfiUser"),
    //     page: id,
    //   })
    //   .then((response) => {
    //     console.log(response);
    //     const data = response.data;

    props.setSiteSelected(siteSelected);
    setPageSelected(element.show);
    props.setEditPage(element);
    //   })
    //   .catch((error) => {
    //     console.error(error);
    //   });
  };
  const isOtherSites = () => {
    if (props.otherSites.length > 0) {
      return ["VISIT FAROE ISLANDS WEBSITES", "REGIONAL WEBSITES"];
    }
    return [""];
  };

  return (
    <div className="sites-pages-wrap">
      <div className="sites-list loaded">
        <div className="the-list">
          <div className="divide-sites">
            {isOtherSites().map((element, i) => {
              let newTheSites = cloneDeep(props.listOptions);
              if (isOtherSites().length > 1) {
                if (i === 0) {
                  newTheSites = props.listOptions.filter(
                    (x) => x.other_sites === "0" || x.rootPage === 1
                  );
                }
                if (i === 1) {
                  newTheSites = props.listOptions.filter(
                    (x) => x.other_sites === "1"
                  );
                }
              }
              if (newTheSites.length === 0) {
                return null;
              }
              return (
                <div key={"sites" + i} className="wrap-sites">
                  <div className={"sites-headline"}>
                    {!element ? "WEBSITES" : element}
                  </div>
                  {newTheSites.map((element, i) => {
                    // if (element.index !== '0') {

                    return (
                      <div
                        key={element.siteId}
                        className={
                          "site" +
                          (element.siteId === siteSelected.siteId &&
                          Boolean(element.rootPage) === rootPageSelected
                            ? " selected"
                            : "")
                        }
                        onClick={(e) => {
                          console.log(element);
                          setSiteSelected(element);
                          setRootPageSelected(Boolean(element.rootPage));
                          props.setSiteSelected(element);
                          setPageSelected("");
                        }}
                      >
                        {element.name}
                      </div>
                    );
                    // }
                  })}
                </div>
              );
            })}
          </div>
        </div>
      </div>
      {Object.keys(siteSelected).length > 0 ? (
        <Pages
          mypages={props.mypages}
          siteName={siteSelected.name}
          siteId={siteSelected.siteId}
          setPageSelected={setPageSelected}
          pageSelected={pageSelected}
          editPage={editPage}
        />
      ) : (
        ""
      )}
      {/* {thePages.map((element, i) => {
                console.log(element)
                return(
                    <Pages siteName={element.name} siteId={element.siteId} />
                )
            }) } */}
    </div>
  );
}
