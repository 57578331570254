import { faSpinner } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import React, { useEffect, useState } from "react";
import VfiCheckbox from "../../../assets/VfiCheckbox";
import env from "../../../environment.json";
import InfoscreenArticles from "./InfoscreenArticles/InfoscreenArticles";
import InfoscreenPage from "./InfoscreenPage";

import "./infoscreenView.scss";
import ScreenManagement from "./ScreenManagement/ScreenManagement";

export default function InfoscreenView({
  infoscreenMenu,
  openOverlay,
  setConfirmBeforeNavigate,
  setSiteRef,
  newData,
}) {
  const [whatsonList, setWhatsonList] = useState([]);
  const [selectedMenuID, setSelectedMenuID] = useState("0");
  const [siteInfo, setSiteInfo] = useState(undefined);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    axios
      .get(env.protocol + env.env + "/api/public/GetWhatsonSites.php")
      .then((response) => {
        let regions = [{ id: "0", name: "Regions" }];
        setWhatsonList(regions.concat(response.data));
      })
      .catch((error) => console.error(error));

    axios
      .post(env.protocol + env.env + "/api/public/GetSites.php", {
        userId: sessionStorage.getItem("vfiUser"),
      })
      .then((response) => {
        setSiteInfo(response.data.find((e) => e.type_of === "articles"));
      })
      .catch((error) => console.error(error));
  }, []);

  return (
    <div className="infoscreen-view">
      {infoscreenMenu === "articles" && (
        <InfoscreenArticles
          openOverlay={openOverlay}
          setConfirmBeforeNavigate={setConfirmBeforeNavigate}
          setSiteRef={setSiteRef}
          newData={newData}
        />
      )}
      {infoscreenMenu === "screenManagement" && (
        <ScreenManagement
          site={siteInfo}
          openOverlay={openOverlay}
          setIsLoading={setIsLoading}
        />
      )}
      {isLoading ? (
        <div className="blur">
          <FontAwesomeIcon icon={faSpinner} spin />
        </div>
      ) : (
        ""
      )}
    </div>
  );
}
