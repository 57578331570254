import { useEffect } from "react";
import CCategoryFilter from "./C_CategoryFilter";
import CWhatWhatson from "./C_WhatWhatson";
import "./WhatsonLeaflet.scss";
import CWhatsonPages from "./C_WhatsonPages";

export default function WhatsonLeaflet(props) {
  const { data, onChange, allWhatson, whatsonTypes, categories } = props;

  useEffect(() => {
    if (data.whatWhatson !== undefined) {
      const sayWhat = whatsonTypes.find((e) => e.id === data.whatWhatson);
      if (sayWhat !== undefined) {
        if (sayWhat.name === "Events") {
        }
        if (sayWhat.name === "Tours") {
        }
        if (sayWhat.name === "Hiking") {
        }
        if (sayWhat.name === "Places") {
        }
      }
    }
  }, [data.whatWhatson, whatsonTypes]);

  const pageFilter = (pages, mustIncludeCategories) => {
    return pages.filter((p) => {
      let foundAll = true;
      mustIncludeCategories.forEach((category) => {
        if (
          !p.categories.find((c) => c.id_category === category) &&
          category !== 0
        ) {
          foundAll = false;
        }
      });
      return foundAll;
    });
  };

  return (
    <div className="whatson-leaflet">
      <CWhatWhatson
        data={data}
        onChange={onChange}
        types={whatsonTypes}
        loaded={true}
      />
      <CCategoryFilter
        key={data.whatWhatson + " " + 0}
        data={data}
        onChange={onChange}
        whatsonPages={pageFilter(
          /*whatsonFiltered*/ allWhatson,
          data.category_filters.slice(0, 0)
        )}
        categories={categories}
        index={0}
      />
      <CCategoryFilter
        key={data.whatWhatson + " " + 1}
        data={data}
        onChange={onChange}
        whatsonPages={pageFilter(
          /*whatsonFiltered*/ allWhatson,
          data.category_filters.slice(0, 1)
        )}
        categories={categories}
        index={1}
      />
      <CWhatsonPages
        data={data}
        onChange={onChange}
        headline={"Results"}
        pages={pageFilter(
          /*whatsonFiltered*/ allWhatson,
          data.category_filters
        )}
        show={data.category_filters.length !== 0}
      />
    </div>
  );
}
