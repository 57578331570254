import React, { useEffect, useState } from "react";
import languages from "./language.json";
import "./MainPages.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faList,
  /*faPause,*/ faTrashAlt,
} from "@fortawesome/pro-light-svg-icons";
import { faPause } from "@fortawesome/pro-regular-svg-icons";
import EditPage from "./EditPage/EditPage";
import axios from "axios";
import env from "../../../environment.json";
import { faSpinner } from "@fortawesome/pro-solid-svg-icons";
import cloneDeep from "lodash.clonedeep";
import HierarchyView from "./HierarchyView";
import ListView from "./ListView";
import Select from "react-select";
import ToolTip from "../../ToolTips/ToolTip";
export default function MainPages(props) {
  // const [statusFilter, setStatusFilter] = useState("all");
  const [pagesShow, setPagesShow] = useState("leaflet");

  const storedPages = JSON.parse(sessionStorage.getItem("storedPages"));
  const [thePages, setThePages] = useState(storedPages ? storedPages : []);

  const [currentLocation, setCurrentLocation] = useState("main");
  const [editData, setEditData] = useState({});
  const language = languages[props.language];

  const [listIsOpen, setListIsOpen] = useState(false);
  const [optionsListOptions, setOptionsListOptions] = useState([]);
  const [optionsListPos, setOptionsListPos] = useState({ x: 0, y: 0 });
  const [, setOptionsListKey] = useState("");
  const [optionsListPageSelected, setOptionsListPageSelected] =
    useState(undefined);

  const [templateList, setTemplateList] = useState([]);
  const [templateListChange, setTemplateListChange] = useState(
    new Date().getTime()
  );

  const [deletedPage, setDeletedPage] = useState(undefined);
  const [isLoading, setIsLoading] = useState(false);

  const [refreshTime, setRefreshTime] = useState(new Date().getTime());

  const [availableLanguages, setAvailableLanguages] = useState([]);
  const [selectedLanguage, setSelectedLanguage] = useState(undefined);

  const siteSelect = (id, name, pageId) => {
    setThePages([
      { name, siteId: id, pageId, index: 0, time: new Date().getTime() },
    ]);
    if (props.sessionSet)
      sessionStorage.setItem(
        "storedPages",
        JSON.stringify([{ name, siteId: id, pageId, index: 0 }])
      );
  };

  useEffect(() => {
    if (props.showInSidebar && !props.ignoreSidebar) {
      if (
        pagesShow === "list" &&
        currentLocation === "main" &&
        !props.selectPage
      ) {
        props.showInSidebar("list", false);
      } else {
        props.showInSidebar("");
      }
    }
    // eslint-disable-next-line
  }, [pagesShow, currentLocation]);
  useEffect(() => {
    axios
      .get(env.protocol + env.env + "/api/public/pages/GetTemplates.php")
      .then((response) => {
        let result = [
          { is: "title", text: "TEMPLATES", style: { marginLeft: "20px" } },
        ];
        response.data.forEach((e) => {
          result.push({
            is: "choice",
            text: e.template_name,
            rightIcon: faTrashAlt,
            rightIconStyle: { color: "red" },
            id: e.id,
            rightIconClick: (e) => {
              if (
                window.confirm(
                  language.main_pages.template_remove_1 +
                    e.text +
                    language.main_pages.template_remove_2
                )
              )
                axios
                  .post(env.protocol + env.env + "/api/pages/RemoveTemplate", {
                    id: e.id,
                  })
                  .then((response) => {
                    setTemplateListChange(new Date().getTime());
                  })
                  .catch((error) => console.error(error));

              setListIsOpen(false);
              setOptionsListPageSelected(undefined);
            },
            types: e.types,
            extra_data: e.extra_data,
          });
        });
        setTemplateList(result);
      })
      .catch((error) => console.error(error));
    // eslint-disable-next-line
  }, [currentLocation, templateListChange]);

  const pageSelect = (id, name, fromPage, siteId) => {
    let index = -1;
    thePages.forEach((e, i) => {
      if (fromPage === e.pageId) {
        index = i + 1;
      }
    });

    let tempPages = Object.assign([], thePages);
    if (index !== -1) {
      tempPages = tempPages.filter((e, i) => i < index);
    }

    tempPages.push({ name, pageId: id, siteId, time: new Date().getTime() });
    if (props.sessionSet)
      sessionStorage.setItem("storedPages", JSON.stringify(tempPages));

    let selectedPages = JSON.parse(sessionStorage.getItem("selectedPages"));
    if (selectedPages) {
      selectedPages.length = tempPages.length - 2;
    } else {
      selectedPages = [];
    }
    selectedPages.push(id);
    if (props.sessionSet)
      sessionStorage.setItem("selectedPages", JSON.stringify(selectedPages));

    setThePages(tempPages);
  };

  const setOptionsList = (id, options, pos) => {
    setOptionsListPageSelected(id);
    setListIsOpen(true);
    setOptionsListOptions(options);
    setOptionsListPos(pos);
    setOptionsListKey(new Date().getTime());
  };

  const addExtraPage = (toPage, values, afterIndex) => {
    let tempPages = Object.assign([], thePages);

    tempPages.forEach((element, i) => {
      if (parseInt(element.pageId) === toPage) {
        tempPages[i].newValue = values;
        tempPages[i].newValue.languages = values.languages
          ? values.languages
          : {};
      }
    });
    setThePages(tempPages);
  };

  useEffect(() => {
    axios
      .get(
        env.protocol +
          env.env +
          "/api/public/pages/GetAllAvailableLanguages.php"
      )
      .then((response) => {
        setAvailableLanguages(
          [...response.data]
            .sort((a, b) => {
              return a.order_by > b.order_by;
            })
            .map((e) => {
              return { label: e.short.toUpperCase(), value: e.what_language };
            })
        );
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  const editPage = (data, isShared) => {
    // console.log(data);
    setEditData({ ...data, shared: isShared });
    setCurrentLocation("edit");
  };

  const movePage = (data) => {
    // console.log(data);
    axios
      .post(env.protocol + env.env + "/api/pages/MovePage", {
        id: data.id,
        toSite: data.toSite,
        toPage: data.toPage,
        shared: data.shared,
        user: sessionStorage.getItem("vfiUser"),
      })
      .then((response) => {
        let copyPages = cloneDeep(thePages);
        copyPages.forEach((e, i) => {
          copyPages[i].time = new Date().getTime();
        });

        setThePages(copyPages);
      })
      .catch((error) => console.error(error));
  };
  const orderPage = (data) => {
    axios
      .post(env.protocol + env.env + "/api/pages/OrderPage", {
        pages: data.pages,
        siteId: data.siteId,
        id: data.id,
        user: sessionStorage.getItem("vfiUser"),
      })
      .then((response) => {})
      .catch((error) => console.error(error));
  };

  const duplicatePage = (id, parentId, afterFunc, refreshAfter) => {
    axios
      .post(env.protocol + env.env + "/api/pages/DuplicatePage", {
        id,
        parentId,
        user: sessionStorage.getItem("vfiUser"),
      })
      .then((response) => {
        if (afterFunc) {
          afterFunc(response.data);
        }
        if (refreshAfter) {
          setRefreshTime(new Date().getTime());
        }
        // addExtraPage(response.data.page.child_of,{...response.data.page,languages:response.data.languages});
      })
      .catch((error) => console.error(error));
  };

  const deletePage = (id, afterFunc) => {
    axios
      .post(env.protocol + env.env + "/api/pages/ChangePageValue", {
        id,
        label: "current_status",
        value: "deleted",
        user: sessionStorage.getItem("vfiUser"),
      })
      .then((response) => {
        if (afterFunc) {
          afterFunc(response);
        }
        setCurrentLocation("main");
        setDeletedPage(id);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const refreshPages = () => {
    let copyPages = cloneDeep(thePages);
    copyPages.forEach((e, i) => {
      copyPages[i].time = new Date().getTime();
    });

    setThePages(copyPages);
  };

  let content = "Empty";
  switch (currentLocation) {
    case "main":
      content = (
        <div
          className={"main-pages" + (props.selectPage ? " select-page" : "")}
          key={refreshTime}
        >
          {isLoading ? (
            <div className="blur">
              <FontAwesomeIcon icon={faSpinner} spin />
            </div>
          ) : (
            ""
          )}
          <div
            className={
              (pagesShow === "list" ? "header" : "header absolute") + " row"
            }
          >
            <div className="left col-lg-10">
              <span className="title">{language.main_pages.header.title}</span>
              {
                <Select
                  className="language-select"
                  options={availableLanguages}
                  value={selectedLanguage}
                  onChange={(e) => {
                    setSelectedLanguage(e);
                  }}
                  isClearable={true}
                />
              }
            </div>
            <div className="right col-lg-2">
              {/* <div className="language-select">
                <Select
                  options={[
                    {
                      label:
                        language.main_pages.header.languages_select.english,
                      value: "1",
                    },
                    //{ label: language.main_pages.header.languages_select.faroese, value: "2" },
                  ]}
                  value={{
                    label: language.main_pages.header.languages_select.english,
                    value: "1",
                  }}
                />
                <FontAwesomeIcon icon={faCaretDown} />
              </div> */}
              <ToolTip
                offset={{ x: 15, y: 30 }}
                title=""
                delay={600}
                toolTipElements={<div>Select leaflet view</div>}
                className={
                  "pages-show-option" +
                  (pagesShow === "leaflet" ? " selected" : "")
                }
                onClick={() => {
                  // setSelectedLanguage(undefined);
                  setPagesShow("leaflet");
                }}
              >
                <FontAwesomeIcon icon={faPause} />
              </ToolTip>
              <ToolTip
                offset={{ x: 15, y: 30 }}
                title=""
                delay={600}
                toolTipElements={<div>Select list view</div>}
                className={
                  "pages-show-option" +
                  (pagesShow === "list" ? " selected" : "")
                }
                onClick={() => {
                  // setSelectedLanguage(undefined);
                  setPagesShow("list");
                }}
              >
                <FontAwesomeIcon icon={faList} />
              </ToolTip>
            </div>
          </div>

          {pagesShow === "leaflet" && (
            <HierarchyView
              listIsOpen={listIsOpen}
              setListIsOpen={setListIsOpen}
              setOptionsListKey={setOptionsListKey}
              optionsListOptions={optionsListOptions}
              optionsListPos={optionsListPos}
              thePages={thePages}
              siteSelect={siteSelect}
              setOptionsList={setOptionsList}
              setOptionsListPageSelected={setOptionsListPageSelected}
              optionsListPageSelected={optionsListPageSelected}
              editPage={editPage}
              addExtraPage={addExtraPage}
              setIsLoading={setIsLoading}
              deletePage={deletePage}
              deletedPage={deletedPage}
              pageSelect={pageSelect}
              movePage={movePage}
              orderPage={orderPage}
              duplicatePage={duplicatePage}
              sessionSet={props.sessionSet}
              selectPage={props.selectPage}
              excludePages={props.excludePages}
              openOverlay={props.openOverlay}
              selectedLanguage={selectedLanguage}
              templateList={templateList}
              ghostPage={props.ghostPage}
              disableBottomSelect={props.disableBottomSelect}
              disableSitesList={props.disableSitesList}
              disableSharedPages={props.disableSharedPages}
              refreshPages={refreshPages}
            />
          )}
          {pagesShow === "list" && (
            <ListView
              listIsOpen={listIsOpen}
              setListIsOpen={setListIsOpen}
              setOptionsListKey={setOptionsListKey}
              optionsListOptions={optionsListOptions}
              optionsListPos={optionsListPos}
              thePages={thePages}
              siteSelect={siteSelect}
              setOptionsList={setOptionsList}
              editPage={editPage}
              addExtraPage={addExtraPage}
              setIsLoading={setIsLoading}
              deletePage={deletePage}
              deletedPage={deletedPage}
              pageSelect={pageSelect}
              movePage={movePage}
              orderPage={orderPage}
              duplicatePage={duplicatePage}
              sessionSet={props.sessionSet}
              selectPage={props.selectPage}
              excludePages={props.excludePages}
              openOverlay={props.openOverlay}
              site={props.site}
              selectedLanguage={selectedLanguage}
            />
          )}
          {/* <div className="sites-pages-wrap">
            <OptionsList
              // key={optionsListKey}
              isOpen={listIsOpen}
              close={(dontClose) => {
                if(!dontClose)
                  setListIsOpen(false);
                else
                  setOptionsListKey(new Date().getTime());
              }}
              options={optionsListOptions}
              position={optionsListPos}
            />
            <SitesList
              className={"bg-" + Math.min(thePages.length + 1, 7)}
              siteSelect={siteSelect}
              sessionSet={props.sessionSet}
              setOptionsList={setOptionsList}
              selectPage={props.selectPage}
              editPage={editPage}
              addExtraPage={addExtraPage}
              setIsLoading={(e) => setIsLoading(e)}
            />
            {thePages.map((element, i) => {
              if (props.excludePages) {
                if (props.excludePages.includes(element.pageId)) {
                  stopRenderChildren = true;
                }
              }

              if (stopRenderChildren)
                return "";

              return (
                <PagesList
                  key={element.pageId ? element.pageId + " " + element.time : 0}
                  className={"bg-" + Math.min(thePages.length - i, 7)}
                  siteId={element.siteId}
                  pageId={element.pageId}
                  time={element.time}
                  deletedChild={deletedPage}
                  name={element.name}
                  pageSelect={pageSelect}
                  addExtraPage={addExtraPage}
                  newValue={element.newValue}
                  editPage={editPage}
                  movePage={movePage}
                  orderPage={orderPage}
                  duplicatePage={duplicatePage}
                  selectedPage={
                    sessionStorage.getItem("selectedPages")
                      ? JSON.parse(sessionStorage.getItem("selectedPages"))[i]
                      : undefined
                  }
                  setOptionsList={setOptionsList}
                  selectAPage={props.selectPage}
                  deletePage={deletePage}
                  setIsLoading={(e) => setIsLoading(e)}
                  openOverlay={props.openOverlay}
                  excludePages={props.excludePages}
                />
              );
            })}
          </div> */}
        </div>
      );
      break;
    case "edit":
      content = (
        <EditPage
          key={editData.page.id + " " + refreshTime}
          data={editData}
          newData={props.newData}
          setSiteRef={props.setSiteRef}
          editPage={editPage}
          screenSize={editData.screenSize}
          openOverlay={props.openOverlay}
          setIsLoading={(e) => setIsLoading(e)}
          close={() => {
            setCurrentLocation("main");
            setIsLoading(false);
          }}
          deletePage={deletePage}
          setConfirmBeforeNavigate={props.setConfirmBeforeNavigate}
        />
      );
      break;
    default:
      content = "Should not get here...";
      break;
  }

  return content;
}
