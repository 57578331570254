import {} from "@fortawesome/pro-light-svg-icons";
import {
  faExternalLink,
  faBookReader,
  faTrafficLight,
  faFileAlt,
  faCopy,
  faTrashAlt,
  faEllipsisH,
} from "@fortawesome/pro-regular-svg-icons";
import { faSortAlt as faSortAltSelected } from "@fortawesome/pro-duotone-svg-icons";
import { faSortAlt } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import { useCallback, useEffect, useRef, useState } from "react";
import VfiCheckbox from "../../../assets/VfiCheckbox";
import VfiInputText from "../../../assets/VfiInputText/VfiInputText";
import "./ListView.scss";
import env from "../../../environment.json";
import {
  searchFilter,
  showDate,
  forceCheckLanguage,
} from "../../../assets/helperFunctions";
import noImage from "../../../assets/images/empty.jpg";
import cloneDeep from "lodash.clonedeep";
import languages from "./language.json";
const lang = localStorage.getItem("language")
  ? localStorage.getItem("language")
  : "english";
var dontSort = false;

export default function ListView(props) {
  const [pages, setPages] = useState([]);
  const [shownPages, setShownPages] = useState([]);
  const [search, setSearch] = useState("");
  const [sortBy, setSortBy] = useState("");
  const [updated, setUpdated] = useState(new Date().getTime());
  const [isBottom, setIsBottom] = useState(false);
  const [maxShown, setMaxShown] = useState(20);
  const [statusFilter, setStatusFilter] = useState("all");
  const pagesTableWrapRef = useRef(null);

  const language = languages[lang];

  const {
    // listIsOpen,
    // setListIsOpen,
    // setOptionsListKey,
    // optionsListOptions,
    // optionsListPos,
    // thePages,
    // siteSelect,
    // setOptionsList,
    editPage,
    // addExtraPage,
    setIsLoading,
    deletePage,
    // deletedPage,
    // pageSelect,
    // movePage,
    // orderPage,
    duplicatePage,
    // sessionSet,
    selectPage,
    // excludePages,
    // openOverlay,
    site,
    selectedLanguage,
  } = props;
  const getAllPagesInSite = useCallback(
    (afterFunc) => {
      axios
        .get(
          env.protocol +
            env.env +
            "/api/public/pages/GetAllPagesInSite.php?site=" +
            (site ?? sessionStorage.getItem("storedSite")) +
            "&list&language=en&all&deleted&users&languages&path"
        )
        .then((response) => {
          setPages(response.data.allPages);
          setShownPages(response.data.allPages);

          if (afterFunc) afterFunc(response.data.allPages);
        })
        .catch((error) => console.error(error));
    },
    [site]
  );

  useEffect(() => {
    getAllPagesInSite();
  }, [site, getAllPagesInSite]);

  useEffect(() => {
    let copyPages = cloneDeep(pages);

    switch (statusFilter) {
      case "all":
        copyPages = copyPages?.filter((e) => e.current_status !== "deleted");
        break;
      case "published":
        copyPages = copyPages?.filter((e) => e.current_status === "published");
        break;
      case "drafts":
        copyPages = copyPages?.filter((e) => e.current_status === "draft");
        break;
      case "scheduled":
        copyPages = copyPages?.filter((e) => e.current_status === "scheduled");
        break;
      case "deleted":
        copyPages = copyPages?.filter((e) => e.current_status === "deleted");
        break;
      default:
        break;
    }

    if (!copyPages) return;

    copyPages = searchFilter(copyPages, search);

    if (selectedLanguage) {
      copyPages = copyPages.filter((page) =>
        Object.keys(page.languages).find((e) => e === selectedLanguage.value)
      );
      // if(Object.keys(page.languages).find(e=>e === selectedLanguage.value) === undefined)
      //   return "";
    }

    if (sortBy !== "" && !dontSort) {
      if (sortBy.search("page") !== -1) {
        copyPages = copyPages.sort((a, b) => {
          if (a.pl_title === b.pl_title) {
            return 0;
          }

          return (a.pl_title ? a.pl_title.toLowerCase() : "") >
            (b.pl_title ? b.pl_title.toLowerCase() : "")
            ? 1
            : -1;
        });

        if (sortBy === "page_r") {
          copyPages.reverse();
        }
      }

      if (sortBy.search("created") !== -1) {
        copyPages.sort((a, b) => {
          const aT = new Date(a.create_date).getTime(),
            bT = new Date(b.create_date).getTime();
          if (aT === bT) {
            return 0;
          }
          return aT < bT ? 1 : -1;
        });

        if (sortBy === "created_r") {
          copyPages.reverse();
        }
      }
    }

    setShownPages(copyPages);
    setUpdated(new Date().getTime());
    dontSort = false;
  }, [pages, search, sortBy, statusFilter, selectedLanguage]);

  const selectThePage = (id) => {
    setIsLoading(true);
    axios
      .get(env.protocol + env.env + "/api/public/pages/GetPage.php?id=" + id)
      .then((response) => {
        selectPage({ ...response.data, id: response.data.page.id });
      })
      .catch((error) => console.error(error));
  };

  const handleScroll = (e) => {
    const bottom =
      e.target.scrollHeight - e.target.scrollTop - 10 <= e.target.clientHeight;
    if (bottom && isBottom === false) {
      setIsBottom(true);
      setMaxShown(maxShown + 10);
    }
    if (!bottom && isBottom === true) {
      setIsBottom(false);
    }
  };

  const openEditPage = (id) => {
    props.setIsLoading(true);
    axios
      .post(env.protocol + env.env + "/api/public/pages/GetPage.php", {
        user: sessionStorage.getItem("vfiUser"),
        page: id,
      })
      .then((response) => {
        const data = response.data;

        let lang = "";
        for (var prop in data.languages) {
          if (prop === "en") {
            lang = prop;
          }
        }
        if (lang === "") {
          for (var prop in data.languages) {
            lang = prop;
            break;
          }
        }
        const state = { page_id: id };
        const title = data.languages[lang].title;
        if (!window.history.state) {
          window.history.pushState(state, title);
        } else if (window.history.state.page_id != id) {
          window.history.pushState(state, title);
        }
        editPage(data);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  return (
    <div className="main-pages-list-view">
      {/* <SitesList /> */}
      <div className="listHeaderWrapper">
        <div className={"list-header row"}>
          <div className="left col-lg-10">
            <div className="status-filter-parent">
              <span
                className={
                  statusFilter === "all"
                    ? "status-filter selected"
                    : "status-filter"
                }
                onClick={() => {
                  setStatusFilter("all");
                }}
              >
                {language.main_pages.header.status.all + " "}
                <span className="status-filter-amount">
                  (
                  {pages &&
                    pages.filter((e) => e.current_status !== "deleted").length}
                  )
                </span>
              </span>
              |
              <span
                className={
                  statusFilter === "published"
                    ? "status-filter selected"
                    : "status-filter"
                }
                onClick={() => {
                  setStatusFilter("published");
                }}
              >
                {language.main_pages.header.status.published + " "}
                <span className="status-filter-amount">
                  (
                  {pages &&
                    pages.filter((e) => e.current_status === "published")
                      .length}
                  )
                </span>
              </span>
              |
              <span
                className={
                  statusFilter === "drafts"
                    ? "status-filter selected"
                    : "status-filter"
                }
                onClick={() => {
                  setStatusFilter("drafts");
                }}
              >
                {language.main_pages.header.status.drafts + " "}
                <span className="status-filter-amount">
                  (
                  {pages &&
                    pages.filter((e) => e.current_status === "draft").length}
                  )
                </span>
              </span>
              |
              <span
                className={
                  statusFilter === "scheduled"
                    ? "status-filter selected"
                    : "status-filter"
                }
                onClick={() => {
                  setStatusFilter("scheduled");
                }}
              >
                {language.main_pages.header.status.scheduled + " "}
                <span className="status-filter-amount">
                  (
                  {pages &&
                    pages.filter((e) => e.current_status === "scheduled")
                      .length}
                  )
                </span>
              </span>
              |
              <span
                className={
                  statusFilter === "deleted"
                    ? "status-filter selected"
                    : "status-filter"
                }
                onClick={() => {
                  setStatusFilter("deleted");
                }}
              >
                {language.main_pages.header.status.deleted + " "}
                <span className="status-filter-amount">
                  (
                  {pages &&
                    pages.filter((e) => e.current_status === "deleted").length}
                  )
                </span>
              </span>
              {/* |
                <span
                  className={
                    statusFilter === "expired"
                      ? "status-filter selected"
                      : "status-filter"
                  }
                  onClick={() => {
                    setStatusFilter("expired");
                  }}
                >
                  {language.main_pages.header.status.expired + " "}
                  <span className="status-filter-amount">{5}</span>
                </span> */}
            </div>
          </div>
        </div>
        <VfiInputText
          className="search-box"
          isSearch={true}
          value={search}
          onChange={(e) => {
            setSearch(e.target.value);
          }}
          placeholder="Search Pages..."
        />
      </div>

      <div
        className="pages-table-wrap"
        onScroll={handleScroll}
        ref={pagesTableWrapRef}
      >
        <table className="pages-table">
          <thead>
            <tr>
              <th
                className="page"
                onClick={() => {
                  sortBy === "page" ? setSortBy("page_r") : setSortBy("page");
                }}
              >
                <span className="text">Page</span>{" "}
                {/*<FontAwesomeIcon icon={faLongArrowUp} /><FontAwesomeIcon icon={faLongArrowDown} />*/}
                <FontAwesomeIcon
                  color={sortBy.search("page") !== -1 ? "black" : undefined}
                  rotation={sortBy === "page_r" ? 180 : 0}
                  icon={
                    sortBy.search("page") === -1 ? faSortAlt : faSortAltSelected
                  }
                />
              </th>
              <th
                className="created"
                onClick={() => {
                  sortBy === "created"
                    ? setSortBy("created_r")
                    : setSortBy("created");
                }}
              >
                <span className="text">Created</span>{" "}
                {/*<FontAwesomeIcon icon={faLongArrowUp} /><FontAwesomeIcon icon={faLongArrowDown} />*/}
                <FontAwesomeIcon
                  color={sortBy.search("created") !== -1 ? "black" : undefined}
                  rotation={sortBy === "created_r" ? 180 : 0}
                  icon={
                    sortBy.search("created") === -1
                      ? faSortAlt
                      : faSortAltSelected
                  }
                />
              </th>
              <th className="located">
                <div className="text located">Located</div>
              </th>
              <th className="duplicate">
                <div className="text duplicate">Duplicate</div>
              </th>
              <th className="delete">
                <div className="text delete">Delete</div>
              </th>
              <th className="external">
                <FontAwesomeIcon icon={faExternalLink} />
              </th>
              <th className="seo">
                <FontAwesomeIcon icon={faTrafficLight} />
              </th>
              <th className="published">
                <FontAwesomeIcon icon={faBookReader} />
              </th>
              <th className="checkbox">
                <VfiCheckbox />
              </th>
            </tr>
          </thead>
          <tbody>
            {shownPages &&
              shownPages.map((page, i) => {
                if (i >= maxShown) return "";

                return (
                  <tr key={page.id + " " + updated}>
                    <td
                      className="page"
                      onClick={() => {
                        selectPage
                          ? selectThePage(page.id)
                          : openEditPage(page.id);
                      }}
                    >
                      <div className="page-info">
                        <img
                          alt={""}
                          src={
                            page.feature_media
                              ? page.feature_image_dir.thumbnail
                              : noImage
                          }
                        />
                        <div className="right-side">
                          <div className="headline">
                            {page.pl_title}
                            {(page.deleted ||
                              page.current_status === "deleted") && (
                              <span className="deleted">
                                {" ∙ "}
                                {language.list_view.deleted}
                              </span>
                            )}
                          </div>
                          <div className="last-edit">
                            {showDate(page.last_update_date) +
                              " ∙ " +
                              (page.last_update_user
                                ? page.last_update_user.firstname +
                                  " " +
                                  page.last_update_user.lastname +
                                  " ∙ " +
                                  page.last_update_user.company
                                : "")}
                          </div>
                        </div>
                      </div>
                    </td>
                    <td className="created">
                      <div className="create-date">
                        {showDate(page.create_date)}
                      </div>
                      <div className="user-created">
                        {page.created_user
                          ? page.created_user.firstname +
                            " " +
                            page.created_user.lastname +
                            " ∙ " +
                            page.created_user.company
                          : ""}
                      </div>
                    </td>
                    <td className="located">
                      <FontAwesomeIcon icon={faFileAlt} />
                      <div className="ellipsis">
                        <FontAwesomeIcon icon={faEllipsisH} />
                        <div className="the-path">{page.path}</div>
                      </div>
                    </td>
                    <td
                      className="duplicate"
                      onClick={() => {
                        dontSort = true;
                        duplicatePage(
                          page.id,
                          page.child_of,
                          (e) => {
                            let copyPages = cloneDeep(pages);
                            const index = pages.findIndex(
                              (e) => e.id === page.id
                            );
                            let newPage = cloneDeep({ ...e.page });
                            newPage.pl_title = forceCheckLanguage(
                              e.languages
                            ).title;
                            copyPages.splice(index + 1, 0, newPage);
                            setPages(copyPages);
                            // getAllPagesInSite();
                            // setSortBy("created");
                            // pagesTableWrapRef.current.scroll({top: 0, left:0, behavior:'smooth'});
                          },
                          false
                        );
                      }}
                    >
                      <FontAwesomeIcon icon={faCopy} />
                    </td>
                    <td
                      className="delete"
                      onClick={() => {
                        deletePage(page.id, () => {
                          let copyPages = cloneDeep(pages);
                          copyPages.find(
                            (e) => e.id === page.id
                          ).deleted = true;
                          setPages(copyPages);
                        });
                      }}
                    >
                      <FontAwesomeIcon icon={faTrashAlt} />
                    </td>
                    <td className="external">
                      <div className="wrapper">1</div>
                    </td>
                    <td className="seo">
                      <div className="wrapper">
                        <div
                          className={
                            "dot " +
                            (Math.floor(Math.random() * 2) === 1
                              ? "green"
                              : "red")
                          }
                        />
                      </div>
                    </td>
                    <td className="published">
                      <div className="wrapper">
                        <div
                          className={
                            "dot " +
                            // (Math.floor(Math.random() * 2) === 1
                            (page.current_status === "published"
                              ? "green"
                              : "red")
                          }
                        />
                      </div>
                    </td>
                    <td className="checkbox">
                      <VfiCheckbox />
                    </td>
                  </tr>
                );
              })}
          </tbody>
        </table>
      </div>
    </div>
  );
}
