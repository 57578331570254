import React from "react";
import RelatedFeature from "../../RelatedFeature/RelatedFeature";
import VfiCKeditor from "../../VfiCKeditor/VfiCKeditor";

export function moduleOptions(index, contentData, setContentData) {
  return [
    // {
    //   is: "choice",
    //   icon: faFrown,
    //   text: "None implemented yet...",
    //   onClick: () => {
    //     let newContentData = Object.assign([], contentData);
    //     // newContentData.splice(i, 1);
    //     // newContentData[index].type = "first_letter_big";
    //     setContentData(newContentData);
    //   },
    //   style: { color: "red" },
    // },
  ];
}

export default function RelatedAndText(props) {
  const { inEdit, editPage, data } = props;

  return (
    <div
      style={props.style}
      className={"related-and-text " + (props.className ? props.className : "")}
    >
      <RelatedFeature
        className="related-feature"
        data={data}
        changeFeature={props.changeFeature}
        inEdit={inEdit}
        editPage={editPage}
      />
      <VfiCKeditor
        className="related-text"
        value={props.value}
        onBlur={props.onBlur}
        toolbar={props.toolbar}
      />
    </div>
  );
}
