import { faTimes } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import VfiCKeditor from "../../../../assets/VfiCKeditor/VfiCKeditor";
import "./AdvancedTextArea.scss";

function AdvancedTextArea(props) {
  const [content, setContent] = useState(props.data.content);

  return (
    <div className="advanced-text-area">
      <div className="close-overlay">
        <FontAwesomeIcon
          icon={faTimes}
          onClick={() => {
            props.close();
          }}
        />
      </div>
      <h1>{props.data.heading}</h1>
      <VfiCKeditor
        onChange={(e) => {
          setContent(e);
        }}
        value={content}
      />
      <div
        className="cancel-button"
        onClick={() => {
          props.close();
        }}
      >
        Cancel
      </div>
      <div
        className="update-button"
        onClick={() => {
          props.addContent(content);
          props.close();
        }}
      >
        Update
      </div>
    </div>
  );
}

export default AdvancedTextArea;
