import { faImage } from "@fortawesome/pro-light-svg-icons";
import OneMedia from "../PageModules/OneMedia";
import VfiCustomSelect from "../VfiCustomSelect";
import VfiInputText from "../VfiInputText/VfiInputText";
import env from "../../environment.json";
import languages from "./language.json";
import { cloneDeep } from "lodash";
import { useState } from "react";
import "./InstagramInWhatson.scss";
import VerifyMethods from "../VerifyMethods";
const language = localStorage.getItem("language")
  ? localStorage.getItem("language")
  : "english";

export default function InstagramInWhatson({
  data,
  onChange = () => void 0,
  onMediaClick = () => void 0,
  setSiteRef = () => void 0,
  openOverlay = () => void 0,
  defaultDragStart = () => void 0,
  checkErrors = false,
  disabled = false,
  className = "",
}) {
  const theLanguage = languages[language];
  const [instagramImageAmount, setInstagramImageAmount] = useState(
    data.images.length
  );
  const [indexImageDrag, setIndexImageDrag] = useState(undefined);
  const [indexImageDragOn, setIndexImageDragOn] = useState(undefined);

  return (
    <div className={"instagram-in-whatson " + className}>
      <div className="row">
        <div className="hashtag col-lg-6">
          <p>{theLanguage.hashtag}</p>
          <VfiInputText
            value={data.hashtag}
            onChange={(e) => onChange({ ...data, hashtag: e.target.value })}
            errorCheckSequence={checkErrors ? [VerifyMethods.notEmpty] : []}
            disabled={disabled}
          />
        </div>
        <div className="profile-link col-lg-6">
          <p>{theLanguage.profile_link}</p>
          <VfiInputText
            value={data.profile}
            onChange={(e) => onChange({ ...data, profile: e.target.value })}
            errorCheckSequence={checkErrors ? [VerifyMethods.notEmpty] : []}
            disabled={disabled}
          />
        </div>
      </div>
      <p className="image-count-text">{theLanguage.image_amount}</p>
      <VfiCustomSelect
        isDisabled={disabled}
        options={[
          { label: "3 " + theLanguage.images, value: 3 },
          { label: "5 " + theLanguage.images, value: 5 },
          { label: "9 " + theLanguage.images, value: 9 },
        ]}
        onChange={(e) => {
          const copyData = cloneDeep(data);

          copyData.images = copyData.images.slice(0, e.value);

          while (copyData.images.length < e.value) {
            copyData.images.push({
              instaLink: "",
              media: {},
              key: copyData.images.length,
            });
          }

          onChange(copyData);
          setInstagramImageAmount({ instagramImageAmount: e.value });
        }}
        value={
          instagramImageAmount > 0
            ? {
                label: instagramImageAmount + " " + theLanguage.images,
                value: instagramImageAmount,
              }
            : undefined
        }
        error={checkErrors ? instagramImageAmount === 0 : false}
      />
      <div className="instagram-image-links">
        {data.images.map((element, i) => {
          return (
            <div
              className={
                "insta" +
                (indexImageDrag === i ? " dragging" : "") +
                (indexImageDragOn === i ? " dragging-on" : "")
              }
              key={element.key}
              onDragOver={(e) => {
                e.preventDefault();
              }}
              onDragEnter={(e) => {
                setTimeout(() => {
                  setIndexImageDragOn(i);
                }, 0);
              }}
              onDragLeave={(e) => {
                setIndexImageDragOn(undefined);
              }}
              onDrop={(e) => {
                let images = Object.assign([], data.images);
                let image = images[indexImageDrag];
                images.splice(indexImageDrag, 1);
                images.splice(i, 0, image);
                data.images = images;
                onChange(data);
              }}
              draggable="false"
            >
              <div className="insta-image">
                <OneMedia
                  siteRef={1}
                  setSiteRef={setSiteRef}
                  openOverlay={(e, args) => openOverlay(e, args, element.key)}
                  whatMedia={["images"]}
                  media={element.media}
                  onClick={() => {
                    onMediaClick(i);
                  }}
                  faIcon={faImage}
                  draggable={!disabled}
                  onDragStart={(e) => {
                    defaultDragStart(e);
                    setIndexImageDrag(i);

                    if (element.media) {
                      var img = document.createElement("img");
                      img.src =
                        env.protocol +
                        env.env +
                        "/uploads/" +
                        element.media.file_name +
                        "_thumbnail." +
                        element.media.extension;
                      e.dataTransfer.setDragImage(img, 0, 0);
                    }
                  }}
                  onDragEnd={(e) => {
                    setIndexImageDrag(undefined);
                    setIndexImageDragOn(undefined);
                  }}
                  disableChange={disabled}
                />
                <div className="drag-element" />
              </div>
              <div className="insta-link-wrap">
                <VfiInputText
                  className="insta-link"
                  value={element.instaLink}
                  onChange={(e) => {
                    const copyData = cloneDeep(data);
                    copyData.images[i].instaLink = e.target.value;
                    onChange(copyData);
                  }}
                  placeholder="Instagram link"
                  errorCheckSequence={
                    checkErrors ? [VerifyMethods.notEmpty] : []
                  }
                  disabled={disabled}
                />
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}
