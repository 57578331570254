import {
  faEdit,
  faSlidersH,
  faPennant,
  faEye,
  faTimes,
} from "@fortawesome/pro-light-svg-icons";
import { } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./MyWhatson.scss";
import Select from "react-select";
import { useEffect, useRef, useState } from "react";
import MultiRangeSlider from "../../../assets/MultiRangeSlider";
import axios from "axios";
import env from "../../../environment.json";
import OneMedia from "../../../assets/PageModules/OneMedia";
// import ReactMapboxGl, { Marker, Popup } from "react-mapbox-gl";
// import "react-mapbox-gl/dist/mapbox-gl.css";
import noImage from "../../../assets/images/empty.jpg";
import mapMarkers from "../../../assets/images/map-markers/map-markers";
import WhatsonCreateNew from "../WhatsonDisplay/WhatsonCreateNew";
import { cloneDeep } from "lodash";
import VfiInputText from "../../../assets/VfiInputText/VfiInputText";
import { searchFilter } from "../../../assets/helperFunctions";
import RightSideInfo from "../../../assets/RightSideInfo";
import VfiCheckbox from "../../../assets/VfiCheckbox";
import ReactMapGl, { Marker, Popup } from "react-map-gl";
import globalObject from "../../../assets/globalVariables";

// const Map = ReactMapboxGl({
//   accessToken:
//     //   'pk.eyJ1IjoiZmFicmljOCIsImEiOiJjaWc5aTV1ZzUwMDJwdzJrb2w0dXRmc2d0In0.p6GGlfyV-WksaDV_KdN27A'
//     "pk.eyJ1IjoiYXNrb2RhbiIsImEiOiJja2drcWR4eW4xYWtuMnN0ZXJpNnJlemk3In0.Dy7UfnJQiXaBYYkN-okLpw",
// });
import mapboxgl from "mapbox-gl";

// The following is required to stop "npm build" from transpiling mapbox code.
// notice the exclamation point in the import.
// @ts-ignore
// eslint-disable-next-line import/no-webpack-loader-syntax, import/no-unresolved
mapboxgl.workerClass = require("worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker").default; // prettier-ignore

export default function MyWhatson({
  openOverlay,
  whatWhatson,
  addOne,
  addContent,
}) {
  const [minViews, setMinViews] = useState(0);
  const [maxViews, setMaxViews] = useState(100000);
  const [regions, setRegions] = useState([
    { label: "Islandwide", value: "all" },
  ]);
  const [regionSelected, setRegionSelected] = useState({
    label: "Islandwide",
    value: "all",
  });
  const [placeCategories, setPlaceCategories] = useState([
    { label: "All", value: "all" },
  ]);
  const [selectedPlaceCategories, setSelectedPlaceCategories] = useState([
    { label: "All", value: "all" },
  ]);
  const [tourActivities, setTourActivities] = useState([
    { label: "All", value: "all" },
  ]);
  const [selectedTourActivities, setSelectedTourActivities] = useState([
    { label: "All", value: "all" },
  ]);
  const [places, setWhatson] = useState([]);
  const [placesFiltered, setPlacesFilterd] = useState([]);
  const [search, setSearch] = useState("");
  const [filterShow, setFilterShow] = useState(false);
  const [filterOptions, setFilterOptions] = useState([]);
  const [popup, setPopup] = useState(undefined);
  const [editData, setEditData] = useState(null);
  const [reGetWhatson, setReGetPlace] = useState(0);
  const [editWhat, setEditWhat] = useState("place");
  const [viewport, setViewport] = useState({
    latitude: 61.8926,
    longitude: -6.9118,
    zoom: 8,
    transitionDuration: 500,
  });
  const sideScroll = useRef(null);
  const mapRef = useRef(null);

  useEffect(() => {
    setWhatson([]);

    let user = globalObject.vfiUser.userId;
    const previewUserId = sessionStorage.getItem("preview_user_id");
    if (previewUserId) {
      user = previewUserId;
    }

    if (whatWhatson === "places") {
      axios
        .get(
          `${env.protocol}${env.env}/api/whatson/places/GetPlacesList?author=${user}&tours_amount`
        )
        .then((response) => {
          // console.log(response)
          setWhatson(response.data);
        })
        .catch((error) => console.error(error));
    }

    if (whatWhatson === "tours") {
      axios
        .get(
          `${env.protocol}${env.env}/api/whatson/tours/GetToursList?author=${user}&activities`
        )
        .then((response) => {
          // console.log(response);
          setWhatson(response.data);
        })
        .catch((error) => console.error(error));
    }
    setPopup(undefined);
  }, [reGetWhatson, whatWhatson]);

  useEffect(() => {
    let filterOptions = []; // Default filter options
    filterOptions.push(
      {
        type: "dropdown",
        title: "Views",
        content: (
          <div className="views-wrap">
            <MultiRangeSlider
              min={0}
              max={100000}
              onMouseUp={(e) => {
                setMinViews(e.min);
                setMaxViews(e.max);
              }}
              onChange={() => void 0}
            />
          </div>
        ),
      },
      {
        type: "dropdown",
        title: "Regions",
        content: (
          <div className="region-select">
            <Select
              options={regions}
              value={regionSelected}
              onChange={(e) => setRegionSelected(e)}
            />
          </div>
        ),
      }
    );
    if (whatWhatson === "places") {
      filterOptions.push({
        type: "dropdown",
        title: "Place categories",
        content: (
          <div className="place-category">
            <div className="place-categories">
              {placeCategories.map((e) => {
                return (
                  <label key={e.value} className="option">
                    <VfiCheckbox
                      className="checkbox"
                      onChange={() => {
                        let copyCategories = cloneDeep(selectedPlaceCategories);
                        if (e.value === "all") {
                          copyCategories = [{ label: "All", value: "all" }];
                        } else {
                          if (
                            copyCategories.find((f) => f.value === e.value) ===
                            undefined
                          ) {
                            copyCategories = copyCategories.filter(
                              (f) => f.value !== "all"
                            );
                            copyCategories.push(e);

                            if (
                              copyCategories.length ===
                              placeCategories.length - 1
                            ) {
                              copyCategories = [{ label: "All", value: "all" }];
                            }
                          } else {
                            copyCategories = copyCategories.filter(
                              (f) => f.value !== e.value
                            );
                            if (copyCategories.length === 0) {
                              copyCategories = [{ label: "All", value: "all" }];
                            }
                          }
                        }
                        setSelectedPlaceCategories(copyCategories);
                      }}
                      checked={
                        selectedPlaceCategories.find(
                          (f) => f.value === e.value
                        ) !== undefined
                      }
                    />
                    <div className="text">{e.label}</div>
                  </label>
                );
              })}
            </div>
          </div>
        ),
      });
    }

    if (whatWhatson === "tours") {
      filterOptions.push({
        type: "dropdown",
        title: "Tour activities",
        content: (
          <div className="tour-activity">
            <div className="place-categories">
              {tourActivities.map((e) => {
                return (
                  <label key={"activities" + e.value} className="option">
                    <VfiCheckbox
                      className="checkbox"
                      onChange={() => {
                        let copyActivities = cloneDeep(selectedTourActivities);
                        if (e.value === "all") {
                          copyActivities = [{ label: "All", value: "all" }];
                        } else {
                          if (
                            copyActivities.find((f) => f.value === e.value) ===
                            undefined
                          ) {
                            copyActivities = copyActivities.filter(
                              (f) => f.value !== "all"
                            );
                            copyActivities.push(e);

                            if (
                              copyActivities.length ===
                              tourActivities.length - 1
                            ) {
                              copyActivities = [{ label: "All", value: "all" }];
                            }
                          } else {
                            copyActivities = copyActivities.filter(
                              (f) => f.value !== e.value
                            );
                            if (copyActivities.length === 0) {
                              copyActivities = [{ label: "All", value: "all" }];
                            }
                          }
                        }
                        setSelectedTourActivities(copyActivities);
                      }}
                      checked={
                        selectedTourActivities.find(
                          (f) => f.value === e.value
                        ) !== undefined
                      }
                    />
                    <div className="text">{e.label}</div>
                  </label>
                );
              })}
            </div>
          </div>
        ),
      });
    }

    setFilterOptions(filterOptions);
  }, [
    whatWhatson,
    placeCategories,
    selectedPlaceCategories,
    tourActivities,
    selectedTourActivities,
  ]);

  useEffect(() => {
    axios
      .get(`${env.protocol}${env.env}/api/public/GetRegions.php`)
      .then((response) => {
        let regions = [{ label: "Islandwide", value: "all" }];
        response.data.forEach((element) => {
          regions.push({
            label: element.region_name,
            value: element.region_id,
          });
        });
        setRegions(regions);
      })
      .catch((error) => console.error(error));

    axios
      .get(`${env.protocol}${env.env}/api/whatson/places/GetPlaceCategories`)
      .then((response) => {
        let categories = [{ label: "All", value: "all" }];
        response.data.forEach((element) => {
          categories.push({ label: element.category_en, value: element.id });
        });
        setPlaceCategories(categories);
      })
      .catch((error) => console.error(error));

    axios
      .get(`${env.protocol}${env.env}/api/whatson/tours/GetTourActivities`)
      .then((response) => {
        let activities = [{ label: "All", value: "all" }];
        response.data.forEach((element) => {
          activities.push({ label: element.english, value: element.id });
        });
        setTourActivities(activities);
      })
      .catch((error) => console.error(error));
  }, []);

  useEffect(() => {
    let copyPlaces = cloneDeep(places);

    if (regionSelected.value !== "all") {
      copyPlaces = copyPlaces.filter((e) => {
        return e.region_id === parseInt(regionSelected.value);
      });
    }

    if (search !== "") {
      copyPlaces = searchFilter(copyPlaces, search);
    }

    if (minViews > 0) {
      copyPlaces = copyPlaces.filter((e) => {
        return e.comb_visitors >= minViews;
      });
    }

    if (maxViews < 100000) {
      copyPlaces = copyPlaces.filter((e) => {
        return e.comb_visitors <= maxViews;
      });
    }

    if (
      whatWhatson === "places" &&
      selectedPlaceCategories[0].value !== "all"
    ) {
      copyPlaces = copyPlaces.filter((e) => {
        let theReturn = false;
        if (e.categories)
          e.categories.forEach((f) => {
            if (
              selectedPlaceCategories.find((g) => g.value === f.id_category)
            ) {
              theReturn = true;
            }
          });
        return theReturn;
      });
    }

    if (whatWhatson === "tours" && selectedTourActivities[0].value !== "all") {
      copyPlaces = copyPlaces.filter((e) => {
        let theReturn = false;
        if (e.activities)
          e.activities.forEach((f) => {
            if (selectedTourActivities.find((g) => g.value === f.id)) {
              theReturn = true;
            }
          });
        return theReturn;
      });
    }

    setPlacesFilterd(copyPlaces);
  }, [
    places,
    regionSelected,
    search,
    minViews,
    maxViews,
    selectedPlaceCategories,
    selectedTourActivities,
    whatWhatson,
  ]);

  const editWhatson = (id) => {
    let getPartUrl = "";
    let editWhat = "";
    switch (whatWhatson) {
      case "places":
        getPartUrl = "/places/GetPlace.php";
        editWhat = "place";
        break;
      case "tours":
        getPartUrl = "/tours/GetTour.php";
        editWhat = "tour";
        break;
      default:
        break;
    }

    if (id !== undefined) {
      axios
        .post(env.protocol + env.env + "/api/public/whatson" + getPartUrl, {
          id,
        })
        .then((response) => {
          setEditWhat(editWhat);
          setEditData(response.data);
        })
        .catch((error) => console.error(error));
    } else {
      setEditWhat(editWhat);
      setEditData(undefined);
    }
  };

  if (editData !== null) {
    return (
      <WhatsonCreateNew
        newWhat={editWhat}
        updateData={editData}
        pushOneAndGoToList={() => {
          setEditData(null);
          setReGetPlace(new Date().getTime());
        }}
        updateOneAndGoToList={() => {
          setEditData(null);
          setReGetPlace(new Date().getTime());
        }}
        // autoSave={this.autoSave}
        autoSave={(value) => {
          setEditData(value);
        }}
        openOverlay={openOverlay}
        addOne={addOne}
        addContent={addContent}
        setSiteRef={() => { }}
        language={"english"}
        close={() => {
          setEditData(null);
          setReGetPlace(new Date().getTime());
        }}
      />
    );
  }

  var clear;

  return (
    <div className="my-whatson-wrap">
      <div className="my-whatson">
        {/* <div style={{display:"flex"}}>{Object.keys(mapMarkers).map((m)=><img src={mapMarkers[m]} />)}</div> */}
        <div className="header">
          <div className="left">
            <div className="title">My Places</div>
            <div
              className="new-place"
              onClick={() => {
                editWhatson(undefined);
              }}
            >
              Add new {whatWhatson}
            </div>

            {/* <div className="star-rating">
                    <div className="star-title">Rating</div>
                    <div className="stars">
                        <FontAwesomeIcon icon={faStar} />
                        <FontAwesomeIcon icon={faStar} />
                        <FontAwesomeIcon icon={faStar} />
                        <FontAwesomeIcon icon={faStar} />
                        <FontAwesomeIcon icon={faStar} />
                    </div>
                    <div className="sub-selected">1-4 stars</div>
                </div> */}

            {/* <div className="bookings-wrap">
                    <span className="text">Bookings</span>
                    <MultiRangeSlider min={0} max={5000}
                        onMouseUp={(e) => {
                            setMinViews(e.min);
                            setMaxViews(e.max);
                            console.log(e);
                        }}
                    />
                </div> */}
          </div>
          <div className="left">
            <div className="search-n-filter">
              <div className="search">
                <VfiInputText
                  value={search}
                  placeholder="Search places..."
                  onChange={(e) => {
                    setSearch(e.target.value);
                  }}
                  isSearch={true}
                />
              </div>
              <div
                className={
                  filterShow ? "filter-button active" : "filter-button"
                }
                onClick={() => setFilterShow(true)}
              >
                <span>Filter</span>
                <FontAwesomeIcon icon={faSlidersH} />
              </div>
            </div>
          </div>
          {/* <div className="right col-xl-2">
          <div className="boxes-button">
            <FontAwesomeIcon icon={faThLarge} />
          </div>
          <div className="map-button">
            <FontAwesomeIcon icon={faMapMarkerAlt} />
          </div>
        </div> */}
        </div>
        <div className="content">
          <div className="places-list" ref={sideScroll}>
            <div className="places-list-wrap">
              {placesFiltered.length === 0 && (
                <div className="no-places">No places found.</div>
              )}
              {placesFiltered.map((place) => {
                let altText = "No alt text";
                if (place.image.info && place.image.info.languages["en"]) {
                  altText = place.image.info.languages["en"].alt;
                }

                return (
                  <div
                    // style={{display:'none'}}
                    key={place.id}
                    className={`place ${popup && popup.id === place.id ? "selected" : ""
                      }`}
                    onDoubleClick={() => {
                      editWhatson(place.id);
                    }}
                    onClick={(e) => {
                      const click = e.detail;

                      if (click === 1) {
                        clear = setTimeout(() => {
                          setPopup(place);
                          setViewport({
                            ...viewport,
                            viewState: {
                              ...viewport.viewState,
                              longitude: parseFloat(place.longitude),
                              latitude: parseFloat(place.latitude),
                              zoom: 14,
                            },
                          });
                        }, 300);
                      }
                      if (click === 2) {
                        clearTimeout(clear);
                      }
                    }}
                  >
                    <div className="image-wrap">
                      {/* <img src={place.image.thumbnail} alt={altText} /> */}
                      <OneMedia
                        media={{ src: place.image.thumbnail }}
                        alt={altText}
                        disableChange={true}
                      />
                    </div>
                    <div className="place-content">
                      <div className="place-name">{place.headline}</div>
                      <div className="sub-info">{place.region_name}</div>
                      <div className="place-info">
                        {place.type === "place" && (
                          <div className="tours">
                            <div className="icon">
                              <FontAwesomeIcon icon={faPennant} />
                            </div>
                            <div className="amount">{place.tours_amount}</div>
                          </div>
                        )}
                        {/* <div className="bookings">
                        <div className="icon">
                          <FontAwesomeIcon icon={faTag} />
                        </div>
                        <div className="amount">2700|170</div>
                      </div> */}
                        <div className="views">
                          <div className="icon">
                            <FontAwesomeIcon icon={faEye} />
                          </div>
                          <div className="amount">{place.comb_visitors}</div>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
          <div key={"map"} className="map-wrap">
            <ReactMapGl
              {...viewport}
              // // style="mapbox://styles/mapbox/streets-v9"
              mapStyle={"mapbox://styles/mapbox/light-v10"}
              containerStyle={{
                width: "100%",
                height: "100%",
              }}
              // center={mapCenter}
              // zoom={[mapZoom]}
              ref={mapRef}
              // onZoomEnd={(e) => { setMapZoom(e.style.z) }}
              width="100%"
              height="100%"
              mapboxApiAccessToken="pk.eyJ1IjoiYXNrb2RhbiIsImEiOiJja2drcWR4eW4xYWtuMnN0ZXJpNnJlemk3In0.Dy7UfnJQiXaBYYkN-okLpw"
              onViewStateChange={setViewport}
            >
              {placesFiltered.map((place, i) => {
                return (
                  <Marker
                    key={place.id}
                    //   coordinates={[-6.8118, 61.8926]}
                    // coordinates={[place.longitude, place.latitude]}
                    longitude={parseFloat(place.longitude)}
                    latitude={parseFloat(place.latitude)}
                    // anchor="bottom"
                    offsetLeft={-18}
                    offsetTop={-50}
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      // setMapCenter([parseFloat(place.longitude),parseFloat(place.latitude)]);
                      // setMapZoom(14);
                      setPopup(place);
                      sideScroll.current.scroll({
                        top: i * 120,
                        left: 0,
                        behaviour: "smooth",
                      });
                    }}
                  >
                    <img
                      // src={mapMarkers[mMKeys]}
                      alt="Whatson content"
                      src={mapMarkers["mapothers"]}
                    />
                  </Marker>
                );
              })}
              {popup && (
                <Popup
                  coordinates={[popup.longitude, popup.latitude]}
                  // offset={{
                  //   'bottom-left': [12, -38], 'bottom': [0, -38], 'bottom-right': [-12, -38]
                  // }}
                  longitude={parseFloat(popup.longitude)}
                  latitude={parseFloat(popup.latitude)}
                  closeButton={false}
                  style={{ margin: 0 }}
                >
                  <div className="top">
                    <div
                      className="close"
                      onClick={() => {
                        // setMapZoom(mapRef.current.props.zoom[0]);
                        setPopup(undefined);
                      }}
                    >
                      <FontAwesomeIcon icon={faTimes} />
                    </div>
                  </div>
                  <div className="image">
                    <OneMedia
                      media={{
                        src: popup.image.info ? popup.image.medium : noImage,
                      }}
                      disableChange={true}
                    />
                  </div>
                  <div className="text">
                    <h2>{popup.headline}</h2>
                    <p>{popup.excerpt}</p>
                    <div className="bottom-buttons">
                      <div
                        className="button edit"
                        onClick={() => {
                          editWhatson(popup.id);
                        }}
                      >
                        Edit
                      </div>
                    </div>
                  </div>
                </Popup>
              )}
            </ReactMapGl>
          </div>
        </div>
      </div>
      <RightSideInfo
        title={"Filter " + whatWhatson}
        info={filterOptions}
        type={"filter"}
        isShowing={filterShow}
        close={() => setFilterShow(false)}
      />
      {/* <Filter /> */}
    </div>
  );
}
