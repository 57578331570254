import { useEffect, useState } from "react";
import Leaflet from "../Leaflet/Leaflet";
import styles from "./ArticlesDisplay.module.scss";
import { articlesSections } from "./sections";
import InfoscreenView from "../InfoscreenView/InfoscreenView";
import InfoscreenArticles from "../InfoscreenView/InfoscreenArticles/InfoscreenArticles";

interface Props {
  language: string;
  adminMenu: string;
  setSiteRef: any;
  openOverlay: any;
  infoscreenMenu: any;
  setConfirmBeforeNavigate: any;
  addOne: any;
}

export default function ArticlesDisplay({
  language,
  adminMenu,
  setSiteRef,
  openOverlay,
  addOne,
  infoscreenMenu,
  setConfirmBeforeNavigate,
}: Props) {
  const [selectedView, setSelectedView] = useState<string>("");

    useEffect(() => {
        console.log(selectedView);
        
    }, [selectedView]);

  return (
    <div className={styles.container}>
      <Leaflet
        title={"Articles"}
        sections={articlesSections}
        selectedView={selectedView}
        setSelectedView={setSelectedView}
      />

    <div className={styles.content}>
    {(
        selectedView === "See & Do"
    ) && (
        <div className="infoscreen-content">
          <span className="shadow-maker" />
          <InfoscreenArticles
            hideList={true}
            articleInitialType={selectedView}
            openOverlay={openOverlay}
            setConfirmBeforeNavigate={setConfirmBeforeNavigate}
            setSiteRef={setSiteRef}
            newData={addOne}
          />
        </div>
      )}
      {(
        selectedView === "Eat & Drink"
    ) && (
        <div className="infoscreen-content">
          <span className="shadow-maker" />
          <InfoscreenArticles
            hideList={true}
            articleInitialType={selectedView}
            openOverlay={openOverlay}
            setConfirmBeforeNavigate={setConfirmBeforeNavigate}
            setSiteRef={setSiteRef}
            newData={addOne}
          />
        </div>
      )}
      {(
        selectedView === "Transport"
    ) && (
        <div className="infoscreen-content">
          <span className="shadow-maker" />
          <InfoscreenArticles
            hideList={true}
            articleInitialType={selectedView}
            openOverlay={openOverlay}
            setConfirmBeforeNavigate={setConfirmBeforeNavigate}
            setSiteRef={setSiteRef}
            newData={addOne}
          />
        </div>
      )}
    </div>
      
    </div>
  );
}
