import axios from "axios";
import { create } from "zustand";
import env from "../../environment.json";
import React from "react";

export const articlesStore = (set) => ({
  articleTypes: [],
  getArticleTypes: () => {
    axios
      .get(`${env.protocol}${env.env}/api/articles/GetArticleTypes`)
      .then((response) => {
        set({ articleTypes: response.data });
      })
      .catch((error) => console.error(error));
  },
});

export const useArticlesStore = create(articlesStore);

export const withArticlesStore = (BaseComponent) => (props) => {
  const store = useArticlesStore();
  return <BaseComponent {...props} articlesStore={store} />;
};
