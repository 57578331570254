import p1 from "./person-1.png";
import p2 from "./person-2.png";
import p3 from "./person-3.png";
import p4 from "./person-4.png";
import p5 from "./person-5.png";
import p6 from "./person-6.png";
import p7 from "./person-7.png";
import p8 from "./person-8.png";
import p9 from "./person-9.png";
import p10 from "./person-10.png";
import p11 from "./person-11.png";
import p12 from "./person-12.png";

const values = [p1, p2, p3, p4, p5, p6, p7, p8, p9, p10, p11, p12];

export default values;
