import React, { Component } from "react";
import "./App.scss";
import Main from "./Components/Main";
import Login from "./Components/Login/Login";
import "bootstrap/dist/css/bootstrap.min.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import env from "./environment.json";
import { faSpinner } from "@fortawesome/pro-solid-svg-icons";
import ThemeSelector from "./ThemeSelector";
import { GlobalToolTip } from "./Components/ToolTips/GlobalToolTip";
import { faGameConsoleHandheld } from "@fortawesome/pro-regular-svg-icons";
import Overlays from "./Components/OverlayElements/Overlays";

class App extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loggedIn: false,
      idleLoggedOutAfterLoggedIn: false,
      sessionChecked: false,
      error: false,
      errorMessage: "",
      loggedInInfo: {},
    };

    this.timer = false;
    this.axios = require("axios");
    if (!localStorage.getItem("language")) {
      localStorage.setItem("language", "english");
    }

    if (
      window.location.hostname !== "localhost" &&
      window.location.hostname !== "127.0.0.1"
    ) {
      let redirect = false;
      let host = window.location.host;
      let protocol = window.location.protocol;

      if (host.substring(0, 4).toLocaleLowerCase() === "www.") {
        host = host.substring(4);
        redirect = true;
      }
      if (protocol === "http:") {
        protocol = "https:";
        redirect = true;
      }
      if (redirect) {
        window.location.replace(`${protocol}//${host}`);
      }
    }
  }

  componentDidMount() {
    this.fetchSessionId();
  }

  logout = () => {
    // this.setState({ loggedIn: false });
    localStorage.clear();
    sessionStorage.clear();
    this.axios
      .post(env.protocol + env.env + "/api/public/LogOut.php")
      .then((response) => {
        window.location = "";
        // if (response === true) {
        //   console.log("Logged out successfully");
        // } else {
        //   console.log("Something went wrong with logging out");
        // }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  fetchSessionId() {
		const fromWhatson = new URLSearchParams(window.location.search).get('fromWhatsonFront') === 'true';
		// If we're coming in from whatson...
		if(fromWhatson){
			// Set session as checked and don't log in the user (hacky fix because routing is in login)
			this.setState({sessionChecked:true});
		} else {
			this.axios
      .post(env.protocol + env.env + "/api/public/checkLogin.php")
      .then((response) => {
        if (response.data === "logggedIn") {
          this.setState({ sessionChecked: true, loggedIn: true });
        } else {
          this.setState({ sessionChecked: true, loggedIn: false });
        }
      })
      .catch((error) => {
        console.log(error);
        this.setState({
          sessionChecked: true,
          error: true,
          errorMessage: error.message,
        });
      });
		}
  }

  checkLogin() {
    if (this.timer) {
      this.fetchSessionId();
      this.timer = false;
    } else {
      window.clearTimeout(this.clear);
      this.clear = setTimeout(() => {
        this.timer = true;
        this.checkLogin();
      }, 600000);
    }
  }

  render() {
		
    return (
      <div onClick={() => this.checkLogin()} className="App">
        <GlobalToolTip />
        <link
          rel="stylesheet"
          href="https://pro.fontawesome.com/releases/v5.10.1/css/all.css"
        ></link>
        {this.state.error ? (
          this.state.errorMessage
        ) : this.state.sessionChecked ? (
          this.state.loggedIn ? (
            <ThemeSelector themes={{ [env.theme]: true }}>
              <Main logout={this.logout} />
            </ThemeSelector>
          ) : (
            <Login
              loginSuccess={() => {
                this.setState({ loggedIn: true });
              }}
            />
          )
        ) : (
          <FontAwesomeIcon className="loading-spinner" icon={faSpinner} spin />
        )}
        <Overlays />
      </div>
    );
  }
}

export default App;
