import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/pro-light-svg-icons";
import "./themes.scss";
import noImage from "../../../assets/images/empty.jpg";
import env from "../../../environment.json";
import axios from "axios";

export default function Themes(props) {
  const [themeId, setThemeId] = useState(
    JSON.parse(props.siteSelected.themeId)
  );
  const themes = [
    { id: 1, name: "Visit Faroe Islands" },
    { id: 2, name: "Dekksentrið" },
  ];
  const selectedTheme = (themeId) => {
    axios
      .post(env.protocol + env.env + "/api/appearances/saveTheme", {
        siteId: props.siteSelected.siteId,
        themeId,
      })
      .then((response) => {
        setThemeId(themeId);
      })
      .catch((error) => console.error(error));
  };

  return (
    <div className="themes">
      <div className="themes-section">
        <div className="themes-section-header">
          <div className="fixed">
            <span
              className="header-title"
              onClick={() => {
                props.close();
              }}
            >
              <FontAwesomeIcon icon={faArrowLeft} /> Appearances
            </span>
          </div>
        </div>
        <div className={"themes-section-content"}>
          <h1>Select Theme</h1>
          <div className="select-themes">
            {themes.map((element) => {
              return (
                <div
                  onClick={() => {
                    selectedTheme(element.id);
                  }}
                  className={(themeId === element.id && "selected") + " theme"}
                >
                  <img src={noImage} />
                  <div>{element.name}</div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
}
