import { Component } from "react";
import Goose from "../../../../assets/images/Goose";
import Button from "../../../../assets/Button";

/**
 * Information form
 * 
 * This is the first step general information form for edit/add tour operator
 * 
 * @returns 	{jsx} 												Information form element
 * 
 * @author 					Pætur Mortensen
 */
class CompleteRegistration extends Component {
	
	/**
	 * props:
	 * @property 		{object} 		newTourOperator 					Tour operator information
	 * @property 		{object} 		language 									Language object with strings
	 * @property 		{function} 	saveChanges 							Save changes  
	 * @property		{component} whatsonNavigation 				WhatsonNavigation component
	 * 
	 * @author 					Pætur Mortensen
	 */
	constructor(props) {
		super(props);

		this.state = {
			isPublished:false
		}
	}

	/**
	 * Complete the registration
	 * 
	 * This saves the changes with the state set to 'published'
	 * 
	 * @author 					Pætur Mortensen
	 */
	complete_registration(){
		this.props.saveChanges('published');
		this.setState({isPublished:true});
	}

	/**
	 * Render the "ready to register" view
	 * 
	 * @returns 		{jsx} 												Ready to register view
	 * 
	 * @author 					Pætur Mortensen 
	 */
	render_ready_view(){
		// Check whether all validation passes
		const validationPassed = this.props.checkValidation();

		// Build config for the whatson navigation component
		const navigationConfig = {
			customForwardText:this.props.language.register.register,
			customForwardFunc:() => {this.complete_registration()},
			forwardDisabled:false
		}

		// If validation failed..
		if(!validationPassed){
			navigationConfig.forwardDisabled = true;
		}

		return (
			<>
				<div className="ready-cont">
					<h3><b>
						{validationPassed ? 
							this.props.language.register.ready_header
							:
							this.props.language.register.please_correct}
					</b></h3>
					<p className="register-sub-header">
						{validationPassed ?
							this.props.language.register.ready_sub_header
							:
							this.props.language.register.please_correct_sub_header}</p>
					{/* Add the top navigation and control panel */}
					{this.props.whatsonNavigation(navigationConfig)}
				</div>
			</>
		);
	}

	/**
	 * Render the "registration completed" view
	 * 
	 * @returns 		{jsx} 													The "registration completed" view
	 * 
	 * @author 					Pætur Mortensen 
	 */
	render_completed_view(){
		return (
			<div className="registration-complete-cont">
				<h3><b>{this.props.language.register.good_old_chap}</b></h3>
				<p className="register-sub-header">{this.props.language.register.completed_sub_header}</p>
				<div className="goose-image">
					<img src={Goose.thanksx2} />
				</div>
				<div className="complete-buttons">
					<Button
						onClick={() => {
							// Move to first step of form
							this.props.outerSetState({index:0});
						}}
						type="link"
					>
						{this.props.language.register.edit}
					</Button>
					<Button
						onClick={() => {
							// Routing is not complete, and navigation requires prop drilling to the top, so for 
							// now, we simply refresh the page, leading the user back to whatson overview
							window.location.reload();
						}}
						type="primary"
						size="large"
					>
						{this.props.language.register.submit_your_first_tour}
					</Button>
				</div>
			</div>
		);
	}

	render() {
		return (
			<div className="new-content eight">
				{/* 'Ready to publish' or 'done publishing' view depending on whether TO is published */}
				{!this.state.isPublished ? this.render_ready_view() : this.render_completed_view()}
			</div>
		);
	}
}

export default CompleteRegistration;
