import React, { Component } from "react";
import Dropzone from "react-dropzone";
import env from "../../../../environment.json";
import {
  faInfo,
  faList,
  faCheck,
  faMapMarkerAlt,
  faImage,
  faClock,
  faExclamation,
  faUser,
  faConciergeBell,
  faPencil,
  faTimes as faTimes2,
  faPlus,
} from "@fortawesome/pro-light-svg-icons";
import {
  faImage as faImage2,
  faTimes,
} from "@fortawesome/pro-regular-svg-icons";
import { faPen, faChild, faPlusCircle } from "@fortawesome/pro-solid-svg-icons";
import WhatsonNavigation from "../WhatsonNavigation";
import enFlag from "../../../../../src/assets/images/en.png";
import foFlag from "../../../../../src/assets/images/fo.png";
import "./NewPlace.scss";
import VfiInputText from "../../../../assets/VfiInputText/VfiInputText";
import VfiTextarea from "../../../../assets/VfiTextarea/VfiTextarea";
// import eventGroups from "../../../../assets/eventGroups.json";
import { VfiCheckbox2 as VfiCheckbox } from "../../../../assets/VfiCheckbox";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import OpeningHours from "../../../../assets/OpeningHours/OpeningHours";
import Switch from "react-switch";
import GoogleMapSearchBox from "../../../../assets/GoogleMapSearchBox";
import SimpleMap from "../../../../assets/SimpleMap";
import zipCodes from "../../../../assets/zipCodes.json";
// import Select from "react-select";
import Select from "../../../../assets/VfiCustomSelect";
import Verify from "../../../../assets/VerifyMethods";
import {
  allObjTrue,
  slugify,
  countryCodeReturn,
} from "../../../../assets/helperFunctions";
import loadash, { cloneDeep } from "lodash";
import language from "../language.json";
import globalObject from "../../../../assets/globalVariables";
import countryCodes from "../../../../assets/countryCodes.json";
import Goose from "../../../../assets/images/Goose";
import { faInstagram } from "@fortawesome/free-brands-svg-icons";
import InstagramInWhatson from "../../../../assets/InstagramInWhatson";
import {
  categoriesPassed,
  descriptionPassed,
  openingHoursPassed,
  permissionPassed,
  imagesPassed,
  placePassed,
  contactDetailsPassed,
  amenitiesPassed,
} from "./PlaceVerify";
import Amenities from "./Amenities";

function pushMedia(e) {
  return {
    id: e.id,
    name: e.headline,
    description: e.description,
    fileName: e.name,
    author: e.author_info,
    locations: e.sites,
    fileSize: e.file_size,
    fileExtension: e.file_extension,
    fileDimensions: e.file_dimensions_x + " x " + e.file_dimensions_y + " px",
    date: e.fo_upload_date,
    selected: false,
    sortedBy: "",
    alt: e.alt,
    file_name: e.file_name,
    hearted: e.hearted,
    addMediaDroppedDown: false,
  };
}

class NewPlace extends Component {
  constructor(props) {
    super(props);

    this.state = {
      uploadedFile: undefined,
      extraVideoLink: [],
      mapMarker: { lat: 0, lng: 0 },
      mapZoom: 8,
      contactDetails: {
        name: "",
        socialLink: "",
        website: "",
        email: "",
        phoneNumber: "",
        countryCode: 298,
      },
      beenEdit: {
        textFo: {
          headline: false,
          description: false,
          shortDescription: false,
        },
        textEn: {
          headline: false,
          description: false,
          shortDescription: false,
        },
        categories: false,
        amenities: false,
        openingHours: false,
        placeRestrictions: { minAge: false, capacity: false },
        images: { mainImages: false },
        urls: { extraVideoLinks: false },
        address: false,
        contactDetails: {
          name: false,
          socialLink: false,
          website: false,
          email: false,
          phoneNumber: false,
          countryCode: false,
        },
        instagram: false,
      },
      includeMap: true,
    };

    // this.dateChange = this.dateChange.bind(this);
    // this.priceChange = this.priceChange.bind(this);
    this.uploadFile = this.uploadFile.bind(this);
    this.onMarkerChange = this.onMarkerChange.bind(this);
    this.onAddressChange = this.onAddressChange.bind(this);
    this.defaultDragStart = this.defaultDragStart.bind(this);

    this.headerRef = React.createRef();

    this.axios = require("axios");
    this.user = { firstname: "", lastname: "" };

    this.zipCodes = [];
    for (let element in zipCodes) {
      this.zipCodes.push({
        value: zipCodes[element].code,
        label: zipCodes[element].code + " " + zipCodes[element].city,
      });
    }

    this.submitDescription =
      language[this.props.language].suggest_edit_description;
    this.language = language[this.props.language].places.new;

    this.protocolAndHost = env.protocol + env.env;

    this.submit = [];
    this.changes = {
      id: "",
      eventId: "",
      title: [],
      currentOrSuggested: [],
      newValues: [],
      categories: [],
      categoriesEdited: [],
      amenities: [],
      amenitiesEdited: [],
    };

    this.newPlace = {
      textFo: {
        showIt: true,
        headline: "",
        shortDescription: "",
        description: "",
        urlSlug: "",
      },
      textEn: {
        showIt: true,
        headline: "",
        shortDescription: "",
        description: "",
        urlSlug: "",
      },
      categories: [],
      amenities: [],
      openingHours: [],
      placeRestrictions: {},
      images: { mainImages: [] },
      urls: { extraVideoLinks: [] },
      address: {
        mapMarker: { lat: 62.0107, lng: -6.7741 },
        includeMap: true,
      },
      contactDetails: {
        countryCode: 298,
      },
      instagram: {
        active: true,
        profile: "",
        hashtag: "",
        images: [],
      },
    };

    if (props.updateData !== undefined) {
      const data = this.props.updateData;

      this.setState({ beenEdit: allObjTrue(this.state.beenEdit) });

      // If not front then we want to check for updates
      if (
        !props.submitChange &&
        !props.isFront &&
        props.updateData.submittedChanges
      ) {
        let newValues = props.updateData.submittedChanges.newValues.replace(
          /\n/g,
          "\\n"
        ); //Somehow it cannot read the JSON object correct and we make it work here

        this.changes = {
          id: props.updateData.submittedChanges.id,
          placeId: props.updateData.submittedChanges.placeId,
          title: JSON.parse(props.updateData.submittedChanges.title),
          titleEdited: JSON.parse(props.updateData.submittedChanges.title),
          newValues: JSON.parse(newValues),
          categories: [],
          categoriesEdited: [],
          amenities: [],
          amenitiesEdited: [],
          currentOrSuggested: [],
        };

        this.changes.currentOrSuggested = Array(this.changes.title.length).fill(
          ""
        );

        //Only get unique categories
        this.changes.title.forEach((x) => {
          var cate = x.includes(".") ? x.split(".")[0] : x;

          if (this.changes.categories.indexOf(cate) === -1) {
            this.changes.categories.push(cate);
            this.changes.categoriesEdited.push(cate);
          }
        });
      }

      let textEn = {};
      let textFo = {};

      if (data.text) {
        Object.entries(data.text).forEach((entry) => {
          const [key, value] = entry;
          if (key === "english") {
            textEn = {
              headline: value.headline,
              shortDescription: value.excerpt,
              description: value.description,
              urlSlug: value.url_slug,
              showIt: value.show_it === 1 ? true : false,
            };
          }
          if (key === "faroese") {
            textFo = {
              headline: value.headline,
              shortDescription: value.excerpt,
              description: value.description,
              urlSlug: value.url_slug,
              showIt: value.show_it === 1 ? true : false,
            };
          }
        });
      }

      let openingHours = [];

      if (data.opening_hours)
        data.opening_hours.forEach((element) => {
          openingHours.push({
            day: element.day,
            from: {
              hour: {
                label: element.from_time.split(":")[0],
                value: element.from_time.split(":")[0],
              },
              minutes: {
                label: element.from_time.split(":")[1],
                value: element.from_time.split(":")[1],
              },
            },
            to: {
              hour: {
                label: element.to_time.split(":")[0],
                value: element.to_time.split(":")[0],
              },
              minutes: {
                label: element.to_time.split(":")[1],
                value: element.to_time.split(":")[1],
              },
            },
            closed: element.is_closed === 1 ? true : false,
          });
        });

      let mainImages = [];

      if (typeof data.images === "object")
        Object.entries(data.images).forEach((entry) => {
          const [key, value] = entry;

          let imgKey = 0;
          if (key === "main") {
            value.forEach((e) => {
              mainImages.push({
                key: imgKey,
                id: e.id_media,
                file_extension: e.file_extension,
                alt: e.alt,
                file_name: e.file_name,
                enText: e.english ? e.english.text : "",
                foText: e.faroese ? e.faroese.text : "",
              });
              imgKey++;
            });
          }
        });

      let extraVideoLinks = [];
      const videoLinks = data.video_links;
      if (videoLinks)
        videoLinks.forEach((element, i) => {
          extraVideoLinks.push({ key: i + 1, url: element.video_link });
        });

      const name = data.data.address_name;
      const pin = {
        lat: parseFloat(data.data.lat_map),
        lng: parseFloat(data.data.lng_map),
      };
      const mapMarker = pin;
      const mapCenter = pin;
      const includeMap = data.data.address_include_map === 1 ? true : false;
      const mapZoom = 15;
      const showMarker = true;
      const region = {
        label: data.data.region_name,
        value: data.data.region_id,
      };
      const zipCode = data.data.address_zip;

      const address = {
        name,
        mapMarker,
        mapCenter,
        includeMap,
        mapZoom,
        showMarker,
        region,
        zipCode,
      };

      const c_name = data.data.contact_name;
      const socialLink = data.data.contact_social;
      const website = data.data.contact_website;
      const email = data.data.contact_email;
      const phoneNumber = data.data.contact_phone;
      const countryCode = data.data.contact_zip;

      const contactDetails = {
        name: c_name,
        socialLink,
        website,
        email,
        phoneNumber,
        countryCode,
      };

      const categories = [];
      data.categories.forEach((e) => {
        categories.push({ id: e.id_category });
      });

      // Init empty amenities
      const amenities = [];
      // For each selected amenity for place (if any)
      data.amenities.forEach((amenity) => {
        // Push selected amenities
        amenities.push({ id: amenity.amenityID });
      });

      let instagram = {
        active: data.data.instagram_active ? true : false,
        hashtag: data.data.instagram_hashtag,
        profile: data.data.instagram_profile,
        // images: [],
        images: data.instagram.map((element, i) => {
          return {
            instaLink: element.url,
            media:
              element.id_media !== null
                ? {
                    id: element.id_media,
                    alt: element.alt,
                    file_name: element.file_name,
                    extension: element.extension,
                  }
                : {},
            key: i,
          };
        }),
      };

      this.newPlace = {
        id: data.data.id,
        textFo,
        textEn,
        categories,
        amenities,
        openingHours,
        placeRestrictions: {
          minAge: data.data.min_age,
          capacity: data.data.capacity,
        },
        images: {
          mainImages,
        },
        urls: { extraVideoLinks },
        address,
        contactDetails,
        instagram,
      };
    }
    this.newPlaceSaved = cloneDeep(this.newPlace);
  }

  componentDidMount() {
    const vfiUser = sessionStorage.getItem("vfiUser");
    this.axios
      .post(env.protocol + env.env + "/api/GetUserInfo", {
        vfiUser,
      })
      .then((response) => {
        this.user = response.data;
      })
      .catch((error) => console.error(error));

    if (this.props.updateData !== undefined) {
      const data = this.props.updateData;
      let uploadedFile = undefined;

      if (data.data.main_image !== "0") {
        uploadedFile = {
          id: data.data.main_image,
          fileExtension: data.data.file_extension,
          file_name: data.data.file_name,
        };
      }

      this.setState({ uploadedFile });
    }

    this.regions = [];
    this.axios
      .get(env.protocol + env.env + "/api/public/GetRegions.php")
      .then((response) => {
        const regions = response.data;
        regions.forEach((element) => {
          this.regions.push({
            label: element.region_name,
            value: element.region_id,
          });
        });
      })
      .catch((error) => {
        console.error(error);
      });

    this.categories = [];
    this.axios
      .get(env.protocol + env.env + "/api/whatson/places/GetPlaceCategories")
      .then((response) => {
        this.categories = response.data;
      })
      .catch((error) => console.error(error));

    this.amenities = [];
    this.axios
      .get(env.protocol + env.env + "/api/whatson/places/GetPlaceAmenities")
      .then((response) => {
        this.amenities = response.data;
      })
      .catch((error) => console.error(error));

    if (!this.props.isFront) {
      this.saveInterval = setInterval(() => {
        if (!loadash.isEqual(this.newPlaceSaved, this.newPlace)) {
          // Do the auto-save
          const vfiUser = sessionStorage.getItem("vfiUser");

          let link;
          let isNewPlace;

          if (this.newPlace.id !== undefined) {
            link = env.protocol + env.env + "/api/whatson/places/UpdatePlace";
            isNewPlace = false;
          } else {
            link = env.protocol + env.env + "/api/whatson/places/AddPlace";
            isNewPlace = true;
          }
          this.axios
            .post(link, {
              ...this.newPlace,
              currentStatus: "draft",
              vfiUser,
            })
            .then((response) => {
              let newValue = response.data[0];

              this.props.autoSave(newValue, isNewPlace);

              this.newPlace.id = newValue.id;
              this.newPlaceSaved = loadash.cloneDeep(this.newPlace);
            })
            .catch((error) => console.error(error));
        }
      }, 30000);
    }
  }

  componentWillUnmount() {
    clearInterval(this.saveInterval);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.addOne !== this.props.addOne) {
      const media = this.props.addOne;
      if (media.fileExtension === undefined)
        media.fileExtension = media.file_extension;

      let maxKey = 0;
      switch (this.mediaIs) {
        case "mainImage":
          this.newPlace.images.mainImages.forEach((element) => {
            if (element.key > maxKey) maxKey = element.key;
          });
          media.forEach((m) => {
            this.newPlace.images.mainImages.push({
              key: maxKey + 1,
              id: m.id,
              file_extension: m.fileExtension,
              alt: m.alt,
              file_name: m.file_name,
            });
          });
          this.setState({
            beenEdit: {
              ...this.state.beenEdit,
              images: { ...this.state.beenEdit.images, mainImages: true },
            },
          });
          break;
        case "instagram":
          this.newPlace.instagram.images[this.instaIndex].media = {
            ...media,
            extension: media.fileExtension,
          };
          break;
        default:
          break;
      }
      this.setState({});
    }

    const headerElement = this.headerRef.current;
    const index = this.props.index;
    if (headerElement && index !== undefined) {
      headerElement.scroll({
        left: 73 * index + 51.5 - headerElement.parentNode.scrollWidth / 2,
        behavior: "smooth",
      });
    }
  }

  addContent(destination, value) {
    if (
      this.changes.currentOrSuggested[
        this.changes.title.indexOf(destination)
      ] === "suggested"
    ) {
      //If suggested changes
      this.changes.newValues[this.changes.title.indexOf(destination)] = value;
    } else {
      if (typeof destination === "string") destination = destination.split(".");

      destination.reverse().forEach((element, i) => {
        value = { [element]: value };
      });

      //IF!!!!

      loadash.merge(this.newPlace, value);
    }
  }

  uploadFile(e) {
    this.setState({ uploadFiles: e });

    var formData = new FormData();

    e.forEach((element) => {
      formData.append("fileToUpload[]", element, element.name);
    });
    formData.append("siteId", "0");
    formData.append("userId", sessionStorage.getItem("vfiUser"));

    this.axios
      .post(
        env.protocol + env.env + "/api/UploadMediaFile",
        formData,
        this.config
      )
      .then((res) => {
        this.setState({ uploadedFile: pushMedia(res.data[0]) });
        this.newPlace.images.mainImage = res.data[0];
      })
      .catch(function (err) {
        console.error(err);
      });
  }

  saveFile = (e) => {
    //Saving images and adding them to mainimage so they can be shown. But not uploaded.
    //Goyma hana sum temp fíl
    let maxKey = 0;
    this.newPlace.images.mainImages.forEach((element) => {
      if (element.key > maxKey) maxKey = element.key;
    });

    var images = this.newPlace.images.mainImages;
    e.forEach((element, i) => {
      const reader = new FileReader();
      reader.addEventListener(
        "load",
        () => {
          images.push({
            key: maxKey + i + 1,
            elm: reader.result,
            element: element,
            name: element.name,
          });
        },
        false
      );
      reader.readAsDataURL(element);
      reader.addEventListener("loadend", () => this.setState({}));
    });
    this.newPlace.images.mainImages = images;
    this.setState({ addMediaDroppedDown: false });
  };

  onMarkerChange(e) {
    const lat = e.latLng.lat();
    const lng = e.latLng.lng();
    this.regionAndZipPopulate(e.geocode);
    this.newPlace.address.name = e.geocode.results[0]
      ? e.geocode.results[0].formatted_address
      : this.newHiking.address.name;

    this.newPlace.address = {
      ...this.newPlace.address,
      showMarker: true,
      mapMarker: { lat: lat, lng: lng },
      mapCenter: { lat: lat, lng: lng },
    };
    this.setState({ showMarker: true, mapMarker: { lat: lat, lng: lng } });
  }

  onAddressChange(data) {
    const lat = data.geometry.location.lat(data.formatted_address);
    const lng = data.geometry.location.lng(data.formatted_address);

    if (data.address_components)
      this.regionAndZipPopulate({
        results: [{ address_components: data.address_components }],
      });

    this.newPlace.address = {
      ...this.newPlace.address,
      name: data.formatted_address,
      mapCenter: { lat: lat, lng: lng },
      mapZoom: 15,
      showMarker: true,
      mapMarker: { lat: lat, lng: lng },
    };

    this.setState({
      address: data.formatted_address,
      mapCenter: { lat: lat, lng: lng },
      mapZoom: 15,
      showMarker: true,
      mapMarker: { lat: lat, lng: lng },
      beenEdit: { ...this.state.beenEdit, address: true },
    });
  }

  regionAndZipPopulate(data) {
    var zip = -1;
    var region = -1;
    const results = data.results;

    for (var i = 0; i < results.length; i++) {
      //found the address
      var result = results[i];

      for (var j = 0; j < result.address_components.length; j++) {
        var component = result.address_components[j];
        if (
          component.types.filter(function (x) {
            return x === "route";
          }).length > 0
        ) {
        } else if (
          component.types.filter(function (x) {
            return x === "street_number";
          }).length > 0
        ) {
        } else if (
          component.types.filter(function (x) {
            return x === "postal_code";
          }).length > 0
        ) {
          zip = component.long_name;
        } else if (
          component.types.filter(function (x) {
            return x === "administrative_area_level_1";
          }).length > 0
        ) {
          region = component.long_name;
        }
      }
      break;
    }

    if (region === "Northern Isles") {
      region = "Norðoyggjar";
    }
    if (region === "Suduroy") {
      region = "Suðuroy";
    }

    this.regions.forEach((element) => {
      if (element.label === region) {
        this.newPlace.address.region = this.regions.find(
          (e) => e.label === region
        );
      }
    });

    Object.keys(zipCodes).forEach((k, v) => {
      if (zipCodes[k].code.toString() === zip) {
        const zipLabel = zip + " " + zipCodes[k].city;
        this.newPlace.address.zipCode = { label: zipLabel, value: zip };
      }
    });
  }

  config = {
    headers: {
      "Content-Type": "multipart/form-data",
    },
    onUploadProgress: (progressEvent) => {
      var percentCompleted = Math.floor(
        (progressEvent.loaded * 100) / progressEvent.total
      );
      this.props.blurScreen(true, percentCompleted);
    },
  };

  uploadFileAndPlace = () => {
    //Gera myndirnar klárar
    var img = this.newPlace.images.mainImages;
    if (img.length > 0) {
      var formData = new FormData();
      img.forEach((e) => {
        formData.append("fileToUpload[]", e.element, e.name);
      });
      formData.append("siteId", "0");
      formData.append("userId", sessionStorage.getItem("vfiUser"));

      //Uploada media
      this.axios
        .post(
          env.protocol + env.env + "/api/UploadMediaFile",
          formData,
          this.config
        )
        .then((res) => {
          let newImages = [];
          //Goyma id og annað í event áðrenn event verður skrásett
          var images = this.newPlace.images.mainImages;
          images.forEach((element, i) => {
            const elm = {
              ...element,
              elm: undefined,
              key: element.key,
              id: res.data[i].id,
              file_extension: res.data[i].file_extension,
              file_name: res.data[i].file_name,
            };
            newImages.push(elm);
          });
          this.newPlace.images.mainImages = newImages;

          //Stovna event
          let edited = JSON.parse(JSON.stringify(this.state.beenEdit));
          edited = allObjTrue(edited);

          this.setState({ beenEdit: edited }, () => {
            if (this.descriptionPassed) {
              this.publish(
                false,
                env.protocol + env.env + "/api/whatson/places/AddPlace"
              );
            }
          });
        })
        .catch(function (err) {
          console.error(err);
        });
    } else {
      //Stovna event beinanvegin tá ongar myndir eru
      let edited = JSON.parse(JSON.stringify(this.state.beenEdit));
      edited = allObjTrue(edited);

      this.setState({ beenEdit: edited }, () => {
        if (this.descriptionPassed) {
          this.publish(
            false,
            env.protocol + env.env + "/api/whatson/places/AddPlace"
          );
        }
      });
    }
  };

  uploadFileandSubmit = () => {
    var mainImages = this.newPlace.images.mainImages;
    var img = mainImages.filter((img) => img.elm !== undefined);
    var newImages = mainImages.filter((img) => img.elm === undefined);

    var newInstaImages = this.newPlace.instagram.images
      .map((e, i) => {
        return { ...e, actualIndex: i };
      })
      .filter((e) => e.media.newFile);

    this.props.blurScreen(true);
    if ((img.length > 0 || newInstaImages.length > 0) && this.allPassed) {
      const sendImages = (formData, afterFunc = (res) => void 0) => {
        this.axios
          .post(
            env.protocol + env.env + "/api/UploadMediaFile",
            formData,
            this.config
          )
          .then((response) => {
            //Goyma id og annað í event áðrenn event verður skrásett
            afterFunc(response);
          })
          .catch(function (err) {
            console.error(err);
          });
      };

      //Uploada media
      const mainImagesSend = new Promise((resolve) => {
        if (img.length > 0) {
          var formData = new FormData();
          img.forEach((e) => {
            formData.append("fileToUpload[]", e.element, e.name);
          });
          formData.append("siteId", "0");
          formData.append("userId", sessionStorage.getItem("vfiUser"));
          sendImages(formData, (response) => {
            img.forEach((element, i) => {
              const elm = {
                ...element,
                elm: undefined,
                key: element.key,
                id: response.data[i].id,
                alt: response.data[i].alt,
                file_name: response.data[i].file_name,
                file_extension: response.data[i].file_extension,
              };
              newImages.push(elm);
            });
            this.newPlace.images.mainImages = newImages;
            resolve(true);
          });
        } else {
          resolve(true);
        }
      });

      const instagramImagesSend = new Promise((resolve) => {
        if (newInstaImages.length > 0) {
          var formDataInsta = new FormData();
          newInstaImages.forEach((e) => {
            formDataInsta.append(
              "fileToUpload[]",
              e.media.newFile,
              e.media.newFile.name
            );
          });
          formDataInsta.append("siteId", "0");
          formDataInsta.append("userId", sessionStorage.getItem("vfiUser"));

          sendImages(formDataInsta, (response) => {
            newInstaImages.forEach((e, i) => {
              this.newPlace.instagram.images[e.actualIndex].media.id =
                response.data[i].id;
              this.newPlace.instagram.images[e.actualIndex].media.file_name =
                response.data[i].file_name;
              this.newPlace.instagram.images[e.actualIndex].media.extension =
                response.data[i].file_extension;
            });
            resolve(true);
          });
        } else {
          resolve(true);
        }
      });

      Promise.all([mainImagesSend, instagramImagesSend]).then((values) => {
        this.submitChange();
        this.props.blurScreen(false);
      });
    } else {
      this.submitChange();
    }
  };

  uniqueSlug(slug, assignDestination, localSlugs, language) {
    this.axios
      .post(env.protocol + env.env + "/api/whatson/places/UniqueSlug", {
        slug,
        localSlugs,
        placeId: this.newPlace.id,
        language,
      })
      .then((response) => {
        this.addContent(assignDestination, response.data);

        this.setState({});
      })
      .catch((error) => {
        console.error(error);
      });
  }

  defaultDragStart(e) {
    this.setState({ imageDragging: true });
  }
  defaultDragStop(e) {
    this.setState({ imageDragging: false });
  }

  addOrUpdatePlace = (isDraft = false) => {
    if (this.props.isFront) {
      if (this.props.submitChange === "true") {
        this.uploadFileandSubmit();
      } else {
        this.uploadFileAndPlace();
      }
    } else {
      if (this.changes.title.length > 0) {
        //If there are submitted changes, we want to merge them with this.newPlace

        if (this.changes.categoriesEdited.length === 0) {
          //All submitted changes have been processed or no submitted changes have been made
          for (var i = 0; i < this.changes.currentOrSuggested.length; i++) {
            if (this.changes.currentOrSuggested[i] === "suggested") {
              if (this.changes.title[i].includes(".")) {
                //E.g. textEn.headline
                let path = this.changes.title[i].split(".");
                this.newPlace[path[0]][path[1]] = this.changes.newValues[i];
              } else {
                this.newPlace[this.changes.title[i]] =
                  this.changes.newValues[i];
              }
            }
          }

          this.setState({});
        } else {
          console.error("ERROR, not all changes have been processed");
        }
      }
      if (this.changes.categoriesEdited.length === 0) {
        //If normal update or if all changes have been processed
        let link = "";
        if (this.newPlace.id !== undefined) {
          link = env.protocol + env.env + "/api/whatson/places/UpdatePlace";
        } else {
          link = env.protocol + env.env + "/api/whatson/places/AddPlace";
        }
        if (isDraft) {
          this.publish(true, link);
        } else {
          let edited = Object.assign({}, this.state.beenEdit);
          edited = allObjTrue(edited);

          this.setState({ beenEdit: edited }, () => {
            if (this.allPassed) {
              this.publish(false, link);
            }
          });
        }
      }
    }
  };

  publish = (isDraft, link) => {
    this.props.blurScreen(true);
    const vfiUser = sessionStorage.getItem("vfiUser");
    if (this.props.isFront) {
      this.newPlace.address.currentStatus = "published";
    }
    clearInterval(this.saveInterval);
    this.axios
      .post(link, {
        ...this.newPlace,
        currentStatus: isDraft ? "draft" : "published",
        vfiUser,
      })
      .then((response) => {
        let newValue = response.data[0];

        if (this.changes.title.length > 0) {
          //If there are submitted changes, we want to merge them with this.newEvent
          //Then we want to remove the submitted changes
          this.axios
            .post(env.protocol + env.env + "/api/deleteChanges", {
              id: this.newPlace.id,
              type: "place",
            })
            .then((response) => {
              newValue.submittedChangeId = null;
              this.props.updateOneAndGoToList(
                //Update list after we have deleted the changes
                this.newPlace.id,
                newValue
              );
            });
        } else {
          // Eru vit í backend skulu vit pusha til listan
          if (!this.props.isFront) {
            if (this.props.updateData !== undefined) {
              this.props.updateOneAndGoToList(
                //this.props.updateData.data.id,
                this.newPlace.id,
                newValue,
                isDraft
              );
            } else {
              this.props.pushOneAndGoToList(newValue, isDraft);
            }

            this.props.blurScreen(false);
            this.props.indexChange(1);
          } else {
            this.setState({ frontFinnished: true });
          }
        }
      })
      .catch((error) => console.error(error));
  };

  submitChange = () => {
    this.props.blurScreen(true);
    if (this.descriptionPassed) {
      this.axios
        .post(env.protocol + env.env + "/api/submitChanges", {
          type: "place",
          data: this.newPlace,
          changes: this.submit,
          userId: this.props.userFront,
        })
        .then((res) => {
          this.props.blurScreen(false);
          this.props.indexChange(1);
        })
        .catch(function (err) {
          console.error(err);
        });
    }
  };

  updateEditedFields = (title) => {
    if (this.changes.titleEdited.indexOf(title) > -1) {
      this.changes.titleEdited.splice(
        this.changes.titleEdited.indexOf(title),
        1
      );

      this.changes.categoriesEdited = this.changes.titleEdited.map((x) => {
        if (x.includes(".")) {
          return x.split(".")[0];
        } else {
          return x;
        }
      });
    }
  };

  onFrontInstaImageChange = (event) => {
    if (event.target.files && event.target.files[0]) {
      this.newPlace.instagram.images[this.instaIndex] = {
        ...this.newPlace.instagram.images[this.instaIndex],
        media: {
          src: URL.createObjectURL(event.target.files[0]),
          newFile: event.target.files[0],
        },
      };
      this.setState({ image: URL.createObjectURL(event.target.files[0]) });
    }
  };

  render() {
    const showEn = this.newPlace.textEn.showIt;
    const showFo = this.newPlace.textFo.showIt;
    const uploadHidden = this.state.addMediaDroppedDown ? "" : "hidden";
    let changes = this.changes;
    let submitChange = this.props.submitChange === "true";
    let placeSuggested =
      changes.currentOrSuggested[changes.title.indexOf("address")] ===
      "suggested"
        ? true
        : false;
    let contactSuggested =
      changes.currentOrSuggested[changes.title.indexOf("contactDetails")] ===
      "suggested"
        ? true
        : false;
    const beenEdit = this.state.beenEdit;

    let content = "";
    if (this.state.frontFinnished) {
      //Submitta á frontend. So kemur tillukku tekstur framm
      content = (
        <div className="finnished">
          <h1>{this.language.register.header_register}</h1>
          <div className="new-edit">
            <div className="final-text">
              <FontAwesomeIcon icon={faChild} className="wooh" />
              <p>
                {this.user.firstname.toUpperCase() +
                  " " +
                  this.user.lastname.toUpperCase()}
                , {this.language.register.final_text_registered}
              </p>
            </div>
          </div>{" "}
        </div>
      );
    } else {
      switch (this.props.index) {
        case 0:
          content = (
            <div className="new-content one">
              <WhatsonNavigation
                language={this.props.language}
                indexChange={this.props.indexChange}
                isFront={this.props.isFront}
                undoAndRedoDisabled={true}
                forwardDisabled={false}
                backwardDisabled={true}
                saveDraft={this.addOrUpdatePlace}
              />
              <h1>{this.language.info.header}</h1>
              <p>{this.language.info.sub_text}</p>
              {this.props.isFront && this.props.submitChange === "true" ? (
                <i>
                  <p
                    dangerouslySetInnerHTML={{ __html: this.submitDescription }}
                  />
                </i>
              ) : (
                ""
              )}

              <div className="fill-form row">
                <div
                  className={
                    "col-lg-6 language-box english" + (showEn ? " active" : "")
                  }
                >
                  {this.changes.title.includes("textEn.showIt") ? (
                    <div className="btn_current_submitted">
                      <span
                        className={
                          changes.currentOrSuggested[
                            changes.title.indexOf("textEn.showIt")
                          ] === "current"
                            ? "choosen btn"
                            : "btn"
                        }
                        onClick={() => {
                          changes.currentOrSuggested[
                            changes.title.indexOf("textEn.showIt")
                          ] = "current";
                          this.updateEditedFields("textEn.showIt");
                          this.setState({});
                        }}
                      >
                        Current
                      </span>
                      <span
                        className={
                          changes.currentOrSuggested[
                            changes.title.indexOf("textEn.showIt")
                          ] === "suggested"
                            ? "choosen btn"
                            : "btn"
                        }
                        onClick={() => {
                          changes.currentOrSuggested[
                            changes.title.indexOf("textEn.showIt")
                          ] = "suggested";
                          this.updateEditedFields("textEn.showIt");
                          this.setState({});
                        }}
                      >
                        Suggested changes
                      </span>
                    </div>
                  ) : (
                    ""
                  )}
                  <img className="flag" src={enFlag} alt="en-flag" />
                  <VfiCheckbox
                    className="l-include"
                    checked={showEn}
                    onChange={(e) => {
                      this.newPlace.textEn.showIt = e.target.checked;
                      this.setState({});
                    }}
                  />
                  <div className="headline row">
                    {submitChange ? (
                      <div
                        className={
                          "enable_editing " +
                          (this.submit.includes("textEn.headline") && "enabled")
                        }
                        onClick={(e) => {
                          if (
                            submitChange &&
                            !this.submit.includes("textEn.headline")
                          ) {
                            this.submit.push("textEn.headline");
                            this.submit.push("textEn.urlSlug");
                          }
                          this.setState({});
                        }}
                      >
                        Enable editing
                        <FontAwesomeIcon icon={faPencil} />
                      </div>
                    ) : (
                      ""
                    )}
                    {this.changes.title.includes("textEn.headline") ? (
                      <div className="btn_current_submitted">
                        <span
                          className={
                            changes.currentOrSuggested[
                              changes.title.indexOf("textEn.headline")
                            ] === "current"
                              ? "choosen btn"
                              : "btn"
                          }
                          onClick={() => {
                            changes.currentOrSuggested[
                              changes.title.indexOf("textEn.headline")
                            ] = "current";
                            changes.currentOrSuggested[
                              changes.title.indexOf("textEn.urlSlug")
                            ] = "current";
                            this.updateEditedFields("textEn.headline");
                            this.updateEditedFields("textEn.urlSlug");
                            this.setState({});
                          }}
                        >
                          Current
                        </span>
                        <span
                          className={
                            changes.currentOrSuggested[
                              changes.title.indexOf("textEn.headline")
                            ] === "suggested"
                              ? "choosen btn"
                              : "btn"
                          }
                          onClick={() => {
                            changes.currentOrSuggested[
                              changes.title.indexOf("textEn.headline")
                            ] = "suggested";
                            changes.currentOrSuggested[
                              changes.title.indexOf("textEn.urlSlug")
                            ] = "suggested";
                            this.updateEditedFields("textEn.headline");
                            this.updateEditedFields("textEn.urlSlug");
                            this.setState({});
                          }}
                        >
                          Suggested changes
                        </span>
                      </div>
                    ) : (
                      ""
                    )}
                    <div className="text col-xl-3">
                      {this.language.info.headline_en}
                    </div>
                    {changes.currentOrSuggested[
                      changes.title.indexOf("textEn.headline")
                    ] === "suggested" ? (
                      <VfiInputText
                        className={"col-xl-9"}
                        value={
                          changes.newValues[
                            changes.title.indexOf("textEn.headline")
                          ]
                        }
                        onChange={(e) => {
                          changes.newValues[
                            changes.title.indexOf("textEn.headline")
                          ] = e.target.value;
                          changes.newValues[
                            changes.title.indexOf("textEn.urlSlug")
                          ] = slugify(e.target.value);
                          this.setState({
                            beenEdit: {
                              ...this.state.beenEdit,
                              textEn: {
                                ...this.state.beenEdit.textEn,
                                headline: true,
                              },
                            },
                          });
                        }}
                        placeholder={this.language.info.headline_en}
                        errorCheckSequence={[
                          Verify.notEmpty,
                          Verify.least3Char,
                        ]}
                        hideError={
                          !this.state.beenEdit.textEn.headline || !showEn
                        }
                        disabled={
                          submitChange
                            ? !this.submit.includes("textEn.headline") ||
                              !showEn
                            : !showEn
                        }
                        onBlur={(e) => {
                          this.uniqueSlug(
                            slugify(e.target.value),
                            "textEn.urlSlug",
                            [
                              changes.newValues[
                                changes.title.indexOf("textEn.urlSlug")
                              ],
                            ],
                            "english"
                          );
                        }}
                      />
                    ) : (
                      <VfiInputText
                        className="col-xl-9"
                        onChange={(e) => {
                          this.newPlace.textEn.headline = e.target.value;
                          this.newPlace.textEn.urlSlug = slugify(
                            e.target.value
                          );
                          this.setState({
                            beenEdit: {
                              ...this.state.beenEdit,
                              textEn: {
                                ...this.state.beenEdit.textEn,
                                headline: true,
                              },
                            },
                          });
                        }}
                        value={this.newPlace.textEn.headline}
                        placeholder={this.language.info.headline_en}
                        errorCheckSequence={[
                          Verify.notEmpty,
                          Verify.least3Char,
                        ]}
                        hideError={
                          !this.state.beenEdit.textEn.headline || !showEn
                        }
                        disabled={
                          submitChange
                            ? !this.submit.includes("textEn.headline") ||
                              !showEn
                            : !showEn
                        }
                        onBlur={(e) => {
                          this.uniqueSlug(
                            slugify(e.target.value),
                            "textEn.urlSlug",
                            [this.newPlace.textEn.urlSlug],
                            "english"
                          );
                        }}
                      />
                    )}
                  </div>
                  {/* <div className="slug-url-wrap row">
                    <p className="col-xl-3">{this.language.info.slug_url_en}</p>
                    <VfiInputText
                      className="col-xl-9"
                      value={changes.currentOrSuggested[changes.title.indexOf("textEn.headline")] === "suggested" ? changes.newValues[changes.title.indexOf("textEn.urlSlug")] : this.newPlace.textEn.urlSlug}
                      onChange={(e) => {
                        if (changes.currentOrSuggested[changes.title.indexOf("textEn.headline")] === "suggested") {
                          changes.newValues[changes.title.indexOf("textEn.urlSlug")] = e.target.value;
                        } else {
                          this.newPlace.textEn.urlSlug = e.target.value;
                        }
                        this.setState({});
                      }}
                      inputType="slug"
                      disabled={true}
                    />
                  </div> */}
                  <div className="small-description row">
                    {submitChange ? (
                      <div
                        className={
                          "enable_editing " +
                          (this.submit.includes("textEn.shortDescription") &&
                            "enabled")
                        }
                        onClick={(e) => {
                          if (
                            !this.submit.includes("textEn.shortDescription")
                          ) {
                            this.submit.push("textEn.shortDescription");
                          }
                          this.setState({});
                        }}
                      >
                        Enable editing
                        <FontAwesomeIcon icon={faPencil} />
                      </div>
                    ) : (
                      ""
                    )}
                    {this.changes.title.includes("textEn.shortDescription") ? (
                      <div className="btn_current_submitted">
                        <span
                          className={
                            changes.currentOrSuggested[
                              changes.title.indexOf("textEn.shortDescription")
                            ] === "current"
                              ? "choosen btn"
                              : "btn"
                          }
                          onClick={() => {
                            changes.currentOrSuggested[
                              changes.title.indexOf("textEn.shortDescription")
                            ] = "current";

                            this.updateEditedFields("textEn.shortDescription");
                            this.setState({});
                          }}
                        >
                          Current
                        </span>
                        <span
                          className={
                            changes.currentOrSuggested[
                              changes.title.indexOf("textEn.shortDescription")
                            ] === "suggested"
                              ? "choosen btn"
                              : "btn"
                          }
                          onClick={() => {
                            changes.currentOrSuggested[
                              changes.title.indexOf("textEn.shortDescription")
                            ] = "suggested";

                            this.updateEditedFields("textEn.shortDescription");
                            this.setState({});
                          }}
                        >
                          Suggested changes
                        </span>
                      </div>
                    ) : (
                      ""
                    )}
                    <div className="text col-xl-3">
                      {this.language.info.excerpt_en}
                    </div>
                    {changes.currentOrSuggested[
                      changes.title.indexOf("textEn.shortDescription")
                    ] === "suggested" ? (
                      <VfiTextarea
                        className={"col-xl-9"}
                        placeholder={this.language.info.excerpt_en}
                        onChange={(e) => {
                          changes.newValues[
                            changes.title.indexOf("textEn.shortDescription")
                          ] = e;
                          this.setState({
                            beenEdit: {
                              ...this.state.beenEdit,
                              textEn: {
                                ...this.state.beenEdit.textEn,
                                shortDescription: true,
                              },
                            },
                          });
                        }}
                        value={
                          changes.newValues[
                            changes.title.indexOf("textEn.shortDescription")
                          ]
                        }
                        errorCheckSequence={[
                          Verify.notEmpty,
                          Verify.least10Char,
                        ]}
                        hideError={
                          !this.state.beenEdit.textEn.shortDescription ||
                          !showEn
                        }
                        disabled={
                          submitChange
                            ? !this.submit.includes(
                                "textEn.shortDescription"
                              ) || !showEn
                            : !showEn
                        }
                      />
                    ) : (
                      <VfiTextarea
                        className="col-xl-9"
                        placeholder={this.language.info.excerpt_en}
                        onChange={(e) => {
                          this.newPlace.textEn.shortDescription = e;
                          this.setState({
                            beenEdit: {
                              ...this.state.beenEdit,
                              textEn: {
                                ...this.state.beenEdit.textEn,
                                shortDescription: true,
                              },
                            },
                          });
                        }}
                        value={this.newPlace.textEn.shortDescription}
                        errorCheckSequence={[
                          Verify.notEmpty,
                          Verify.least10Char,
                        ]}
                        hideError={
                          !this.state.beenEdit.textEn.shortDescription ||
                          !showEn
                        }
                        disabled={
                          submitChange
                            ? !this.submit.includes(
                                "textEn.shortDescription"
                              ) || !showEn
                            : !showEn
                        }
                      />
                    )}
                  </div>
                  <div className="description row">
                    {submitChange ? (
                      <div
                        className={
                          "enable_editing " +
                          (this.submit.includes("textEn.description") &&
                            "enabled")
                        }
                        onClick={(e) => {
                          if (!this.submit.includes("textEn.description")) {
                            this.submit.push("textEn.description");
                          }
                          this.setState({});
                        }}
                      >
                        Enable editing
                        <FontAwesomeIcon icon={faPencil} />
                      </div>
                    ) : (
                      ""
                    )}
                    {this.changes.title.includes("textEn.description") ? (
                      <div className="btn_current_submitted">
                        <span
                          className={
                            changes.currentOrSuggested[
                              changes.title.indexOf("textEn.description")
                            ] === "current"
                              ? "choosen btn"
                              : "btn"
                          }
                          onClick={() => {
                            changes.currentOrSuggested[
                              changes.title.indexOf("textEn.description")
                            ] = "current";
                            this.updateEditedFields("textEn.description");
                            this.setState({});
                          }}
                        >
                          Current
                        </span>
                        <span
                          className={
                            changes.currentOrSuggested[
                              changes.title.indexOf("textEn.description")
                            ] === "suggested"
                              ? "choosen btn"
                              : "btn"
                          }
                          onClick={() => {
                            changes.currentOrSuggested[
                              changes.title.indexOf("textEn.description")
                            ] = "suggested";
                            this.updateEditedFields("textEn.description");
                            this.setState({});
                          }}
                        >
                          Suggested changes
                        </span>
                      </div>
                    ) : (
                      ""
                    )}
                    <div className="text col-xl-3">
                      {this.language.info.description_en}
                    </div>
                    {changes.currentOrSuggested[
                      changes.title.indexOf("textEn.description")
                    ] === "suggested" ? (
                      <VfiTextarea
                        className={"col-xl-9"}
                        placeholder={this.language.info.description_en}
                        onChange={(e) => {
                          changes.newValues[
                            changes.title.indexOf("textEn.description")
                          ] = e;
                          this.setState({
                            beenEdit: {
                              ...this.state.beenEdit,
                              textEn: {
                                ...this.state.beenEdit.textEn,
                                description: true,
                              },
                            },
                          });
                        }}
                        value={
                          changes.newValues[
                            changes.title.indexOf("textEn.description")
                          ]
                        }
                        errorCheckSequence={[
                          Verify.notEmpty,
                          Verify.least10Char,
                        ]}
                        hideError={
                          !this.state.beenEdit.textEn.description || !showEn
                        }
                        disabled={
                          submitChange
                            ? !this.submit.includes("textEn.description") ||
                              !showEn
                            : !showEn
                        }
                      />
                    ) : (
                      <VfiTextarea
                        className="col-xl-9"
                        placeholder={this.language.info.description_en}
                        onChange={(e) => {
                          this.newPlace.textEn.description = e;
                          this.setState({
                            beenEdit: {
                              ...this.state.beenEdit,
                              textEn: {
                                ...this.state.beenEdit.textEn,
                                description: true,
                              },
                            },
                          });
                        }}
                        value={this.newPlace.textEn.description}
                        errorCheckSequence={[
                          Verify.notEmpty,
                          Verify.least100Char,
                        ]}
                        hideError={
                          !this.state.beenEdit.textEn.description || !showEn
                        }
                        disabled={
                          submitChange
                            ? !this.submit.includes("textEn.description") ||
                              !showEn
                            : !showEn
                        }
                      />
                    )}
                  </div>
                </div>
                <div
                  className={
                    "col-lg-6 language-box faroese" + (showFo ? " active" : "")
                  }
                >
                  {this.changes.title.includes("textFo.showIt") ? (
                    <div className="btn_current_submitted">
                      <span
                        className={
                          changes.newValues[
                            changes.title.indexOf("textFo.showIt")
                          ] === "current"
                            ? "choosen btn"
                            : "btn"
                        }
                        onClick={() => {
                          changes.currentOrSuggested[
                            changes.title.indexOf("textFo.showIt")
                          ] = "current";
                          this.updateEditedFields("textFo.showIt");
                          this.setState({});
                        }}
                      >
                        Current
                      </span>
                      <span
                        className={
                          changes.currentOrSuggested[
                            changes.title.indexOf("textFo.showIt")
                          ] === "suggested"
                            ? "choosen btn"
                            : "btn"
                        }
                        onClick={() => {
                          changes.currentOrSuggested[
                            changes.title.indexOf("textFo.showIt")
                          ] = "suggested";
                          this.updateEditedFields("textFo.showIt");
                          this.setState({});
                        }}
                      >
                        Suggested changes
                      </span>
                    </div>
                  ) : (
                    ""
                  )}
                  <img className="flag" src={foFlag} alt="fo-flag" />
                  <VfiCheckbox
                    className="l-include"
                    checked={showFo}
                    onChange={(e) => {
                      this.newPlace.textFo.showIt = e.target.checked;
                      this.setState({});
                    }}
                  />
                  <div className="headline row">
                    {submitChange ? (
                      <div
                        className={
                          "enable_editing " +
                          (this.submit.includes("textFo.headline") && "enabled")
                        }
                        onClick={(e) => {
                          if (!this.submit.includes("textFo.headline")) {
                            this.submit.push("textFo.headline");
                            this.submit.push("textFo.urlSlug");
                          }
                          this.setState({});
                        }}
                      >
                        Enable editing
                        <FontAwesomeIcon icon={faPencil} />
                      </div>
                    ) : (
                      ""
                    )}
                    {this.changes.title.includes("textFo.headline") ? (
                      <div className="btn_current_submitted">
                        <span
                          className={
                            changes.currentOrSuggested[
                              changes.title.indexOf("textFo.headline")
                            ] === "current"
                              ? "choosen btn"
                              : "btn"
                          }
                          onClick={() => {
                            changes.currentOrSuggested[
                              changes.title.indexOf("textFo.headline")
                            ] = "current";
                            changes.currentOrSuggested[
                              changes.title.indexOf("textFo.urlSlug")
                            ] = "current";
                            this.updateEditedFields("textFo.headline");
                            this.updateEditedFields("textFo.urlSlug");
                            this.setState({});
                          }}
                        >
                          Current
                        </span>
                        <span
                          className={
                            changes.currentOrSuggested[
                              changes.title.indexOf("textFo.headline")
                            ] === "suggested"
                              ? "choosen btn"
                              : "btn"
                          }
                          onClick={() => {
                            changes.currentOrSuggested[
                              changes.title.indexOf("textFo.headline")
                            ] = "suggested";
                            changes.currentOrSuggested[
                              changes.title.indexOf("textFo.urlSlug")
                            ] = "suggested";
                            this.updateEditedFields("textFo.headline");
                            this.updateEditedFields("textFo.urlSlug");
                            this.setState({});
                          }}
                        >
                          Suggested changes
                        </span>
                      </div>
                    ) : (
                      ""
                    )}
                    <div className="text col-xl-3">
                      {this.language.info.headline_fo}
                    </div>
                    {changes.currentOrSuggested[
                      changes.title.indexOf("textFo.headline")
                    ] === "suggested" ? (
                      <VfiInputText
                        className={"col-xl-9"}
                        value={
                          changes.newValues[
                            changes.title.indexOf("textFo.headline")
                          ]
                        }
                        onChange={(e) => {
                          changes.newValues[
                            changes.title.indexOf("textFo.headline")
                          ] = e.target.value;
                          this.changes.newValues[
                            changes.title.indexOf("textFo.urlSlug")
                          ] = slugify(e.target.value);
                          this.setState({
                            beenEdit: {
                              ...this.state.beenEdit,
                              textFo: {
                                ...this.state.beenEdit.textFo,
                                headline: true,
                              },
                            },
                          });
                        }}
                        placeholder={this.language.info.headline_fo}
                        errorCheckSequence={[
                          Verify.notEmpty,
                          Verify.least3Char,
                        ]}
                        hideError={
                          !this.state.beenEdit.textFo.headline || !showFo
                        }
                        disabled={
                          submitChange
                            ? !this.submit.includes("textFo.headline") ||
                              !showFo
                            : !showFo
                        }
                        onBlur={(e) => {
                          this.uniqueSlug(
                            slugify(e.target.value),
                            "textFo.urlSlug",
                            [
                              changes.newValues[
                                changes.title.indexOf("textFo.urlSlug")
                              ],
                            ],
                            "faroese"
                          );
                        }}
                      />
                    ) : (
                      <VfiInputText
                        className="col-xl-9"
                        placeholder={this.language.info.headline_fo}
                        onChange={(e) => {
                          this.newPlace.textFo.headline = e.target.value;
                          this.newPlace.textFo.urlSlug = slugify(
                            e.target.value
                          );
                          this.setState({
                            beenEdit: {
                              ...this.state.beenEdit,
                              textFo: {
                                ...this.state.beenEdit.textFo,
                                headline: true,
                              },
                            },
                          });
                        }}
                        value={this.newPlace.textFo.headline}
                        errorCheckSequence={[
                          Verify.notEmpty,
                          Verify.least3Char,
                        ]}
                        hideError={
                          !this.state.beenEdit.textFo.headline || !showFo
                        }
                        disabled={
                          submitChange
                            ? !this.submit.includes("textFo.headline") ||
                              !showFo
                            : !showFo
                        }
                        onBlur={(e) => {
                          this.uniqueSlug(
                            slugify(e.target.value),
                            "textFo.urlSlug",
                            [this.newPlace.textFo.urlSlug],
                            "faroese"
                          );
                        }}
                      />
                    )}
                  </div>
                  {/* <div className="slug-url-wrap row">
                    <p className="col-xl-3">{this.language.info.slug_url_fo}</p>
                    <VfiInputText
                      className="col-xl-9"
                      value={changes.currentOrSuggested[changes.title.indexOf("textFo.headline")] === "suggested" ? changes.newValues[changes.title.indexOf("textFo.urlSlug")] : this.newPlace.textFo.urlSlug}
                      onChange={(e) => {
                        if (changes.currentOrSuggested[changes.title.indexOf("textFo.headline")] === "suggested") {
                          changes.newValues[changes.title.indexOf("textFo.urlSlug")] = e.target.value;
                        } else {
                          this.newPlace.textFo.urlSlug = e.target.value;
                        }
                        this.setState({});
                      }}
                      inputType="slug"
                      disabled={true}
                    />
                  </div> */}
                  <div className="small-description row">
                    {submitChange ? (
                      <div
                        className={
                          "enable_editing " +
                          (this.submit.includes("textFo.shortDescription") &&
                            "enabled")
                        }
                        onClick={(e) => {
                          if (
                            !this.submit.includes("textFo.shortDescription")
                          ) {
                            this.submit.push("textFo.shortDescription");
                          }
                          this.setState({});
                        }}
                      >
                        Enable editing
                        <FontAwesomeIcon icon={faPencil} />
                      </div>
                    ) : (
                      ""
                    )}
                    {this.changes.title.includes("textFo.shortDescription") ? (
                      <div className="btn_current_submitted">
                        <span
                          className={
                            changes.currentOrSuggested[
                              changes.title.indexOf("textFo.shortDescription")
                            ] === "current"
                              ? "choosen btn"
                              : "btn"
                          }
                          onClick={() => {
                            changes.currentOrSuggested[
                              changes.title.indexOf("textFo.shortDescription")
                            ] = "current";
                            this.updateEditedFields("textFo.shortDescription");
                            this.setState({});
                          }}
                        >
                          Current
                        </span>
                        <span
                          className={
                            changes.currentOrSuggested[
                              changes.title.indexOf("textFo.shortDescription")
                            ] === "suggested"
                              ? "choosen btn"
                              : "btn"
                          }
                          onClick={() => {
                            changes.currentOrSuggested[
                              changes.title.indexOf("textFo.shortDescription")
                            ] = "suggested";
                            this.updateEditedFields("textFo.shortDescription");
                            this.setState({});
                          }}
                        >
                          Suggested changes
                        </span>
                      </div>
                    ) : (
                      ""
                    )}
                    <div className="text col-xl-3">
                      {this.language.info.excerpt_fo}
                    </div>
                    {changes.currentOrSuggested[
                      changes.title.indexOf("textFo.shortDescription")
                    ] === "suggested" ? (
                      <VfiTextarea
                        className={"col-xl-9"}
                        placeholder={this.language.info.excerpt_fo}
                        onChange={(e) => {
                          changes.newValues[
                            changes.title.indexOf("textFo.shortDescription")
                          ] = e;
                          this.setState({
                            beenEdit: {
                              ...this.state.beenEdit,
                              textFo: {
                                ...this.state.beenEdit.textFo,
                                shortDescription: true,
                              },
                            },
                          });
                        }}
                        value={
                          changes.newValues[
                            changes.title.indexOf("textFo.shortDescription")
                          ]
                        }
                        errorCheckSequence={[
                          Verify.notEmpty,
                          Verify.least10Char,
                        ]}
                        hideError={
                          !this.state.beenEdit.textFo.shortDescription ||
                          !showFo
                        }
                        disabled={
                          submitChange
                            ? !this.submit.includes(
                                "textFo.shortDescription"
                              ) || !showFo
                            : !showFo
                        }
                      />
                    ) : (
                      <VfiTextarea
                        className={"col-xl-9"}
                        placeholder={this.language.info.excerpt_fo}
                        onChange={(e) => {
                          this.newPlace.textFo.shortDescription = e;
                          this.setState({
                            beenEdit: {
                              ...this.state.beenEdit,
                              textFo: {
                                ...this.state.beenEdit.textFo,
                                shortDescription: true,
                              },
                            },
                          });
                        }}
                        value={this.newPlace.textFo.shortDescription}
                        errorCheckSequence={[
                          Verify.notEmpty,
                          Verify.least10Char,
                        ]}
                        hideError={
                          !this.state.beenEdit.textFo.shortDescription ||
                          !showFo
                        }
                        disabled={
                          submitChange
                            ? !this.submit.includes(
                                "textFo.shortDescription"
                              ) || !showFo
                            : !showFo
                        }
                      />
                    )}
                  </div>
                  <div className="description row">
                    {submitChange ? (
                      <div
                        className={
                          "enable_editing " +
                          (this.submit.includes("textFo.description") &&
                            "enabled")
                        }
                        onClick={(e) => {
                          if (!this.submit.includes("textFo.description")) {
                            this.submit.push("textFo.description");
                          }
                          this.setState({});
                        }}
                      >
                        Enable editing
                        <FontAwesomeIcon icon={faPencil} />
                      </div>
                    ) : (
                      ""
                    )}
                    {this.changes.title.includes("textFo.description") ? (
                      <div className="btn_current_submitted">
                        <span
                          className={
                            changes.currentOrSuggested[
                              changes.title.indexOf("textFo.description")
                            ] === "current"
                              ? "choosen btn"
                              : "btn"
                          }
                          onClick={() => {
                            changes.currentOrSuggested[
                              changes.title.indexOf("textFo.description")
                            ] = "current";
                            this.updateEditedFields("textFo.description");
                            this.setState({});
                          }}
                        >
                          Current
                        </span>
                        <span
                          className={
                            changes.currentOrSuggested[
                              changes.title.indexOf("textFo.description")
                            ] === "suggested"
                              ? "choosen btn"
                              : "btn"
                          }
                          onClick={() => {
                            changes.currentOrSuggested[
                              changes.title.indexOf("textFo.description")
                            ] = "suggested";
                            this.updateEditedFields("textFo.description");
                            this.setState({});
                          }}
                        >
                          Suggested changes
                        </span>
                      </div>
                    ) : (
                      ""
                    )}
                    <div className="text col-xl-3">
                      {this.language.info.description_fo}
                    </div>
                    {changes.currentOrSuggested[
                      changes.title.indexOf("textFo.description")
                    ] === "suggested" ? (
                      <VfiTextarea
                        className={"col-xl-9"}
                        placeholder={this.language.info.description_fo}
                        onChange={(e) => {
                          changes.newValues[
                            changes.title.indexOf("textFo.description")
                          ] = e;
                          this.setState({
                            beenEdit: {
                              ...this.state.beenEdit,
                              textFo: {
                                ...this.state.beenEdit.textFo,
                                description: true,
                              },
                            },
                          });
                        }}
                        value={
                          changes.newValues[
                            changes.title.indexOf("textFo.description")
                          ]
                        }
                        errorCheckSequence={[
                          Verify.notEmpty,
                          Verify.least10Char,
                        ]}
                        hideError={
                          !this.state.beenEdit.textFo.shortDescription ||
                          !showFo
                        }
                        disabled={
                          submitChange
                            ? !this.submit.includes("textFo.description") ||
                              !showFo
                            : !showFo
                        }
                      />
                    ) : (
                      <VfiTextarea
                        className="col-xl-9"
                        placeholder={this.language.info.description_fo}
                        onChange={(e) => {
                          this.newPlace.textFo.description = e;
                          this.setState({
                            beenEdit: {
                              ...this.state.beenEdit,
                              textFo: {
                                ...this.state.beenEdit.textFo,
                                description: true,
                              },
                            },
                          });
                        }}
                        value={this.newPlace.textFo.description}
                        errorCheckSequence={[
                          Verify.notEmpty,
                          Verify.least100Char,
                        ]}
                        hideError={
                          !this.state.beenEdit.textFo.description || !showFo
                        }
                        disabled={
                          submitChange
                            ? !this.submit.includes("textFo.description") ||
                              !showFo
                            : !showFo
                        }
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
          );
          break;

        case 1:
          content = (
            <div className="new-content two">
              <WhatsonNavigation
                indexChange={this.props.indexChange}
                undoAndRedoDisabled={true}
                isFront={this.props.isFront}
                forwardDisabled={false}
                backwardDisabled={false}
                saveDraft={this.addOrUpdatePlace}
                language={this.props.language}
              />
              <h1>{this.language.categories.header}</h1>
              <p>{this.language.categories.sub_text}</p>

              {submitChange ? (
                <div
                  className={
                    "enable_editing_checkbox " +
                    (this.submit.includes("categories") && "enabled")
                  }
                >
                  <VfiCheckbox
                    className="text-include"
                    checked={this.submit.includes("categories")}
                    onChange={(e) => {
                      if (!this.submit.includes("categories")) {
                        this.submit.push("categories");
                      }
                      this.setState({});
                    }}
                  />
                  <p>Enable editing</p>
                </div>
              ) : (
                ""
              )}
              {this.changes.title.includes("categories") ? (
                <div className="btn_current_submitted">
                  <span
                    className={
                      changes.currentOrSuggested[
                        changes.title.indexOf("categories")
                      ] === "current"
                        ? "choosen btn"
                        : "btn"
                    }
                    onClick={() => {
                      changes.currentOrSuggested[
                        changes.title.indexOf("categories")
                      ] = "current";
                      this.updateEditedFields("categories");
                      this.setState({});
                    }}
                  >
                    Current
                  </span>
                  <span
                    className={
                      changes.currentOrSuggested[
                        changes.title.indexOf("categories")
                      ] === "suggested"
                        ? "choosen btn"
                        : "btn"
                    }
                    onClick={() => {
                      changes.currentOrSuggested[
                        changes.title.indexOf("categories")
                      ] = "suggested";
                      this.updateEditedFields("categories");
                      this.setState({});
                    }}
                  >
                    Suggested changes
                  </span>
                </div>
              ) : (
                ""
              )}

              <ul className="place-categories">
                {this.categories &&
                  this.categories.map((element) => {
                    return (
                      <li className="group" key={element.id}>
                        <label>
                          <VfiCheckbox
                            checked={
                              changes.currentOrSuggested[
                                changes.title.indexOf("categories")
                              ] === "suggested"
                                ? //Suggested change
                                  this.changes.newValues[
                                    changes.title.indexOf("categories")
                                  ].find((el) => el.id === element.id)
                                  ? true
                                  : false
                                : //Current change
                                this.newPlace.categories.find(
                                    (el) => el.id === element.id
                                  )
                                ? true
                                : false
                            }
                            disabled={
                              submitChange
                                ? !this.submit.includes("categories")
                                : false
                            }
                            onChange={(e) => {
                              if (e.target.checked) {
                                this.newPlace.categories.push({
                                  id: element.id,
                                });
                              } else {
                                this.newPlace.categories =
                                  this.newPlace.categories.filter(
                                    (el) => el.id !== element.id
                                  );
                              }

                              this.setState({
                                beenEdit: { ...beenEdit, categories: true },
                              });
                            }}
                          />{" "}
                          <span className="text">
                            {this.props.language === "english"
                              ? element.category_en
                              : ""}
                            {this.props.language === "faroese"
                              ? element.category_fo
                              : ""}
                          </span>
                        </label>
                      </li>
                    );
                  })}
              </ul>
              <div className="verify-errors">
                {this.newPlace.categories.length === 0 && (
                  <span>At least 1 must be selected</span>
                )}
              </div>
              {!submitChange &&
              !this.props.isFront &&
              globalObject.vfiUser.superAdmin === "1" ? (
                <div>
                  <p>Add Category</p>
                  <div className="add-category">
                    <VfiInputText
                      className="en label"
                      value={this.state.tempCategoryEn}
                      onChange={(e) =>
                        this.setState({ tempCategoryEn: e.target.value })
                      }
                      disabled={
                        submitChange
                          ? !this.submit.includes("categories")
                          : false
                      }
                      placeholder="New english category label"
                    />
                    <VfiInputText
                      className="fo label"
                      value={this.state.tempCategoryFo}
                      onChange={(e) =>
                        this.setState({ tempCategoryFo: e.target.value })
                      }
                      disabled={
                        submitChange
                          ? !this.submit.includes("categories")
                          : false
                      }
                      placeholder="New faroese category label"
                    />
                    <div
                      className="add-button"
                      onClick={() => {
                        this.props.blurScreen(true);
                        this.axios
                          .post(
                            env.protocol +
                              env.env +
                              "/api/whatson/places/AddPlaceCategory",
                            {
                              enName: this.state.tempCategoryEn,
                              foName: this.state.tempCategoryFo,
                            }
                          )
                          .then((response) => {
                            if (response.data) {
                              this.categories.push(response.data);
                              this.setState({
                                tempCategoryEn: "",
                                tempCategoryFo: "",
                              });
                            }
                            this.props.blurScreen(false);
                          })
                          .catch((error) => console.error(error));
                      }}
                    >
                      <FontAwesomeIcon icon={faPlusCircle} />
                    </div>
                  </div>
                </div>
              ) : (
                ""
              )}
            </div>
          );
          break;

        case 2:
          const openingHoursSuggested =
            changes.currentOrSuggested[
              changes.title.indexOf("openingHours")
            ] === "suggested";
          content = (
            <div className="new-content three">
              <WhatsonNavigation
                language={this.props.language}
                indexChange={this.props.indexChange}
                undoAndRedoDisabled={true}
                isFront={this.props.isFront}
                forwardDisabled={false}
                backwardDisabled={false}
                saveDraft={this.addOrUpdatePlace}
              />
              <h1>{this.language.opening_hours.header}</h1>
              <p>{this.language.opening_hours.sub_text}</p>
              {submitChange ? (
                <div
                  className={
                    "enable_editing_checkbox " +
                    (this.submit.includes("openingHours") && "enabled")
                  }
                >
                  <VfiCheckbox
                    className="text-include"
                    checked={this.submit.includes("openingHours")}
                    onChange={(e) => {
                      if (!this.submit.includes("openingHours")) {
                        this.submit.push("openingHours");
                      }
                      this.setState({});
                    }}
                  />
                  <p>Enable editing</p>
                </div>
              ) : (
                ""
              )}
              {this.changes.title.includes("openingHours") ? (
                <div className="btn_current_submitted">
                  <span
                    className={
                      changes.currentOrSuggested[
                        changes.title.indexOf("openingHours")
                      ] === "current"
                        ? "choosen btn"
                        : "btn"
                    }
                    onClick={() => {
                      changes.currentOrSuggested[
                        changes.title.indexOf("openingHours")
                      ] = "current";
                      this.updateEditedFields("openingHours");
                      this.setState({});
                    }}
                  >
                    Current
                  </span>
                  <span
                    className={openingHoursSuggested ? "choosen btn" : "btn"}
                    onClick={() => {
                      changes.currentOrSuggested[
                        changes.title.indexOf("openingHours")
                      ] = "suggested";
                      this.updateEditedFields("openingHours");
                      this.setState({});
                    }}
                  >
                    Suggested changes
                  </span>
                </div>
              ) : (
                ""
              )}

              <OpeningHours
                key={openingHoursSuggested ? "1" : "0"}
                onChange={(e) => {
                  if (openingHoursSuggested) {
                    changes.newValues[changes.title.indexOf("openingHours")] =
                      e;
                  } else {
                    this.newPlace.openingHours = e;
                  }
                  this.setState({
                    beenEdit: { ...beenEdit, openingHours: true },
                  });
                }}
                defaultValue={
                  openingHoursSuggested
                    ? changes.newValues[changes.title.indexOf("openingHours")]
                    : this.newPlace.openingHours
                }
                language={this.props.language}
                disabled={
                  submitChange ? !this.submit.includes("openingHours") : false
                }
                showErrors={beenEdit.openingHours}
              />
            </div>
          );
          break;

        case 3:
          content = (
            <div className="new-content four permission-section">
              <WhatsonNavigation
                language={this.props.language}
                indexChange={this.props.indexChange}
                undoAndRedoDisabled={true}
                forwardDisabled={false}
                isFront={this.props.isFront}
                backwardDisabled={false}
                saveDraft={this.addOrUpdatePlace}
              />
              <h1>{this.language.permission.header}</h1>
              <p>{this.language.permission.sub_text}</p>
              <div className="restrictions">
                {submitChange ? (
                  <div
                    className={
                      "enable_editing_checkbox " +
                      (this.submit.includes("placeRestrictions") && "enabled")
                    }
                  >
                    <VfiCheckbox
                      className="text-include"
                      checked={this.submit.includes("placeRestrictions")}
                      onChange={(e) => {
                        if (!this.submit.includes("placeRestrictions")) {
                          this.submit.push("placeRestrictions");
                        }
                        this.setState({});
                      }}
                    />
                    <p>Enable editing</p>
                  </div>
                ) : (
                  ""
                )}
                {this.changes.title.includes("placeRestrictions") ? (
                  <div className="btn_current_submitted">
                    <span
                      className={
                        changes.currentOrSuggested[
                          changes.title.indexOf("placeRestrictions")
                        ] === "current"
                          ? "choosen btn"
                          : "btn"
                      }
                      onClick={() => {
                        changes.currentOrSuggested[
                          changes.title.indexOf("placeRestrictions")
                        ] = "current";
                        this.updateEditedFields("placeRestrictions");
                        this.setState({});
                      }}
                    >
                      Current
                    </span>
                    <span
                      className={
                        changes.currentOrSuggested[
                          changes.title.indexOf("placeRestrictions")
                        ] === "suggested"
                          ? "choosen btn"
                          : "btn"
                      }
                      onClick={() => {
                        changes.currentOrSuggested[
                          changes.title.indexOf("placeRestrictions")
                        ] = "suggested";
                        this.updateEditedFields("placeRestrictions");
                        this.setState({});
                      }}
                    >
                      Suggested changes
                    </span>
                  </div>
                ) : (
                  ""
                )}
                <div className="min-age col-lg-6 row">
                  <div className="text col-xl-3">
                    <p>{this.language.permission.age_limit}</p>
                  </div>
                  <VfiInputText
                    disabled={
                      submitChange
                        ? !this.submit.includes("placeRestrictions")
                        : false
                    }
                    className="col-xl-9"
                    onChange={(e) => {
                      if (
                        changes.currentOrSuggested[
                          changes.title.indexOf("placeRestrictions")
                        ] === "suggested"
                      ) {
                        changes.newValues[
                          changes.title.indexOf("placeRestrictions")
                        ].minAge = e.target.value;
                      } else {
                        this.newPlace.placeRestrictions.minAge = e.target.value;
                      }
                      this.setState({
                        beenEdit: {
                          ...beenEdit,
                          placeRestrictions: { ...beenEdit, minAge: true },
                        },
                      });
                    }}
                    value={
                      changes.currentOrSuggested[
                        changes.title.indexOf("placeRestrictions")
                      ] === "suggested"
                        ? changes.newValues[
                            changes.title.indexOf("placeRestrictions")
                          ].minAge
                        : this.newPlace.placeRestrictions.minAge
                    }
                    placeholder={this.language.permission.age_limit_placeholder}
                    inputType="int"
                    // errorCheckSequence={beenEdit.placeRestrictions.minAge ? [Verify.notEmpty] : []}
                  />
                </div>
                <div className="capacity col-lg-6 row">
                  <div className="text col-xl-3">
                    <p>{this.language.permission.capacity}</p>
                  </div>
                  <VfiInputText
                    className="col-xl-9"
                    disabled={
                      submitChange
                        ? !this.submit.includes("placeRestrictions")
                        : false
                    }
                    onChange={(e) => {
                      if (
                        changes.currentOrSuggested[
                          changes.title.indexOf("placeRestrictions")
                        ] === "suggested"
                      ) {
                        changes.newValues[
                          changes.title.indexOf("placeRestrictions")
                        ].capacity = e.target.value;
                      } else {
                        this.newPlace.placeRestrictions.capacity =
                          e.target.value;
                      }
                      this.setState({
                        beenEdit: {
                          ...beenEdit,
                          placeRestrictions: { ...beenEdit, capacity: true },
                        },
                      });
                    }}
                    value={
                      changes.currentOrSuggested[
                        changes.title.indexOf("placeRestrictions")
                      ] === "suggested"
                        ? changes.newValues[
                            changes.title.indexOf("placeRestrictions")
                          ].capacity
                        : this.newPlace.placeRestrictions.capacity
                    }
                    placeholder={this.language.permission.capacity_placeholder}
                    inputType="int"
                    // errorCheckSequence={beenEdit.placeRestrictions.capacity ? [Verify.notEmpty] : []}
                  />
                </div>
              </div>
            </div>
          );
          break;

        case 4:
          content = (
            <div className="new-content five">
              <WhatsonNavigation
                language={this.props.language}
                indexChange={this.props.indexChange}
                undoAndRedoDisabled={true}
                forwardDisabled={false}
                isFront={this.props.isFront}
                backwardDisabled={false}
                saveDraft={this.addOrUpdatePlace}
              />
              <h1>{this.language.media.header}</h1>
              <p>{this.language.media.sub_text}</p>
              {submitChange ? (
                <div
                  className={
                    "enable_editing_checkbox " +
                    (this.submit.includes("images") && "enabled")
                  }
                >
                  <VfiCheckbox
                    className="text-include"
                    checked={this.submit.includes("images")}
                    onChange={(e) => {
                      if (!this.submit.includes("images")) {
                        this.submit.push("images");
                        this.submit.push("instagram");
                      }
                      this.setState({});
                    }}
                  />
                  <p>Enable editing</p>
                </div>
              ) : (
                ""
              )}
              {this.changes.title.includes("images") ? (
                <div className="btn_current_submitted">
                  <span
                    className={
                      changes.currentOrSuggested[
                        changes.title.indexOf("images")
                      ] === "current"
                        ? "choosen btn"
                        : "btn"
                    }
                    onClick={() => {
                      changes.currentOrSuggested[
                        changes.title.indexOf("images")
                      ] = "current";
                      changes.currentOrSuggested[
                        changes.title.indexOf("instagram")
                      ] = "current";
                      this.updateEditedFields("images");
                      this.updateEditedFields("instagram");
                      this.setState({});
                    }}
                  >
                    Current
                  </span>
                  <span
                    className={
                      changes.currentOrSuggested[
                        changes.title.indexOf("images")
                      ] === "suggested"
                        ? "choosen btn"
                        : "btn"
                    }
                    onClick={() => {
                      changes.currentOrSuggested[
                        changes.title.indexOf("images")
                      ] = "suggested";
                      changes.currentOrSuggested[
                        changes.title.indexOf("instagram")
                      ] = "suggested";
                      this.updateEditedFields("images");
                      this.updateEditedFields("instagram");
                      this.setState({});
                    }}
                  >
                    Suggested changes
                  </span>
                </div>
              ) : (
                ""
              )}
              <div className="uploaded-images">
                {changes.currentOrSuggested[changes.title.indexOf("images")] ===
                "suggested"
                  ? changes.newValues[
                      changes.title.indexOf("images")
                    ].mainImages.map((element, i) => {
                      return (
                        <div
                          className={
                            "one-image" +
                            (this.state.indexImageDrag === i
                              ? " dragging"
                              : "") +
                            (this.state.indexImageDragOn === i
                              ? " dragging-on"
                              : "")
                          }
                          key={element.key + "suggested"}
                          onDragOver={(e) => {
                            e.preventDefault();
                          }}
                          onDragEnter={(e) => {
                            setTimeout(() => {
                              this.setState({ indexImageDragOn: i });
                            }, 0);
                          }}
                          onDragLeave={(e) => {
                            this.setState({ indexImageDragOn: undefined });
                          }}
                          onDrop={(e) => {
                            let images = JSON.parse(
                              JSON.stringify(
                                changes.newValues[
                                  changes.title.indexOf("images")
                                ].mainImages
                              )
                            );
                            let image = images[this.state.indexImageDrag];
                            images.splice(this.state.indexImageDrag, 1);
                            images.splice(i, 0, image);
                            changes.newValues[
                              changes.title.indexOf("images")
                            ].mainImages = images;
                          }}
                          draggable="false"
                        >
                          {this.props.isFront && element.elm !== undefined ? (
                            <div className="media-wrap">
                              <img
                                src={element.elm}
                                alt="upload"
                                draggable="false"
                              />
                            </div>
                          ) : (
                            <div className="media-wrap">
                              <img
                                src={
                                  env.protocol +
                                  env.env +
                                  "/uploads/" +
                                  element.file_name +
                                  "_medium." +
                                  element.file_extension
                                }
                                alt="upload"
                                draggable="false"
                              />
                            </div>
                          )}
                          {!submitChange || this.submit.includes("images") ? ( // Disable editing
                            <div>
                              <div
                                className="drag-element"
                                draggable="true"
                                onDragStart={(e) => {
                                  this.defaultDragStart(e);
                                  this.setState({ indexImageDrag: i });

                                  var img = document.createElement("img");
                                  img.src =
                                    env.protocol +
                                    env.env +
                                    "/uploads/" +
                                    element.file_name +
                                    "_thumbnail." +
                                    element.file_extension;
                                  e.dataTransfer.setDragImage(img, 0, 0);
                                }}
                                onDragEnd={(e) => {
                                  this.setState({
                                    indexImageDrag: undefined,
                                    indexImageDragOn: undefined,
                                  });
                                }}
                              />
                              <div
                                className="remove-image"
                                onClick={() => {
                                  this.newPlace.images.mainImages.splice(i, 1);
                                  this.setState({});
                                }}
                              >
                                <FontAwesomeIcon icon={faTimes} />
                              </div>
                            </div>
                          ) : (
                            ""
                          )}

                          {showEn ? (
                            <div
                              className={
                                element.focused
                                  ? "text-wrap text-en"
                                  : "text-wrap text-en hidden"
                              }
                            >
                              {/* <img className="flag" src={enFlag} alt="en-flag" /> */}
                              <VfiTextarea
                                disabled={
                                  submitChange
                                    ? !this.submit.includes("images")
                                    : false
                                }
                                onChange={(e) =>
                                  (changes.newValues[
                                    changes.title.indexOf("images")
                                  ].mainImages[i].enText = e)
                                }
                                defaultValue={
                                  changes.newValues[
                                    changes.title.indexOf("images")
                                  ].mainImages[i].enText
                                }
                                onFocus={() => {
                                  changes.newValues[
                                    changes.title.indexOf("images")
                                  ].mainImages[i].focused = true;
                                  this.setState({});
                                }}
                                onBlur={() => {
                                  changes.newValues[
                                    changes.title.indexOf("images")
                                  ].mainImages[i].focused = false;
                                  this.setState({});
                                }}
                              />
                            </div>
                          ) : (
                            ""
                          )}
                          {showFo ? (
                            <div
                              className={
                                element.focused
                                  ? "text-wrap text-fo"
                                  : "text-wrap text-fo hidden"
                              }
                            >
                              {/* <img className="flag" src={foFlag} alt="fo-flag" /> */}
                              <VfiTextarea
                                disabled={
                                  submitChange
                                    ? !this.submit.includes("images")
                                    : false
                                }
                                onChange={(e) =>
                                  (changes.newValues[
                                    changes.title.indexOf("images")
                                  ].mainImages[i].foText = e)
                                }
                                defaultValue={
                                  changes.newValues[
                                    changes.title.indexOf("images")
                                  ].mainImages[i].foText
                                }
                                onFocus={() => {
                                  changes.newValues[
                                    changes.title.indexOf("images")
                                  ].mainImages[i].focused = true;
                                  this.setState({});
                                }}
                                onBlur={() => {
                                  changes.newValues[
                                    changes.title.indexOf("images")
                                  ].mainImages[i].focused = false;
                                  this.setState({});
                                }}
                              />
                            </div>
                          ) : (
                            ""
                          )}
                        </div>
                      );
                    })
                  : this.newPlace.images.mainImages.map((element, i) => {
                      return (
                        <div
                          className={
                            "one-image" +
                            (this.state.indexImageDrag === i
                              ? " dragging"
                              : "") +
                            (this.state.indexImageDragOn === i
                              ? " dragging-on"
                              : "")
                          }
                          key={element.key + "current"}
                          onDragOver={(e) => {
                            e.preventDefault();
                          }}
                          onDragEnter={(e) => {
                            setTimeout(() => {
                              this.setState({ indexImageDragOn: i });
                            }, 0);
                          }}
                          onDragLeave={(e) => {
                            this.setState({ indexImageDragOn: undefined });
                          }}
                          onDrop={(e) => {
                            let images = JSON.parse(
                              JSON.stringify(this.newPlace.images.mainImages)
                            );
                            let image = images[this.state.indexImageDrag];
                            images.splice(this.state.indexImageDrag, 1);
                            images.splice(i, 0, image);
                            this.newPlace.images.mainImages = images;
                          }}
                          draggable="false"
                        >
                          {this.props.isFront && element.elm !== undefined ? (
                            <div className="media-wrap">
                              <img
                                src={element.elm}
                                alt="upload"
                                draggable="false"
                              />
                            </div>
                          ) : (
                            <div className="media-wrap">
                              <img
                                src={
                                  env.protocol +
                                  env.env +
                                  "/uploads/" +
                                  element.file_name +
                                  "_medium." +
                                  element.file_extension
                                }
                                alt="upload"
                                draggable="false"
                              />
                            </div>
                          )}
                          {!submitChange || this.submit.includes("images") ? ( // Disable editing
                            <div>
                              <div
                                className="drag-element"
                                draggable="true"
                                onDragStart={(e) => {
                                  this.defaultDragStart(e);
                                  this.setState({ indexImageDrag: i });

                                  var img = document.createElement("img");
                                  img.src =
                                    env.protocol +
                                    env.env +
                                    "/uploads/" +
                                    element.file_name +
                                    "_thumbnail." +
                                    element.file_extension;
                                  e.dataTransfer.setDragImage(img, 0, 0);
                                }}
                                onDragEnd={(e) => {
                                  this.setState({
                                    indexImageDrag: undefined,
                                    indexImageDragOn: undefined,
                                  });
                                }}
                              />
                              <div
                                className="remove-image"
                                onClick={() => {
                                  this.newPlace.images.mainImages.splice(i, 1);
                                  this.setState({});
                                }}
                              >
                                <FontAwesomeIcon icon={faTimes} />
                              </div>
                            </div>
                          ) : (
                            ""
                          )}

                          {showEn ? (
                            <div
                              className={
                                element.focused
                                  ? "text-wrap text-en"
                                  : "text-wrap text-en hidden"
                              }
                            >
                              {/* <img className="flag" src={enFlag} alt="en-flag" /> */}
                              <VfiTextarea
                                disabled={
                                  submitChange
                                    ? !this.submit.includes("images")
                                    : false
                                }
                                onChange={(e) =>
                                  (this.newPlace.images.mainImages[i].enText =
                                    e)
                                }
                                defaultValue={
                                  this.newPlace.images.mainImages[i].enText
                                }
                                onFocus={() => {
                                  this.newPlace.images.mainImages[
                                    i
                                  ].focused = true;
                                  this.setState({});
                                }}
                                onBlur={() => {
                                  this.newPlace.images.mainImages[
                                    i
                                  ].focused = false;
                                  this.setState({});
                                }}
                                placeholder="English text"
                                errorCheckSequence={[Verify.notEmpty]}
                              />
                            </div>
                          ) : (
                            ""
                          )}
                          {showFo ? (
                            <div
                              className={
                                element.focused
                                  ? "text-wrap text-fo"
                                  : "text-wrap text-fo hidden"
                              }
                            >
                              {/* <img className="flag" src={foFlag} alt="fo-flag" /> */}
                              <VfiTextarea
                                disabled={
                                  submitChange
                                    ? !this.submit.includes("images")
                                    : false
                                }
                                onChange={(e) =>
                                  (this.newPlace.images.mainImages[i].foText =
                                    e)
                                }
                                defaultValue={
                                  this.newPlace.images.mainImages[i].foText
                                }
                                onFocus={() => {
                                  this.newPlace.images.mainImages[
                                    i
                                  ].focused = true;
                                  this.setState({});
                                }}
                                onBlur={() => {
                                  this.newPlace.images.mainImages[
                                    i
                                  ].focused = false;
                                  this.setState({});
                                }}
                                placeholder="Faroese text"
                                errorCheckSequence={[Verify.notEmpty]}
                              />
                            </div>
                          ) : (
                            ""
                          )}
                        </div>
                      );
                    })}

                {!this.props.isFront ? (
                  <div
                    className="image-upload-block"
                    onClick={() => {
                      this.props.setSiteRef(1);
                      this.props.openOverlay("mediaChoose", {
                        filter: { mediaType: ["images"] },
                      });
                      this.mediaIs = "mainImage";
                    }}
                  >
                    <div className={"upload-box"}>
                      <div className="image-container">
                        <FontAwesomeIcon className="pen" icon={faPlus} />
                        <FontAwesomeIcon
                          className="before-image"
                          icon={faImage2}
                        />
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className="add-new-media-content">
                    <div
                      className="add-new-media"
                      onClick={() => {
                        if (!submitChange || this.submit.includes("images")) {
                          this.setState({
                            addMediaDroppedDown:
                              !this.state.addMediaDroppedDown,
                          });
                        }
                      }}
                    >
                      <div className="text">Add new media</div>
                    </div>
                    <div className="upload-media-parent">
                      <Dropzone onDrop={/*(acceptedFiles) => */ this.saveFile}>
                        {({ getRootProps, getInputProps }) => (
                          <section>
                            <div {...getRootProps()}>
                              <input
                                {...getInputProps()}
                                accept=".jpg,.jpeg,.png,.gif,.mp4,.gpx"
                                maxLength={1}
                              />
                              <div className={"upload-box " + uploadHidden}>
                                <div className="text-content">
                                  <div className="first">
                                    Drop files or click to upload
                                  </div>
                                  <div className="second">
                                    Upload up to 10 files
                                  </div>
                                </div>
                              </div>
                            </div>
                          </section>
                        )}
                      </Dropzone>
                    </div>
                  </div>
                )}
              </div>
              <h2 style={{ marginTop: "40px" }}>
                Instagram <FontAwesomeIcon icon={faInstagram} />
              </h2>
              <label className="instagram-enabled">
                <VfiCheckbox
                  className="instagram-active"
                  checked={this.newPlace.instagram.active}
                  onChange={() => {
                    this.newPlace.instagram.active =
                      !this.newPlace.instagram.active;
                    this.setState({});
                  }}
                />{" "}
                {this.language.media.instagram_enabled}
              </label>
              {this.newPlace.instagram.active && (
                <InstagramInWhatson
                  data={
                    changes.currentOrSuggested[
                      changes.title.indexOf("instagram")
                    ] === "suggested"
                      ? changes.newValues[changes.title.indexOf("instagram")]
                      : this.newPlace.instagram
                  }
                  onChange={(e) => {
                    this.newPlace.instagram = e;
                    this.setState({
                      beenEdit: { ...beenEdit, instagram: true },
                    });
                  }}
                  onMediaClick={(i) => {
                    this.mediaIs = "instagram";
                    this.instaIndex = i;
                  }}
                  setSiteRef={this.props.setSiteRef}
                  openOverlay={
                    !this.props.isFront
                      ? this.props.openOverlay
                      : (e) => {
                          this.setState(
                            {
                              imageInput:
                                window.document.createElement("input"),
                            },
                            () => {
                              this.state.imageInput.type = "file";
                              this.state.imageInput.accept = "image/*";
                              this.state.imageInput.addEventListener(
                                "change",
                                this.onFrontInstaImageChange
                              );
                              this.state.imageInput.click();
                            }
                          );
                        }
                  }
                  defaultDragStart={this.defaultDragStart}
                  checkErrors={beenEdit.instagram}
                  disabled={
                    this.props.isFront && !this.submit.includes("instagram")
                  }
                />
              )}
            </div>
          );
          break;

        case 5:
          const addressText = placeSuggested
            ? changes.newValues[changes.title.indexOf("address")].name
            : this.newPlace.address.name;
          content = (
            <div className="new-content six place-section">
              <WhatsonNavigation
                language={this.props.language}
                indexChange={this.props.indexChange}
                undoAndRedoDisabled={true}
                forwardDisabled={false}
                isFront={this.props.isFront}
                backwardDisabled={false}
                saveDraft={this.addOrUpdatePlace}
              />
              <div className="address">
                <h1>{this.language.place.header}</h1>
                <p>{this.language.place.sub_text}</p>
                {submitChange ? (
                  <div
                    className={
                      "enable_editing_checkbox " +
                      (this.submit.includes("address") && "enabled")
                    }
                  >
                    <VfiCheckbox
                      className="text-include"
                      checked={this.submit.includes("address")}
                      onChange={(e) => {
                        if (!this.submit.includes("address")) {
                          this.submit.push("address");
                        }
                        this.setState({});
                      }}
                    />
                    <p>Enable editing</p>
                  </div>
                ) : (
                  ""
                )}
                {this.changes.title.includes("address") ? (
                  <div className="btn_current_submitted">
                    <span
                      className={
                        changes.currentOrSuggested[
                          changes.title.indexOf("address")
                        ] === "current"
                          ? "choosen btn"
                          : "btn"
                      }
                      onClick={() => {
                        changes.currentOrSuggested[
                          changes.title.indexOf("address")
                        ] = "current";
                        this.updateEditedFields("address");
                        this.setState({});
                      }}
                    >
                      Current
                    </span>
                    <span
                      className={
                        changes.currentOrSuggested[
                          changes.title.indexOf("address")
                        ] === "suggested"
                          ? "choosen btn"
                          : "btn"
                      }
                      onClick={() => {
                        changes.currentOrSuggested[
                          changes.title.indexOf("address")
                        ] = "suggested";
                        this.updateEditedFields("address");
                        this.setState({});
                      }}
                    >
                      Suggested changes
                    </span>
                  </div>
                ) : (
                  ""
                )}
                <div className="edit-contents">
                  <GoogleMapSearchBox
                    key={placeSuggested ? "1" : "0"}
                    change={this.onAddressChange}
                    onChange={(e) => {
                      if (placeSuggested)
                        this.changes.newValues[
                          this.changes.title.indexOf("address")
                        ].name = e.target.value;
                      else this.newPlace.address.name = e.target.value;
                      this.setState({
                        beenEdit: { ...beenEdit, address: true },
                      });
                    }}
                    name="address"
                    listResult={true}
                    defaultValue={addressText}
                    // defaultValue={placeSuggested ? changes.newValues[changes.title.indexOf("address")].name : this.newEvent.address.name}
                    className={
                      "search-box" +
                      (beenEdit.address &&
                      (Verify.notEmpty(addressText) ||
                        addressText === undefined)
                        ? " error"
                        : "")
                    }
                    placeholder={this.language.place.address_placeholder}
                    disabled={
                      submitChange ? !this.submit.includes("address") : false
                    }
                  />

                  {placeSuggested
                    ? changes.newValues[changes.title.indexOf("address")]
                        .includeMap && (
                        <SimpleMap
                          googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyA9xP3HbkU6iQiud39nHIrSRVKlvemgsXY&v=3.exp&libraries=geometry,drawing,places"
                          loadingElement={<div style={{ height: `100%` }} />}
                          containerElement={<div style={{ height: `600px` }} />}
                          mapElement={<div style={{ height: `100%` }} />}
                          center={
                            changes.newValues[changes.title.indexOf("address")]
                              .mapCenter
                          }
                          zoom={
                            changes.newValues[changes.title.indexOf("address")]
                              .mapZoom
                          }
                          isMarkerShown={
                            changes.newValues[changes.title.indexOf("address")]
                              .showMarker
                          }
                          onMarkerChange={this.onMarkerChange}
                          marker={
                            changes.newValues[changes.title.indexOf("address")]
                              .mapMarker
                          }
                          disabled={
                            submitChange
                              ? !this.submit.includes("address")
                              : false
                          }
                        />
                      )
                    : this.newPlace.address.includeMap && (
                        <SimpleMap
                          googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyA9xP3HbkU6iQiud39nHIrSRVKlvemgsXY&v=3.exp&libraries=geometry,drawing,places"
                          loadingElement={<div style={{ height: `100%` }} />}
                          containerElement={<div style={{ height: `600px` }} />}
                          mapElement={<div style={{ height: `100%` }} />}
                          center={this.newPlace.address.mapCenter}
                          zoom={this.newPlace.address.mapZoom}
                          isMarkerShown={this.newPlace.address.showMarker}
                          onMarkerChange={this.onMarkerChange}
                          marker={this.newPlace.address.mapMarker}
                          disabled={
                            submitChange
                              ? !this.submit.includes("address")
                              : false
                          }
                        />
                      )}
                  <div className="map-inclusion">
                    <label>
                      <VfiCheckbox
                        disabled={
                          submitChange
                            ? !this.submit.includes("address") &&
                              !this.submit.includes("place")
                            : false
                        }
                        className="include-map"
                        checked={
                          placeSuggested
                            ? changes.newValues[
                                changes.title.indexOf("address")
                              ].includeMap
                            : this.newPlace.address.includeMap
                        }
                        onChange={() => {
                          this.setState({ includeMap: !this.state.includeMap });
                          if (placeSuggested) {
                            changes.newValues[
                              changes.title.indexOf("address")
                            ].includeMap =
                              !changes.newValues[
                                changes.title.indexOf("address")
                              ].includeMap;
                          } else {
                            this.newPlace.address.includeMap =
                              !this.newPlace.address.includeMap;
                          }
                        }}
                        height={16}
                        width={32}
                      />
                      <p>{this.language.place.include_map}</p>
                      {/* <Switch
                      disabled={submitChange ? !(this.submit.includes('address')) && !this.submit.includes('place') : false}
                      className="include-map"
                      checked={placeSuggested ? changes.newValues[changes.title.indexOf("address")].includeMap : this.newPlace.address.includeMap}
                      onChange={() => {
                        this.setState({ includeMap: !this.state.includeMap });
                        if (placeSuggested) {
                          changes.newValues[changes.title.indexOf("address")].includeMap = !changes.newValues[changes.title.indexOf("address")].includeMap;
                        } else {
                          this.newPlace.address.includeMap = !this.newPlace.address.includeMap;
                        }
                      }}
                      height={16}
                      width={32}
                    /> */}
                    </label>
                  </div>

                  <div className="more-values row">
                    <div className="region col-xl-6">
                      <p>{this.language.place.region}</p>
                      <Select
                        className={
                          beenEdit.address &&
                          !this.newPlace.address.region &&
                          "error"
                        }
                        isDisabled={
                          submitChange
                            ? !this.submit.includes("address")
                            : false
                        }
                        onChange={(e) => {
                          this.setState({
                            region: e.value,
                            beenEdit: { ...beenEdit, address: true },
                          });
                          if (placeSuggested) {
                            changes.newValues[
                              changes.title.indexOf("address")
                            ].region = e;
                          } else {
                            this.newPlace.address.region = e;
                          }
                        }}
                        options={this.regions}
                        value={
                          placeSuggested
                            ? changes.newValues[
                                changes.title.indexOf("address")
                              ].region
                            : this.newPlace.address.region
                        }
                      />
                    </div>
                    <div className="zip-code col-xl-6">
                      <p>{this.language.place.zip_code}</p>
                      <Select
                        className={
                          beenEdit.address &&
                          !this.newPlace.address.zipCode &&
                          "error"
                        }
                        isDisabled={
                          submitChange
                            ? !this.submit.includes("address")
                            : false
                        }
                        onChange={(e) => {
                          this.setState({
                            zipCode: e.value,
                            beenEdit: { ...beenEdit, address: true },
                          });
                          if (placeSuggested) {
                            changes.newValues[
                              changes.title.indexOf("address")
                            ].zipCode = e.value;
                          } else {
                            this.newPlace.address.zipCode = e.value;
                          }
                        }}
                        options={this.zipCodes}
                        value={
                          placeSuggested
                            ? this.zipCodes.find(
                                (e) =>
                                  e.value ===
                                  parseInt(
                                    changes.newValues[
                                      changes.title.indexOf("address")
                                    ].zipCode
                                  )
                              )
                            : this.zipCodes.find(
                                (e) =>
                                  e.value ===
                                  parseInt(this.newPlace.address.zipCode)
                              )
                        }
                      />
                    </div>
                  </div>
                  {/* <div className="map-inclusion">
                  <p>{this.language.place.include_map}:</p>{" "}
                  <Switch
                    className="include-map"
                    checked={this.newPlace.address.includeMap}
                    onChange={() => {
                      this.setState({ includeMap: !this.state.includeMap });
                      this.newPlace.address.includeMap = !this.newPlace.address.includeMap;
                    }}
                    height={16}
                    width={32}
                  />
                </div> */}
                  {/* {this.newPlace.address.includeMap && (
                  <SimpleMap
                    googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyA9xP3HbkU6iQiud39nHIrSRVKlvemgsXY&v=3.exp&libraries=geometry,drawing,places"
                    loadingElement={<div style={{ height: `100%` }} />}
                    containerElement={<div style={{ height: `600px` }} />}
                    mapElement={<div style={{ height: `100%` }} />}
                    center={this.newPlace.address.mapCenter}
                    zoom={this.newPlace.address.mapZoom}
                    isMarkerShown={this.newPlace.address.showMarker}
                    onMarkerChange={this.onMarkerChange}
                    marker={this.newPlace.address.mapMarker}
                  /> 
                )} */}
                </div>
              </div>
            </div>
          );
          break;
        case 6:
          content = <Amenities parent={this} />;
          break;
        case 7:
          content = (
            <div className="new-content seven">
              {" "}
              <WhatsonNavigation
                language={this.props.language}
                indexChange={this.props.indexChange}
                isFront={this.props.isFront}
                undoAndRedoDisabled={true}
                forwardDisabled={false}
                backwardDisabled={false}
                saveDraft={this.addOrUpdatePlace}
                customForwardText={
                  this.props.updateData === undefined
                    ? this.language.register.navigation_submit
                    : this.language.register.navigation_update
                }
                customForwardFunc={() =>
                  this.setState({ beenEdit: allObjTrue(beenEdit) }, () => {
                    this.addOrUpdatePlace(false);
                  })
                }
              />
              <h1>{this.language.contact.header}</h1>
              <p>{this.language.contact.sub_text}</p>
              {submitChange ? (
                <div
                  className={
                    "enable_editing_checkbox " +
                    (this.submit.includes("contactDetails") && "enabled")
                  }
                >
                  <VfiCheckbox
                    className="text-include"
                    checked={this.submit.includes("contactDetails")}
                    onChange={(e) => {
                      if (!this.submit.includes("contactDetails")) {
                        this.submit.push("contactDetails");
                      }
                      this.setState({});
                    }}
                  />
                  <p>Enable editing</p>
                </div>
              ) : (
                ""
              )}
              {this.changes.title.includes("contactDetails") ? (
                <div className="btn_current_submitted">
                  <span
                    className={
                      changes.currentOrSuggested[
                        changes.title.indexOf("contactDetails")
                      ] === "current"
                        ? "choosen btn"
                        : "btn"
                    }
                    onClick={() => {
                      changes.currentOrSuggested[
                        changes.title.indexOf("contactDetails")
                      ] = "current";
                      this.updateEditedFields("contactDetails");
                      this.setState({});
                    }}
                  >
                    Current
                  </span>
                  <span
                    className={
                      changes.currentOrSuggested[
                        changes.title.indexOf("contactDetails")
                      ] === "suggested"
                        ? "choosen btn"
                        : "btn"
                    }
                    onClick={() => {
                      changes.currentOrSuggested[
                        changes.title.indexOf("contactDetails")
                      ] = "suggested";
                      this.updateEditedFields("contactDetails");
                      this.setState({});
                    }}
                  >
                    Suggested changes
                  </span>
                </div>
              ) : (
                ""
              )}
              <div className="edit-contents">
                <div className="contact-content">
                  <p className="">{this.language.contact.full_name}:</p>
                  <VfiInputText
                    disabled={
                      submitChange
                        ? !this.submit.includes("contactDetails")
                        : false
                    }
                    className=""
                    onChange={(e) => {
                      const contactDetailsD = JSON.parse(
                        JSON.stringify(this.state.contactDetails)
                      );
                      contactDetailsD.name = e.target.value;
                      this.setState({
                        contactDetails: contactDetailsD,
                        beenEdit: {
                          ...beenEdit,
                          contactDetails: {
                            ...beenEdit.contactDetails,
                            name: true,
                          },
                        },
                      });
                      this.newPlace.contactDetails.name = e.target.value;
                    }}
                    value={
                      contactSuggested
                        ? changes.newValues[
                            changes.title.indexOf("contactDetails")
                          ].name
                        : this.newPlace.contactDetails.name
                    }
                    placeholder={this.language.contact.placeholder.name}
                    errorCheckSequence={
                      beenEdit.contactDetails.name ? [Verify.notEmpty] : []
                    }
                  />
                </div>
                <div className="contact-content">
                  <p className="">{this.language.contact.social_link}:</p>
                  <VfiInputText
                    disabled={
                      submitChange
                        ? !this.submit.includes("contactDetails")
                        : false
                    }
                    className=""
                    onChange={(e) => {
                      const contactDetailsD = JSON.parse(
                        JSON.stringify(this.state.contactDetails)
                      );
                      contactDetailsD.socialLink = e.target.value;
                      this.setState({
                        contactDetails: contactDetailsD,
                        beenEdit: {
                          ...beenEdit,
                          contactDetails: {
                            ...beenEdit.contactDetails,
                            socialLink: true,
                          },
                        },
                      });
                      this.newPlace.contactDetails.socialLink = e.target.value;
                    }}
                    value={
                      contactSuggested
                        ? changes.newValues[
                            changes.title.indexOf("contactDetails")
                          ].socialLink
                        : this.newPlace.contactDetails.socialLink
                    }
                    placeholder={this.language.contact.placeholder.social}
                    errorCheckSequence={
                      beenEdit.contactDetails.socialLink
                        ? [Verify.notEmpty]
                        : []
                    }
                  />
                </div>
                <div className="contact-content">
                  <p className="">{this.language.contact.website}:</p>
                  <VfiInputText
                    disabled={
                      submitChange
                        ? !this.submit.includes("contactDetails")
                        : false
                    }
                    className=""
                    onChange={(e) => {
                      const contactDetailsD = JSON.parse(
                        JSON.stringify(this.state.contactDetails)
                      );
                      contactDetailsD.website = e.target.value;
                      this.setState({
                        contactDetails: contactDetailsD,
                        beenEdit: {
                          ...beenEdit,
                          contactDetails: {
                            ...beenEdit.contactDetails,
                            website: true,
                          },
                        },
                      });
                      this.newPlace.contactDetails.website = e.target.value;
                    }}
                    value={
                      contactSuggested
                        ? changes.newValues[
                            changes.title.indexOf("contactDetails")
                          ].website
                        : this.newPlace.contactDetails.website
                    }
                    placeholder={this.language.contact.placeholder.web}
                    errorCheckSequence={
                      beenEdit.contactDetails.website ? [Verify.notEmpty] : []
                    }
                  />
                </div>
                <div className="contact-content">
                  <p className="">{this.language.contact.email}:</p>
                  <VfiInputText
                    disabled={
                      submitChange
                        ? !this.submit.includes("contactDetails")
                        : false
                    }
                    className=""
                    onChange={(e) => {
                      const contactDetailsD = JSON.parse(
                        JSON.stringify(this.state.contactDetails)
                      );
                      contactDetailsD.email = e.target.value;
                      this.setState({
                        contactDetails: contactDetailsD,
                        beenEdit: {
                          ...beenEdit,
                          contactDetails: {
                            ...beenEdit.contactDetails,
                            email: true,
                          },
                        },
                      });
                      this.newPlace.contactDetails.email = e.target.value;
                    }}
                    value={
                      contactSuggested
                        ? changes.newValues[
                            changes.title.indexOf("contactDetails")
                          ].email
                        : this.newPlace.contactDetails.email
                    }
                    placeholder={this.language.contact.placeholder.email}
                    errorCheckSequence={
                      beenEdit.contactDetails.email
                        ? [Verify.notEmpty, Verify.validEmail]
                        : []
                    }
                  />
                </div>
                <div className="contact-content">
                  <p className="">{this.language.contact.country_code}:</p>
                  <Select
                    isDisabled={
                      submitChange
                        ? !this.submit.includes("contactDetails")
                        : false
                    }
                    className=""
                    onChange={(e) => {
                      const contactDetailsD = JSON.parse(
                        JSON.stringify(this.state.contactDetails)
                      );
                      contactDetailsD.countryCode = e.value;
                      this.setState({ contactDetails: contactDetailsD });
                      this.newPlace.contactDetails.countryCode = e.value;
                    }}
                    // options={this.zipCodes}
                    options={countryCodes.map(countryCodeReturn)}
                    // value={contactSuggested ? changes.newValues[changes.title.indexOf("contactDetails")].countryCode : this.newPlace.contactDetails.countryCode}

                    // onChange={(e) => {
                    //   this.setState({ zipCode: e.value });
                    //   if (placeSuggested) {
                    //     changes.newValues[changes.title.indexOf("address")].zipCode = e.value;
                    //   } else {
                    //     this.newPlace.address.zipCode = e.value;
                    //   }
                    // }}
                    value={countryCodeReturn(
                      countryCodes.find((e) =>
                        contactSuggested
                          ? parseInt(
                              changes.newValues[
                                changes.title.indexOf("contactDetails")
                              ].countryCode
                            ) === e.dial_code
                          : parseInt(
                              this.newPlace.contactDetails.countryCode
                            ) === e.dial_code
                      )
                    )}
                  />
                </div>

                <div className="contact-content">
                  <p className="">{this.language.contact.phone_number}:</p>
                  <VfiInputText
                    disabled={
                      submitChange
                        ? !this.submit.includes("contactDetails")
                        : false
                    }
                    className=""
                    onChange={(e) => {
                      const contactDetailsD = JSON.parse(
                        JSON.stringify(this.state.contactDetails)
                      );
                      contactDetailsD.phoneNumber = e.target.value;
                      this.setState({
                        contactDetails: contactDetailsD,
                        beenEdit: {
                          ...beenEdit,
                          contactDetails: {
                            ...beenEdit.contactDetails,
                            phoneNumber: true,
                          },
                        },
                      });
                      this.newPlace.contactDetails.phoneNumber = e.target.value;
                    }}
                    value={
                      contactSuggested
                        ? changes.newValues[
                            changes.title.indexOf("contactDetails")
                          ].phoneNumber
                        : this.newPlace.contactDetails.phoneNumber
                    }
                    placeholder={this.language.contact.placeholder.phone}
                    errorCheckSequence={
                      beenEdit.contactDetails.phoneNumber
                        ? [Verify.notEmpty]
                        : []
                    }
                    inputType={"int"}
                  />
                </div>
              </div>
            </div>
          );
          break;
        case 8:
          content = (
            <div className="new-content eight">
              {" "}
              <WhatsonNavigation
                language={this.props.language}
                indexChange={this.props.indexChange}
                isFront={this.props.isFront}
                undoAndRedoDisabled={true}
                forwardDisabled={false}
                backwardDisabled={true}
                saveDraftDisabled={true}
                saveDraft={this.addOrUpdatePlace}
                customForwardText={"To list"}
                customForwardFunc={this.props.goToList}
              />
              {/* {this.props.updateData === undefined ? (
                <div>
                  <h1>{this.language.register.header_register}</h1>
                  <div className="new-edit">
                    <div className="final-text">
                      <FontAwesomeIcon icon={faChild} className="wooh" />
                      <p>
                        {this.user.firstname.toUpperCase() +
                          " " +
                          this.user.lastname.toUpperCase() + ", "}
                        {this.language.register.final_text_register}
                      </p>
                    </div>
                  </div>{" "}
                </div>
              ) : (
                submitChange ?
                  <div>
                    <h1>{this.language.submit.header_submit}</h1>
                    <div className="new-edit">
                      <div className="final-text">
                        <FontAwesomeIcon icon={faChild} className="wooh" />
                        <p> {this.language.submit.final_text_submit}
                        </p>
                      </div>
                    </div>{" "}
                  </div>
                  :
                  <div>
                    <h1>{this.language.register.header_update}</h1>
                    <div className="new-edit">
                      <div className="final-text">
                        <FontAwesomeIcon icon={faChild} className="wooh" />
                        <p>
                          {this.user.firstname.toUpperCase() +
                            " " +
                            this.user.lastname.toUpperCase()}
                          , {this.language.register.final_text_update}
                        </p>
                      </div>
                    </div>{" "}
                  </div>
              )} */}
              <h3>
                <b>Good old chap!</b>
              </h3>
              <p>Your place is registered.</p>
              <div className="goose-image">
                <img src={Goose.thanksx2} />
              </div>
            </div>
          );
          break;
        default:
          content = "Should not get here...";
          break;
      }
    }

    this.descriptionPassed = descriptionPassed({
      showEn,
      showFo,
      beenEdit,
      newPlace: this.newPlace,
    });
    this.categoriesPassed = categoriesPassed({
      beenEdit,
      newPlace: this.newPlace,
    });
    this.amenitiesPassed = amenitiesPassed({
      beenEdit,
      newPlace: this.newPlace,
    });
    this.openingHoursPassed = openingHoursPassed({
      beenEdit,
      newPlace: this.newPlace,
    });
    this.permissionPassed = permissionPassed({
      beenEdit,
      newPlace: this.newPlace,
    });
    this.imagesPassed = imagesPassed({
      showEn,
      showFo,
      beenEdit,
      newPlace: this.newPlace,
    });
    this.placePassed = placePassed({
      beenEdit,
      newPlace: this.newPlace,
    });
    this.contactDetailsPassed = contactDetailsPassed({
      beenEdit,
      newPlace: this.newPlace,
    });
    this.allPassed =
      this.descriptionPassed &&
      this.categoriesPassed &&
      this.openingHoursPassed &&
      this.permissionPassed &&
      this.imagesPassed &&
      this.placePassed &&
      this.amenitiesPassed &&
      this.contactDetailsPassed;

    return (
      <div className="new-place">
        <div className="close-place" onClick={this.props.closePlace}>
          <FontAwesomeIcon icon={faTimes2} />
        </div>
        <div className="header-display" ref={this.headerRef}>
          {this.props.stepBubble(
            faInfo,
            this.language.info.bubble_text,
            0,
            this.descriptionPassed,
            this.changes.categoriesEdited.includes("textFo") ||
              this.changes.categoriesEdited.includes("textEn"),
            false
          )}
          {this.props.stepBubble(
            faList,
            this.language.categories.bubble_text,
            1,
            this.categoriesPassed,
            this.changes.categoriesEdited.includes("categories"),
            false
          )}
          {this.props.stepBubble(
            faClock,
            this.language.opening_hours.bubble_text,
            2,
            this.openingHoursPassed,
            this.changes.categoriesEdited.includes("openingHours"),
            false
          )}
          {this.props.stepBubble(
            faExclamation,
            this.language.permission.bubble_text,
            3,
            this.permissionPassed,
            this.changes.categoriesEdited.includes("placeRestrictions"),
            false
          )}
          {this.props.stepBubble(
            faImage,
            this.language.media.bubble_text,
            4,
            this.imagesPassed,
            this.changes.categoriesEdited.includes("images"),
            false
          )}
          {this.props.stepBubble(
            faMapMarkerAlt,
            this.language.place.bubble_text,
            5,
            this.placePassed,
            this.changes.categoriesEdited.includes("address"),
            false
          )}
          {this.props.stepBubble(
            faConciergeBell,
            this.language.amenities.bubble_text,
            6,
            this.amenitiesPassed,
            this.changes.categoriesEdited.includes("amenities"),
            false
          )}
          {this.props.stepBubble(
            faUser,
            this.language.contact.bubble_text,
            7,
            this.contactDetailsPassed,
            this.changes.categoriesEdited.includes("contactDetails"),
            false
          )}
          {this.props.stepBubble(
            faCheck,
            this.props.updateData === undefined
              ? this.language.register.bubble_text_register
              : this.language.register.bubble_text_update,
            8,
            true,
            false,
            true
          )}
        </div>
        <div className="w-content">{content}</div>
      </div>
    );
  }
}

export default NewPlace;
