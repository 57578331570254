import "./WhatsonFilterShow.scss";

export default function WhatsonFilterShow(props) {
  const { data, openOverlay } = props;

  return (
    <div style={props.style} className="whatson-filter-show">
      Places go here...
      <div
        className="select-whatson"
        onClick={() => {
          openOverlay("whatsonChoose", data);
        }}
      >
        SELECT PLACE
      </div>
    </div>
  );
}
