import { useState } from "react";
import "./LinkToEdit.scss";
import "./SideDescription.scss";
import VfiInputText from "../../VfiInputText/VfiInputText";
import VfiCKeditor from "../../VfiCKeditor/VfiCKeditor";
import { faTimes } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import UploadOrFindImage from "./UploadOrFindImage";
import { cloneDeep, isArray } from "lodash";

export default function SideDescription(props) {
  const [innerValues, setInnerValues] = useState(props.values);
  const [screen, setScreen] = useState("info");
  const { change, openInfo, isArticle } = props;

  const changeFunc = (name, value) => {
    const theChange = cloneDeep(innerValues);
    if (isArray(name)) {
      for (let i = 0; i < name.length; i++) {
        theChange[name[i]] = value[i];
      }
    } else {
      theChange[name] = value;
    }

    change(theChange);
    setInnerValues(theChange);
  };

  return (
    <div className="side-description edit-module" key={innerValues.key}>
      <div className="top">
        <div
          className={"top-choice " + (screen === "info" ? "active " : "")}
          onClick={() => setScreen("info")}
        >
          FULLSCREEN IMAGE TEXT INFO
        </div>
        <div
          className="close"
          onClick={() => {
            props.close();
          }}
        >
          <FontAwesomeIcon icon={faTimes} />
        </div>
      </div>
      <div className="content">
        <UploadOrFindImage
          index={props.index}
          siteId={props.siteRef}
          values={innerValues}
          openOverlay={props.openOverlay}
          // setValues={setValues}
          afterUpload={(response) => {
            const newMedium = response.data[0];
            // changeFunc(["id","src"],[newMedium.id,newMedium.medium.large]);
            props.openOverlay(
              "mediaChoose",
              {
                filter: { mediaType: ["images", "videos"] },
                singleMedia: true,
                selectedMedias: [newMedium.id],
              },
              props.index,
              "medium"
            );
            props.close();
          }}
        />
        {!isArticle && (
          <>
            <label className="option description">
              <div
                className="info"
                onClick={() => {
                  if (openInfo) {
                    openInfo(
                      <div>
                        <h1>Fullscreen image text info</h1>
                        <p>
                          Something about it being in the image slider, and can
                          be used to link to instagram.
                        </p>
                      </div>
                    );
                  }
                }}
              >
                i
              </div>
              <div className="label">Description</div>
              <VfiCKeditor
                key={innerValues.side_description ?? ""}
                value={innerValues.side_description}
                onBlur={(e) => {
                  changeFunc("side_description", e);
                }}
              />
            </label>
            <label className="option link">
              <div className="label">Link</div>
              <div className="value">
                <VfiInputText
                  key={innerValues.side_url ?? ""}
                  defaultValue={innerValues.side_url ?? ""}
                  onBlur={(e) => {
                    changeFunc("side_url", e.target.value);
                  }}
                />
              </div>
            </label>
          </>
        )}
      </div>
    </div>
  );
}
