import bloglist from "./modulikon_bloglist.svg";
import carousel from "./modulikon_carousel.svg";
import downloads from "./modulikon_downloads.svg";
import facts from "./modulikon_facts.svg";
import factsimage from "./modulikon_factsimage.svg";
import gallery from "./modulikon_gallery.svg";
import grid from "./modulikon_grid.svg";
import inspiration from "./modulikon_inspiration.svg";
import related from "./modulikon_related.svg";
import slider from "./modulikon_slider.svg";
import socials from "./modulikon_socials.svg";
import soundcloud from "./modulikon_soundcloud.svg";
import thumbnailgallery from "./modulikon_thumbnailgallery.svg";
import topslider from "./modulikon_topslider.svg";

const values = {
  bloglist,
  carousel,
  downloads,
  facts,
  factsimage,
  gallery,
  grid,
  inspiration,
  related,
  slider,
  socials,
  soundcloud,
  thumbnailgallery,
  topslider,
};

export default values;
