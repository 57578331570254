import { Component } from "react";
import enFlag from "../../../../../src/assets/images/en.png";
import foFlag from "../../../../../src/assets/images/fo.png";
import { VfiCheckbox2 as VfiCheckbox } from "../../../../assets/VfiCheckbox";
import VfiInputText from "../../../../assets/VfiInputText/VfiInputText";
import Verify from "../../../../assets/VerifyMethods";
import VfiTextarea from "../../../../assets/VfiTextarea/VfiTextarea";

/**
 * Information form
 * 
 * This is the first step general information form for edit/add tour operator
 * 
 * @returns 	{jsx} 												Information form element
 * 
 * @author 					Pætur Mortensen
 */
class Information extends Component {
	
	/**
	 * props:
	 * @property 		{object} 		newTourOperator 					Tour operator information
	 * @property 		{object} 		language 									Language object with strings
	 * @property 		{object} 		state 										NewTourOperator component state object
	 * @property		{function} 	setState 									NewTourOperator setState method  
	 * @property		{component} whatsonNavigation 				WhatsonNavigation component
	 * 
	 * @author 					Pætur Mortensen
	 */
	constructor(props) {
		super(props);
	}

	/**
	 * Render the language checkboxes
	 *
	 * Checkboxes to check whether or not to include the selected language
	 *
	 * @param 		{string} 	langKeyStr 						Language key ("textEn" OR "textFo")
	 * @param 		{bool} 		showLang 							Whether to show the currently selected language
	 * @param 		{string} 	boxLang 							Language to use ("english" or "faroese")
	 *
	 * @returns 	{jsx}														The language checkbox for language
	 *
	 * @author 					Pætur Mortensen
	 */
	render_language_checkbox(langKeyStr, showLang, boxLang) {
		return (
			<>
				{/* Language flag icon */}
				<img
					className="flag"
					src={boxLang === "english" ? enFlag : foFlag}
					alt="Language flag"
				/>

				{/* Whether to include this language in the form */}
				<VfiCheckbox
					className="l-include"
					checked={showLang}
					onChange={(e) => {
						this.props.newTourOperator[langKeyStr].showIt = e.target.checked;
						this.props.setState({});
					}}
				/>
			</>
		);
	}

	/**
	 * Render the headline input row
	 *
	 * @param 		{string} 	langKeyStr 						Language key ("textEn" OR "textFo")
	 * @param 		{bool} 		showLang 							Whether to show the currently selected language
	 * @param 		{string} 	boxLang 							Language to use ("english" or "faroese")
	 *
	 * @returns 	{jsx}														The headline input row
	 *
	 * @author 					Pætur Mortensen
	 */
	render_headline_row(langKeyStr, showLang, boxLang) {
		// Get the language key for the headline string
		const headlineKey = boxLang === "english" ? "headline_en" : "headline_fo";

		return (
			<div className="headline row">
				{/* Field label */}
				<div className="text col-xl-3">
					{this.props.language.info[headlineKey]}
				</div>

				<VfiInputText
					className="col-xl-9"
					onChange={(e) => {
						this.props.newTourOperator[langKeyStr].headline = e.target.value;
						this.props.setState({
							beenEdit: {
								...this.props.state.beenEdit,
								[langKeyStr]: {
									...this.props.state.beenEdit[langKeyStr],
									headline: true,
								},
							},
						});
					}}
					value={this.props.newTourOperator[langKeyStr].headline}
					placeholder={this.props.language.info[headlineKey]}
					errorCheckSequence={[
						Verify.notEmpty,
						{ function: Verify.minChars, args: [3] },
					]}
					hideError={
						!this.props.state.beenEdit[langKeyStr].headline || !showLang
					}
					maxLength={50}
				/>
			</div>
		);
	}

	/**
	 * Render the small description input row
	 *
	 * @param 		{string} 		langKeyStr 					Language key ("textEn" or "textFo")
	 * @param 		{bool} 			showLang 						Whether to show the currently selected language
	 * @param 		{string} 		boxLang 						Language to use ("english" or "faroese")
	 *
	 * @returns 	{jsx} 													The small description input row
	 *
	 * @author 					Pætur Mortensen
	 */
	render_small_desc_row(langKeyStr, showLang, boxLang) {
		// Language key to use with the excerpt string (faroese or english)
		const excerptKey = boxLang === "english" ? "excerpt_en" : "excerpt_fo";

		return (
			<div className="small-description row">
				{/* Short description label */}
				<div className="text col-xl-3">
					{this.props.language.info[excerptKey]}
				</div>
				<VfiTextarea
					className="col-xl-9"
					placeholder={this.props.language.info[excerptKey]}
					onChange={(e) => {
						this.props.newTourOperator[langKeyStr].shortDescription = e;
						this.props.setState({
							beenEdit: {
								...this.props.state.beenEdit,
								[langKeyStr]: {
									...this.props.state.beenEdit[langKeyStr],
									shortDescription: true,
								},
							},
						});
					}}
					maxLength={100}
					value={this.props.newTourOperator[langKeyStr].shortDescription}
					errorCheckSequence={[
						Verify.notEmpty,
						{ function: Verify.minChars, args: [10] },
					]}
					hideError={
						!this.props.state.beenEdit[langKeyStr].shortDescription || !showLang
					}
				/>
			</div>
		);
	}

	/**
	 * Render the description field row
	 *
	 * @param 		{string} 	langKeyStr 					Key string to use for language ("textEn" or "textFo")
	 * @param 		{bool} 		showLang 						Whether to show this language (or deactivate field)
	 * @param 		{string} 	boxLang 						Language for this field ("english" or "faroese")
	 *
	 * @returns 	{jsx} 												Description field
	 *
	 * @author 					Pætur Mortensen
	 */
	render_description_row(langKeyStr, showLang, boxLang) {
		// Description language key (not determined by langKeyStr)
		const descriptionKey =
			boxLang === "english" ? "description_en" : "description_fo";

		return (
			<div className="description row">
				{/* Description label */}
				<div className="text col-xl-3">
					&nbsp;
					{this.props.language.info[descriptionKey]}{" "}
				</div>
				<VfiTextarea
					className="col-xl-9"
					placeholder={this.props.language.info[descriptionKey]}
					onChange={(e) => {
						this.props.newTourOperator[langKeyStr].description = e;
						this.props.setState({
							beenEdit: {
								...this.props.state.beenEdit,
								[langKeyStr]: {
									...this.props.state.beenEdit[langKeyStr],
									description: true,
								},
							},
						});
					}}
					value={this.props.newTourOperator[langKeyStr].description}
					errorCheckSequence={[
						Verify.notEmpty,
						{ function: Verify.minChars, args: [100] },
					]}
					hideError={
						!this.props.state.beenEdit[langKeyStr].description || !showLang
					}
					maxLength={1500}
				/>
			</div>
		);
	}

	/**
	 * Render a single language form
	 *
	 * This method will render the information fields for a single language, english or faroese
	 *
	 * @param 		{string} 	boxLang 										Language for form ("english" or "faroese")
	 *
	 * @returns 	{jsx} 																The form for the selected language
	 *
	 * @author 					Pætur Mortensen
	 */
	render_language_form(boxLang) {
		// Whether to show the selected language
		const showLang =
			boxLang === "english"
				? this.props.newTourOperator.textEn.showIt
				: this.props.newTourOperator.textFo.showIt;

		// Key string used for "text lang", either "textEn" or "textFo" used as key for the correct
		// language in multiple objects, not just for language strings
		// (e.g. "textEn.showIt", which is bool)
		const langKeyStr = boxLang === "english" ? "textEn" : "textFo";

		return (
			<div
				className={
					"col-lg-6 language-box " + boxLang + (showLang ? " active" : "")
				}
			>
				{/* Render the three form fields: headline, small description, description */}
				{this.render_language_checkbox(langKeyStr, showLang, boxLang)}
				{this.render_headline_row(langKeyStr, showLang, boxLang)}
				{this.render_small_desc_row(langKeyStr, showLang, boxLang)}
				{this.render_description_row(langKeyStr, showLang, boxLang)}
			</div>
		);
	}

	render() {
		return (
			<div className="new-content one">
				{/* Add the top navigation and control panel */}
				{this.props.whatsonNavigation({ backwardDisabled: true })}

				{/* Information header */}
				<h1>{this.props.language.info.header}</h1>
				<p>{this.props.language.info.sub_text}</p>

				{/* Render english and faroese forms */}
				<div className="fill-form row">
					{this.render_language_form("english")}
					{this.render_language_form("faroese")}
				</div>
			</div>
		);
	}
}

export default Information;
