import style from './Button.module.scss';

/**
 * Get the button class string
 * 
 * Will build the class string for the button depending on the input 
 * 
 * @param 		{string} 	type 										Button type 
 * @param 		{string} 	size	 									Button size 
 * @param 		{bool} 		block 									Whether button is block-type element 
 * @param 		{bool} 		rounded 								Whether button should have rounded edges 
 * @param 		{jsx} 		icon 										Icon to include (if any) 
 * @param 		{string} 	iconPos 								Icon position, "left" or "right" 
 * @param 		{bool} 		uppercase 							Whether button text should be uppercase 	
 * @param 		{string} 	className 							Custom classNames to add if any
 *  
 * @returns 	{string} 													Button classes
 * 
 * @author 					Pætur Mortensen 
 */
function get_button_class(type, size, block, rounded, icon, iconPos, uppercase, className){
	// Init class string
	let classStr = style[type]+' '+style['size_'+size];

	// If this is a block-type button...
	if(block){
		classStr += ' '+style.block;
	}

	// If the button is rounded...
	if(rounded){
		classStr += ' '+style.rounded;
	}

	// If the button has an icon...
	if(icon){
		classStr += ' '+style['icon_'+iconPos];
	}

	// If classnames have been passed...
	if(className){
		classStr += ' '+className;
	}

	// If the button text should be uppercase...
	if(uppercase){
		classStr += ' '+style.uppercase;
	}

	return classStr;
}

/**
 * Render button icon
 * 
 * @param 		{jsx} 		icon 										Icon to render in button 
 * @param 		{string} 	iconPos 								Icon position, "left" or "right"
 *  
 * @returns 	{jsx} 														Button icon
 * 
 * @author 					Pætur Mortensen 
 */
function render_icon(icon, iconPos){
	return (
		<div className={`${style.iconCont} ${style[iconPos]}`}>
			{icon}
		</div>
	);
}

/**
 * Button component
 * 
 * Creates a JSX button
 * 
 * @param 		{string} 		type											Button type
 * @param 		{string} 		size 											Button size
 * @param 		{bool} 			block 										Whether button is block-type
 * @param 		{bool} 			rounded 									Whether button is to be rounded
 * @param 		{bool} 			uppercase 								Whether the button text should be uppercase
 * @param 		{jsx} 			icon 											Icon to add to the button
 * @param 		{string} 		iconPos 									Icon position, "left" or "right"
 * @param 		{function} 	onClick										onClick handler
 * @param 		{string} 		className 								Class names to add to button
 * @param 		{jsx} 			children 									Children to include in button
 * 
 * @returns 	{jsx} 																Button element
 * 
 * @author 					Pætur Mortensen 
 */
function Button({
	type='default', 
	size='normal', 
	block=false, 
	rounded=false,
	uppercase=false,
	icon,
	iconPos='right',
	onClick,
	callbackArg,
	className,
	children
}){
	// Get the button class
	const btnClass = get_button_class(
		type, 
		size, 
		block, 
		rounded, 
		icon, 
		iconPos, 
		uppercase, 
		className
	);

	/**
	 * Handle button click
	 * 
	 * @author 					Pætur Mortensen
	 */
	function handleClick(){
		onClick(callbackArg);
	}

	return (
		<button className={btnClass} onClick={handleClick}>
			{children}
			{icon && render_icon(icon, iconPos)}
		</button>
	);
}

export default Button;
