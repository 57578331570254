// import React, { useState, useEffect } from "react";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./FooterFrontEnd.scss";
import vfiLogo from "../../../assets/images/vfi-logo.png";
import vitaLogo from "../../../assets/images/vita-logo.png";
import env from "../../../environment.json";

export default function FooterFrontEnd(props) {
  return (
    <div className={"footer-display-wrap"}>
      <div className={"footer-display"}>
        <div className="top_module">
          <div className="cols">
            <div className="Col1">
              <div className="center">
                <img
                  src={env.env === "cms.vita.fo" ? vitaLogo : vfiLogo}
                  alt="sheep"
                />
                <div className="text">{props.siteName}</div>
                {/* <div className="text">{unMappedLang[selectedLanguage].siteName}</div> */}
              </div>
            </div>
            <div className="Col2">
              <div className="center">
                <span className="Headtext"> Follow us: </span>
                <span className="socialIcons">
                  {props.contentData.follow.length > 0 &&
                    props.contentData.follow.map((e) => (
                      <span dangerouslySetInnerHTML={{ __html: e.block }} />
                    ))}
                </span>
              </div>
            </div>
            <div className="Col3">
              <div className="center">
                {/* <div className="row"> */}
                <div className="Headtext">Other Sites: </div>
                {props.contentData.other_sites.length > 0 && (
                  <div
                    className="vals"
                    dangerouslySetInnerHTML={{
                      __html:
                        props.contentData.other_sites[0].languages[
                          props.selectedLanguage
                        ].blocks,
                    }}
                  ></div>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="modules">
          {props.contentData.ckeditor_5.length > 0 &&
            props.contentData.ckeditor_5.map((e, i) => (
              <div
                className="footer-column"
                dangerouslySetInnerHTML={{
                  __html: e.languages[props.selectedLanguage].blocks,
                }}
                key={i}
              ></div>
            ))}
        </div>
      </div>
    </div>
  );
}
