import favoritesSVG from "../../images/Socials/favorites.svg";
import facebookSVG from "../../images/Socials/facebook.svg";
import shareArrowSVG from "../../images/Socials/share-arrow.svg";
import "./SocialModule.scss";

export default function SocialModule(props) {
  return (
    <div style={props.style} className="social-module">
      <div className="icons-container">
        <div className="icon">
          <img src={favoritesSVG} alt="heart favorites icon" />
        </div>
        <div className="icon">
          <img src={facebookSVG} alt="facebook share icon" />
        </div>
        <div className="icon">
          <img src={shareArrowSVG} alt="arrow share icon" />
        </div>
      </div>
    </div>
  );
}
