import axios from "axios";
import { create } from "zustand";
import env from "../../environment.json";
import React from "react";

export const userStore = (set) => ({
  loggedInUser: {},
  users: [],
  roles: [],
  getUsers: (e) => {
    axios
      .get(`${env.protocol}${env.env}/api/GetUsers`)
      .then((response) => {
        set({ users: response.data });
      })
      .catch((error) => console.error(error));
  },
  getRoles: () => {
    axios
      .get(`${env.protocol}${env.env}/api/GetUserRoles`)
      .then((response) => {
        set({ roles: response.data });
      })
      .catch((error) => console.error(error));
  },
});

export const useUserStore = create(userStore);

export const withUserStore = (BaseComponent) => (props) => {
  const store = useUserStore();
  return <BaseComponent {...props} userStore={store} />;
};
