import React, { Component } from "react";
import env from "../../../environment.json";
import "./MediaDisplay.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowToTop, faListUl } from "@fortawesome/pro-light-svg-icons";
import { faSlidersH } from "@fortawesome/pro-regular-svg-icons";
import { faThLarge } from "@fortawesome/pro-solid-svg-icons";
import MediaList from "./MediaList";
import Dropzone from "react-dropzone";
import VfiCheckbox from "../../../assets/VfiCheckbox/VfiCheckbox";
import VfiInputText from "../../../assets/VfiInputText/VfiInputText";
import MediaEditMany from "./MediaEditMany";
import helperFunctions, {
  searchJson,
  pushMedia,
  uploadMedia,
  getUsers,
} from "../../../assets/helperFunctions";
import axios from "axios";
import RightSideInfo from "../../../assets/RightSideInfo";
import globalObjects from "../../../assets/globalVariables";
import FilterMedia, { defaultFilterMedia, filterMedia } from "./FilterMedia";
import ToolTip from "../../ToolTips/ToolTip";

export function changeMediaLocation(
  id,
  site,
  add = true,
  afterFunc = (response) => {}
) {
  axios
    .post(env.protocol + env.env + "/api/ChangeMediaLocation", {
      media: id,
      site,
      add: add ? 1 : 0,
    })
    .then((response) => {
      if (afterFunc) afterFunc(response);
    })
    .catch((error) => console.error(error));
}

class MediaDisplay extends Component {
  sortedBy = "";
  _isMounted = false;
  constructor(props) {
    super(props);

    this.state = {
      addMediaDroppedDown: false,
      addFilterDroppedDown: false,
      uploadFiles: [],
      uploadFilePercent: 0,
      searchValue: "",
      displayMedia: "list",
      sortedBy: "",
      heartEditable: true,
      checkboxFilter: { mediaType: ["all"], format: ["all"] },
      showEditImages: false,
      showAmount: 30,
      dragEvent: false,
      filterData: defaultFilterMedia,
      listReversed: false,
      filterUpdate: 0,
    };

    this.mediaContents = [];

    this.axios = require("axios");

    this.refresh = this.refresh.bind(this);
    this.uploadFile = this.uploadFile.bind(this);
    this.sortList = this.sortList.bind(this);
    this.selectById = this.selectById.bind(this);
    this.getFilterMedia = this.getFilterMedia.bind(this);
    this.selectAll = this.selectAll.bind(this);
    this.heartClicked = this.heartClicked.bind(this);
    this.changeMedia = this.changeMedia.bind(this);
    this.deleteMedia = this.deleteMedia.bind(this);
    this.checkboxFilter = this.checkboxFilter.bind(this);
    this.dragEnterEvent = this.dragEnterEvent.bind(this);
    this.dragLeaveEvent = this.dragLeaveEvent.bind(this);
    this.filterScreen = this.filterScreen.bind(this);

    this.config = {
      headers: {
        "Content-Type": "multipart/form-data",
      },
      onUploadProgress: (progressEvent) => {
        var percentCompleted = Math.floor(
          (progressEvent.loaded * 100) / progressEvent.total
        );
        this.setState(
          {
            uploadFilePercent: percentCompleted,
          },
          () => {
            if (percentCompleted === 100) {
              const newImages = [];
              this.state.uploadFiles.forEach((element) => {
                newImages.push();
              });
              this.mediaContents.unshift(...newImages);
            }
          }
        );
      },
    };

    this.axios
      .post(env.protocol + env.env + "/api/public/GetSites.php", {
        userId: sessionStorage.getItem("vfiUser"),
      })
      .then((response) => {
        this.sites = response.data;
      })
      .catch((error) => console.error(error));
  }

  GetMediaBySiteId = (sortBy) => {
    this.sortedBy = "";
    let link = "";
    let postData = {};
    if (
      this.props.site !== "0" &&
      globalObjects.vfiUser.profession !== "tour_operator"
    ) {
      link = env.protocol + env.env + "/api/GetMediaBySiteId";
      postData = {
        id: this.props.site,
        // adminGetAll: this.props.onInsertClick ? true : false, // When inserting, super admin should be able to add from any site.
        userId: sessionStorage.getItem("vfiUser"),
      };
    } else {
      link = env.protocol + env.env + "/api/GetAllMediaToUser";
      postData = {
        userId: sessionStorage.getItem("vfiUser"),
      };
    }

    return this.axios
      .post(link, postData)
      .then((response) => {
        this.mediaContents = [];
        response.data = response.data !== "" ? response.data : [];
        response.data.forEach((e) => {
          this.mediaContents.push({
            ...pushMedia(e),
            selected: this.props.selectedMedias
              ? this.props.selectedMedias.find(
                  (f) => parseInt(f) === parseInt(e.id)
                )
                ? true
                : false
              : false,
          });
        });
        this.setState({
          showEditImages: this.props.selectedMedias !== undefined,
        });
        if (sortBy !== "refresh") {
          if (sortBy === "date") this.sortList(sortBy, false);
          else this.sortList(this.sortedBy, false);
        } else {
          this.sortedBy = "date";
        }
        return this.mediaContents;
      })
      .catch((error) => {
        console.error(error);
        this.setState({
          treeChecked: true,
          error: true,
          errorMessage: error.message,
        });
      });
  };

  dragEnterEvent(e) {
    // e.preventDefault();
    if (this.state.dragEvent === false)
      this.setState({
        dragEvent: true,
      });
  }
  dragLeaveEvent(e) {
    if (this.state.dragEvent === true)
      this.setState({
        dragEvent: false,
      });
  }

  componentDidMount() {
    this._isMounted = true;
    this.GetMediaBySiteId("date").then((response) => {
      if (this._isMounted) {
        this.setState({});
      }
    });

    getUsers(this.props.site, (response) => {
      this.props.handleUserData(response);
      helperFunctions.sortList("firstname", this.props.users, false);
    });

    // window.addEventListener('dragenter',this.dragEnterEvent);
    // window.addEventListener('dragleave',this.dragLeaveEvent);
  }
  componentWillUnmount() {
    this._isMounted = false;

    // window.removeEventListener('dragenter',this.dragEnterEvent);
    // window.removeEventListener('dragleave',this.dragLeaveEvent);
  }

  componentDidUpdate(prevProps, prevStates) {
    if (prevProps.site !== this.props.site) {
      this.GetMediaBySiteId("date").then((response) => {
        this.setState({});
      });
      getUsers(this.props.site, (response) => {
        this.props.handleUserData(response);
        helperFunctions.sortList("firstname", this.props.users, false);
      });
    }

    if (
      prevStates.filterData.sortBy.value !==
        this.state.filterData.sortBy.value ||
      prevStates.filterData.sortBy.reverse !==
        this.state.filterData.sortBy.reverse
    ) {
      // this.sortList(
      //   this.state.filterData.sortBy.value,
      //   this.state.filterData.sortBy.reverse
      // );
    }
    const addOne = this.props.addOne;
    if (addOne !== null && addOne !== prevProps.addOne) {
      console.log(this.props.addOne);
      let loopValue = addOne;
      if (typeof this.props.addOne === "object" && !Array.isArray(addOne)) {
        loopValue = [addOne];
      }
      loopValue.forEach((add, i) => {
        this.mediaContents.unshift(add);
        this.setState({ showInSettings: add });
      });
    }
    if (this.props.showMore === true) {
      if (this.state.showAmount < this.mediaContents.length) {
        this.setState({ showAmount: this.state.showAmount + 15 });
        this.props.setBottom(false);
      }
    }

    if (this.state.errorUploadMessage) {
      clearTimeout(this.errorMsg);
      this.errorMsg = setTimeout(() => {
        this.setState({ errorUploadMessage: "" });
      }, 5000);
    }
  }

  refresh() {
    this.GetMediaBySiteId("refresh").then((response) => {
      this.setState({});
    });
  }

  uploadFile(e) {
    this.setState({ uploadFiles: e, dragEvent: false });

    let success = true;
    let errorMessage = "";
    console.log(e);
    let fEE = this.fileExplorerExtensions.replaceAll(".", "");
    fEE = fEE.split(",");
    console.log(fEE);
    for (const file of e) {
      let extension = "";
      console.log(file);
      // if(file.type)
      // {
      //   extension = file.type.split("/").pop().toLowerCase();
      // }
      // else
      // {
      extension = file.name.split(".").pop().toLowerCase();
      // }
      success = fEE.includes(extension);

      if (!success) {
        errorMessage += `File: '${file.name}' extension is not supported.`;
        break;
      }
    }

    if (success) {
      uploadMedia(e, this.props.site, this.config, (res) => {
        res.data.forEach((element) => {
          this.mediaContents.unshift(pushMedia(element));
          this.selectById(element.id);
        });
        this.setState({
          uploadFiles: [],
          showEditImages: true,
          addMediaDroppedDown: false,
          errorUploadMessage: "",
        });
      });
    } else {
      this.setState({ uploadFiles: [], errorUploadMessage: errorMessage });
    }
  }

  pushOneMoreIntoList(element) {
    this.mediaContents.unshift(pushMedia(element));
    this.setState({});
  }

  filterChange(changeWhat, value) {
    let currentFilter = Object.assign({}, this.state.checkboxFilter);
    changeWhat = changeWhat.split(".");

    if (changeWhat[1] === "all") {
      if (value === true) {
        currentFilter[changeWhat[0]] = ["all"];
      } else {
        // Do nothing
      }
    } else {
      if (value === true) {
        currentFilter[changeWhat[0]] = currentFilter[changeWhat[0]].filter(
          function (e) {
            return e !== "all" && e !== changeWhat[1];
          }
        );
        currentFilter[changeWhat[0]].splice(
          currentFilter[changeWhat[0]].length - 1,
          0,
          changeWhat[1]
        );
      } else {
        currentFilter[changeWhat[0]] = currentFilter[changeWhat[0]].filter(
          function (e) {
            return e !== changeWhat[1];
          }
        );
        if (currentFilter[changeWhat[0]].length === 0) {
          currentFilter[changeWhat[0]] = ["all"];
        }
      }
    }
    this.setState({ checkboxFilter: currentFilter });
  }

  sortList(sortingBy, reverse) {
    if (sortingBy !== undefined && sortingBy !== "") {
      var sortingByArr = sortingBy.split(".");

      // if (sortingBy === this.sortedBy && reverse === undefined) {
      if (sortingBy === this.sortedBy && reverse === undefined) {
        reverse = !this.state.listReversed;
      }
      this.mediaContents.sort((a, b) => {
        var aPart = a;
        var bPart = b;
        sortingByArr.forEach((part) => {
          if (aPart !== undefined) aPart = aPart[part];
          if (bPart !== undefined) bPart = bPart[part];
        });

        if (sortingBy === "fileSize") {
          aPart = parseInt(aPart);
          bPart = parseInt(bPart);
          return aPart > bPart ? 1 : -1;
        }

        if (sortingBy === "date") {
          aPart = new Date(aPart);
          bPart = new Date(bPart);
        }

        if (aPart === undefined) aPart = "";
        if (bPart === undefined) bPart = "";

        if (typeof aPart === "string" || aPart instanceof String) {
          return (aPart ? aPart.toLowerCase() : "") >
            (bPart ? bPart.toLowerCase() : "")
            ? 1
            : -1;
        } else return aPart < bPart ? 1 : -1;
      });
      this.sortedBy = sortingBy;

      if (reverse === true) {
        this.mediaContents.reverse();
      }
      const filterData = this.state.filterData;
      this.setState(
        {
          filterData: {
            ...filterData,
            time: new Date().getTime(),
            sortBy: {
              ...filterData.sortBy,
              value: sortingBy,
              reverse: reverse ?? false,
            },
          },
          listReversed: reverse ?? false,
          filterUpdate: new Date().getTime(),
        },
        () => {
          this.setState({
            inputInfo: this.filterScreen(this.state.filterData),
          });
        }
      );
    }
  }

  heartClicked(id) {
    if (this.state.heartEditable) {
      this.setState({ heartEditable: false });
      this.mediaContents.forEach((element, i) => {
        if (element.id === id) {
          this.axios
            .post(env.protocol + env.env + "/api/mediaHeartProcess", {
              process: this.mediaContents[i].hearted ? "remove" : "add",
              media_id: id,
            })
            .then((response) => {
              this.mediaContents[i].hearted = !this.mediaContents[i].hearted;
              this.setState({ heartEditable: true });
            })
            .catch((error) => {
              console.error(error);
            });
        }
      });
    }
  }

  changeMedia(value) {
    this.mediaContents.forEach((element, i) => {
      if (element.id === value.id && element.isSettings !== true) {
        this.mediaContents[i] = { ...value };
        this.setState({});
      }
    });
  }

  deleteMedia(id) {
    return new Promise((resolve) => {
      this.axios
        .post(env.protocol + env.env + "/api/deleteMedia", {
          id: id,
        })
        .then((response) => {
          this.mediaContents.forEach((element, i) => {
            if (element.id === id) {
              this.mediaContents.splice(i, 1);
            }
          });
          this.mediaContents = this.mediaContents.filter(
            (e) => !response.data.deletedIds.includes(e.id)
          );
          this.setState({});
          resolve(true);
        })
        .catch((error) => {
          console.error(error);
          resolve(false);
        });
    });
  }

  selectById(id) {
    this.mediaContents.forEach((element, i) => {
      if (element.id === id) {
        this.mediaContents[i].selected = !this.mediaContents[i].selected;
      }
    });
    this.setState({});
  }

  getFilterMedia() {
    let filter = { types: [] };
    const outsideFilter = this.props.filter;
    if (outsideFilter !== undefined && outsideFilter.length !== 0) {
      if (outsideFilter.mediaType.includes("images")) {
        filter.types.push("jpg", "jpeg", "png", "gif");
      }
      if (outsideFilter.mediaType.includes("videos")) {
        filter.types.push("mp4", "mov", "wmv", "flv", "avi", "mkv", "webm");
      }
      if (outsideFilter.mediaType.includes("gpx")) {
        filter.types.push("gpx");
      }
      if (outsideFilter.mediaType.includes("icons")) {
        filter.types.push("svg");
      }

      // return filterMedia(
      //   this.mediaContents,
      //   this.state.filterData,
      //   this.props.sites,
      //   this.props.users
      // ).filter(e=>{
      //   if(filter.length === 0)
      //     return true;
      //   return filter.types.includes(e.fileExtension);
      // });
    } else {
      // return filterMedia(
      //   this.mediaContents,
      //   this.state.filterData,
      //   this.props.sites,
      //   this.props.users
      // );
    }

    return filterMedia(
      this.mediaContents,
      this.state.filterData,
      this.props.sites,
      this.props.users
    ).filter((e) => {
      if (filter.types.length === 0) return true;
      return filter.types.includes(e.fileExtension);
    });
  }

  selectAll(bool) {
    const filtered = this.getFilterMedia();
    filtered.forEach((element, i) => {
      // if (searchJson(element, this.state.searchValue))
      // filtered[i].selected = bool;
      const theMedia = this.mediaContents.find((m) => m.id === element.id);
      theMedia.selected = bool;
    });
    this.setState({});
  }

  downloadMediaAppear(bool) {
    if (bool === true) {
    } else {
    }
  }

  //-----

  checkboxFilter(list) {
    const filter =
      this.props.filter !== undefined
        ? { ...this.state.checkboxFilter, ...this.props.filter }
        : this.state.checkboxFilter;

    Object.keys(filter).forEach((key) => {
      if (filter[key].includes("all")) {
        return;
      }

      if (key === "mediaType") {
        let tempList = [];

        filter[key].forEach((element) => {
          if (element === "images") {
            const filteredList = list.filter(
              (q) =>
                q.fileExtension.toLowerCase() === "jpg" ||
                q.fileExtension.toLowerCase() === "jpeg" ||
                q.fileExtension.toLowerCase() === "png" ||
                q.fileExtension.toLowerCase() === "gif"
            );
            tempList = tempList.concat(filteredList);
          }

          if (element === "videos") {
            const filteredList = list.filter(
              (q) =>
                q.fileExtension.toLowerCase() === "mp4" ||
                q.fileExtension.toLowerCase() === "mov" ||
                q.fileExtension.toLowerCase() === "wmv" ||
                q.fileExtension.toLowerCase() === "flv" ||
                q.fileExtension.toLowerCase() === "avi" ||
                q.fileExtension.toLowerCase() === "mkv"
            );
            tempList = tempList.concat(filteredList);
          }

          if (element === "gpx") {
            const filteredList = list.filter(
              (q) => q.fileExtension.toLowerCase() === "gpx"
            );
            tempList = tempList.concat(filteredList);
          }
        });

        list = list.filter((l) => tempList.includes(l));
      }

      if (key === "format") {
        let tempList = [];

        filter[key].forEach((element) => {
          if (element === "landscape") {
            const filteredList = list.filter((q) => {
              const width = parseInt(q.fileDimensions.split("x")[0]);
              const height = parseInt(q.fileDimensions.split("x")[1]);

              return width > height;
            });
            tempList = tempList.concat(filteredList);
          }

          if (element === "portrait") {
            const filteredList = list.filter((q) => {
              const width = parseInt(q.fileDimensions.split("x")[0]);
              const height = parseInt(q.fileDimensions.split("x")[1]);

              return width < height;
            });
            tempList = tempList.concat(filteredList);
          }

          if (element === "square") {
            const filteredList = list.filter((q) => {
              const width = parseInt(q.fileDimensions.split("x")[0]);
              const height = parseInt(q.fileDimensions.split("x")[1]);

              return width === height;
            });
            tempList = tempList.concat(filteredList);
          }
        });

        list = list.filter((l) => tempList.includes(l));
      }
    });

    return list;
  }

  //-----

  filterScreen(data) {
    return (
      <FilterMedia
        data={data}
        onChange={(values) => {
          // this.sortedBy = values.sortBy.value;
          this.setState({ filterData: values });
        }}
        sites={this.props.sites}
        users={this.props.users}
        media={this.mediaContents}
        forceUpdate={this.state.filterUpdate}
      />
    );
  }

  fileExplorerExtensions =
    ".jpg,.jpeg,.png,.gif,.mp4,.avi,.wmv,.webm,.gpx,.pdf,.svg,.pptx,ppt";
  // fileExplorerExtensions2 = ".jpg,.jpeg,.png,.gif,.mp4,.avi,.wmv,.webm,.gpx,.pdf,.svg,.pptx";

  render() {
    const uploadHidden =
      this.state.addMediaDroppedDown && parseInt(this.props.site) !== 0
        ? ""
        : "hidden";
    const filterHidden = this.state.addFilterDroppedDown ? "" : "hidden";
    const boxSelected = this.state.displayMedia === "box" ? " selected" : "";
    const listSelected = this.state.displayMedia === "list" ? " selected" : "";
    const selectedMedia = this.mediaContents.filter((e) => e.selected);

    return (
      <div
        className="media-display-parent"
        onDragEnter={this.dragEnterEvent}
        // onDragOver={this.dragEnterEvent}
      >
        <div className="media-display">
          <div className="header-panel">
            <div className="header-title">
              <h1>Media</h1>
            </div>
            <div className="add-new-media-parent">
              {selectedMedia.length > 0 ? (
                <div className="selected-options">
                  {!this.state.showEditImages && [
                    <div
                      key="delete-selected"
                      className="delete-selected"
                      onClick={() => {
                        if (
                          window.confirm(
                            `Are you sure you want to delete the ${
                              selectedMedia.length
                            } selected file${
                              selectedMedia.length > 1 ? "s" : ""
                            }?\nThis process cannot be undone.`
                          )
                        ) {
                          this.deleteMedia(selectedMedia.map((e) => e.id));
                        }
                      }}
                    >
                      <div className="text">Delete selected</div>
                    </div>,
                    <div
                      key="edit-selected"
                      className="edit-selected"
                      onClick={() => {
                        this.setState({ showEditImages: true });
                      }}
                    >
                      <div className="text">Edit selected</div>
                    </div>,
                  ]}
                  {this.props.onInsertClick ? (
                    <div
                      className="insert-selected"
                      onClick={() => {
                        const mediaToAdd = this.mediaContents.filter(
                          (e) => e.selected
                        );
                        this.props.onInsertClick(mediaToAdd);
                        mediaToAdd.forEach((element) => {
                          if (
                            !element.locations.find(
                              (e) => e.siteId === this.props.site
                            )
                          ) {
                            changeMediaLocation(element.id, this.props.site);
                          }
                        });
                      }}
                    >
                      <div className="text">Insert selected</div>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              ) : (
                ""
              )}
              <ToolTip
                offset={{ x: -100, y: 30 }}
                title=""
                delay={600}
                toolTipElements={<div>Add new media</div>}
                className={
                  "add-new-media" +
                  (parseInt(this.props.site) === 0 ? " hidden" : "")
                }
                onClick={() =>
                  this.setState({
                    addMediaDroppedDown: !this.state.addMediaDroppedDown,
                  })
                }
              >
                <div className="text">Add new media</div>
              </ToolTip>
            </div>
            <div
              className={
                "upload-media-parent" +
                (parseInt(this.props.site) === 0 ? " hidden" : "")
              }
            >
              <Dropzone
                onDrop={(acceptedFiles) => {
                  this.uploadFile(acceptedFiles);
                }}
              >
                {({ getRootProps, getInputProps }) => (
                  <section>
                    <div {...getRootProps()}>
                      <input
                        {...getInputProps()}
                        accept={this.fileExplorerExtensions}
                        maxLength={1}
                      />
                      <div className={"upload-box " + uploadHidden}>
                        <div className="text-content">
                          <div className="first">
                            Drop files or click to upload
                          </div>
                          <div className="second">Upload up to 10 files</div>
                        </div>
                      </div>
                    </div>
                  </section>
                )}
              </Dropzone>
            </div>
            <div className="search-parent">
              <div className="search">
                <VfiInputText
                  value={this.state.filterData.search}
                  placeholder="Search media..."
                  onChange={(e) => {
                    // this.setState({ searchValue: e.target.value });
                    this.setState({
                      filterData: {
                        ...this.state.filterData,
                        search: e.target.value,
                      },
                    });
                  }}
                  isSearch={true}
                />
              </div>
            </div>
            <div className="all-sort-and-filter">
              <ToolTip
                offset={{ x: 20, y: 30 }}
                priority={"right,bottom"}
                title=""
                delay={600}
                toolTipElements={<div>Select box view</div>}
                className={"image-show" + boxSelected}
                onClick={() => {
                  this.setState({ displayMedia: "box" });
                }}
              >
                <FontAwesomeIcon className="icon" icon={faThLarge} />
              </ToolTip>
              <ToolTip
                offset={{ x: 20, y: 30 }}
                priority={"right,bottom"}
                title=""
                delay={600}
                toolTipElements={<div>Select list view</div>}
                className={"list-show" + listSelected}
                onClick={() => {
                  this.setState({ displayMedia: "list" });
                }}
              >
                <FontAwesomeIcon className="icon" icon={faListUl} />
              </ToolTip>
              <ToolTip
                offset={{ x: -80, y: 30 }}
                priority={"right,bottom"}
                title=""
                delay={600}
                toolTipElements={<div>Apply filters</div>}
                className={"filter-button " + filterHidden}
                onClick={() =>
                  this.setState({
                    addFilterDroppedDown: !this.state.addFilterDroppedDown,
                    inputInfo: this.filterScreen(this.state.filterData),
                    inputInfoShown: true,
                  })
                }
              >
                <span className="text">Filter</span>
                <FontAwesomeIcon
                  className="slide-icon"
                  icon={faSlidersH}
                  flip={"vertical"}
                />
              </ToolTip>
            </div>
            {/* <div className={"filter-panel " + filterHidden}>
              <div className="media-type">
                <p>Media Type</p>
                <div>
                  <label>
                    <VfiCheckbox
                      checked={this.state.checkboxFilter.mediaType.includes(
                        "all"
                      )}
                      onChange={(e) => {
                        this.filterChange("mediaType.all", e.target.checked);
                      }}
                    />{" "}
                    All
                  </label>
                </div>
                <div>
                  <label>
                    <VfiCheckbox
                      checked={this.state.checkboxFilter.mediaType.includes(
                        "images"
                      )}
                      onChange={(e) => {
                        this.filterChange("mediaType.images", e.target.checked);
                      }}
                    />{" "}
                    Images
                  </label>
                </div>
                <div>
                  <label>
                    <VfiCheckbox
                      checked={this.state.checkboxFilter.mediaType.includes(
                        "videos"
                      )}
                      onChange={(e) => {
                        this.filterChange("mediaType.videos", e.target.checked);
                      }}
                    />{" "}
                    Videos
                  </label>
                </div>
                <div>
                  <label>
                    <VfiCheckbox
                      checked={this.state.checkboxFilter.mediaType.includes(
                        "gpx"
                      )}
                      onChange={(e) => {
                        this.filterChange("mediaType.gpx", e.target.checked);
                      }}
                    />{" "}
                    GPX files
                  </label>
                </div>
              </div>
              <div className="media-type">
                <p>Format</p>
                <div>
                  <label>
                    <VfiCheckbox
                      checked={this.state.checkboxFilter.format.includes("all")}
                      onChange={(e) => {
                        this.filterChange("format.all", e.target.checked);
                      }}
                    />{" "}
                    All
                  </label>
                </div>
                <div>
                  <label>
                    <VfiCheckbox
                      checked={this.state.checkboxFilter.format.includes(
                        "landscape"
                      )}
                      onChange={(e) => {
                        this.filterChange("format.landscape", e.target.checked);
                      }}
                    />{" "}
                    Landscape
                  </label>
                </div>
                <div>
                  <label>
                    <VfiCheckbox
                      checked={this.state.checkboxFilter.format.includes(
                        "portrait"
                      )}
                      onChange={(e) => {
                        this.filterChange("format.portrait", e.target.checked);
                      }}
                    />{" "}
                    Portrait
                  </label>
                </div>
                <div>
                  <label>
                    <VfiCheckbox
                      checked={this.state.checkboxFilter.format.includes(
                        "square"
                      )}
                      onChange={(e) => {
                        this.filterChange("format.square", e.target.checked);
                      }}
                    />{" "}
                    Square
                  </label>
                </div>
              </div>
            </div> */}
          </div>
          <div className="error-upload-message">
            {this.state.errorUploadMessage ?? ""}
          </div>
          {this.state.showEditImages ? (
            <MediaEditMany
              sites={this.sites}
              images={this.mediaContents.filter((e) => e.selected)}
              openEdit={this.props.openEdit}
              deleteMedia={this.deleteMedia}
              updateClick={this.props.onUpdateClick}
              setSiteRef={this.props.setSiteRef}
              changeMedia={(e) => {
                this.changeMedia(e);
              }}
              close={() => this.setState({ showEditImages: false })}
              closeSpecificImage={(id) => {
                this.mediaContents.find((e) => e.id === id).selected = false;
                this.setState({});
              }}
              showInputInfo={(v) =>
                this.setState({
                  inputInfo: v,
                  inputInfoShown: true,
                  addFilterDroppedDown: true,
                })
              }
            />
          ) : (
            ""
          )}
          <MediaList
            site={this.props.site}
            sites={this.sites}
            // mediaFiles={this.mediaContents}
            mediaFiles={this.getFilterMedia()}
            filesInUpload={this.state.uploadFiles}
            uploadPercent={this.state.uploadFilePercent}
            display={this.state.displayMedia} // list or box
            searchFilter={this.state.searchValue}
            // checkboxFilter={this.checkboxFilter}
            checkboxFilter={this.getFilterMedia}
            heartClicked={this.heartClicked}
            selectById={this.selectById}
            selectAll={this.selectAll}
            sortList={this.sortList}
            sortedBy={this.sortedBy}
            reverse={this.state.listReversed ?? false}
            refresh={this.refresh}
            deleteMedia={this.deleteMedia}
            openEdit={this.props.openEdit}
            showInSettings={this.state.showInSettings}
            updateClick={(e) => {
              this.changeMedia(e);
              if (this.props.onUpdateClick) {
                this.props.onUpdateClick(e);
              }
            }}
            insertClick={this.props.onInsertClick}
            setSiteRef={this.props.setSiteRef}
            showAmount={this.state.showAmount}
            singleMedia={this.props.singleMedia}
            showInputInfo={(v) =>
              this.setState({
                inputInfo: v,
                inputInfoShown: true,
                addFilterDroppedDown: true,
              })
            }
            filterData={this.state.filterData}
          />
        </div>
        {parseInt(this.props.site) !== 0 && (
          <div
            className={
              "drag-on-screen" + (this.state.dragEvent ? "" : " hidden")
            }
            // onDragEnter={this.dragEnterEvent}
            // onDragLeave={this.dragLeaveEvent}
            onDragLeave={this.dragLeaveEvent}
            onDrop={this.dragLeaveEvent}
          >
            <Dropzone
              onDrop={(acceptedFiles) => {
                this.uploadFile(acceptedFiles);
              }}
            >
              {({ getRootProps, getInputProps }) => (
                <section>
                  <div {...getRootProps()}>
                    <input
                      {...getInputProps()}
                      // accept=".jpg,.jpeg,.png,.gif,.mp4,.gpx"
                      accept={this.fileExplorerExtensions}
                      maxLength={1}
                    />
                    <div className={"upload-box"}>
                      <div className="content">
                        <FontAwesomeIcon icon={faArrowToTop} />
                        <div className="text">Drop to upload.</div>
                      </div>
                    </div>
                  </div>
                </section>
              )}
            </Dropzone>
          </div>
        )}
        <RightSideInfo
          info={this.state.inputInfo}
          isShowing={this.state.addFilterDroppedDown}
          close={() =>
            this.setState({
              inputInfoShown: false,
              addFilterDroppedDown: false,
            })
          }
        />
      </div>
    );
  }
}

export default MediaDisplay;
