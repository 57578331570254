import { useEffect, useRef, useState } from "react";
import CloseBtn from "../../buttons/closeBtn";
import styles from "./Card.module.scss";
import { overlayStore } from "../../OverlayStore";

interface Props {
  children: any;
  close: () => any;
}

export default function Card({ children, close }: Props) {
  const [loaded, setLoaded] = useState<boolean>(false);

  const ref = useRef<any>(null);
  const closeBtnRef = useRef<any>(null);

  useEffect(() => {
    closeBtnRef.current?.focus();

    setLoaded(true);
  }, []);

  return (
    <div ref={ref} className={`${styles.container} ${loaded && styles.loaded}`}>

      {/* Card */}

      <div className={styles.card}>

        {/* Close button */}

        <button
          className={styles.closeBtn}
          ref={closeBtnRef}
          onClick={() => {
            close();
          }}
        >
          <CloseBtn width={20} height={20} />
        </button>

        {/* JSX */}

        <div className={styles.content}>{children}</div>
      </div>
    </div>
  );
}
