import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPencil } from "@fortawesome/pro-light-svg-icons";

/**
 * Enable editing one-way enabler
 * (click element to allow editing, no uncheck funcitonality)
 *
 * @param     {object}  parent                  Parent object instance
 * @param     {string}  type                    String used as key in submit array
 *
 * @returns   jsx                               Enable editing element
 *
 * @author          Pætur Mortensen
 */
function EnableEditingCheck({ parent, type }) {
  const submit = parent.submit;
  const language = parent.language;

  // Set editing enabled className for this language box
  const editingEnabled = parent.submit.includes(type);

  return (
    <div
      className={`enable_editing_noMargin ${editingEnabled ? " enabled" : ""}`}
      onClick={(e) => {
        if (!submit.includes(type)) {
          submit.push(type);
        }
        parent.setState({});
      }}
    >
      {language.enable_editing}
      <FontAwesomeIcon icon={faPencil} />
    </div>
  );
}

export default EnableEditingCheck;
