import React, { useState } from "react";
import env from "../../../../environment.json";
import VfiCheckbox from "../../../../assets/VfiCheckbox/VfiCheckbox";
import axios from "axios";
import { showDate, capitalize } from "../../../../assets/helperFunctions";
import noImage from "../../../../assets/images/empty.jpg";
// import language from "./language.json";
// import "./WhatsonApprovalStatusButtons.scss";

export function approvalStatusChange(
  whatWhatson,
  id,
  value,
  afterFunc = () => void 0
) {
  axios
    .post(env.protocol + env.env + "/api/whatson/ApprovalStatusChange", {
      whatWhatson,
      id,
      value,
      userId: sessionStorage.getItem("vfiUser"),
    })
    .then((response) => {
      afterFunc(response);
    })
    .catch((error) => {
      console.error(error);
    });
}

export function IsArticleApprovalStatusButtons({ element }) {
  const [status, setStatus] = useState(element.approvalStatus);
  const lang = localStorage.getItem("language");

  return (
    <div className="approval-wrap">
      Not yet implemented
      {/* <div
        className={
          status === "approve" ? "approve chosen" : "approve"
        }
        onClick={() => {
          approvalStatusChange(
            whatWhatson.toLowerCase(),
            element.id,
            "approve",
            ()=>{setStatus("approve"); onChange({...element, approval_status:"approve"})}
          );
          // approvalStatusChange(element.id, "approve");
        }}
      >
        {language[lang].table_body.approval.approve}
      </div>
      <div
        className={
          status === "changes required"
            ? "changes-required chosen"
            : "changes-required"
        }
        onClick={() => {
          approvalStatusChange(
            whatWhatson.toLowerCase(),
            element.id,
            "changes required",
            ()=>{setStatus("changes required"); onChange({...element, approval_status:"changes required"})}
          );
          // approvalStatusChange(element.id, "changes required");
        }}
      >
        {language[lang].table_body.approval.changes_required}
      </div>
      <div
        className={status === "deny" ? "deny chosen" : "deny"}
        onClick={() => {
          approvalStatusChange(
            whatWhatson.toLowerCase(),
            element.id,
            "deny",
            ()=>{setStatus("deny"); onChange({...element, approval_status:"deny"})}
          );
          // approvalStatusChange(element.id, "deny");
        }}
      >
        {language[lang].table_body.approval.deny}
      </div> */}
    </div>
  );
}

function IsArticleContent(props) {
  const protocolAndHost = env.protocol + env.env;
  const languages = props.element.languages;

  return (
    <tr>
      <td
        className="file"
        onClick={() => {
          // GET THE ARTICLE TO PREPARE DISPLAY

          // axios
          // .post(
          //     env.protocol + env.env + "/api/whatson/events/GetEvent",
          //     { id: props.element.id }
          // )
          // .then((response) => {
          //     props.editContent(response.data);
          // })
          // .catch((error) => console.error(error));

          props.editContent(props.element.id);
        }}
      >
        {/* {props.element.id} */}
        {/* {Verify.extensionIsVideo(props.element.fileExtension) ?
        <div className="video-icon"><VideoThumbnail videoUrl={
          protocolAndHost +
          "/uploads/" +
          props.element.file_name +
          "." +
          props.element.fileExtension} snapshotAtTime={10} />
          <FontAwesomeIcon className="play-circle" icon={faCircle} />
          <FontAwesomeIcon className="play-icon" icon={faPlayCircle} /></div>: */}
        {props.element.submittedChanges ? (
          <div className="changesIcon">1</div>
        ) : (
          ""
        )}
        <img
          src={
            props.element.mainImage?.info?.id
              ? props.element.mainImage.thumbnail
              : noImage
          }
          alt="media view"
        />
        <div className="file-info">
          <div className="name">
            {languages.en ? languages.en.title : languages.fo.title}
          </div>
        </div>
      </td>
      <td className="author">
        <div className="author-name">
          {props.element.author.firstname + " " + props.element.author.lastname}
        </div>
      </td>
      <td className="region">
        <div className="what-region">
          {props.element.region ? props.element.region : ""}
        </div>
      </td>
      <td className="location">
        <div className="location-sites">
          {props.element.location ? props.element.location : ""}
        </div>
      </td>
      <td className="approval">
        {props.element.current_status === "published" &&
        props.approvalRequired ? (
          <IsArticleApprovalStatusButtons element={props.element} />
        ) : (
          capitalize(props.element.current_status)
        )}
      </td>
      <td className="date">
        <div className="the-date">{showDate(props.element.create_date)}</div>
      </td>
      {/* <td className="favourite">
      <FontAwesomeIcon
        className={props.element.hearted ? "hearted" : ""}
        icon={props.element.hearted ? faHeartSolid : faHeart}
        onClick={() => props.heartClicked(props.element.id)}
      />
    </td> */}
      <td className="check-select">
        {/* <label>
        <input
          type="checkbox"
          checked={props.element.selected}
          onChange={() => props.selectById(props.element.id)}
        />
        <div className="vfi-checkbox">
          <span className="left-shorter" />
          <span className="right-longer" />
        </div>
      </label> */}
        <VfiCheckbox
          checked={props.element.selected}
          onChange={() => props.selectById(props.element.id)}
        />
      </td>
    </tr>
  );
}

export default IsArticleContent;
