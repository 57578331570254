import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHeart } from "@fortawesome/pro-regular-svg-icons";
import { faHeart as faHeartSolid } from "@fortawesome/free-solid-svg-icons";
// import localHost from '../../../../src/assets/localHost';
import VfiCheckbox from "../../../assets/VfiCheckbox/VfiCheckbox";
import {
  faFilePdf,
  faFilePowerpoint,
  faPlayCircle,
  faRoute,
} from "@fortawesome/pro-light-svg-icons";
import { faCircle } from "@fortawesome/pro-solid-svg-icons";
import VideoThumbnail from "react-video-thumbnail";
import Verify from "../../../assets/VerifyMethods";
import { showDate } from "../../../assets/helperFunctions";
import env from "../../../environment.json";
import { GlobalToolTipController } from "../../ToolTips/GlobalToolTip";

function MediaContent(props) {
  const protocolAndHost = env.protocol + env.env;
  const fileExtension = props.element.fileExtension;

  if (props.isUploading === undefined || props.isUploading === false)
    return (
      <tr className="media-content">
        <td
          className="file"
          onClick={(e) => {
            props.expandSettings({ ...props.element, index: props.index });

            if (e.detail === 2) {
              // Double Click..
              if (props.insertClick) props.insertClick([props.element]);
            }
          }}
        >
          {Verify.extensionIsVideo(fileExtension) ? (
            <div className="video-icon">
              <VideoThumbnail
                videoUrl={
                  protocolAndHost +
                  "/uploads/" +
                  props.element.file_name +
                  "." +
                  fileExtension
                }
                snapshotAtTime={10}
              />
              <FontAwesomeIcon className="play-circle" icon={faCircle} />
              <FontAwesomeIcon className="play-icon" icon={faPlayCircle} />
            </div>
          ) : fileExtension.toLowerCase() === "gpx" ? (
            <div className="gpx-icon">
              <FontAwesomeIcon icon={faRoute} />
              <p>GPX</p>
            </div>
          ) : fileExtension.toLowerCase() === "pdf" ? (
            <div className="gpx-icon">
              <FontAwesomeIcon icon={faFilePdf} />
              <p>PDF</p>
            </div>
          ) : ["pptx", "ppt"].includes(fileExtension.toLowerCase()) ? (
            <div className="gpx-icon">
              <FontAwesomeIcon icon={faFilePowerpoint} />
              <p>{fileExtension.toUpperCase()}</p>
            </div>
          ) : (
            <div className="td-img-container">
              <img
                src={
                  protocolAndHost +
                  "/uploads/" +
                  props.element.file_name +
                  (fileExtension.toLowerCase() !== "gif" &&
                  fileExtension.toLowerCase() !== "svg"
                    ? "_thumbnail."
                    : ".") +
                  fileExtension
                }
                alt="media view"
                draggable={false}
              />
            </div>
          )}
          <div className="file-info">
            <div className="name">{props.element.fileName}</div>
            <div className="file-name">
              {props.element.file_name + "." + fileExtension}
            </div>
          </div>
        </td>
        <td className="author">
          <div className="author-name">
            {props.element.author.firstname +
              " " +
              props.element.author.lastname}
          </div>
        </td>
        <td className="location">
          <div className="location-sites">
            {props.element.sites
              ? props.element.sites.map((l, index) => {
                  return (
                    l.name +
                    (props.element.sites.length !== index + 1 ? ", " : "")
                  );
                })
              : ""}
          </div>
        </td>
        <td className="size">
          <div className="size-mb">
            {(parseInt(props.element.fileSize) / 1024 / 1024).toFixed(2)} MB
          </div>
          <div className="size-dimensions">{props.element.fileDimensions}</div>
        </td>
        <td className="date">{showDate(props.element.date)}</td>
        <td className="favourite">
          <FontAwesomeIcon
            className={props.element.hearted ? "hearted" : ""}
            icon={props.element.hearted ? faHeartSolid : faHeart}
            onClick={() => props.heartClicked(props.element.id)}
          />
        </td>
        <td className="check-select">
          {!props.singleMedia && (
            <VfiCheckbox
              checked={props.element.selected}
              onChange={() => props.selectById(props.element.id)}
            />
          )}
        </td>
      </tr>
    );
  else
    return (
      <tr>
        <td className="file">
          <div className="upload-progress"></div>
          <div className="file-info">
            <div className="name progress-bar">
              <progress
                value={props.uploadPercent ? props.uploadPercent : 0}
                max="100"
              />
            </div>
            <div className="file-name in-progress">
              {props.element.fileName}
            </div>
          </div>
        </td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
      </tr>
    );
}

export default MediaContent;
