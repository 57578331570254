import { Component } from "react";
import env from "../../../environment.json";
import "./WhatsonSidebar.scss";
import WhatsonOption from "./WhatsonOption";

const menuGroups = [
	{name:'Events', items:['Events', 'Places']}, 
	{name:'Tours', items:['Tours', 'Tour Operator']}, 
	{name:'Hiking', items:['Hiking']}
];

class WhatsonSidebar extends Component {
  constructor(props) {
    super(props);

    this.whatsonList = [];
    this.axios = require("axios");
  }

  componentDidMount() {
    this.axios
      .post(env.protocol + env.env + "/api/public/GetWhatsonSites.php", {
        userId: sessionStorage.getItem("vfiUser"),
      })
      .then((response) => {
        this.whatsonList = response.data !== "" ? response.data : [];
        this.setState({});

        if (Array.isArray(this.whatsonList)) {
          if (this.whatsonList[0]) {
            let index = 0;
            this.whatsonList.forEach((element, i) => {
              if (
                element.name === sessionStorage.getItem("whatson_site_name")
              ) {
                index = i;
              }
            });

            this.optionClicked({
              ...this.whatsonList[index],
              index: this.whatsonList[index].id,
            });
          }
        }
      })
      .catch((error) => {
        console.error(error);
      });
  }

  optionClicked(e) {
    this.whatsonList.forEach((element, i) => {
      this.whatsonList[i].selected = false;

      if (element.id === e.index) {
        this.whatsonList[i].selected = true;
      }
    });

    this.props.siteSelect(e);

    sessionStorage.setItem("whatson_site_name", e.name);
  }

  render() {
    return (
      <div className="whatson-sidebar">
        <h1>What's On</h1>

        <div
          className="list-parent"
          onDrop={this.drop}
          onDragOver={this.dragOver}
        >
          {menuGroups.map(group => {
						return (
							<div key={group.name}>
								<p className="small-gray">{group.name}</p>
								{group.items.map(item => {
									const menuItem = this.whatsonList[
										this.whatsonList.findIndex(element => element.name === item)];
									
									if(!menuItem) {
										return null;
									}
									
									return (
										<WhatsonOption
                			key={menuItem.id}
                			html={<div className="one-list-option">{menuItem.name}</div>}
                			onClick={() =>
                  			this.optionClicked({ ...menuItem, index: menuItem.id })
                			}
                			selected={menuItem.selected}
                			draggable={false}
              			/>
									);
								})}
							</div>
						)
					})}
        </div>
      </div>
    );
  }
}
export default WhatsonSidebar;
