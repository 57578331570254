import React, { useEffect, useState } from "react";
import { faSpinner } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import languages from "../language.json";
import './pages.scss';
const language = localStorage.getItem("language")
  ? localStorage.getItem("language")
  : "english";

export default function Pages(props) {
  const [pagesFetched, setPagesFetched] = useState(false);
  // const [mypages, setPages] = useState([{appearance:"Header"},{appearance:"Site menu"}, {appearance:"Footer"}]);

  const theLanguage = languages[language];
  const mypages = [
    { appearance: "Menu", label: theLanguage.site_options.header },
    { appearance: "Sites menu", label: theLanguage.site_options.sites_menu },
    { appearance: "Footer", label: theLanguage.site_options.footer },
    { appearance: "Themes", label: theLanguage.site_options.themes },
  ];

  useEffect(() => {
    // setPagesFetched(false);
    // setPages([]);
    // axios
    //     .post(env.protocol + env.env + "/api/public/GetApperances.php", {
    //         site: props.siteId
    //     })
    //     .then((response) => {
    //         console.log(response.data);

    //         setPages(response.data)
    setPagesFetched(true);
    //     });
  }, [props.siteName]);

  return (
    <div className={"pages-list" + (pagesFetched ? " loaded" : "")}>
      {!pagesFetched ? (
        <div className="load-icon">
          <FontAwesomeIcon icon={faSpinner} spin />
        </div>
      ) : (
        ""
      )}

      <div className="the-list">
        <div className="pages-headline top">Settings</div>{" "}
        {/*props.name.toUpperCase() */}
        {mypages.map((element, i) => {
          return (
            <div
              key={i}
              className={
                "page" +
                (element.appearance === props.pageSelected ? " selected" : "")
              }
              onClick={(e) => {
                props.editPage(element);
              }}
            >
              <span className="title">{element.label}</span>
            </div>
          );
        })}
      </div>
    </div>
  );
}
