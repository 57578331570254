import React from "react";
import "./ConfirmModal.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/pro-regular-svg-icons";

function ConfirmModal({ data, close, onConfirm }) {
  console.log(data);
  return (
    <div className="confirm-modal-background">
      <div className="confirm-modal">
        <div className="confirm-modal-container top-container">
          <div className="confirm-modal-message">
            Are you sure you want to revoke <span>{data.firstname}</span>'s
            access?
          </div>
          <div className="confirm-modal-close-btn-container">
            <button
              className="confirm-modal-close-btn"
              onClick={(e) => {
                e.preventDefault();
                close();
              }}
            >
              <FontAwesomeIcon icon={faXmark} />
            </button>
          </div>
        </div>
        <div className="confirm-modal-container bottom-container">
          <button
            className="modal-btn cancel-btn revoke-access"
            onClick={(e) => {
              e.preventDefault();
              close();
            }}
          >
            Cancel
          </button>
          <button
            className="modal-btn confirm-btn revoke-access"
            onClick={(e) => {
              e.preventDefault();
              onConfirm();
              close();
            }}
          >
            Revoke Access
          </button>
        </div>
      </div>
    </div>
  );
}

export default ConfirmModal;
