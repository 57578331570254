import React from "react";
import useUnload from "./UseUnload";

export default function MyUnloadComponent() {
  useUnload((e) => {
    e.preventDefault();
    e.returnValue = "";
  });

  return <div style={{ position: "absolute" }}></div>;
}
